import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

import { IntlTelInputComponent } from './intl-tel-input.component';
import { IntlTelInputDirective } from './intl-tel-input.directive';
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatSelectModule, MatInputModule, MatDividerModule, MatButtonModule, ReactiveFormsModule, TooltipModule, TranslateModule],
  declarations: [IntlTelInputComponent, IntlTelInputDirective],
  exports: [IntlTelInputComponent]
})
export class IntlTelInputModule { }
