import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of, combineLatest } from 'rxjs';
import { switchMap, catchError, concatMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State, ResetExtras, ResetSelectedRate } from '../store';

import { SessionService, CommonService, RateService, RateExtraService, LoggingService } from '../core/services';
import { LoaderLiteService } from '../components';

import { Session } from '../core/models/session.model';

import { LOG_TYPES, PAGES } from '../core/enums';
import { loadVehicles$, loadRateExtras$, loadRates$, loadRecommendedRates$, loadReservation$, loadSelectedExtras$, loadSelectedRate$ } from './common';

@Injectable()
export class VehicleExtrasGuard  {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
    private _commonService: CommonService,
    private _logger: LoggingService,
    private _rateService: RateService,
    private _rateExtService: RateExtraService,
    private _loaderService: LoaderLiteService,
    private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this._loaderService.show();
    let session: Session = this._sessionService.getSession();

    if ((!session || session == null) && route.params.recommendid && route.params.recommendid != "") {
      this._sessionService.setSession(
        {
          isModify: false
        }
      );

      session = this._sessionService.getSession();
    }

    if (!session?.selectedRateId && !route.params.recommendid) {
      this._loaderService.hide();
      this.redirectToRentACar(route.params.lang);
      return of(false);
    }

    return this.checkStore(route.params.lang, session, route.params.recommendid).pipe(
      switchMap((canLoad) => {
        this._commonService.sessionObs.next(true);
        this._loaderService.hide();
        if (canLoad) {
          return of(true);
        }
        else {
          this.redirectToRentACar(route.params.lang);
          return of(false);
        }

      }),
      catchError((err) => {
        this._loaderService.hide();
        this._logger.log(LOG_TYPES.ERROR, err.message);
        this.redirectToRentACar(route.params.lang);
        return of(false);
      })
    );
  }

  checkStore(lang: string, session: Session, recommendId: string): Observable<boolean> {
    if (!session.selectedRateId || session.selectedRateId === "") {
      return of(false);
    }

    let combined$: Observable<boolean>;
    if (recommendId && recommendId != "") {
      combined$ = combineLatest([
        loadVehicles$(lang, this.store),
        loadRecommendedRates$(lang, recommendId, this._sessionService, this.store).pipe(
          switchMap((loaded) => {
            if (!loaded) {
              return of(false);
            }

            const combined2$ = combineLatest([
              loadSelectedRate$(lang, session.selectedRateId, this.store),
              loadRateExtras$(lang, this._sessionService, this.store)
            ],
              (rateLoaded, extrasLoaded) => {
                return rateLoaded && extrasLoaded;
              }
            );

            return combined2$.pipe(
              switchMap((loaded) => {
                return of(loaded);
              })
            );
          })
        )
      ],
        (vehLoaded, ratesLoaded) => {
          return vehLoaded && ratesLoaded;
        }
      );
    }
    else {
      combined$ = combineLatest([
        loadVehicles$(lang, this.store),
        loadRates$(lang, this._sessionService.getSession(), this.store)
      ],
        (v, r) => v && r
      ).pipe(
        concatMap((loaded) => {
          if (!loaded) {
            return of(false);
          }

          return loadSelectedRate$(lang, session.selectedRateId, this.store).pipe(
            concatMap(_ => {
              return loadRateExtras$(lang, this._sessionService, this.store);
            })
          );
        })
      );
    }

    return combined$.pipe(
      switchMap((loaded) => {
        return combineLatest([
          loadSelectedExtras$(this._sessionService.getSession(), this.store, this._rateService, this._rateExtService),
          loadReservation$(lang, this._sessionService.getSession(), this.store)
        ],
          (_a, _b) => {
            return loaded;
          }
        );
      })
    );
  }

  private redirectToRentACar = (lang: string) => {
    this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, lang)).then(
      (status) => {
        if (status) {
          //this.store.dispatch(new ResetRates());
          this.store.dispatch(new ResetSelectedRate());
          this.store.dispatch(new ResetExtras());
        }
      }
    );
  };
}
