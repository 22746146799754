import { createReducer, on, Action } from '@ngrx/store';

import * as MyReservationsActions from './my-reservations.action';

import { IMyReservationsState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';
import { ResponseStatus, Reservation } from 'src/app/core/models';
import { HttpErrorResponse } from '@angular/common/http';

const initialState: IMyReservationsState = {
  reservations: [],
  statusCode: STORE_STATUS_CODES.INITIAL,
  responseStatus: null,
  processing: false,
  processed: false
};

const _myReservationsReducer = createReducer(
  initialState,

  on(MyReservationsActions.loadMyReservations, (state, { reset }) => {
    return {
      ...state,
      reservations: reset ? [] : state.reservations,
      statusCode: STORE_STATUS_CODES.INITIAL,
      responseStatus: null,
      processing: true,
      processed: false
    };
  }),

  on(MyReservationsActions.loadMyReservationsSuccess, (state, { payload }) => {
    if (payload && payload.length > 0) {
      return {
        ...state,
        reservations: payload,
        statusCode: STORE_STATUS_CODES.SUCCESS,
        responseStatus: { status: "OK", statusCode: 200 },
        processing: false,
        processed: true
      };
    }
    else {
      return {
        ...state,
        statusCode: STORE_STATUS_CODES.FAILED,
        responseStatus: { status: "No Content", statusCode: 204 },
        processing: false,
        processed: true
      };
    }
  }),

  on(MyReservationsActions.loadMyReservationsFail, (state, { payload }) => {
    let resStatus: ResponseStatus = {
      statusCode: 400, status: payload ? payload.toString() : "Bad Request"
    };

    if (payload && payload.error_description) {
      resStatus = {
        statusCode: 400, status: payload.error_description
      };
    }
    else if (payload instanceof HttpErrorResponse) {
      if (payload.error && payload.error.exceptionMessage) {
        resStatus = {
          statusCode: 500, status: payload.error.exceptionMessage
        };
      }
      else if (payload.error && payload.error.error_description) {
        resStatus = {
          statusCode: 400, status: payload.error.error_description
        };
      }
      else if (payload.error && payload.error.message) {
        resStatus = {
          statusCode: 400, status: payload.error.message
        };
      }
    }

    return {
      ...state,
      statusCode: STORE_STATUS_CODES.FAILED,
      responseStatus: resStatus,
      processing: false,
      processed: false
    }
  }),

  on(MyReservationsActions.updateMyReservations, (state, { payload }) => {
    if (payload && payload.length > 0 && state.reservations.length > 0) {
      const reservations = state.reservations.reduce((reservations: Reservation[], reservation: Reservation) => {
        const rez = payload.find(x => x.reservationNo === reservation.reservationNo);

        if (rez) {
          return [
            ...reservations,
            rez
          ];
        }
        else {
          return [
            ...reservations,
            reservation
          ];
        }
      }, []);

      return {
        ...state,
        responseStatus: { status: "OK", statusCode: 200 },
        statusCode: STORE_STATUS_CODES.FAILED,
        reservations: reservations,
        processing: false,
        processed: true
      };
    }

    return state;
  }),
);

export function myReservationsReducer(state: IMyReservationsState, action: Action) {
  return _myReservationsReducer(state, action);
}
