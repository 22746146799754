import { Injectable } from '@angular/core';

@Injectable()
export class CreditCardValidatorService {

  private defaultFormat = /(\d{1,4})/g;

  private readonly VISA = 'VI';
  private readonly MASTERCARD = 'MC';
  private readonly AMERICAN_EXPRESS = 'AX';
  private readonly DINERS_CLUB = 'DC';
  private readonly DISCOVER = 'DS';
  private readonly JCB = 'JCB';
  private readonly UNIONPAY = 'unionpay';
  private readonly MAESTRO = 'maestro';

  private cards = {
    [this.VISA]: {
      niceType: 'Visa',
      type: this.VISA,
      prefixPattern: /^4$/,
      exactPattern: /^4\d*$/,
      gaps: [4, 8, 12],
      lengths: [16, 18, 19],
      code: {
        name: 'CVV',
        size: 3
      }
    },
    [this.MASTERCARD]: {
      niceType: 'Mastercard',
      type: this.MASTERCARD,
      prefixPattern: /^(5|5[1-5]|2|22|222|222[1-9]|2[3-6]|27|27[0-2]|2720)$/,
      exactPattern: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[0-1]|2720)\d*$/,
      gaps: [4, 8, 12],
      lengths: [16],
      code: {
        name: 'CVC',
        size: 3
      }
    },
    [this.AMERICAN_EXPRESS]: {
      niceType: 'American Express',
      type: this.AMERICAN_EXPRESS,
      prefixPattern: /^(3|34|37)$/,
      exactPattern: /^3[47]\d*$/,
      isAmex: true,
      gaps: [4, 10],
      lengths: [15],
      code: {
        name: 'CID',
        size: 4
      }
    },
    [this.DINERS_CLUB]: {
      niceType: 'Diners Club',
      type: this.DINERS_CLUB,
      prefixPattern: /^(3|3[0689]|30[0-5])$/,
      exactPattern: /^3(0[0-5]|[689])\d*$/,
      gaps: [4, 10],
      lengths: [14, 16, 19],
      code: {
        name: 'CVV',
        size: 3
      }
    },
    [this.DISCOVER]: {
      niceType: 'Discover',
      type: this.DISCOVER,
      prefixPattern: /^(6|60|601|6011|65|64|64[4-9])$/,
      exactPattern: /^(6011|65|64[4-9])\d*$/,
      gaps: [4, 8, 12],
      lengths: [16, 19],
      code: {
        name: 'CID',
        size: 3
      }
    },
    [this.JCB]: {
      niceType: 'JCB',
      type: this.JCB,
      prefixPattern: /^(2|21|213|2131|1|18|180|1800|3|35)$/,
      exactPattern: /^(2131|1800|35)\d*$/,
      gaps: [4, 8, 12],
      lengths: [16, 17, 18, 19],
      code: {
        name: 'CVV',
        size: 3
      }
    },
    [this.UNIONPAY]: {
      niceType: 'UnionPay',
      type: this.UNIONPAY,
      prefixPattern: /^((6|62|62\d|(621(?!83|88|98|99))|622(?!06)|627[02,06,07]|628(?!0|1)|629[1,2])|622018)$/,
      exactPattern: /^(((620|(621(?!83|88|98|99))|622(?!06|018)|62[3-6]|627[02,06,07]|628(?!0|1)|629[1,2]))\d*|622018\d{12})$/,
      gaps: [4, 8, 12],
      lengths: [16, 17, 18, 19],
      code: {
        name: 'CVN',
        size: 3
      }
    },
    [this.MAESTRO]: {
      niceType: 'Maestro',
      type: this.MAESTRO,
      prefixPattern: /^(5|5[06-9]|6\d*)$/,
      exactPattern: /^(5[06-9]|6[37])\d*$/,
      gaps: [4, 8, 12],
      lengths: [12, 13, 14, 15, 16, 17, 18, 19],
      code: {
        name: 'CVC',
        size: 3
      }
    }
  };

  private readonly testOrder = [
    this.VISA,
    this.MASTERCARD,
    this.AMERICAN_EXPRESS,
    this.DINERS_CLUB,
    this.DISCOVER,
    this.JCB,
    this.UNIONPAY,
    this.MAESTRO
  ];

  getCreditCardType = (cardNumber: string): string => {
    if (cardNumber == "")
      return "";

    let result: string = "";

    cardNumber = cardNumber.replace(/\D/g, ''); // removes non-digit characters
    for (let i = 0; i < this.testOrder.length; i++) {
      let type = this.testOrder[i];
      let card = this.cards[type];

      if (card.exactPattern.test(cardNumber)) {
        result = type;
        break;
      }
    }

    return result;
  };

  getCreditCardMatchingTypes = (cardNumber: string): string[] => {
    if (cardNumber == "")
      return [];

    cardNumber = cardNumber.replace(/\D/g, ''); // removes non-digit characters

    let prefixResults: string[] = [];
    let exactResults: string[] = [];

    for (let i = 0; i < this.testOrder.length; i++) {
      let type = this.testOrder[i];
      let card = this.cards[type];

      if (cardNumber.length === 0) {
        prefixResults.push(card.type);
        continue;
      }

      if (card.exactPattern.test(cardNumber)) {
        exactResults.push(card.type);
      } else if (card.prefixPattern.test(cardNumber)) {
        prefixResults.push(card.type);
      }
    }

    return exactResults.length ? exactResults : prefixResults;
  };

  // using Luhn algorithm
  validateCreditCard = (cardNumber: string): boolean => {

    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(cardNumber)) return false;

    cardNumber = cardNumber.replace(/\D/g, "");

    const cardTypes = this.getCreditCardMatchingTypes(cardNumber);

    if (cardTypes && cardTypes.length > 0) {
      const card = this.cards[cardTypes[0]];

      if (!card) return false;
      else {

        // check length
        let isValidLength: boolean = false;
        for (let i = 0; i < card.lengths.length; i++) {
          if (cardNumber.length == card.lengths[i]) {
            isValidLength = true;
            break;
          }
        }

        // The Luhn Algorithm. It's so pretty.
        let isValidLuhn: boolean = false;
        let nCheck = 0, nDigit = 0, bEven = false;

        for (let n = cardNumber.length - 1; n >= 0; n--) {
          let cDigit = cardNumber.charAt(n),
            nDigit = parseInt(cDigit, 10);

          if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
          }

          nCheck += nDigit;
          bEven = !bEven;
        }

        isValidLuhn = (nCheck % 10) == 0;


        return isValidLength && isValidLuhn;
      }
    }

    return false;
  };

  getCodeType = (cardNumber: string): { name: string, size: number } => {

    let result: { name: string, size: number } = { name: '', size: 3 };

    if (cardNumber != "" && cardNumber.length > 0) {
      cardNumber = cardNumber.replace(/\D/g, "");
      for (let i = 0; i < this.testOrder.length; i++) {
        let type = this.testOrder[i];
        let card = this.cards[type];

        if (card.exactPattern.test(cardNumber)) {
          result = card.code;
          break;
        }
      }
    }

    return result;
  };

  formatCardNumber = (cardNumber: string): string => {
    var card, groups, upperLength, _ref;

    const type = this.getCreditCardMatchingTypes(cardNumber)[0];
    card = this.cards[type];
    if (!card) {
      return cardNumber;
    }
    upperLength = card.lengths[card.lengths.length - 1];
    cardNumber = cardNumber.replace(/\D/g, '');
    cardNumber = cardNumber.slice(0, +upperLength + 1 || 9e9);
    if (this.defaultFormat.global) {
      return (_ref = cardNumber.match(this.defaultFormat)) != null ? _ref.join(' ') : void 0;
    } else {
      groups = this.defaultFormat.exec(cardNumber);
      if (groups != null) {
        groups.shift();
      }
      return groups != null ? groups.join(' ') : void 0;
    }
  };

  formatBackCardNumber = (event): string => {
    if (event.meta) {
      return "";
    }
    if (event.which !== 8) {
      return "";
    }

    const cardNumber = event.target.value;

    if (/\d\s$/.test(cardNumber)) {
      event.preventDefault();
      return cardNumber.replace(/\d\s$/, '');
    }
    else if (/\s\d?$/.test(cardNumber)) {
      event.preventDefault();
      return cardNumber.replace(/\s\d?$/, '');
    }

    return "";
  };
}
