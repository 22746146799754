import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Event } from 'src/app/core/events/dialog.events'

@Injectable()
export class ChangePhoneDialogService {

  public readonly events: Observable<Event> = new Subject<Event>();

  constructor() {
  }

  triggerEvent(event: Event): void { (this.events as Subject<Event>).next(event); }
}
