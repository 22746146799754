import { RateExtra } from 'src/app/core/models';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

export interface IExtrasState {
  readonly entitiesSource: { [id: string]: RateExtra },
  readonly entities: { [id: string]: RateExtra },
  readonly statusCode: STORE_STATUS_CODES;
  readonly loaded: boolean;
  readonly loading: boolean;
}
