<header class="app-header" [ngClass]="{'mobile':isMobileDevice$ | async}" [@.disabled]="(isMobileDevice$ | async)">
  <nav class="navbar" [ngClass]="{'navbar-inverse':(isMobileDevice$ | async) === false,'navbar-default':isMobileDevice$ | async}">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-navbar-collapsible" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a [routerLink]="homeUrl" class="navbar-brand" *ngIf="isMobileDevice$ | async" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
          <img class="logo" src="{{'page-header.globecar-logo' | translate}}" alt="{{'page-header.globecar-logo-alt' | translate}}" />
        </a>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="bs-navbar-collapsible">
        <ul class="nav navbar-nav navbar-left">
          <li><a class="white" href="{{mainSiteUrl}}/{{appSettings?.language}}/" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''"><i class="far fa-arrow-alt-circle-left fa-lg fa-fw" aria-hidden="true"></i> {{'page-header.back-to-globe' | translate}}</a></li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li><a class="white" [routerLink]="homeUrl" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''"><i class="fas fa-home fa-lg fa-fw" aria-hidden="true"></i> {{'page-header.home' | translate}}</a></li>

          <li class="dropdown">
            <a href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" [ngClass]="{'orange icon':(isMobileDevice$ | async) === false}">
              <i class="fas fa-phone-alt fa-lg fa-fw" aria-hidden="true"></i><ng-container *ngIf="isMobileDevice$ | async"> {{'common.telephone' | translate}} <span class="caret"></span></ng-container>
            </a>
            <ul class="dropdown-menu">
              <li><a href="tel:+15147330988">{{'page-header.montreal' | translate}}: <span class="color-orange">(514) 733-0988</span></a></li>
              <li><a href="tel:+14169005944">Toronto: <span class="color-orange">(416) 900-5944</span></a></li>
              <li><a href="tel:+18665045623">{{'page-header.canada-and-usa' | translate}}: <span class="color-orange">1 (866) 504-5623</span></a></li>
              <li><a href="tel:+0033182880494">{{'page-header.france-and-europe' | translate}}: <span class="color-orange">00-331-8288-0494</span></a></li>
              <li><a href="sms:+15146001018"><i class="far fa-comment-dots fa-fw color-orange" aria-hidden="true"></i>&nbsp;{{'common.sms' | translate}}&nbsp;<span class="color-orange">(514) 600-1018</span></a></li>
            </ul>
          </li>

          <li>
            <a href="{{mainSiteUrl}}{{'page-header.contact-us-page-url' | translate}}" target="_blank" rel="noopener noreferrer" [ngClass]="{'orange icon':(isMobileDevice$ | async) === false}" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
              <i class="fas fa-envelope fa-lg fa-fw" aria-hidden="true"></i>
              <span class="hidden-lg hidden-md"> {{'common.email' | translate}}</span>
            </a>
          </li>

          <ng-container *ngIf="(user$ | async)?.firstName === null">
            <li><a [routerLink]="loginPageUrl" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''"><i class="fas fa-sign-in-alt fa-lg fa-fw" aria-hidden="true"></i> {{'page-header.login' | translate }}</a></li>
            <li><a class="white" [routerLink]="registerPageUrl" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''"><i class="fas fa-user-plus fa-fw" aria-hidden="true" style="font-size: 1.2em;"></i> {{'page-header.register' | translate }}</a></li>
          </ng-container>
          <ng-container *ngIf="user$ | async as user">
            <li *ngIf="user?.firstName" class="dropdown">
              <a href="#" class="dropdown-toggle white" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-user fa-fw" aria-hidden="true"></i> {{user.firstName}} <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a [routerLink]="'links.reserve-your-car' | translate" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
                    <i class="fas fa-car fa-fw" aria-hidden="true"></i>&nbsp;&nbsp;{{'common.book-veh' | translate }}
                  </a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a [routerLink]="myRezPageUrl" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
                    <i class="fas fa-calendar-alt fa-fw" aria-hidden="true"></i>&nbsp;&nbsp;{{'page-header.my-reservations' | translate }}
                  </a>
                </li>
                <li>
                  <a [routerLink]="myProfilePageUrl" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
                    <i class="fas fa-id-card fa-fw" aria-hidden="true"></i>&nbsp;&nbsp;{{'page-header.my-profile' | translate }}
                  </a>
                </li>
                <li>
                  <a [routerLink]="changePwdPageUrl" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
                    <i class="fas fa-key fa-fw" aria-hidden="true"></i>&nbsp;&nbsp;{{'page-header.change-pwd' | translate }}
                  </a>
                </li>
                <li role="separator" class="divider"></li>
                <li>
                  <a href="#" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''" onclick="return false;" (click)="logout();">
                    <i class="fas fa-sign-out fa-fw" aria-hidden="true"></i>&nbsp;&nbsp;{{'page-header.logout' | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>

          <li class="dropdown">
            <a href="#" class="dropdown-toggle" [ngClass]="{'orange':(isMobileDevice$ | async) === false}" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              <span class="flag flag-{{getCountryFlagCode(appSettings?.language)}} flag-icon"> </span>
              <span> {{('page-header.' + appSettings?.language) | translate | uppercase }}</span> <span class="caret"></span>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a (click)="onSwitchLanguage('en')" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
                  <span class="flag flag-ca flag-icon"> </span> {{'page-header.en' | translate | uppercase}}
                </a>
              </li>
              <li role="separator" class="divider"></li>
              <li>
                <a (click)="onSwitchLanguage('fr')" [attr.data-toggle]="(isMobileDevice$ | async) ? 'collapse' : ''" [attr.data-target]="(isMobileDevice$ | async) ? '#bs-navbar-collapsible' : ''">
                  <span class="flag flag-fr flag-icon"> </span> {{'page-header.fr' | translate | uppercase}}
                </a>
              </li>
            </ul>
          </li>

        </ul>
      </div><!-- /.navbar-collapse -->
    </div><!-- /.container-fluid -->
    <div [hidden]="(routeLoader$ | async) === false" class="route-loader-bar">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </nav>
  <div>
    <div class="container">
      <div class="row">
        <div *ngIf="(isMobileDevice$ | async) === false" class="col-md-3 col-lg-3">
          <a [routerLink]="homeUrl">
            <img src="{{'page-header.globecar-logo' | translate}}" alt="{{'page-header.globecar-logo-alt' | translate}}" />
          </a>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 text-right">
          <rez-nav [showRezNav]="showRezNav"></rez-nav>
        </div>
      </div>
    </div>
  </div>
</header>
