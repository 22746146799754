import { createSelector } from '@ngrx/store';
import { State, IVehicleState } from '../_base';

export const getVehiclesState = (state: State) => state.vehicles;

export const getVehiclesEntitiesSource = createSelector(getVehiclesState, (state: IVehicleState) => state.vehiclesSource);
export const getVehiclesEntities = createSelector(getVehiclesState, (state: IVehicleState) => state.vehicles);

export const getVehiclesSource = createSelector(getVehiclesEntitiesSource, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getVehicles = createSelector(getVehiclesEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getVehiclesCurrentLanguage = createSelector(getVehiclesEntities, (entities) => {
  const vehicle = Object.keys(entities).map(id => entities[id])[0];
  return vehicle ? vehicle.vehicleDesc[0].language : "";
});

export const getVehiclesStatus = createSelector(getVehiclesState, (state: IVehicleState) => state.statusCode);
export const getVehiclesLoading = createSelector(getVehiclesState, (state: IVehicleState) => state.loading);
export const getVehiclesLoaded = createSelector(getVehiclesState, (state: IVehicleState) => state.loaded);

export const getVehicleById = (id: string) => {
  return createSelector(getVehiclesEntities, (entities) => {
    return entities[id];
  });
};
