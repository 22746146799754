import { VehiclesAction, LOAD_VEHICLES, LOAD_VEHICLES_SUCCESS, LOAD_VEHICLES_FAIL, RESET_VEHICLES, UPDATE_VEHICLES } from './vehicle.action';
import { VehicleDto } from 'src/app/core/models';
import { IVehicleState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

const initialState: IVehicleState = {
  vehiclesSource: {},
  vehicles: {},
  statusCode: STORE_STATUS_CODES.INITIAL,
  loading: false,
  loaded: false
};

export function vehicleReducer(state = initialState, action: VehiclesAction): IVehicleState {
  switch (action.type) {
    case LOAD_VEHICLES: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_VEHICLES_SUCCESS: {

      const entities = action.payload.reduce((entities: { [id: string]: VehicleDto }, vehicle) => {
        return {
          ...entities,
          [vehicle.id]: {
            ...vehicle
          }
        };
      },
        {});

      return {
        ...state,
        vehiclesSource: JSON.parse(JSON.stringify(entities)),
        vehicles: entities,
        statusCode: STORE_STATUS_CODES.SUCCESS,
        loading: false,
        loaded: true
      };
    }

    case LOAD_VEHICLES_FAIL: {
      return {
        ...state,
        statusCode: STORE_STATUS_CODES.FAILED,
        loading: false,
        loaded: false,
      };
    }

    case UPDATE_VEHICLES: {

      const entities = action.payload.reduce((entities: { [id: string]: VehicleDto }, vehicle) => {
        return {
          ...entities,
          [vehicle.id]: {
            ...vehicle
          }
        };
      },
        {});

      return {
        ...state,
        vehicles: entities
      };
    }

    case RESET_VEHICLES: {
      return {
        ...state,
        ...initialState
      };
    }
  }

  return state;
}
