import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, first, tap, exhaustMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { RateExtra, Session } from 'src/app/core/models';

import { RateExtraService, SessionService } from 'src/app/core/services';

import { State } from '../';

import { getSelectedExtras } from './rate-extra.selectors';

import { LOAD_EXTRAS, LoadExtras, LoadExtrasSuccess, LoadExtrasFail, UPDATE_EXTRAS, UpdateExtras } from './rate-extra.action';

@Injectable()
export class ExtrasEffects {
  constructor(
    private actions$: Actions,
    private rateExtraService: RateExtraService,
    private _sessionService: SessionService,
    private store: Store<State>
  ) { }


  loadExtras$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_EXTRAS),
      map((action: LoadExtras) => action.payload),
      tap((params) => {
        const _session = this._sessionService.getSession();
        if (_session) {

          const session: Session = {
            ..._session,
            extrasLoadedFor: params.branchCode + params.vehicleCode + params.ageGroup
          };

          this._sessionService.setSession(session);
        }
      }),
      exhaustMap(params => {
        return this.rateExtraService
          .fetchExtras(params.lang, params.branchCode, params.vehicleCode, params.ageGroup, params.rateSpecificExtraCodes, params.discountedExtraCodes)
          .pipe(
            map((allExtras) => {
              let _extras = [...[], ...allExtras];
              if (params.rateDetail) {
                _extras = allExtras.reduce((extras: RateExtra[], extra: RateExtra) => {
                  if (params.rateSpecificExtraCodes && params.rateSpecificExtraCodes.split(",").find(x => x === extra.code) && (!extra.totalCharge || extra.totalCharge <= 0)) {
                    return [
                      ...extras,
                      {
                        ...extra,
                        totalCharge: this.rateExtraService.calcExtraTotalCharge(extra, extra.autoChecked, params.rateDetail),
                        totalChargeChildren: this.rateExtraService.calcExtraChildrenTotalCharge(params.rateDetail, extra)
                      }
                    ];
                  }
                  else if (!extra.isFree && params.preselectedExtraCodes && params.preselectedExtraCodes.find(x => x.split(':')[0] === extra.code) && (!extra.totalCharge || extra.totalCharge <= 0)) {
                    return [
                      ...extras,
                      {
                        ...extra,
                        totalCharge: this.rateExtraService.calcExtraTotalCharge(extra, true, params.rateDetail),
                        totalChargeChildren: this.rateExtraService.calcExtraChildrenTotalCharge(params.rateDetail, extra)
                      }
                    ];
                  }
                  else
                  {
                    return [
                      ...extras,
                      {
                        ...extra
                      }
                    ];
                  }

                }, []);
              }

              if (params.totalFreeMiles > 0) {
                _extras = _extras.reduce((extras: RateExtra[], extra: RateExtra) => {
                  if ((extra.extraMiles > 0 && (extra.extraMiles + params.totalFreeMiles) > params.mileageCapValue) || (extra.extraMiles === -1 && params.totalFreeMiles >= params.mileageCapValue)) {
                    return [...extras];
                  }
                  else {
                    return [
                      ...extras,
                      extra
                    ];
                  }
                }, []);
              }

              return new LoadExtrasSuccess(_extras, params.preselectedExtraCodes, params.isUpdateTranslationOnly);
            }),
            catchError(error => of(new LoadExtrasFail(error)))
          );
      })
    ));


  UpdateExtras$ = createEffect(() => this.actions$
    .pipe(
      ofType(UPDATE_EXTRAS),
      map((action: UpdateExtras) => action.payload),
      tap(() => {
        const _session = this._sessionService.getSession();
        if (_session) {
          this.store.pipe(
            select(getSelectedExtras),
            tap((extras) => {
              const _ext = extras.reduce((exts: { code: string; qty: number; children: { code: string; qty: number }[] }[], extra: RateExtra) => {

                const childExts = extra.children.reduce((childExts: { code: string; qty: number }[], childExtra: RateExtra) => {
                  if (childExtra.autoChecked) {

                    return [
                      ...childExts,
                      {
                        code: childExtra.code,
                        qty: childExtra.qty
                      }
                    ];

                  }
                  else {
                    return [...childExts];
                  }
                }, []);

                return [
                  ...exts,
                  {
                    code: extra.code,
                    qty: extra.qty,
                    children: childExts
                  }
                ];

              }, []);

              const session: Session = {
                ..._session,
                selectedExtrasCodes: _ext
              };

              this._sessionService.setSession(session);

            }),
            first()
          ).subscribe();
        }
      })
    ), { dispatch: false });
}
