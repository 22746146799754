import { createSelector } from '@ngrx/store';
import { State, IRateState } from '../_base';
import { RateDto } from 'src/app/core/models';

export const selectRatesState = (state: State) => state.rates;

export const getRatesResultSource = createSelector(selectRatesState, (state: IRateState) => state.ratesResultSource);
export const getRatesResult = createSelector(selectRatesState, (state: IRateState) => state.ratesResult);

export const getRateParams = createSelector(getRatesResult, (ratesRes) => ratesRes ? ratesRes.rateParams : null);
export const getRatesBaseCurrency = createSelector(getRatesResult, (ratesRes) => ratesRes ? ratesRes.baseCurrency : "");
export const getMandatoryCCEnabled = createSelector(getRatesResult, (ratesRes) => ratesRes ? ratesRes.isMandatoryCCEnabled : false);
export const getHighSeasonStatus = createSelector(getRatesResult, (ratesRes) => ratesRes ? ratesRes.isHighSeason : false);
export const getPickupWithinHours = createSelector(getRatesResult, (ratesRes) => ratesRes ? ratesRes.pickupWithinHours : 0);

export const getAvailableRatesEntities = createSelector(selectRatesState, (state: IRateState) => state.availableRates);
export const getRestrictedVehiclesByAgeEntities = createSelector(selectRatesState, (state: IRateState) => state.restrictedVehiclesByAge);
export const getLimitedAvailableRatesEntities = createSelector(selectRatesState, (state: IRateState) => state.limitedAvailableRates);
export const getSoldOutVehiclesEntities = createSelector(selectRatesState, (state: IRateState) => state.soldOutVehicles);

export const getAvailableRates = createSelector(getAvailableRatesEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getRestrictedVehiclesByAge = createSelector(getRestrictedVehiclesByAgeEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getLimitedAvailableRates = createSelector(getLimitedAvailableRatesEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getSoldOutVehicles = createSelector(getSoldOutVehiclesEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getRatesResponseStatus = createSelector(selectRatesState, (state: IRateState) => state.responseStatus);
export const getRatesStatus = createSelector(selectRatesState, (state: IRateState) => state.statusCode);
export const getRatesLoading = createSelector(selectRatesState, (state: IRateState) => state.loading);
export const getRatesLoaded = createSelector(selectRatesState, (state: IRateState) => state.loaded);
export const getRatesUpdatingStatus = createSelector(selectRatesState, (state: IRateState) => state.updating);
export const getRatesUpdatedStatus = createSelector(selectRatesState, (state: IRateState) => state.updated);

export const getRateById = (id: string) => {
  return createSelector(getAvailableRatesEntities, (entities) => {
    return entities[id];
  });
};

export const getHotDeals = (currVehType: string, currVehClassCode: string, selectedAgeGroup: string, pickupBranch: string, returnBranch: string, rateBeforeTaxes: number) => {
  return createSelector(getAvailableRates, (rates) => {
    currVehClassCode = currVehClassCode.toUpperCase();
    currVehType = currVehType.toUpperCase();
    const _rates = rates.filter(x =>
      (x.vehicle.vehicleType.toUpperCase() === currVehType || x.vehicle.code.toUpperCase() === "FQBR")
      && x.rateSummary.rateBeforeTaxes > rateBeforeTaxes
      && (x.vehicle.pickupAvailableAt.toUpperCase().split(",").includes(pickupBranch) && x.vehicle.returnAvailableAt.toUpperCase().split(",").includes(returnBranch))
      && x.vehicle.code.toUpperCase() !== currVehClassCode
      && !!x.vehicle.ageGroups.find(x => x.code === selectedAgeGroup))
      .sort((a, b) => { return a.rateSummary.rateBeforeTaxes - b.rateSummary.rateBeforeTaxes; })
      .reduce((_rates: RateDto[], rate, index) => {
        if (index < 3)
          return [..._rates, rate];
        else
          return [..._rates];
      }, []);
    return _rates;
  });
};

export const getRatesResultState = createSelector(
  getRatesResult,
  getRatesStatus,
  (result, status) => {
    return {
      ...result,
      reqStatus: status
    };
  }
);
