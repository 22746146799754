import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { ReservationService, RateService, SessionService} from 'src/app/core/services';

import { RateParams, Reservation, RatesResult, RateDto, ResponseStatus, Session } from 'src/app/core/models';

import { LOAD_RATES, LoadRates, LoadRatesSuccess, LoadRatesFail, UPDATE_RATES_TRANSLATION, UpdateRatesTranslation } from './rate.action';

@Injectable()
export class RatesEffects {
  constructor(
    private actions$: Actions,
    private rateService: RateService,
    private rezService: ReservationService,
    private _sessionService: SessionService
  ) { }


  loadRates$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_RATES),
      map((action: LoadRates) => action.payload),
      switchMap(rateParams => {
        return this.LoadRates(rateParams);
      })
    ));


  UpdateRatesTranslation$ = createEffect(() => this.actions$
    .pipe(
      ofType(UPDATE_RATES_TRANSLATION),
      map((action: UpdateRatesTranslation) => { return { ...action.rateParams, language: action.language } }),
      switchMap(rateParams => {
        return this.LoadRates(rateParams);
      })
    ));

  private LoadRates = (rateParams: RateParams): Observable<Action> => {
    const session = this.setSession(rateParams);
    return this.rateService
      .fetchRates(rateParams)
      .pipe(
        switchMap(result => {
          return this.getUpdatedRatesResult(result.data, session);
        }),
        map(result => new LoadRatesSuccess(result)),
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.error && err.error.statusCode) {
            const error: ResponseStatus = {
              statusCode: err.error.statusCode,
              status: err.error.status,
              data: err.error.data
            };
            return of(new LoadRatesFail(error));
          }
          else {
            return of(new LoadRatesFail(err));
          }
        })
      );
  };

  private getUpdatedRatesResult = (ratesResult: RatesResult, session: Session): Observable<RatesResult> => {
    const _availableRates: RateDto[] = ratesResult.availableRates ? ratesResult.availableRates : [];

    if (ratesResult && session.reservation && session.reservation.reservationNo) {
      return this.rezService.fetchReservation(ratesResult.rateParams.language, session.reservation).pipe(
        switchMap(rezResult => {
          const reservation: Reservation = rezResult.data;

          if (reservation && reservation.id) {
            return this.rezService.getRateFromReservation(ratesResult.rateParams.language, session.reservation).pipe(
              map((rezRateResult) => {
                if (rezRateResult) {
                  const isParamsEqual: boolean = this.isRateParamEqual(reservation.rateParams, ratesResult.rateParams);
                  const _rezRate: RateDto = rezRateResult.data as RateDto;
                  let newRates = _availableRates.filter(x => x.vehicle.code.toLowerCase() === _rezRate.vehicle.code.toLowerCase());

                  if (isParamsEqual && newRates && newRates.length <= 0) {
                    newRates = [_rezRate];
                  }

                  if (newRates && newRates.length > 0) {
                    let rezRates: RateDto[] = [];

                    if (isParamsEqual) {
                      const newRate = { ...newRates[0] };

                      const tempRate: RateDto = {
                        ..._rezRate,
                        extraRates: _rezRate.extraRates.filter(x => x.groupName.toLowerCase() === "rate"),
                        discountCodeResult: newRate && newRate.id ? newRate.discountCodeResult : null,
                        rateSummary: {
                          ..._rezRate.rateSummary,
                          totalDiscount: newRate && newRate.rateSummary ? newRate.rateSummary.totalDiscount : 0
                        },
                        isSelected: true
                      };

                      rezRates = [
                        ...[],
                        this.rateService.reCalcRate(tempRate)
                      ];

                      this._sessionService.setSession(
                        {
                          ...this._sessionService.getSession(),
                          selectedRateId: _rezRate.id
                        }
                      );
                    }
                    else {
                      rezRates = newRates.reduce((rates: RateDto[], newRate: RateDto) => {
                        return [
                          ...rates,
                          {
                            ...newRate,
                            isSelected: newRate.status === "available" ? true : false
                          }
                        ];
                      }, []);

                      const s = this._sessionService.getSession();
                      this._sessionService.setSession(
                        {
                          ...s,
                          selectedRateId: !!rezRates.find(x => x.id === s.selectedRateId) ? s.selectedRateId : ""
                        }
                      );
                    }

                    const availableRates = [
                      ...rezRates,
                      ..._availableRates.filter(x => x.vehicle.code.toLowerCase() !== _rezRate.vehicle.code.toLowerCase())
                    ];

                    const newRatesResult: RatesResult = {
                      ...ratesResult,
                      availableRates: availableRates
                    };

                    return newRatesResult;
                  }
                  else {
                    return ratesResult
                  }
                }
                else {
                  return ratesResult
                }
              })
            );
          }
          else {
            return of(ratesResult);
          }
        })
      );
    }
    else {
      return of(ratesResult);
    }
  };

  private setSession = (rateParams: RateParams): Session => {
    let _session: Session = this._sessionService.getSession();

    if (!_session) {
      _session = {
        rateParams: rateParams,
        modifiedOn: new Date()
      };
    }

    const extrasLoadedFor = this.isRateParamEqual(_session.rateParams, rateParams) ? _session.extrasLoadedFor : null; // force extras to load on step 2 if params changed

    const session: Session = {
      ..._session,
      rateParams: rateParams,
      extrasLoadedFor: extrasLoadedFor,
      modifiedOn: new Date()
    };

    this._sessionService.setSession(session);

    return session;
  };

  private isRateParamEqual = (rateParams1: RateParams, rateParams2: RateParams): boolean => {
    const prm1 = rateParams1 ? rateParams1.pickupBranch + rateParams1.pickupDate + rateParams1.pickupTime + rateParams1.returnBranch + rateParams1.returnDate + rateParams1.returnTime : "";
    const prm2 = rateParams2 ? rateParams2.pickupBranch + rateParams2.pickupDate + rateParams2.pickupTime + rateParams2.returnBranch + rateParams2.returnDate + rateParams2.returnTime : "";
    return prm1.toLowerCase() === prm2.toLowerCase();
  };
}
