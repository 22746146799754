import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { RecommendOfferDto } from "../../core/models";
import { CommonService, ConfigService, RecommendationEmailService } from 'src/app/core/services';

import { Store, select } from '@ngrx/store';
import { State, getRateParams } from 'src/app/store';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'recommend-dialog',
  templateUrl: './dlg-recommend.component.html',
  styleUrls: ['./dlg-recommend.component.scss']
})
export class RecommendDialogComponent implements OnInit {

  recommendationForm: UntypedFormGroup;
  rateId: string = '';

  isMobileDevice: boolean = false;

  showSpinner: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translate: TranslateService,
    private _commonService: CommonService,
    private _recEmailService: RecommendationEmailService,
    private fb: UntypedFormBuilder,
    private store: Store<State>)
  {
    this.isMobileDevice = data.isMobileDevice as boolean;
    this.rateId = data.rateId as string;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.setupForm();
  }

  private setupForm = () => {
    this.recommendationForm = this.fb.group({
      toEmail: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(ConfigService.EMAIL_REGEX)])
      ],
      fromName: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50)])
      ],
      fromEmail: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(ConfigService.EMAIL_REGEX)])
      ],
      optionalNotes: [null, { updateOn: 'blur' }],
      sendACopy: [false, { updateOn: 'blur' }]
    }, { updateOn: 'submit' });
  };

  onSubmit = (formValue) => {
    if (this.recommendationForm.valid) {

      this.showSpinner = true;
      this.dialogRef.disableClose = true;

      let recOfferDto: RecommendOfferDto = {
        toEmail: formValue.toEmail,
        fromName: formValue.fromName,
        fromEmail: formValue.fromEmail,
        optionalMessage: formValue.optionalNotes,
        sendMeACopy: formValue.sendACopy,
        rateId: this.rateId,
        rateParams: null
      };

      this.store.pipe(
        select(getRateParams),
        tap((params) => {
          if (params) {
            recOfferDto = {
              ...recOfferDto,
              rateParams: params
            };

            this._recEmailService.SendRecommendationEmail(recOfferDto).pipe(
              tap((result) => {
                if (result.data.statusCode === 20065) {
                  this._translate.get(["dlg-recommend.success-msg", "buttons.dismiss"]).pipe(
                    tap((tras) => {
                      this.showSpinner = false;
                      this.dialogRef.disableClose = false;
                      this._commonService.snackbarObs.next({ message: tras["dlg-recommend.success-msg"], action: tras["buttons.dismiss"], config: { duration: 10000 } });
                    }),
                    first()
                  ).subscribe();
                  this.closeDialog();
                }
              }),
              first()
            ).subscribe();
          }
        }),
        first()
      ).subscribe();
    }
  };
}
