import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';

import { TranslateModule } from '@ngx-translate/core';

import { TooltipModule } from 'src/app/shared/directives/tooltip';

import { CoverageDialogModule } from '../dlg-coverage';
import { RateExtraDialogModule } from '../dlg-rate-extra';

import { CoverageBlockComponent } from './coverage-block.component';

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    MatDialogModule,
    TranslateModule,
    TooltipModule,
    CoverageDialogModule,
    RateExtraDialogModule,
  ],
  declarations: [CoverageBlockComponent],
  exports: [CoverageBlockComponent]
})
export class CoverageBlockModule { }
