<div @fade *ngIf="loading$ | async as loading" class="loader-outer">
  <ng-container *ngIf="loading">
    <div class="faded-bg"></div>
    <div class="loader-inner">
      <div class="globe"></div>
      <div *ngIf="circlesType === 'progress1'" class="progress1">
        <div class="circle delay1"></div>
        <div class="circle delay2"></div>
        <div class="circle delay3"></div>
        <div class="circle delay4"></div>
        <div class="circle delay5"></div>
      </div>
      <div *ngIf="circlesType === 'progress2'" class="progress2">
        <div class="circle delay1"></div>
        <div class="circle delay2"></div>
        <div class="circle delay3"></div>
        <div class="circle delay4"></div>
        <div class="circle delay5"></div>
      </div>
      <div *ngIf="showText" class="loading-text">
        {{'common.pls-w8' | translate}}
      </div>
    </div>
  </ng-container>
</div>
