import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { DatePickerComponent } from './date-picker.component';
import { DatePickerDirective } from './date-picker.directive';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatDatepickerModule, TranslateModule, MatMomentDateModule],
  declarations: [DatePickerComponent, DatePickerDirective],
  exports: [DatePickerComponent],
  
})
export class DatePickerModule { }
