import { ExtrasAction, LOAD_EXTRAS, LOAD_EXTRAS_SUCCESS, LOAD_EXTRAS_FAIL, RESET_EXTRAS, UPDATE_EXTRAS, REMOVE_EXTRAS, EXTRAS_ACTION_COMPLETE } from './rate-extra.action';
import { RateExtra } from 'src/app/core/models';
import { IExtrasState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

const initialState: IExtrasState = {
  entitiesSource: {},
  entities: {},
  statusCode: STORE_STATUS_CODES.INITIAL,
  loaded: false,
  loading: false,
};

export function extrasReducer(state = initialState, action: ExtrasAction): IExtrasState {
  switch (action.type) {
    case LOAD_EXTRAS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case LOAD_EXTRAS_SUCCESS: {
      const rateExtras = action.payload;
      const preselectedExtraCodes = action.preselectedExtraCodes;
      const isUpdateTranslationOnly = action.isUpdateTranslationOnly;

      if (rateExtras && rateExtras.length > 0) {
        if (isUpdateTranslationOnly) {

          if (state.entitiesSource) {
            return {
              ...state,
              statusCode: STORE_STATUS_CODES.SUCCESS,
              loading: false,
              loaded: true,
              entities: getTranslatedEntities(state.entities, rateExtras),
              entitiesSource: getTranslatedEntities(state.entitiesSource, rateExtras)
            };
          }
          else {
            return state;
          }
        }
        else {
          const entities = rateExtras.reduce((entities: { [id: string]: RateExtra }, rateExtra) => {

            const children = rateExtra.children.reduce((children: RateExtra[], childExtra: RateExtra) => {

              const isPreSelectChild: boolean = preselectedExtraCodes.find(x => x.split(":")[0] == childExtra.code) ? true : false;

              if (isPreSelectChild)
                return [...children, { ...childExtra, autoChecked: true, qty: Number(preselectedExtraCodes.find(x => x.split(":")[0] == childExtra.code).split(":")[1]) }];
              else
                return [...children, childExtra];

            }, []);

            const isPreSelected: boolean = preselectedExtraCodes.find(x => x.split(":")[0] === rateExtra.code) ? true : false;

            const _autoChecked: boolean = isPreSelected || rateExtra.autoChecked;

            rateExtra = {
              ...rateExtra,
              autoChecked: _autoChecked,
              qty: isPreSelected ? Number(preselectedExtraCodes.find(x => x.split(":")[0] === rateExtra.code).split(":")[1]) : rateExtra.qty,
              children: children,
              totalCharge:
                (rateExtra.extraDesc[0].displayPrice && rateExtra.extraDesc[0].displayPrice.amountText != "") ?
                  rateExtra.extraDesc[0].displayPrice.amountText :
                  rateExtra.totalCharge && rateExtra.totalCharge > 0 ?
                    rateExtra.totalCharge :
                    0.0,
              totalChargeChildren: rateExtra.totalChargeChildren && rateExtra.totalChargeChildren > 0 ? rateExtra.totalChargeChildren : 0.0,
              isFaded: false,
              isCumulativeChild: false,
              isAnimationDisabled: true
            };

            return {
              ...entities,
              [rateExtra.id]: rateExtra
            };
          },
            {
              ...initialState.entities
            });

          return {
            ...state,
            statusCode: STORE_STATUS_CODES.SUCCESS,
            loading: false,
            loaded: true,
            entities: entities,
            entitiesSource: JSON.parse(JSON.stringify(entities))
          };
        }
      }
      else {
        return {
          ...initialState
        };
      }
    }

    case LOAD_EXTRAS_FAIL: {
      return {
        ...state,
        statusCode:STORE_STATUS_CODES.FAILED,
        loading: false,
        loaded: false,
      };
    }

    case RESET_EXTRAS: {
      return {
        ...initialState
      };
    }

    case UPDATE_EXTRAS: {

      const rateExtras = action.payload;

      const entities = rateExtras.reduce((entities: { [id: string]: RateExtra }, rateExtra) => {
        return {
          ...entities,
          [rateExtra.id]: rateExtra
        };
      },
        {
          ...state.entities
        });

      return {
        ...state,
        entities: entities
      };
    }

    case REMOVE_EXTRAS: {

      const rateExtras = action.payload;

      const entities = rateExtras.reduce((entities: { [id: string]: RateExtra }, rateExtra) => {

        const { [rateExtra.id]: removed, ...rest } = entities;

        return rest;
      },
        {
          ...state.entities
        });

      return {
        ...state,
        entities: entities
      };
    }

    case EXTRAS_ACTION_COMPLETE: {
      return state;
    }
  }

  return state;
}

function getTranslatedEntities(entities: { [id: string]: RateExtra }, rateExtras: RateExtra[]) {

  return Object.keys(entities).map(id => entities[id]).reduce((entities: { [id: string]: RateExtra }, rateExtra: RateExtra) => {

    const newExtra = rateExtras.find(x => x.code == rateExtra.code);
    if (newExtra) {

      const children = newExtra.children.reduce((children: RateExtra[], childExtra: RateExtra) => {
        const oldChildExtra = rateExtra.children.find(x => x.code.toLowerCase() == childExtra.code.toLowerCase());
        if (oldChildExtra) {
          const child: RateExtra = {
            ...oldChildExtra,
            extraDesc: childExtra.extraDesc
          };
          return [...children, child];
        }
        else {
          return [...children, childExtra];
        }

      }, []);

      const extra: RateExtra = {
        ...rateExtra,
        extraDesc: newExtra.extraDesc,
        children: children,
        totalCharge: (newExtra.extraDesc[0].displayPrice && newExtra.extraDesc[0].displayPrice.amountText != "") ? newExtra.extraDesc[0].displayPrice.amountText : rateExtra.totalCharge
      }

      return {
        ...entities,
        [extra.id]: extra
      };
    }
    else {
      return {
        ...entities,
        [rateExtra.id]: rateExtra
      };
    }
  },
    { ...initialState.entities }
  );
}
