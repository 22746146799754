import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';

import { Subscription, Observable, of } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { CommonService, RateExtraService } from 'src/app/core/services';

import { RateExtra, Currency, RateDetailDto } from 'src/app/core/models';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'rate-extra-item',
  templateUrl: './rate-extra-item.component.html',
  styleUrls: ['./rate-extra-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateExtraItemComponent implements OnInit {

  private allSubs: Subscription = new Subscription();

  @Output() checkChange = new EventEmitter<any>();

  @Input() baseCurrency: string = "";
  @Input() selectedCurrency: Currency = {} as Currency;
  @Input() extra: RateExtra = null;
  @Input() rateDetail: RateDetailDto;
  @Input() isLast: boolean = false;
  @Input() extraFormGroup: UntypedFormGroup;

  extraInfoSlide = "";

  private transSelectAge: string = "";
  private translations: any;

  private extraChildrenCollection: { [key: string]: string } = {};

  isMobileDevice$: Observable<boolean> = of(false);

  isCalc: boolean = false;
  isCalcChild: boolean = false;

  constructor(
    private _commonService: CommonService,
    private _rateExtraService: RateExtraService,
    private _translateService: TranslateService
  ) { }

  ngOnInit() {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

    const subs = this._translateService.get(['common.lang', 'step2-vehicle-extras.sel-age', 'common.free', 'common.hour', 'common.day', 'common.week', 'common.month', 'common.one-time']).subscribe(
      (translation) => {
        this.transSelectAge = translation['step2-vehicle-extras.sel-age'];
        this.translations = translation;
      }
    );

    this.allSubs.add(subs);

    if (this.extra && this.extra.parent == "") {

      // commented because of cumulativeParenttotal was calculated and passed here. furthermore it seems its not required anymore..
      //if (this.extra.autoChecked && !this.extra.isFree)
      //  this.extra = { ...this.extra, totalCharge: this.calcExtraTotalCharge(this.extra), totalChargeChildren: this.calcTotalChargeChildren(this.extra) };

      this.extraChildrenCollection = { ...this._rateExtraService.getSelectedChildren(this.extra) };

      this.extraInfoSlide = this.extra.autoApply || this.extra.autoChecked ? 'down' : "up";

      this.buildSubForm();
    }
  }

  private buildSubForm = () => {
    if (this.extra.children.length > 0) {
      let childExtrasFormArray = new UntypedFormArray([]);

      for (let i = 1; i <= this.extra.qty; i++) {

        let childValue: string = "";
        if (this.extraChildrenCollection["Child" + i])
          childValue = this.extraChildrenCollection["Child" + i];

        const formCtrl = new UntypedFormGroup({ ['Child' + i]: new UntypedFormControl(childValue, Validators.required) });
        childExtrasFormArray.push(formCtrl);
      }

      this.extraFormGroup.setControl("childExtras", childExtrasFormArray);
    }
    else {
      this.extraFormGroup.removeControl("childExtras");
    }
  };

  getExtraRatePerPeriod = (extra: RateExtra): string => {
    return this._rateExtraService.getExtraRatePerPeriod2(extra, this.rateDetail.ratePlan, this.selectedCurrency.code, this.translations);
  };

  getExtraRateTotalCharge = (extra: RateExtra): string => {
    if (CommonService.isNotANumber(extra.totalCharge)) {
      return extra.totalCharge;
    }
    else {
      const currencySymbol = getCurrencySymbol(this.selectedCurrency.code, "narrow", this.translations["common.lang"]);
      return formatCurrency(extra.totalCharge, this.translations["common.lang"], currencySymbol, this.selectedCurrency.code, "1.2") + " " + this.selectedCurrency.code
    }
  };

  getSelectedChildExtraText = (childId: string) => {
    if (this.extraChildrenCollection[childId]) {
      const childExtra = this.extra.children.find(x => x.code == this.extraChildrenCollection[childId]);
      if (childExtra) {
        return childExtra.extraDesc[0].shortDescription;
      }
      else {
        return this.transSelectAge;
      }
    }
    else
      return this.transSelectAge;
  };

  getSelectedChildExtraRate = (childId: string) => {
    if (this.extraChildrenCollection[childId]) {
      return this.getExtraRatePerPeriod(this.extra.children.find(x => x.code == this.extraChildrenCollection[childId]));
    }
    else
      return "";
  };

  convertNumberToArray = (qty: number): number[] => {
    if (qty && qty > 0) {
      return CommonService.convertNumberToArray(qty);
    }
    else {
      return [];
    }
  };

  changeQty = (operator: string, extraQty: number) => {
    const _extraMaxCount = this.getExtraMaxCount(this.extra);

    let _extraQty: number = 0;

    if (operator == "+") {
      _extraQty = extraQty + 1 < _extraMaxCount ? extraQty + 1 : _extraMaxCount;
      if (!this.extra.autoChecked) {
        this.onExtraClick(true, true);
      }
    }
    else if (operator == "-") {
      _extraQty = extraQty - 1 >= 1 ? extraQty - 1 : 0;
      this.extraChildrenCollection = { ...this._rateExtraService.removeChildExtraFromCollection(this.extra.code, "Child" + (_extraQty + 1)) };
    }

    if (_extraQty === 0 && this.extra.autoChecked) {
      this.onExtraClick(false);
      this.afterCollapse();
    }
    else if (extraQty === _extraQty) {
      return;
    }
    else {
      this.extra = { ...this.extra, qty: _extraQty };
      this.selectExtra(true);
    }

    this.buildSubForm();
  };

  onExtraClick = (isChecked: boolean, forceUpdate: boolean = false) => {

    if (this.extra.autoApply && !forceUpdate)
      return;

    if (isChecked == false && this.extra.parent == "" && Object.keys(this.extraChildrenCollection).length > 0) {
      this.extraChildrenCollection = { ...this._rateExtraService.removeAllExtraChildrenFromCollection(this.extra.code) };
    }

    if (isChecked) {
      this.extra = { ...this.extra, autoChecked: isChecked, qty: 1 };
      this.selectExtra(forceUpdate);
    }
    else {
      if (!isChecked && !(this.extra.children.length == 0 && this.extra.extraDesc[0].notes == '')) {
        this.extra = { ...this.extra, isAnimationDisabled: false };
      }
      else {
        this.extra = { ...this.extra, isAnimationDisabled: true };
      }

      const state: string = isChecked ? "down" : "up";
      if (this.extraInfoSlide != state)
        this.extraInfoSlide = state;
    }

    this.showSpinner();
  };

  onChildExtraClick = (childExtra: RateExtra, childId: string) => {
    this.extraChildrenCollection = { ...this._rateExtraService.addChildExtraToCollection(childExtra, childId) };
    this.selectExtra(true);
    this.buildSubForm();
    this.showChildSpinner();
  };

  private selectExtra = (forceUpdate: boolean = false) => {
    if (this.extra.autoApply && !forceUpdate)
      return;
    else {
      this.extra = { ...this.setExtraChildren(this.extra, this._rateExtraService.getSelectedChildren(this.extra)) };
      //this.extra = { ...extra, totalCharge: this.calcExtraTotalCharge(extra), totalChargeChildren: this.calcTotalChargeChildren(extra) };
      setTimeout(() => {
        this.checkChange.emit({ extra: this.extra });
      }, 300);

    }
  };

  //calcExtraTotalCharge = (parentExtra: RateExtra): number | string => {
  //  const extra = { ...parentExtra };
  //  return this._rateExtraService.calcExtraTotalCharge(extra, extra.autoChecked, this.rateDetail);
  //};

  //calcTotalChargeChildren = (parentExtra: RateExtra): number => {
  //  const extra = { ...parentExtra };

  //  let totalAmount: number = 0.0;

  //  if (extra.parent == "")
  //    totalAmount = this._rateExtraService.calcExtrasChildrenTotalCharge(extra, this.rateDetail);

  //  return totalAmount;
  //};

  //animationDone = ($eventData) => {
  //  if (!this.extra.autoApply && this.extra.autoChecked && $eventData.fromState != "void") {
  //    this.extra = { ...this.extra, autoChecked: false, qty: this.extra.maxCount > 1 ? 0 : 1 };
  //    this.selectExtra();
  //  }
  //};

  afterExpand = () => {
    //this.extra = { ...this.extra, autoChecked: true, qty: 1 };
    //this.selectExtra();
  };

  afterCollapse = () => {
    if (!this.extra.autoApply && this.extra.autoChecked) {
      this.extra = { ...this.extra, autoChecked: false, qty: this.extra.maxCount > 1 ? 0 : 1 };
      this.selectExtra();
    }
  };

  private showSpinner = () => {
    this.isCalc = true;
    setTimeout(() => {
      this.isCalc = false;
    }, 300);
  };

  private showChildSpinner = () => {
    this.isCalcChild = true;
    setTimeout(() => {
      this.isCalcChild = false;
    }, 300);
  };

  private setExtraChildren = (pExtra: RateExtra, extraChildren: { [key: string]: string }): RateExtra => {

    let parentExtra = { ...pExtra };

    if (parentExtra && parentExtra.parent == "" && parentExtra.children.length > 0) {

      /////// start -> reset children to default and set extra's children as per user selection ///////
      let children: string[] = [];
      if (extraChildren) {
        children = Object.keys(extraChildren).map(key => extraChildren[key]);
      }

      const childExtras = parentExtra.children.reduce((extras: RateExtra[], extra: RateExtra) => {
        const childQty = children.filter(x => x.toLowerCase() === extra.code.toLowerCase()).length;
        return [
          ...extras,
          {
            ...extra,
            autoChecked: childQty > 0,
            qty: childQty
          }
        ];
      }, []);
      /////// end ///////

      parentExtra = {
        ...parentExtra,
        children: childExtras
      };
    }

    return parentExtra;
  };

  getExtraMaxCount = (extra: RateExtra): number => {
    if (extra.maxCountToRentalDays)
      return Math.min(extra.maxCount, this.rateDetail.totalRentalDays);
    else
      return extra.maxCount;
  };

}
