import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleOtherFeaturesComponent } from './vehicle-other-features.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [VehicleOtherFeaturesComponent],
  exports: [VehicleOtherFeaturesComponent]
})
export class VehicleOtherFeaturesModule { }
