import { select, Store } from "@ngrx/store";
import { catchError, filter, map, Observable, of, Subscription, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { RateExtra, Reservation } from "../../core/models";
import { SessionService } from "../../core/services";
import { getExtrasSource, getExtrasStatus, LoadExtras, State, UpdateExtras } from "../../store";
import { timeout$ } from "./timeout";

const subsLoadingTimeout$: Subscription = new Subscription();

export const loadRateExtras4Rez$ = (lang: string, sessionService: SessionService, store: Store<State>, rez: Reservation): Observable<boolean> => {
  let session = sessionService.getSession();

  if (!session || session === null || rez.rateParams === null) {
    return of(false);
  }

  let extrasLoadedFor = "";
  return store.pipe(
    select(getExtrasStatus),
    filter(status => status && status.isLoading === false),
    tap((status) => {
      session = sessionService.getSession();
      extrasLoadedFor = (rez.rateParams.pickupBranch + rez.rate.vehicle.code + rez.rateParams.ageGroup).toLowerCase()
      if ((!status.isLoaded || lang !== status.currentLanguage || (session.extrasLoadedFor && session.extrasLoadedFor.toLowerCase() !== extrasLoadedFor)) && rez.rateParams.pickupBranch && status.statusCode !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(new LoadExtras(
          {
            lang: lang,
            branchCode: rez.rateParams.pickupBranch,
            vehicleCode: rez.rate.vehicle.code,
            ageGroup: rez.rateParams.ageGroup,
            rateSpecificExtraCodes: rez.rate.extraRates.filter(x => x.groupName.toLowerCase() === 'rate').map(x => x.code).join(),
            preselectedExtraCodes: rez.rate.extraRates.map(x => x.code + ":" + x.qty),
            rateDetail: rez.rate.rateDetail
          }
        ));

        subsLoadingTimeout$.add(timeout$("Request timed out in loading reservation rate extras.").subscribe());
      }
    }),
    filter((status) => (status.isLoaded && lang === status.currentLanguage && session.extrasLoadedFor && session.extrasLoadedFor.toLowerCase() === extrasLoadedFor) || status.statusCode === STORE_STATUS_CODES.FAILED),
    map((status) => {

      subsLoadingTimeout$.unsubscribe();

      if (status.statusCode === STORE_STATUS_CODES.FAILED) {
        return false;
      }

      store.pipe(
        select(getExtrasSource),
        tap((extras) => {
          const needToProcess = extras.filter(x => !x.autoApply && x.autoChecked).length > 0;

          if (needToProcess) {
            const selectedExtraCodes =
              rez.rate.extraRates
                .filter(x => x.groupName.toLowerCase() === "additional")
                .map(x => x.code);

            const rateExtras = extras
              .filter(x => x.groupName.toLowerCase() === "additional")
              .reduce((_extras: RateExtra[], _extra: RateExtra) => {
                const isSelExtra = !!selectedExtraCodes.find(x => x === _extra.code);

                if (!isSelExtra && _extra.autoChecked) {
                  return [
                    ..._extras,
                    {
                      ..._extra,
                      autoChecked: false
                    }
                  ];
                }
                return [..._extras];

              }, []);

            store.dispatch(new UpdateExtras(rateExtras));
          }
        }),
        take(1)
      ).subscribe();

      return status.isLoaded;
    }),
    take(1),
    catchError(_ => {
      return of(false);
    })
  );
};
