import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { VerifyCodeDialogComponent } from './dlg-verify-code.component';
import { MatButtonModule } from '@angular/material/button';

import { ContainerSpinnerModule } from '../container-spinner';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatInputModule, MatButtonModule, ReactiveFormsModule, ContainerSpinnerModule, TranslateModule],
    declarations: [VerifyCodeDialogComponent],
    exports: [VerifyCodeDialogComponent]
})
export class VerifyCodeDialogModule { }
