import { RentACarGuard } from './step1-rent-a-car.guard';
import { VehicleExtrasGuard } from './step2-vehicle-extras.guard';
import { CustomerDetailsGuard } from './step3-customer-details.guard';
import { ConfirmationGuard } from './step4-confirmation.guard';
import { CheckInGuard } from './step5-check-in.guard'
import { RezIsLoadedGuard } from './rez-is-loaded.guard';
import { RecommendationEmailGuard } from './recommendation-email.guard';

export const guards: any[] = [
  RentACarGuard,
  VehicleExtrasGuard,
  CustomerDetailsGuard,
  ConfirmationGuard,
  CheckInGuard,
  RezIsLoadedGuard,
  RecommendationEmailGuard
];

export * from './step1-rent-a-car.guard';
export * from './step2-vehicle-extras.guard';
export * from './step3-customer-details.guard';
export * from './step4-confirmation.guard';
export * from './step5-check-in.guard';
export * from './rez-is-loaded.guard';
export * from './recommendation-email.guard';
export * from './auth.guard';
export * from './anonymous-only-access.guard';
export * from './userid-token-check.guard';
