declare var grecaptcha: ReCaptcha;

export interface ReCaptcha {
  ///**
  // * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
  // * @param container The HTML element to render the reCAPTCHA widget. Specify either the ID of the container (string) or the DOM element itself.
  // * @param parameters An object containing parameters as key=value pairs, for example, {"sitekey": "your_site_key", "theme": "light"}. See @see render parameters.
  // * @param inherit Invisible reCAPTCHA only. Use existing data-* attributes on the element if the corresponding parameter is not specified.
  // * The values in parameters will take precedence over the attributes.
  // * @return the ID of the newly created widget.
  // */
  //render(container: (string | HTMLElement), parameters?: Parameters, inherit?: boolean): number;
  /**
   * Resets the reCAPTCHA widget.
   * @param opt_widget_id Optional widget ID, defaults to the first widget created if unspecified.
   */
  reset(opt_widget_id?: number): void;
  /**
   * Gets the response for the reCAPTCHA widget.
   * @param opt_widget_id Optional widget ID, defaults to the first widget created if unspecified.
   * @return the response of the reCAPTCHA widget.
   */
  getResponse(opt_widget_id?: number): string;
  /**
   * Programatically invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a div instead of a button.
   * @param opt_widget_id Optional widget ID, defaults to the first widget created if unspecified.
   */
  execute(opt_widget_id?: number): void;
}


export * from './recaptcha-v3.module';
export * from './recaptcha-v3.service';
