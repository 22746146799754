import { createSelector } from '@ngrx/store';
import { State, IUserState } from '../_base';

export const getUserState = (state: State) => state.user;

export const getUser = createSelector(getUserState, (state: IUserState) => state.user);

export const getUserStatus = createSelector(getUserState, (state: IUserState) => state.statusCode);
export const getUserResponseStatus = createSelector(getUserState, (state: IUserState) => state.responseStatus);
export const getUserProcessing = createSelector(getUserState, (state: IUserState) => state.processing);
export const getUserProcessed = createSelector(getUserState, (state: IUserState) => state.processed);

export const getCurrentUserLoadState = createSelector(
  getUserProcessing,
  getUserProcessed,
  getUserStatus,
  getUserResponseStatus,
  (processing, processed, statusCode, resStatus) => {
    return {
      processing: processing,
      processed: processed,
      statusCode: statusCode,
      responseStatus: resStatus
    };
  }
);

export const getCurrentUserState = createSelector(
  getUserProcessed,
  getUserStatus,
  getUserResponseStatus,
  getUser,
  (processed, statusCode, resStatus, user) => {
    return {
      isLoaded: processed,
      statusCode: statusCode,
      responseStatus: resStatus,
      user: user
    };
  }
);
