import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CapsLockStateDirective } from './caps-lock-state.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CapsLockStateDirective],
  exports: [CapsLockStateDirective]
})
export class CapsLockStateModule { }
