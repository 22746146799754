import { select, Store } from "@ngrx/store";
import { catchError, filter, Observable, of, Subscription, switchMap, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { Reservation, ResponseStatus, Session } from "../../core/models";
import { getReservationState, LoadReservation, LoadReservationById, State } from "../../store";
import { timeout$ } from "./timeout";

const subsLoadingTimeout$: Subscription = new Subscription();

export const loadReservation$ = (lang: string, session: Session, store: Store<State>, reservationId = ""): Observable<{ isLoaded: boolean; reservation: Reservation; responseStatus: ResponseStatus }> => {
  if ((!session || !session.reservation || session.reservation === null) && (!reservationId || reservationId === ""))
    return of({ isLoaded: false, reservation: null, responseStatus: null });

  return store.pipe(
    select(getReservationState),
    tap((rezState) => {

      let needFetch = !rezState.isProcessed;

      if (!needFetch) {
        needFetch = rezState.isProcessed && rezState.reservation && rezState.reservation.rateParams && rezState.reservation.rateParams.language !== lang;
      }

      if (needFetch && rezState.statusCode !== STORE_STATUS_CODES.FAILED) {
        if (reservationId && reservationId !== "") {
          store.dispatch(new LoadReservationById(lang, reservationId));
        }
        else {
          store.dispatch(new LoadReservation(lang, { reservationNo: session.reservation.reservationNo, lastName: session.reservation.lastName }));
        }
        subsLoadingTimeout$.add(timeout$("Request timed out in loading reservation.").subscribe());
      }
    }),
    filter((rezState) => rezState.isProcessed && rezState.reservation && rezState.reservation.rateParams && rezState.reservation.rateParams.language === lang || rezState.statusCode === STORE_STATUS_CODES.FAILED),
    take(1),
    switchMap((rezState) => {
      subsLoadingTimeout$.unsubscribe();

      if (rezState.responseStatus && rezState.responseStatus.statusCode === 40091) {
        return of({ isLoaded: rezState.isProcessed, reservation: null, responseStatus: rezState.responseStatus });
      }
      else if (rezState.statusCode === STORE_STATUS_CODES.FAILED) {
        return of({ isLoaded: false, reservation: null, responseStatus: rezState.responseStatus });
      }
      else {
        return of({ isLoaded: rezState.isProcessed, reservation: rezState.reservation, responseStatus: rezState.responseStatus });
      }
    }),
    catchError(_ => {
      return of({ isLoaded: false, reservation: null, responseStatus: null });
    })
  );
};
