import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NewsletterMember, ServiceResult } from '../models';

import { ConfigService } from "./config.service";

@Injectable()
export class NewsLetterService {

  constructor(private _configService: ConfigService, private http: HttpClient) { }

  GetNewsletterSubscription(listId: string, email: string): Observable<ServiceResult> {
    const url = this._configService.getNewsletterApiUrl('/member');

    const content = {
      listId: listId,
      emailAddress: email
    };

    return this.http.post<ServiceResult>(url, content)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  subscribeToNewsletter(member: NewsletterMember): Observable<ServiceResult> {
    const url = this._configService.getNewsletterApiUrl();

    return this.http.post<ServiceResult>(url, member)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  unsubscribeToNewsletter(member: NewsletterMember): Observable<ServiceResult> {
    const url = this._configService.getNewsletterApiUrl();

    return this.http.patch<ServiceResult>(url, member)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }
}
