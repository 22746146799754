import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { IntlTelInputModule } from '../intl-tel-input';
import { MatButtonModule } from '@angular/material/button';

import { ContainerSpinnerModule } from '../container-spinner';

import { ChangePhoneDialogComponent } from './dlg-change-phone.component';
import { ChangePhoneDialogService } from './dlg-change-phone.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatInputModule, IntlTelInputModule, MatButtonModule, ContainerSpinnerModule, TranslateModule],
  declarations: [ChangePhoneDialogComponent],
  exports: [ChangePhoneDialogComponent],
  providers: [ChangePhoneDialogService]
})
export class ChangePhoneDialogModule { }
