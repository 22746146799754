import { Component, OnInit, Inject, } from '@angular/core';
import { FormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { MatChipInputEvent } from "@angular/material/chips";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { EmailService, CommonService, ConfigService } from 'src/app/core/services';

import { NOTIFY_TYPES } from 'src/app/core/enums';
import { ErrorDto, ServiceResult} from 'src/app/core/models';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'email-dialog',
  templateUrl: './dlg-email.component.html',
  styleUrls: ['./dlg-email.component.scss']
})
export class EmailDialogComponent implements OnInit {

  sendEmailForm: UntypedFormGroup;

  confirmationNo: string = "";
  lastName: string = "";
  language: string = "";

  emailSentMsg: string = "";
  emailNotValid: string = "";

  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: { email: string, isValid: boolean }[] = [];

  notify$: BehaviorSubject<{
    notifyType: NOTIFY_TYPES,
    notification: string,
    validationErrors: ErrorDto[]
  }> = new BehaviorSubject({
    notifyType: NOTIFY_TYPES.Hidden,
    notification: "",
    validationErrors: []
  });

  isMobileDevice: boolean = false;

  showSpinner: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _email: EmailService,
    private _commonService: CommonService,
    private fb: UntypedFormBuilder,
    private _translateService: TranslateService
  ) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.confirmationNo = data.confirmationNo;
    this.lastName = data.lastName;
    this.language = data.language;
    this.emails.push({ email: data.email, isValid: true });
  }

  ngOnInit(): void {
    this.sendEmailForm = this.fb.group({
      emailFormControl: [null, Validators.required]
    }, { updateOn: 'submit' });

    this._translateService.get(['dlg-email.emails-not-valid', 'dlg-email.email-sent-msg']).pipe(
      tap(t => {
        this.emailSentMsg = t['dlg-email.email-sent-msg'];
        this.emailNotValid = t['dlg-email.emails-not-valid'];
      }),
      first()
    ).subscribe();
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      this.emails.push({ email: value.trim(), isValid: ConfigService.EMAIL_REGEX.test(value) });
    }

    if (input) {
      input.value = '';
    }
  }

  remove(email: { email: string, isValid: boolean }): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  onSendEmailClick = () => {
    this.notify$.next({ notifyType: NOTIFY_TYPES.Normal, notification: "", validationErrors: [] });

    if (this.isValid()) {

      this.showSpinner = true;
      this.dialogRef.disableClose = true;

      this._email.sendConfirmationEmail(this.language, this.confirmationNo, this.lastName, this.emails.map(x => x.email).join(";")).pipe(
        first(x => x && x.data && x.data != "")
      ).subscribe({
        next: (result: ServiceResult) => {
          if (result.data.statusCode == 20065) {
            this._commonService.snackbarObs.next({
              message: this.emailSentMsg,
              action: "",
              config: {duration: 10000, panelClass: ["success", "center"]}
            });

            this.closeDialog();
          } else {
            this.notify$.next({
              notifyType: NOTIFY_TYPES.Error,
              notification: result.data.statusCode,
              validationErrors: []
            });
          }
        },
        complete: () =>{
          this.showSpinner = false;
          this.dialogRef.disableClose = false;
        }
      });
    }
    else {
      this.notify$.next({ notifyType: NOTIFY_TYPES.Error, notification: this.emailNotValid, validationErrors: [] });
    }
  };

  private isValid = () => {
    let result: boolean = this.emails.length > 0;

    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails[i].isValid == false) {
        result = false;
        break;
      }
    }
    return result;
  };

  closeDialog(): void {
    this.dialogRef.close();
  }
}
