import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn, UntypedFormControl } from '@angular/forms';

import { filter, tap, take, first, exhaustMap, map } from 'rxjs/operators';

import { CommonService, SmsService, UserService } from 'src/app/core/services';
import { combineLatest, timer, BehaviorSubject, of, Observable } from 'rxjs';
import { NOTIFY_TYPES } from 'src/app/core/enums';
import { TranslateService } from '@ngx-translate/core';
import { ChangePhoneDto } from 'src/app/core/models';
import { ChangePhoneDialogService } from './dlg-change-phone.service';
import { SuccessEvent } from 'src/app/core/events/dialog.events';

@Component({
  selector: 'change-phone-dialog',
  templateUrl: './dlg-change-phone.component.html',
  styleUrls: ['./dlg-change-phone.component.scss']
})
export class ChangePhoneDialogComponent implements OnInit {

  isMobileDevice: boolean = false;
  inputForm: UntypedFormGroup;
  dlgType: string = 'ChangePhone';
  secRemaining: number;
  resendBtnValue: string;
  formattedPhoneNumber: string = "";
  showSpinner: boolean = false;
  pwdFieldType: string = 'password';

  code = new UntypedFormControl('', [Validators.required, Validators.pattern(/[\d]{0,6}/)]);

  private userId: string = "";
  private telephone: string = "";
  private currentPhone: string = "";
  private language: string = "en";

  isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  counter$: Observable<number>;

  private transGeneralErrMsg: string = "";
  private transChangePhoneSuccessMsg: string = "";
  private transInvalidPhone: string = "";
  private transInvalidCode: string = "";
  private transCodeSentMsg: string = "";
  private transResendCode: string = "";
  private transConfirmPhoneSuccessMsg: string = "";

  constructor(
    private _commonService: CommonService,
    private _smsService: SmsService,
    private _userService: UserService,
    private _dlgService: ChangePhoneDialogService,
    private _translate: TranslateService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isMobileDevice = this._commonService.IsMobileDevice;
    this.dlgType = CommonService.isNullOrWhiteSpace(data.dlgType as string) ? this.dlgType : data.dlgType as string;
    this.userId = data.userId as string;
    this.currentPhone = data.phone as string;
    this.formattedPhoneNumber = !!this.currentPhone ? "+" + this.currentPhone.replace(/[\+\s\(\)\-\.]+/g, "") : "";
    this.language = data.lang as string;
  }

  ngOnInit(): void {
    this.buildForm();

    this._translate.get(
      [
        "validations.general-error-msg",
        "dlg-change-phone.success-msg",
        "validations.invalid-phone-msg",
        "validations.invalid-code",
        "dlg-verify-code.code-sent",
        "dlg-verify-code.resend-code",
        "dlg-verify-code.success-msg"
      ],
      {
        phoneNo: this.formattedPhoneNumber
      }
    ).pipe(
      filter(t => t && t != ""),
      tap((t) => {
        this.transGeneralErrMsg = t["validations.general-error-msg"];
        this.transChangePhoneSuccessMsg = t["dlg-change-phone.success-msg"];
        this.transInvalidPhone = t["validations.invalid-phone-msg"];
        this.transInvalidCode = t["validations.invalid-code"];
        this.transCodeSentMsg = t["dlg-verify-code.code-sent"];
        this.transResendCode = t["dlg-verify-code.resend-code"];
        this.transConfirmPhoneSuccessMsg = t["dlg-verify-code.success-msg"];

        this.resendBtnValue = this.transResendCode;
      }),
      take(1)
    ).subscribe();

    this.counter$ = this._commonService.accountConfirmPhoneCounter$;
    this.counter$.pipe(
      tap((value) => {
        if (value === 0 && this.dlgType === "ConfirmPhone") {
          this.resendCodeTimer();
        }
      }),
      take(1)
    ).subscribe();
  }

  private buildForm = () => {
    this.inputForm = this.fb.group({
      currentPhone: [{ value: this.currentPhone, disabled: true }],
      newPhone: ['', Validators.compose([
        Validators.required,
        this.confirmUnmatchValidator('currentPhone'),
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],

    });
  };

  private confirmUnmatchValidator = (fieldName: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root instanceof UntypedFormGroup) {
        const orgValue: string = (control.root.get(fieldName) ? control.root.get(fieldName).value as string : "");//.replace(/(\+?)(\d+)(-)(\d.+)/, "$4");
        const confValue: string = control.value;

        if (!CommonService.isNullOrWhiteSpace(orgValue) && CommonService.isNullOrWhiteSpace(confValue)) {
          return { 'required': { value: true } };
        }
        else if (orgValue && orgValue != '' && confValue.toLowerCase() === orgValue.toLowerCase()) {
          return { 'matched': { value: confValue } };
        }
      }
      return null;
    };
  };

  onPhoneNumberChange = (eventData) => {
    this.telephone = eventData;
  };

  onSubmit = () => {
    if (this.inputForm.valid && !CommonService.isNullOrWhiteSpace(this.telephone)) {
      this.setSpinner(true);

      this._smsService.validatePhoneNumber(this.telephone).pipe(
        exhaustMap((isPhoneValid) => {
          if (isPhoneValid) {
            const request: ChangePhoneDto = {
              currentPhone: this.currentPhone,
              newPhone: this.telephone,
              password: this.inputForm.get('password').value
            };

            return combineLatest([
              this._userService.changePhone(request, this.language),
              timer(500), // To delay at least 500ms to see spinner animation.
            ],
              (result, _t) => result
            ).pipe(
              map((result) => {
                return result.data;
              })
            );
          }
          else {
            return of("InvalidPhone");
          }
        }),
        first(),
        tap((res) => {
          if (res.succeeded === true) {
            //this._commonService.bottomSheetObs.next({ message: this.transChangePhoneSuccessMsg, notifyType: NOTIFY_TYPES.Success, duration: 10000 });
            //this.closeDialog(this.inputForm.value.newPhone);
            this.currentPhone = this.telephone;
            this.formattedPhoneNumber = !!this.currentPhone ? "+" + this.currentPhone.replace(/[\+\s\(\)\-\.]+/g, "") : "";
            this._dlgService.triggerEvent(new SuccessEvent(this.currentPhone));
            this._translate.get(["dlg-change-phone.success-msg-n-sent", "dlg-verify-code.code-sent"], { phoneNo: this.formattedPhoneNumber }).pipe(
              filter(t => t && t != ""),
              tap((t) => {
                this.resendCodeTimer(t["dlg-change-phone.success-msg-n-sent"]);
                this.dlgType = "ConfirmPhone";
                this.transCodeSentMsg = t["dlg-verify-code.code-sent"];
              }),
              take(1)
            ).subscribe();
          }
          else if (res === "InvalidPhone") {
            this._commonService.bottomSheetObs.next({ message: this.transInvalidPhone, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
            this.setSpinner(false);
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
            this.setSpinner(false);
          }
        })
      ).subscribe({
        next: () => { },
        error: (e) => {
          this.setSpinner(false);
          if (e.code == "401") {
            this.closeDialog();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        }
      });
    }
    else {
      CommonService.updateAllFormFieldValueAndValidity(this.inputForm);
    }
  };

  onResendCodeClick = () => {
    this.resendCodeTimer();
  };

  private resendCodeTimer = (codeSentMsg: string = "") => {
    if (!CommonService.isNullOrWhiteSpace(this.currentPhone) && !CommonService.isNullOrWhiteSpace(this.userId)) {
      this.setSpinner(true);

      combineLatest([
        this._userService.confirmPhoneNumber(this.language),
        timer(1000), // To delay at least 1000ms to see spinner animation.
      ],
        (result, _t) => result
      ).pipe(
        filter(result => result && result.data && result.data != ""),
        take(1),
        tap((result) => {
          this.setSpinner(false);
          if (!!result.data.succeeded) {
            this._commonService.bottomSheetObs.next({ message: !CommonService.isNullOrWhiteSpace(codeSentMsg) ? codeSentMsg : this.transCodeSentMsg, notifyType: NOTIFY_TYPES.Success, duration: 10000 });
            this._smsService.startAccountConfirmPhoneCounter(true);
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        })
      ).subscribe(
        () => {
          this.setSpinner(false);
        },
        (errorResp) => {
          let isAlreadyVerified: boolean = errorResp.error && errorResp.error.statusCode === 40097 ? true : false;
          if (isAlreadyVerified) {
            this._commonService.bottomSheetObs.next({ message: this.transConfirmPhoneSuccessMsg, notifyType: NOTIFY_TYPES.Success, duration: 5000 });
            this.closeDialog();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
          this.setSpinner(false);
        }
      );
    }
  };

  onKeyPress = (event) => {
    if (CommonService.isControlKeyPress(event))
      return true;

    if (CommonService.isValidNumericKeyPress(event)) {
      return true;
    }
    return false;
  };

  onKeyUp = (event) => {
    if (!!event.target.value && event.target.value.length >= 6) {
      this.onVerifyCodeClick();
    }
  };

  onVerifyCodeClick = () => {
    this.code.markAsTouched({ onlySelf: true });
    if (!CommonService.isNullOrWhiteSpace(this.currentPhone) && !CommonService.isNullOrWhiteSpace(this.code.value) && !CommonService.isNullOrWhiteSpace(this.userId)) {
      this.setSpinner(true);

      combineLatest([
        this._userService.verifyPhoneNumber(this.userId, this.code.value),
        timer(1000), // To delay at least 1000ms to see spinner animation.
      ],
        (result, _t) => result
      ).pipe(
        filter(result => result && result.data && result.data != ""),
        take(1),
        tap((result) => {
          this.setSpinner(false);
          if (!!result.data.succeeded) {
            this._commonService.bottomSheetObs.next({ message: this.transConfirmPhoneSuccessMsg, notifyType: NOTIFY_TYPES.Success, duration: 5000 });
            this._smsService.stopAccountConfirmPhoneCounter();
            this.closeDialog();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transInvalidCode, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        })
      ).subscribe(
        () => {
          this.setSpinner(false);
        },
        (errorResp) => {
          if (errorResp.error && errorResp.error.statusCode === 40018) {
            this._commonService.bottomSheetObs.next({ message: this.transInvalidCode, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
          this.setSpinner(false);
        }
      );
    }
  };

  private setSpinner = (show: boolean = false) => {
    this.isProcessing$.next(show);
    this.dialogRef.disableClose = show;
  };

  closeDialog(): void {
    this.dialogRef.close(this.currentPhone);
  }
}
