import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { ContainerSpinnerModule } from '../container-spinner';

import { ConfirmAccEmailDialogComponent } from './dlg-confirm-acc-email.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, ContainerSpinnerModule, TranslateModule],
  declarations: [ConfirmAccEmailDialogComponent],
  exports: [ConfirmAccEmailDialogComponent]
})
export class ConfirmAccEmailDialogModule { }
