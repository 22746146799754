<div *ngIf="coverageExtras?.length > 0" class="coverage-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'coverage.add-cvrg' | translate}}</h4>
  <mat-dialog-content>
    <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
    <div class="text">
      {{'coverage.coverage-note' | translate}}
    </div>
    <div class="seperator"></div>
    <div class="seperator"></div>
    <rate-extra-list [rateExtras]="coverageExtras" [baseCurrency]="baseCurrency" [selectedCurrency]="selectedCurrency" [rateDetail]="rateDetail" [mileageCap]="mileageCap" [totalFreeMiles]="totalFreeMiles" [parentForm]="formGroup" (extrasUpdated)="onExtrasUpdated($event)" [ngStyle]="{'background-color': 'inherit'}"></rate-extra-list>
    <div class="seperator"></div>

  </mat-dialog-content>
  <mat-dialog-actions [align]="isMobileDevice ? 'center' : 'end'" class="actions">
    <button type="button" class="btn-main dlg-btn btn-orange" (click)="onAddClick()">{{'buttons.add' | translate}}</button>
    &nbsp;&nbsp;
    <button type="button" class="btn-main dlg-btn btn-white" [mat-dialog-close]="">{{'buttons.cancel' | translate}}</button>
  </mat-dialog-actions>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
