import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'nanorep',
  templateUrl: './nanorep.component.html'
})
export class NanorepComponent implements OnChanges {

  @Input() id: string;
  @Input() enabled: boolean;
  @ViewChild('element', { static: true }) public viewElement: ElementRef;

  htmlSnippet: string = '';
  public element: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {

    const isIdChanged: boolean = changes.id && changes.id.currentValue != changes.id.previousValue || changes.id.firstChange;

    if (this.enabled && isIdChanged)
      this.appendHTMLSnippetToDOM();
  }

  public appendHTMLSnippetToDOM() {
    const script = `<script>!function (t, e, o, c, n, a) { var s = window.nanorep = window.nanorep || {}; s = s[e] = s[e] || {}, s.apiHost = a, s.host = n, s.path = c, s.account = t, s.protocol = "https:" === location.protocol ? "https:" : "http:", s.on = s.on || function () { s._calls = s._calls || [], s._calls.push([].slice.call(arguments)) }; var p = s.protocol + "//" + n + c + o + "?account=" + t, l = document.createElement("script"); l.async = l.defer = !0, l.setAttribute("src", p), document.getElementsByTagName("head")[0].appendChild(l) }("globecar", "floatingWidget", "floating-widget.js", "/web/", "globecar.nanorep.co");</script>
                    <script>
                      nanorep.floatingWidget.on({
                        init: function () {
                          this.setKB('${this.id}');
                        }
                      });
                    </script>`;

    this.element = this.viewElement.nativeElement;
    const fragment = document.createRange().createContextualFragment(script);
    this.element.innerHTML = "";
    this.element.appendChild(fragment);
  }
}
