import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { Observable, of, Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { map, tap, first, concatMap, filter, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import {
  State, getExtrasSource, getExtraMilesTotal, getUnSelectedExtras, getExtrasByGroup, getSelectedExtrasSource, UpdateReservation, getReservationResponseStatus, UpdateExtras
} from 'src/app/store';

import { CommonService, RateService, RateExtraService } from 'src/app/core/services';
import { Reservation, RateExtra, Currency, AddReservationDto, ShortRate } from 'src/app/core/models';
import { CoverageDialogService, CoverageDialogComponent } from '../dlg-coverage';
import { RateExtraDialogService, RateExtraDialogComponent } from '../dlg-rate-extra';
import { AddEvent } from 'src/app/core/events/dialog.events';
import { UntypedFormGroup } from '@angular/forms';
import { REZ_ACTIONS } from 'src/app/core/enums';

@Component({
  selector: 'coverage-block',
  templateUrl: './coverage-block.component.html',
  styleUrls: ['./coverage-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoverageBlockComponent implements OnInit, OnDestroy {

  @Output() beforeUpdate = new EventEmitter<any>();
  @Output() afterUpdate = new EventEmitter<any>();

  @Input() lang: string = "en";
  @Input() reservation: Reservation = null;
  @Input() baseCurrency = "";
  @Input() selectedCurrency: Currency = {} as Currency;

  @Input() parentForm: UntypedFormGroup = null;

  readonly mainSiteUrl: string = CommonService.MainSiteUrl;

  private allSubs: Subscription = new Subscription();

  isMobileDevice$: Observable<boolean> = of(false);

  allExtras$: Observable<RateExtra[]>;
  unSelectedExtras$: Observable<RateExtra[]>;

  coveragePanel$: BehaviorSubject<string> = new BehaviorSubject("nocoverage");

  dialogRef$: BehaviorSubject<any> = new BehaviorSubject(null);

  private translations: any;

  translatedVehicleType: string = "car";

  constructor(
    private _commonService: CommonService,
    private _rateService: RateService,
    private _rateExtraService: RateExtraService,
    private _translateService: TranslateService,
    private _dlgCvrgService: CoverageDialogService,
    private _dlgRateExtraService: RateExtraDialogService,
    private dialog: MatDialog,
    private store: Store<State>
  ) { }

  ngOnInit(): void {

    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

    this.loadTranslations();

    if (this.reservation?.reservationNo) {
      this.allExtras$ = this.store.pipe(
        select(getExtrasSource)
      );

      this.unSelectedExtras$ = combineLatest([
        this.store.pipe(select(getExtraMilesTotal)),
        this.store.pipe(select(getUnSelectedExtras))
      ],
        (total, extras) => {
          return {
            extraMilesTotal: total,
            unselectedExtras: extras
          };
        }
      ).pipe(
        map(data => {
          const totalMilesIncluded = this.reservation.rate.totalFreeMiles + data.extraMilesTotal;
          const mileageCap = this.reservation.rate.vehicle.mileageCap.monthly * Math.max(1, this.reservation.rate.rateDetail.rentalMonths);
          const _extras: RateExtra[] = data.unselectedExtras.filter(x => x.cumulative.length == 0).reduce((extras: RateExtra[], extra: RateExtra) => {
            if (extra.extraMiles > 0 && (extra.extraMiles + totalMilesIncluded) > mileageCap) {
              return [...extras];
            }
            else {
              return [
                ...extras,
                extra
              ];
            }
          }, []);
          return _extras
        })
      );

      const subs = this.store.pipe(
        select(getExtrasByGroup("coverage")),
      ).subscribe({
        next: (x: RateExtra[]) => this.setCoverageBlock(x)
      });

      this.allSubs.add(subs);
    }
  }

  private loadTranslations() {
    const vehTypeKey: string = this.reservation?.rate?.vehicle?.vehicleType
      ? "common." + this.reservation.rate.vehicle.vehicleType.toLowerCase()
      : "common.car";

    const subs1 = this._translateService
    .get(['common.free', 'common.hour', 'common.day', 'common.week', 'common.month', 'common.one-time', vehTypeKey])
      .subscribe({
        next: t => {
          this.translations = t;
          this.translatedVehicleType = t[vehTypeKey];
        }
      });

    this.allSubs.add(subs1);
  };

  private setCoverageBlock(coverageExtras: RateExtra[]) {
    if (!coverageExtras?.length) {
      return;
    }

    const selectedExtras = [...coverageExtras.filter(x => x.autoChecked && !x.isFree)];
    const unSelectedExtras = [...coverageExtras.filter(x => !x.autoChecked && !x.isFree)];

    if (selectedExtras.length <= 0) {
      this.coveragePanel$.next("nocoverage");
    }
    else {
      if (selectedExtras.filter(x => x.cumulative.length > 0).length > 0)
        this.coveragePanel$.next("full");
      else if (selectedExtras.filter(x => x.code.substring(0, 3) == "PAP" && unSelectedExtras.map(y => y.code.substring(0, 3)).indexOf("CDW") != -1).length > 0)
        this.coveragePanel$.next("addcdw");
      else if (selectedExtras.filter(x => x.code.substring(0, 3) == "CDW" && unSelectedExtras.map(y => y.code.substring(0, 3)).indexOf("PAP") != -1).length > 0)
        this.coveragePanel$.next("addpap");
      else if (selectedExtras.filter(x => x.code.substring(0, 3) == "CDW" && unSelectedExtras.map(y => y.code.substring(0, 3)).indexOf("PAP") == -1).length > 0)
        this.coveragePanel$.next("nopap");
      else
        this.coveragePanel$.next("");
    }
  };

  onAddCoverageClick = (rez: Reservation) => {
    const dialogRef = this.dialog.open(CoverageDialogComponent, {
      maxWidth: 698,
      width: this._commonService.deviceBreakpoint == 'xs' ? '100vw' : '',
      height: 'auto',
      panelClass: this._commonService.deviceBreakpoint == 'xs' ? 'mobile-dialog' : '',
      data: {
        isMobileDevice: this._commonService.IsMobileDevice,
        baseCurrency: this.baseCurrency,
        selectedCurrency: this.selectedCurrency,
        rateDetail: rez.rate.rateDetail,
        mileageCap: rez.rate.vehicle.mileageCap,
        totalFreeMiles: rez.rate.totalFreeMiles
      }
    });

    this.dialogRef$.next(dialogRef);

    this._dlgCvrgService.events.pipe(
      tap((event) => {
        if (event instanceof AddEvent) {
          const extras: RateExtra[] = event.data;

          if (extras && extras.length > 0) {
            this.addExtras(rez, extras);
          }
        }
      }),
      first()
    ).subscribe();
  };

  addExtras = (rez: Reservation, updatedExtras: RateExtra[]) => {
    if (rez && rez.reservationNo && updatedExtras && updatedExtras.length > 0) {
      this.store.pipe(
        select(getSelectedExtrasSource),
        concatMap((selectedExtrasSrc) => {

          let selExtras: RateExtra[] = [...updatedExtras.filter(x => x.autoChecked || x.autoApply)];

          if (selectedExtrasSrc && selectedExtrasSrc.length > 0) {
            const prevSelectExtras = [...[], ...selectedExtrasSrc.filter(x => updatedExtras.map(y => y.code).indexOf(x.code) === -1)];
            selExtras = selExtras.reduce((extras: RateExtra[], extra: RateExtra) => {
              return [...extras, extra];
            }, [...prevSelectExtras]);
          }

          selExtras = this._rateExtraService.getUpdatedSelectedExtras(selExtras, rez.rate.rateDetail, rez.rate.vehicle.mileageCap, rez.rate.totalFreeMiles);

          this.beforeUpdate.emit();

          return this.updateRez(rez, selExtras).pipe(
            tap((isUpdated) => {
              if (isUpdated) {
                this.store.dispatch(new UpdateExtras(selExtras));
                this.afterUpdate.emit();

                if (!!this.dialogRef$.getValue()) {
                  this.dialogRef$.getValue().close();
                }
              }
            })
          );

        }),
        first()
      ).subscribe();
    }
  };

  onAddExtraClick = (extraCode: string, allExtras: RateExtra[], rez: Reservation) => {

    const extra = allExtras.find(x => x.code == extraCode);

    const dialogRef = this.dialog.open(RateExtraDialogComponent, {
      maxWidth: 698,
      width: this._commonService.deviceBreakpoint == 'xs' ? '100vw' : '',
      height: 'auto',
      panelClass: this._commonService.deviceBreakpoint == 'xs' ? 'mobile-dialog' : '',
      data: {
        isMobileDevice: this._commonService.IsMobileDevice,
        baseCurrency: this.baseCurrency,
        selectedCurrency: this.selectedCurrency,
        selectedExtra: extra,
        rateDetail: rez.rate.rateDetail,
        mileageCap: rez.rate.vehicle.mileageCap,
        totalFreeMiles: rez.rate.totalFreeMiles
      }
    });

    this.dialogRef$.next(dialogRef);

    this._dlgRateExtraService.events.pipe(
      tap((event) => {
        if (event instanceof AddEvent) {
          const extras: RateExtra[] = event.data;
          if (extras && extras.length > 0) {
            this.addExtras(rez, extras);
          }
        }
      }),
      first()
    ).subscribe();
  };

  getExtraRatePerPeriod = (extra: RateExtra, ratePlan: string): string => {
    return this._rateExtraService.getExtraRatePerPeriod2(extra, ratePlan, this.selectedCurrency.code, this.translations);
  };

  getExtraRatePerPeriodByExtraCode = (extraCode: string, extras: RateExtra[], ratePlan: string): string => {
    const extra = extras.find(x => x.code.substring(0, 3) == extraCode);
    if (extra)
      return this.getExtraRatePerPeriod(extra, ratePlan);
    else
      return "";
  };

  private updateRez = (rez: Reservation, updatedExtras: RateExtra[]): Observable<boolean> => {

    const updatedRate = this._rateService.getUpdatedRate(rez.rate, updatedExtras) as ShortRate;

    const updatedRez: AddReservationDto = {
      reservationNo: rez.reservationNo,
      customer: rez.customer,
      customerComments: rez.customerComments,
      paymentDetail: rez.paymentDetail,
      rate: {
        vehicleCode: updatedRate.vehicle.code,
        rateDetail: updatedRate.rateDetail,
        extraRates: updatedRate.extraRates,
        discount: updatedRate.discount,
        taxes: updatedRate.taxes,
        rateSummary: updatedRate.rateSummary,
        totalFreeMiles: updatedRate.totalFreeMiles
      },
      rateParams: rez.rateParams,
      airline: rez.airline,
      flightNo: rez.flightNo,
      isVehicleForWork: rez.isVehicleForWork,
      customerCompany: rez.customerCompany,
      emailNewsletters: rez.emailNewsletters,
      receiveConfirmSms: false,
      isCheckedIn: rez.isCheckedIn,
      isTermsAccepted: rez.isTermsAccepted,
      userSignup: false,
      password: "",
      action: REZ_ACTIONS.Update
    };

    this.store.dispatch(new UpdateReservation(updatedRez, this.baseCurrency));

    return this.store.pipe(
      select(getReservationResponseStatus),
      filter(status => status && status.statusCode && status.statusCode > 0),
      map(status => status.statusCode == 20000 ? true : false),
      take(1)
    );
  };

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }
}
