<div *ngIf="branch?.code?.toUpperCase() === 'EVO' || branch?.code?.toUpperCase() === 'YNG'" class="info-block" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
  <div *ngIf="branch?.code.toUpperCase() === 'EVO'" class="box info">
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-4">
        <h4>
          <em>{{'step4-confirm.branch-info' | translate}}</em>
        </h4>
        <div class="seperator3x hidden-xs"></div>
        <div class="seperator3x hidden-xs hidden-sm"></div>
        <div class="seperator2x hidden-sm hidden-md hidden-lg"></div>
        <div class="text" [innerHTML]="'step4-confirm.branch-info-imp-det-okt' | translate"></div>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8">
        <div class="seperator2x hidden-sm hidden-md hidden-lg"></div>
        <strong>{{'common.pickups-returns' | translate}}</strong>
        <img style="width:100%;" src="assets/images/confirmation/okt-pickup.png" alt="{{'common.pickups-returns' | translate}}" />
      </div>
    </div>
  </div> <!-- end OKT info block-->

  <div *ngIf="branch?.code.toUpperCase() === 'YNG'" class="box info">
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
        <h4>
          <em>{{'step4-confirm.branch-info' | translate}}</em>
        </h4>
        <div class="seperator2x hidden-xs hidden-sm"></div>
        <div class="seperator2x hidden-xs hidden-sm"></div>
        <div class="seperator2x hidden-sm hidden-md hidden-lg"></div>
        <div class="text text-justify" [innerHTML]="'step4-confirm.branch-info-imp-det-yng' | translate"></div>
      </div>
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="seperator2x hidden-sm hidden-md hidden-lg"></div>
            <strong>{{'common.pickups' | translate}}</strong>
            <img style="width:100%;" src="assets/images/confirmation/yng-pickup.png" alt="{{'common.pickups' | translate}}" />
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="seperator2x hidden-sm hidden-md hidden-lg"></div>
            <strong>{{'common.returns' | translate}}</strong>
            <img style="width:100%;" src="assets/images/confirmation/yng-return.png" alt="{{'common.returns' | translate}}" />
          </div>
        </div>
      </div>
    </div>
  </div> <!-- end YNG info block-->
</div>
