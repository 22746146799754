import { Directive, ElementRef, PLATFORM_ID, Inject, Input, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { timer } from 'rxjs';
import { tap, first } from 'rxjs/operators';

@Directive({
  selector: '[autofocus]'
})
export class AutoFocusDirective implements OnInit {

  private focus = true;

  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (this.focus && isPlatformBrowser(this.platformId)) {
      timer(100).pipe(
        tap(() => {
          this.elementRef.nativeElement.focus();
        }),
        first()
      ).subscribe();
    }
  }

  @Input() set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
