export const environment = {
  isLocalhost: false,
  sessionTimeout: 30,
  requestTimeout: 20000,
  production: true,

  mainSiteUrl: "https://www.globecar.dev",
  rezEngineSiteUrl: "https://reserve.globecar.dev",
  cdnUrl: "https://cdn.globecar.dev",

  google: {
    apiKey : "AIzaSyCmEO-_ndKnUfC_59HbGhZ7_663-MdAIO0",
    captcha : "6LdFpMcUAAAAAMVx5mYgJpHXlv--LOZ0MtuUfxDc"
  },

  authApi: {
    hostUrl: "https://api.globecar.dev", // change it to https://id.globecar.dev when the service is ready
    clientAppId: "5e3d9e2de04321683cd4abad",
    key: "62b0b0e9bc717c21126bc94b",
    endPoints: {
      userApi: '/v1/account',
    }
  },

  rentalApi: {
    hostUrl: "https://api.globecar.dev",
    clientAppId: "5e3d9e2de04321683cd4abad",
    key: "62b0b0e9bc717c21126bc94b",
    endPoints: {
      branchesApi: '/v1/rentalbranches',
      countriesApi: '/v1/countries',
      currencyRates: '/v1/currency-rates',
      ip2Location: '/v1/ip-to-location',
      logsApi: '/v1/logs',
      newsletterSubscription: '/v1/newsletter-subscription',
      notificationApi: '/v1/notifications',
      ratesApi: '/v1/rentalrates',
      rateExtrasApi: '/v1/rateextras',
      recommendationEmailApi: '/v1/recommendationemail',
      reservationsApi: '/v1/reservations',
      securityApi: '/v1/security',
      smsServiceApi: '/v1/sms',
      vehiclesApi: '/v1/vehicles',
      validatorApi: '/v1/validator',
    }
  },
  appInsights: {
    instrumentationKey: "501cae98-b663-4e68-bdfd-7e6227f996a8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
