import { Component, Input } from '@angular/core';

@Component({
  selector: 'prices-legal-text',
  templateUrl: './prices-legal-text.component.html',
  styleUrls: ['./prices-legal-text.component.scss']
})
export class PricesLegalTextComponent {

  @Input() text: string = "";
}
