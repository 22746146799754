import { RecommendationEmailAction, LOAD_RECOMMENDATION_EMAIL, LOAD_RECOMMENDATION_EMAIL_SUCCESS, LOAD_RECOMMENDATION_EMAIL_FAIL } from './recommendation-email.action';
import { IRecommendationEmailState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

const initialState: IRecommendationEmailState = {
  RecommendationEmail: null,
  statusCode: STORE_STATUS_CODES.INITIAL,
  loading: false,
  loaded: false
};

export function recommendationEmailReducer(state = initialState, action: RecommendationEmailAction): IRecommendationEmailState {
  switch (action.type) {
    case LOAD_RECOMMENDATION_EMAIL: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_RECOMMENDATION_EMAIL_SUCCESS: {
      return {
        ...state,
        RecommendationEmail: action.payload,
        statusCode: STORE_STATUS_CODES.SUCCESS,
        loading: false,
        loaded: true
      };
    }

    case LOAD_RECOMMENDATION_EMAIL_FAIL: {
      return {
        ...state,
        RecommendationEmail: null,
        statusCode: STORE_STATUS_CODES.FAILED,
        loading: false,
        loaded: false,
      };
    }
  }

  return state;
}
