import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { CoverageDialogComponent } from './dlg-coverage.component';
import { CoverageDialogService } from './dlg-coverage.service';

import { RateExtraModule } from '../rate-extra';
import { ContainerSpinnerModule } from '../container-spinner';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatCheckboxModule, RateExtraModule, ContainerSpinnerModule, TranslateModule],
    declarations: [CoverageDialogComponent],
    exports: [CoverageDialogComponent],
    providers: [CoverageDialogService]
})
export class CoverageDialogModule { }
