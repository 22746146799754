import { IAppSettingsState } from './IAppSettingsState';
import { ICurrencyRatesState } from './ICurrencyRatesState';
import { ICountryState } from './ICountryState';
import { IBranchState } from './IBranchState';
import { IVehicleState } from './IVehicleState';
import { IRateState } from './IRateState';
import { IExtrasState } from './IExtrasState';
import { ISelectedRateState } from './ISelectedRateState';
import { IReservationState } from './IReservationState';
import { IRecommendationEmailState } from './IRecommendationEmailState';
import { IUserState } from './IUserState';
import { IMyReservationsState } from './IMyReservationsState';

export interface State {
  readonly appSettings: IAppSettingsState;
  readonly currencyRates: ICurrencyRatesState;
  readonly countries: ICountryState;
  readonly branches: IBranchState;
  readonly vehicles: IVehicleState;
  readonly rates: IRateState;
  readonly extras: IExtrasState;
  readonly selectedRate: ISelectedRateState;
  readonly reservation: IReservationState;
  readonly recommendationEmail: IRecommendationEmailState;
  readonly user: IUserState;
  readonly myReservations: IMyReservationsState;
}
