import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { Observable, of } from 'rxjs';

import { CommonService } from 'src/app/core/services';

import { BranchDto } from 'src/app/core/models';

@Component({
  selector: 'info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBlockComponent implements OnInit {

  @Input() branch: BranchDto = null;

  isMobileDevice$: Observable<boolean> = of(false);

  constructor(private _commonService: CommonService) {
  }

  ngOnInit(): void {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

  }
}
