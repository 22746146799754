import { createSelector } from '@ngrx/store';
import { State, ICountryState } from '../_base';

export const getCountriesState = (state: State) => state.countries;

export const getCountryEntities = createSelector(getCountriesState, (state: ICountryState) => state.countries);

export const getCountries = createSelector(getCountryEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getCountryStates = (countryCode: string) => {
  return createSelector(getCountryEntities, (entities) => {
    return entities[countryCode] ? entities[countryCode].states : [];
  });
};

export const getCountriesCurrentLang = createSelector(getCountryEntities, (entities) => {
  const country = Object.keys(entities).map(id => entities[id])[0];
  return !!country ? country.countryDesc[0].language : "";
});

export const getCountriesStatus = createSelector(getCountriesState, (state: ICountryState) => state.statusCode);
export const getCountriesLoading = createSelector(getCountriesState, (state: ICountryState) => state.loading);
export const getCountriesLoaded = createSelector(getCountriesState, (state: ICountryState) => state.loaded);

export const getCountriesLoadStatus = createSelector(
  getCountriesLoaded,
  getCountriesStatus,
  (loaded, status) => {
    return {
      loaded: loaded,
      reqStatus: status
    };
  }
);
