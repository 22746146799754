import {
  AppSettingsAction,
  SET_APP_SETTINGS,
  SET_APP_SETTINGS_AVAILABLE_LANGUAGES,
  SET_APP_SETTINGS_LANGUAGE,
  SET_APP_SETTINGS_BASE_CURRENCY,
  SET_APP_SETTINGS_SELECTED_CURRENCY,
  SET_APP_SETTINGS_SHOW_REZ_WIDGET,
  SET_APP_SETTINGS_WIDGET_FLASH_ANIMATION_STATE
} from './app-settings.action';

import { IAppSettingsState } from '../_base';

const initialState: IAppSettingsState = {
  availableLangs: ['en', 'fr'],
  language: "en",
  baseCurrency: "CAD",
  selectedCurrency: { code: "CAD", displayText: "CAD $", description: "Canadian Dollars" },
  showRezWidget: false,
  widgetFlashAnimationState: ""
};

export function appSettingsReducer(state = initialState, action: AppSettingsAction): IAppSettingsState {
  switch (action.type) {
    case SET_APP_SETTINGS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case SET_APP_SETTINGS_AVAILABLE_LANGUAGES: {
      return {
        ...state,
        availableLangs: [...action.languages]
      };
    }

    case SET_APP_SETTINGS_LANGUAGE: {
      return {
        ...state,
        language: action.language
      };
    }

    case SET_APP_SETTINGS_BASE_CURRENCY: {
      return {
        ...state,
        baseCurrency: action.currencyCode
      };
    }

    case SET_APP_SETTINGS_SELECTED_CURRENCY: {
      return {
        ...state,
        selectedCurrency: action.currency
      };
    }

    case SET_APP_SETTINGS_SHOW_REZ_WIDGET: {
      return {
        ...state,
        showRezWidget: action.showWidget
      };
    }

    case SET_APP_SETTINGS_WIDGET_FLASH_ANIMATION_STATE: {
      return {
        ...state,
        widgetFlashAnimationState: action.animationState
      };
    }
  }

  return state;
}
