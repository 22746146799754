<div class="change-branch-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'dlg-change-branch.title' | translate}}<hr /></h4>
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <mat-dialog-content>
    <div class="row table-heading">
      <span>{{'dlg-change-branch.note' | translate}}</span>
    </div>
    <div class="row table-row-seperator">
    </div>
    <div class="row table-row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div><strong>{{'common.pickup' | translate}}:</strong></div>
        <mat-radio-group class="radio-group" [value]="pickupBranchCode" (change)="onPickupChange($event)">
          <mat-radio-button class="radio-button" *ngFor="let br of pickupBranches" [value]="br.code">
            {{br.addresses[0].name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row table-row-seperator">
    </div>
    <div class="row table-row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div><strong>{{'common.return' | translate}}:</strong></div>
        <mat-radio-group class="radio-group" [value]="returnBranchCode" (change)="onReturnChange($event)">
          <mat-radio-button class="radio-button" *ngFor="let br of filteredReturnBranches" [value]="br.code">
            {{br.addresses[0].name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row table-row-seperator">
    </div>
    <hr />
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="actions">
    <button class="btn-main btn-white" type="button" (click)="closeDialog()">{{'buttons.cancel' | translate}}</button>
    &nbsp;&nbsp;&nbsp;
    <button class="btn-main btn-orange" type="button" (click)="onContinueClick()">{{'buttons.continue' | translate}}</button>
  </mat-dialog-actions>
</div>
