import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';

import { fade } from 'src/app/shared/animations/animations';
import { CommonService } from 'src/app/core/services';
import { LoaderService } from './loader.service';


@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  isMobileDevice$: Observable<boolean> = of(false);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _commonService: CommonService,
    private _loaderService: LoaderService
  ) { }

  ngOnInit(): void {

    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

    this.setupLoader();
  }

  private setupLoader = () => {
    let timer: any;

    const sub1 = this._loaderService.loaderObservable.subscribe(show => {
      if (show) {
        timer = setTimeout(() => {
          this.loading$.next(show);
        }, 100);
      } else {
        clearTimeout(timer);
        this.loading$.next(false);
      }
    });
    this.allSubs.add(sub1);
  };

  ngOnDestroy(): void {
    this.allSubs.unsubscribe();
  }
}
