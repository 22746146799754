import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { AuthenticationService, CommonService } from '../services';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { UserLoginInfoDto, ErrorDto } from '../models';

import { Store } from '@ngrx/store';
import { State, logoutUser } from 'src/app/store';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  private ignoreUrls = [
    /\/v1\/account\/.+\/token/i,
    /\/v1\/account\/.+\/logout/i,
    /\/v1\/countries\/.+/i,
    /\/v1\/currency-rates\/.+/i,
    /\/v1\/document-types\/.+/i,
    /\/v1\/ip-to-location\/.+\/city/i,
    /\/v1\/ip-to-location\/.+\/country/i,
    /\/v1\/logs\/.+/i,
    /\/v1\/notifications\/.+/i,
    /\/v1\/places\/.+/i,
    /\/v1\/rateextras\/.+/i,
    /\/v1\/recommendationemail\/.+/i,
    /\/v1\/rentalbranches\/.+/i,
    /\/v1\/rentalrates\/.+/i,
    /\/v1\/security\/.+\//i,
    /\/v1\/sms\/.+\//i,
    /\/v1\/validator\/.+\/(email|phone-number|phonenumber|discount-code)/i,
    /\/v1\/vehicles\/.+/i,

  ];

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private _authService: AuthenticationService,
    private _commonService: CommonService,
    private _store: Store<State>
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

    if (AuthTokenInterceptor.shouldIgnoreUrl(this.ignoreUrls, request.url)) {
      return next.handle(request);
    } else {
      return next.handle(this.addTokenToRequest(request, this._authService.getAuthToken()))
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(() => err);
          }
        })
      );
    }
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // add authorization header with bearer token if available
    if (!CommonService.isNullOrWhiteSpace(token)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this._authService.refreshAuthToken(this._commonService.getCurrentLanguage())
        .pipe(
          switchMap((user: UserLoginInfoDto) => {
            if (user && user != null) {
              this.tokenSubject.next(user.accessToken);
              return next.handle(this.addTokenToRequest(request, user.accessToken));
            }

            const err: ErrorDto = {
              code: "401",
              errorMessage: "Unauthorized"
            };
            return throwError(() => err);
          }),
          catchError(err => {
            this._store.dispatch(logoutUser());
            return throwError(() => err);
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;
      return this.tokenSubject
      .pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        }));
    }
  }

  private static shouldIgnoreUrl(ignoreUrls: RegExp[], url: string): boolean {
    let flag: boolean = false;
    for (let i = 0; i < ignoreUrls.length; i++) {
      if (ignoreUrls[i].test(url)) {
        //flag = false;
        break;
      }
    }

    return flag;
  }
}
