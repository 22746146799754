import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { RecommendationEmailDto } from 'src/app/core/models';

import { RecommendationEmailService } from 'src/app/core/services';

import { LOAD_RECOMMENDATION_EMAIL, LoadRecommendationEmail, LoadRecommendationEmailSuccess, LoadRecommendationEmailFail } from './recommendation-email.action';

@Injectable()
export class RecommendationEmailEffects {
  constructor(
    private actions$: Actions,
    private recEmailService: RecommendationEmailService
  ) { }


  loadRecommendationEmail$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_RECOMMENDATION_EMAIL),
      map((action: LoadRecommendationEmail) => action.id),
      switchMap(id => {
        return this.recEmailService
          .fetchRecommendationEmail(id)
          .pipe(
            map((result) => {
              const _recEmail = result.data as RecommendationEmailDto;
              return new LoadRecommendationEmailSuccess(_recEmail);
            }),
            catchError(
              (error) => {
                return of(new LoadRecommendationEmailFail(error));
              })
          );
      })
    ));
}
