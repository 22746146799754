import { select, Store } from "@ngrx/store";
import { catchError, combineLatest, filter, map, Observable, of, Subscription, switchMap, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { getVehiclesCurrentLanguage, getVehiclesLoaded, getVehiclesStatus, LoadVehicles, State } from "../../store";
import { timeout$ } from "./timeout";

const subsLoadingTimeout$: Subscription = new Subscription();

export const loadVehicles$ = (lang: string, store: Store<State>): Observable<boolean> => {

  const combined$ = combineLatest([
    store.pipe(select(getVehiclesLoaded)),
    store.pipe(select(getVehiclesStatus))
  ],
    (loaded, status) => {
      return {
        isLoaded: loaded,
        status: status
      };
    }
  );

  return combined$.pipe(
    tap((obj) => {
      if (!obj.isLoaded && obj.status !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(new LoadVehicles(lang));

        subsLoadingTimeout$.add(timeout$("Request timed out in loading vehicles.").subscribe());
      }
    }),
    filter((obj) => obj.isLoaded || obj.status === STORE_STATUS_CODES.FAILED),
    take(1),
    switchMap((obj) => {
      subsLoadingTimeout$.unsubscribe();

      if (obj.status === STORE_STATUS_CODES.FAILED) {
        return of(false);
      }
      else {
        return store.pipe(
          select(getVehiclesCurrentLanguage),
          tap((_lang) => {
            if (lang !== _lang && _lang !== "") {
              store.dispatch(new LoadVehicles(lang));
            }
          }),
          filter((_lang) => _lang !== ""),
          map(() => obj.isLoaded),
          take(1)
        );
      }
    }),
    catchError(_ => {
      return of(false);
    })
  );
};
