import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { NotifyBottomComponent } from './notify-bottom.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, TranslateModule, MatListModule, MatButtonModule],
    declarations: [NotifyBottomComponent],
    exports: [NotifyBottomComponent]
})
export class NotifyBottomModule { }
