<div class="confirm-acc-email-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog()">
    <i class="fas fa-times" aria-hidden="true"></i>
  </button>
  <ng-container *ngIf="(isVerified$ | async) === false">
    <h4 mat-dialog-title class="header">{{'dlg-confirm-acc-email.title' | translate}}<hr /></h4>
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <p class="text">
            <span class="fa-stack fa-5x stack-icon">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fas fa-envelope-open fa-stack-1x fa-inverse"></i>
            </span>
          </p>
          <h2>{{'dlg-confirm-acc-email.heading' | translate}}</h2>
          <p class="text">{{'dlg-confirm-acc-email.text1' | translate}}<br />{{'dlg-confirm-acc-email.text2' | translate}}</p>
          <hr />
          <p class="text">
            <button type="button" mat-button color="primary" (click)="onResendClick()" [disabled]="(counter$ | async) > 0">{{'dlg-confirm-acc-email.resend' | translate}}</button>
            <span *ngIf="counter$ | async as counter" class="color-orange">{{counter}}</span>
          </p>
        </div>
      </div>
    </mat-dialog-content>
  </ng-container>
  <ng-container *ngIf="isVerified$ | async">
    <h4 mat-dialog-title class="header">{{'verify-email.success-note2' | translate}}<hr /></h4>
    <mat-dialog-content>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <p class="text">
            <span class="fa-stack fa-5x stack-icon">
              <i class="fas fa-circle fa-stack-2x"></i>
              <i class="fas fa-thumbs-up fa-stack-1x fa-inverse"></i>
            </span>
          </p>
          <h2 [innerHTML]="'verify-email.success-note1' | translate"></h2>
          <p [innerHTML]="'verify-email.success-note2' | translate" class="text"></p>
          <hr />
          <p class="text">
            <button type="button" class="btn-main btn-white normal" (click)="onRentAVehClick()">{{'buttons.rent-a-car' | translate}}</button>
          </p>
        </div>
      </div>
    </mat-dialog-content>
  </ng-container>
  <container-spinner [showSpinner]="isProcessing$ | async" diameter="70"></container-spinner>
</div>
