import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ServiceResult, SignUpUserDto, PasswordResetDto, UserInfoDto, UserNameModelDto, ChangePasswordDto, ChangeEmailDto, ChangePhoneDto } from '../models';

import { ConfigService } from "./config.service";

@Injectable()
export class UserService {

  constructor(private _configService: ConfigService, private http: HttpClient) { }

  registerUser = (signupInfo: SignUpUserDto, lang: string): Observable<ServiceResult> => {
    const url = this._configService.getUserApiUrl('/register', { lang });

    return this.http.post<ServiceResult>(url, signupInfo)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  IsUserRegistered = (userName: string): Observable<boolean> => {

    if (!userName || userName === "") {
      return of(false);
    }

    const userModel: UserNameModelDto = {
      userName: userName
    };

    const url = this._configService.getUserApiUrl('/IsUserRegistered');

    return this.http.post<ServiceResult>(url, userModel)
      .pipe(
        map((res) => {
          return res?.data === true;
        }),
        catchError(() => of(false))
      );
  };

  changeEmail = (data: ChangeEmailDto, lang: string): Observable<ServiceResult> => {
    const url = this._configService.getUserApiUrl('/ChangeEmail', { lang });

    return this.http.post<ServiceResult>(url, data)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  changePhone = (data: ChangePhoneDto, lang: string): Observable<ServiceResult> => {
    const url = this._configService.getUserApiUrl('/ChangePhone', { lang });

    return this.http.post<ServiceResult>(url, data)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  changePassword = (pwdReset: ChangePasswordDto, lang: string): Observable<any> => {
    const url = this._configService.getUserApiUrl('/ChangePassword', { lang });

    return this.http.post<any>(url, pwdReset)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  forgotPassword = (userName: string, lang: string): Observable<any> => {
    const url = this._configService.getUserApiUrl('/forgotpassword', { userName, lang });

    return this.http.post<any>(url, {})
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  resetPassword(pwdReset: PasswordResetDto): Observable<any> {
    const url = this._configService.getUserApiUrl('/passwordreset');

    return this.http.post<any>(url, pwdReset)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  saveUserInfo = (userInfo: UserInfoDto): Observable<ServiceResult> => {
    const url = this._configService.getUserApiUrl('/userinfo');

    return this.http.post<ServiceResult>(url, userInfo)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  public checkEmailVerifiedStatus(userId: string): Observable<boolean> {
    const url = this._configService.getUserApiUrl(`/${userId}/email/status`);

    return this.http.get<ServiceResult>(url, {})
      .pipe(
        map((result) => {
          return result.data.statusCode === 20070;
        }),
        catchError(e => throwError(() => e))
      );
  };

  public confirmAccountEmail(userId: string, lang: string): Observable<ServiceResult> {
    const url = this._configService.getUserApiUrl(`/${userId}/email/token`, { lang });


    return this.http.put<ServiceResult>(url, {})
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  public verifyAccountEmail(userId: string, code: string): Observable<ServiceResult> {

    const url = this._configService.getUserApiUrl('/email/verify', { userid: userId, code });

    return this.http.post<ServiceResult>(url, {})
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  checkPhoneVerifiedStatus = (): Observable<boolean> => {
    const url = this._configService.getUserApiUrl(`/CheckPhoneVerifiedStatus`);

    return this.http.post<ServiceResult>(url, {})
      .pipe(
        map((result) => {
          return result.data.statusCode == 20072;
        }),
        catchError(e => throwError(() => e))
      );
  };

  confirmPhoneNumber = (lang: string): Observable<ServiceResult> => {
    const url = this._configService.getUserApiUrl('/ConfirmPhoneNumber', { lang });

    return this.http.post<ServiceResult>(url, {})
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  verifyPhoneNumber = (userid: string, code: string): Observable<ServiceResult> => {
    const url = this._configService.getUserApiUrl('/VerifyPhoneNumber', { userid, code });

    return this.http.post<ServiceResult>(url, {})
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  deleteAccount(password: string): Observable<ServiceResult> {
    const url = this._configService.getUserApiUrl('/delete');

    return this.http.post<ServiceResult>(url, { password })
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  verifyRecaptchaV3Token = (token: string): Observable<boolean> => {
    const url = this._configService.getSecurityApiUrl('/captcha', { token });
    return this.http.post<ServiceResult>(url, {})
      .pipe(
        map((result) => {
          return !!result.data.success && result.data.score > 0.5;
        }),
        catchError(e => throwError(() => e))
      );
  };
}
