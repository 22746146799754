import { select, Store } from "@ngrx/store";
import { catchError, combineLatest, filter, map, Observable, of, switchMap, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { getCountriesCurrentLang, getCountriesLoaded, getCountriesStatus, LoadCountries, State } from "../../store";

export const loadCountries$ = (lang: string, store: Store<State>): Observable<boolean> => {
  const combined$ = combineLatest([
    store.pipe(select(getCountriesLoaded)),
    store.pipe(select(getCountriesStatus))
  ],
    (loaded, status) => {
      return { isLoaded: loaded, status: status };
    }
  );

  return combined$.pipe(
    tap((obj) => {
      if (!obj.isLoaded && obj.status !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(new LoadCountries(lang));
      }
    }),
    filter((obj) => obj.isLoaded || obj.status === STORE_STATUS_CODES.FAILED),
    take(1),
    switchMap((obj) => {
      if (obj.status === STORE_STATUS_CODES.FAILED) {
        return of(false);
      }
      else {
        return store.pipe(
          select(getCountriesCurrentLang),
          tap((_lang) => {
            if (lang !== _lang && _lang !== "") {
              store.dispatch(new LoadCountries(lang));
            }
          }),
          filter((_lang) => _lang !== ""),
          map(() => obj.isLoaded),
          take(1)
        );
      }
    }),
    catchError(_ => {
      return of(false);
    })
  );
};
