<div class="month-picker" [formGroup]="parentFormGroup">
  <mat-form-field monthPickerField class="input-full-width">
    <mat-label>{{placeHolderKey | translate}}</mat-label>
    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="monthPicker" [formControlName]="formCtrlName" (keypress)="onMonthPickerKeyPress($event)">
    <mat-hint>MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="monthPicker">
      <i matDatepickerToggleIcon class="far fa-calendar-day icon-button"></i>
    </mat-datepicker-toggle>
    <mat-datepicker [touchUi]="isTouchUI" #monthPicker startView="multi-year" (monthSelected)="setMonthAndYear($event, monthPicker)" panelClass="month-picker"></mat-datepicker>
    <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('invalidCtrlValue')">
      <span [innerHTML]="'validations.invalid-expiry' | translate"></span>
    </mat-error>
    <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('required')">
      <span [innerHTML]="'validations.req-expiry' | translate"></span>
    </mat-error>
  </mat-form-field>
</div>
