import { VehicleDto } from 'src/app/core/models';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

export interface IVehicleState {
  readonly vehiclesSource: { [id: string]: VehicleDto };
  readonly vehicles: { [id: string]: VehicleDto };
  readonly statusCode: STORE_STATUS_CODES;
  readonly loading: boolean;
  readonly loaded: boolean;
}
