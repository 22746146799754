<div class="email-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <form [formGroup]="sendEmailForm" (ngSubmit)="onSendEmailClick()" novalidate>
    <h4 mat-dialog-title class="header">{{'dlg-email.rec-email' | translate}}<hr /></h4>
    <mat-dialog-content>
      <notify-area [notifyType]="(notify$ | async)?.notifyType" [notification]="(notify$ | async)?.notification" [errors]="(notify$ | async)?.validationErrors"></notify-area>
      <div class="row table-heading">
        <span>{{'dlg-email.send-conf-email' | translate}}</span><br />
        <span class="small">{{'dlg-email.conf-email' | translate}}</span>
      </div>
      <div class="row email">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <mat-form-field class="email-chip-list">
            <mat-chip-grid #chipList autofilled="true" errorState="true">
              @for (email of emails; track email) {
              <mat-chip-row [removable]="removable" [color]="email.isValid ? 'none' : 'warn'" (removed)="remove(email)">
                {{email.email}}
                <button matChipRemove *ngIf="removable">
                  <i class="far fa-times-circle" aria-hidden="true"></i>
                </button>
              </mat-chip-row>
              }
              <input #emailsInput placeholder="{{'common.email-adr' | translate}}"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)" name="email" autocomplete="email" formControlName="emailFormControl" />
            </mat-chip-grid>
            <mat-error *ngIf="!sendEmailForm.controls['emailFormControl'].valid && sendEmailForm.controls['emailFormControl'].touched">
              <span [innerHTML]="'validations.req-email' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions [align]="isMobileDevice ? 'center' : 'end'" class="actions">
      <button type="submit" class="btn-main dlg-btn btn-orange">{{'buttons.send' | translate}}</button>
      &nbsp;&nbsp;
      <button type="button" class="btn-main dlg-btn btn-white" [mat-dialog-close]="">{{'buttons.cancel' | translate}}</button>
    </mat-dialog-actions>
  </form>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
