import { RatesAction, LOAD_RATES, LOAD_RATES_SUCCESS, LOAD_RATES_FAIL, RESET_RATES, UPDATE_RATES, UPDATE_RATES_TRANSLATION, UPDATE_SELECTED_RATE_STATUS, UPDATE_RATES_TRANSLATION_SUCCESS} from './rate.action';
import { RatesResult, RateDto, VehicleDto, ResponseStatus } from 'src/app/core/models';
import { IRateState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

const initialState: IRateState = {
  ratesResultSource: {} as RatesResult,
  ratesResult: null,
  availableRates: {},
  limitedAvailableRates: {},
  restrictedVehiclesByAge: {},
  soldOutVehicles: {},
  responseStatus: null,
  statusCode: STORE_STATUS_CODES.INITIAL,
  loaded: false,
  loading: false,
  updating: false,
  updated: false
};

export function rateReducer(state = initialState, action: RatesAction): IRateState {
  switch (action.type) {

    case LOAD_RATES: {
      return {
        ...state,
        responseStatus: null,
        loading: true,
        loaded: false
      };
    }

    case LOAD_RATES_SUCCESS: {
      let ratesResult: RatesResult = action.payload;
      let ratesResultSource: RatesResult = JSON.parse(JSON.stringify(ratesResult));
      let _availableRates = ratesResult.availableRates;

      //if (action.selectedRateId && action.selectedRateId != "") {

      //  _availableRates = action.payload.availableRates.reduce((rates: RateDto[], rate: RateDto) => {

      //    return [
      //      ...rates,
      //      {
      //        ...rate,
      //        isSelected: rate.id === action.selectedRateId
      //      }
      //    ];

      //  }, []);


      //  ratesResultSource = {
      //    ...ratesResultSource,
      //    availableRates: _availableRates
      //  };

      //  ratesResult = {
      //    ...ratesResult,
      //    availableRates: _availableRates
      //  };
      //}

      // sorting is handled on server side
      //_availableRates = _availableRates.sort((a, b) => a.vehicle.sortOrder === b.vehicle.sortOrder ? a.rateSummary.totalCharges - b.rateSummary.totalCharges : a.vehicle.sortOrder - b.vehicle.sortOrder);

      const availableRates = getRatesAsEntities(_availableRates);
      const limitedAvailableRates = getVehiclesAsEntities(action.payload.limitedAvailableRates);
      const restrictedVehiclesByAge = getVehiclesAsEntities(action.payload.restrictedVehiclesByAge);
      const soldOutVehicles = getVehiclesAsEntities(action.payload.soldOutVehicles);

      return {
        ...state,
        loading: false,
        loaded: true,
        updating: false,
        updated:true,
        ratesResult: ratesResult,
        ratesResultSource: ratesResultSource,
        availableRates: availableRates,
        limitedAvailableRates: limitedAvailableRates,
        restrictedVehiclesByAge: restrictedVehiclesByAge,
        soldOutVehicles: soldOutVehicles,
        statusCode: STORE_STATUS_CODES.SUCCESS,
        responseStatus: { status: "OK", statusCode: 200 }
      };
    }

    case LOAD_RATES_FAIL: {
      const responseStatus = action.payload as ResponseStatus;
      if (responseStatus && responseStatus.statusCode) {
        return {
          ...initialState,
          responseStatus: { ...responseStatus },
          statusCode: STORE_STATUS_CODES.FAILED,
          loading: false,
          loaded: false,
          updating: false,
          updated: false
        };
      }
      else {
        return {
          ...state,
          responseStatus: { status: "Not Found", statusCode: 404 },
          statusCode: STORE_STATUS_CODES.FAILED,
          loading: false,
          loaded: false,
          updating: false,
          updated: false
        };
      }
    }

    case RESET_RATES: {
      return {
        ...state,
        ...initialState
      };
    }

    case UPDATE_RATES: {
      return {
        ...state,
        ratesResult: { ...action.payload }
      };
    }

    case UPDATE_RATES_TRANSLATION: {
      return {
        ...state,
        updating: true,
        updated: false
      };
    }

    case UPDATE_SELECTED_RATE_STATUS: {

      if (state && state.ratesResultSource && state.ratesResultSource.availableRates) {
        const _selectedRate: RateDto = action.updatedRate;

        const _availableRates = state.ratesResultSource.availableRates.reduce((rates, rate) => {
          const _rate: RateDto = {
            ...rate,
            isSelected: rate.id == action.updatedRate.id
          };
          return [...rates, _rate];
        }, []);

        const ratesResultSource: RatesResult = {
          ...state.ratesResultSource,
          availableRates: _availableRates
        };

        const ratesResult: RatesResult = {
          ...state.ratesResult,
          availableRates: _availableRates
        };

        return {
          ...state,
          ratesResultSource: ratesResultSource,
          ratesResult: ratesResult,
          availableRates: getRatesAsEntities(_availableRates)
        };
      }

      return state;
    }
  }

  return state;
}

function getRatesAsEntities(rates: RateDto[]): { [id: string]: RateDto } {

  const entities = rates.reduce((entities: { [id: string]: RateDto }, rate) => {
    return {
      ...entities,
      [rate.id]: {
        ...rate
      }
    };
  },
    {});

  return entities;
};

function getVehiclesAsEntities(rates: VehicleDto[]): { [id: string]: VehicleDto } {

  const entities = rates.reduce((entities: { [id: string]: VehicleDto }, vehicle) => {
    return {
      ...entities,
      [vehicle.id]: {
        ...vehicle
      }
    };
  },
    {});

  return entities;
};

//export const getRatesResultSource = (state: IRateState) => state.ratesResultSource;
