<div id="divRentalSummaryContainer" class="rental-summary-sidebar collapse" #divRentalSummaryContainer>
  <div id="divRentalSummary" class="rental-summary-outer" [ngClass]="{'rental-summary-fixed':rentalSummaryFixed}" #divRentalSummary>
    <vehicle-info [selectedVehicle]="selectedRate.vehicle" [hideFuelBlock]="true" [hideBtnsBlock]="true" [hideFeatures]="true"></vehicle-info>
    <hr />
    <div class="rental-summary" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
      <div class="row">
        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
          <h4>{{'common.rental-summ' | translate}}</h4>
        </div>
        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 text-right">
          <button type="button" class="btn-main small btn-white btn-change-veh" (click)="ChangeVehClick()">{{'buttons.chng-veh' | translate}}</button>
        </div>
      </div>

      <div class="seperator"></div>
      <div class="box">
        <div *ngIf="rezInfo && rezInfo.confirmationNo !==''" class="row table-row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0">
            <div *ngIf="rezInfo.renterFullName && rezInfo.renterFullName !== ''">
              <strong>{{rezInfo.renterFullName}}</strong>
            </div>
            <span>{{'common.conf-number' | translate}}:</span>&nbsp;<span class="conf-num">{{rezInfo.confirmationNo}}</span>
          </div>
        </div>
        <hr *ngIf="rezInfo && rezInfo.confirmationNo !==''" />
        <div class="row table-row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0">
            <div class="payment-mode">
              <mat-radio-group (change)="PaymentModeChange($event)">
                <div>
                  <mat-radio-button value="1" [checked]="true">{{'step2-vehicle-extras.pay-on-arr' | translate}}</mat-radio-button>
                </div>
                <div *ngIf="acceptPrepayment === true">
                  <mat-radio-button value="2">{{'step2-vehicle-extras.pay-now' | translate}}<span><i class="far fa-long-arrow-alt-right" aria-hidden="true"></i><strong>{{'common.save' | translate}} {{12.00 | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}!&nbsp;</strong></span></mat-radio-button>
                  <a>{{'common.how' | translate}}</a>
                </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <hr />
        <div class="row table-row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0">
              <span [tooltip]="'coverage.coverage-tooltip' | translate:{'mainSiteUrl':mainSiteUrl}" placement="right">
                <strong>{{'coverage.coverage' | translate}}</strong>&nbsp;
                <span class="info-icon"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
              </span>
            </div>
            <div *ngFor="let extra of (selectedCoverageExtras$ | async)" class="row table-row">
              <div class="col-xs-8 col-sm-9 col-md-9 col-lg-9 padding-0">
                <span>{{extra.extraDesc[0].description}}</span>
              </div>
              <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 padding-0 text-right">
                <i class="far fa-check-square tick" aria-hidden="true"></i>
              </div>
            </div>
            <!--<div *ngFor="let extra of (freeCoverageExtras$ | async)" class="row table-row">
              <div class="col-md-9 col-lg-9 padding-0">
                <span>{{extra.extraDesc[0].description}}</span>
              </div>
              <div class="col-md-3 col-lg-3 padding-0 text-right">
                <i class="far fa-check-square tick" aria-hidden="true"></i>
              </div>
            </div>-->
            <div *ngIf="(unSelectedCoverageExtras$ | async) as unSelectedCoverageExtras" class="row table-row">
              <div *ngIf="unSelectedCoverageExtras?.length > 0">
                <div *ngFor="let extra of unSelectedCoverageExtras" class="row table-row">
                  <div class="col-xs-8 col-sm-9 col-md-9 col-lg-9 padding-0">
                    <span>{{extra.extraDesc[0].description}}</span>
                  </div>
                  <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 padding-0 text-right">
                    <a (click)="onAddCoverageClick($event)">{{'common.add-now' | translate}}</a>
                  </div>
                </div>
              </div>
              <div *ngIf="unSelectedCoverageExtras?.length <= 0 && (selectedCoverageExtras$ | async)?.length <= 0" class="row table-row">
                <div class="col-xs-8 col-sm-9 col-md-9 col-lg-9 padding-0">
                  <span>{{'coverage.no-cvrg-sel' | translate}}</span>
                </div>
                <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 padding-0 text-right">
                  <a (click)="onAddCoverageClick($event)">{{'common.add-now' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row table-row">
          <div class="col-md-12 col-lg-12 padding-0">
            <div class="col-xs-8 col-sm-9 col-md-8 col-lg-8 padding-0">
              <span [tooltip]="'common.rental-rate-tooltip' | translate" placement="right">
                <strong>{{'common.rental-rate' | translate}}</strong>&nbsp;<span class="info-icon"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
              </span>
            </div>
            <div class="col-xs-4 col-sm-3 col-md-4 col-lg-4 padding-0 text-right no-word-wrap">
              <strong>{{selectedRate.rateSummary.rateBeforeTaxes | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}</strong>
            </div>
          </div>
          <div class="row table-row">
            <div class="col-xs-8 col-sm-7 col-md-7 col-lg-7 padding-0">
              <span [tooltip]="'common.incl-mileage-tooltip' | translate" placement="right">
                {{'common.incl-mileage' | translate}}<span *ngIf="selectedRate?.rateDetail.perMileRate > 0"> ({{selectedRate?.rateDetail.perMileRate | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.extra-km' | translate}})</span>&nbsp;<span class="info-icon"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
              </span>
            </div>
            <div class="col-xs-4 col-sm-5 col-md-5 col-lg-5 padding-0 text-right no-word-wrap">
              <strong>{{selectedRate?.totalMilesIncludedDisplay?.toLowerCase() === "unlimited" ? ('common.unlimited' | translate | titlecase) + ' (' + (selectedRate?.totalMilesIncluded + selectedRate?.vehicle.mileageUnit.toLowerCase()) + ')' : selectedRate?.totalMilesIncludedDisplay}}</strong>
            </div>
          </div>
        </div>
        <hr />
        <div class="row table-row">
          <div class="col-md-12 col-lg-12 padding-0">
            <div *ngIf="totalAdditionalExtras > 0" class="row table-row">
              <div class="col-xs-8 col-sm-9 col-md-8 col-lg-8 padding-0">
                <span>{{'common.additional-srvcs' | translate}}</span>
              </div>
              <div class="col-xs-4 col-sm-3 col-md-4 col-lg-4 padding-0 text-right no-word-wrap">
                <strong>{{totalAdditionalExtras | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}</strong>
              </div>
            </div>
            <div *ngIf="selectedRate.discountCodeResult !== null" class="row table-row">
              <div class="col-xs-8 col-sm-9 col-md-8 col-lg-8 padding-0">
                <span>{{'common.disc-n-reb' | translate}}</span>&nbsp;<span class="info-icon"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
              </div>
              <div class="col-xs-4 col-sm-3 col-md-4 col-lg-4 padding-0 text-right no-word-wrap discount">
                <strong *ngIf="selectedRate.rateSummary.totalDiscount === 0">{{'common.applied' | translate}}</strong>
                <strong *ngIf="selectedRate.rateSummary.totalDiscount !== 0">{{selectedRate.rateSummary.totalDiscount | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}</strong>
              </div>
            </div>
            <div class="row table-row">
              <div class="col-xs-8 col-sm-9 col-md-8 col-lg-8 padding-0">
                <span [tooltip]="'common.fees-n-taxes-tooltip' | translate" placement="right">
                  {{'common.fees-n-taxes' | translate}}&nbsp;<span class="info-icon"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
                </span>
              </div>
              <div class="col-xs-4 col-sm-3 col-md-4 col-lg-4 padding-0 text-right no-word-wrap">
                <strong>{{selectedRate.rateSummary.totalTaxes + totalRateExtras | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end box-->
      <div class="box subtotal">
        <div class="row table-row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0">
            <h4>{{'common.veh-subtotal' | translate}}<span class="subtotal-value2"><span *ngIf="selectedCurrency.code !== baseCurrency">{{'common.estimated' | translate}}</span> {{selectedRate.rateSummary.totalCharges | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}*<span *ngIf="selectedCurrency.code !== baseCurrency">*</span></span></h4>

            <div *ngIf="chargeablePrice$ | async as chargeablePrice" class="row table-row">
              <div *ngIf="selectedCurrency.code !== baseCurrency && chargeablePrice > 0">
                <div class="row">
                  <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                    <strong>{{'step2-vehicle-extras.chargeable-price' | translate}}</strong>
                  </div>
                  <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 text-right"><strong>{{chargeablePrice | currency:baseCurrency:'symbol-narrow':'1.2':('common.lang' | translate)}} {{baseCurrency}}*</strong></div>
                </div>
                <div class="seperator"></div>
                <div class="seperator"></div>
                <p class="legal-text">** {{'common.cnvrt-prices-msg' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
