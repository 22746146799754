<div *ngIf="extras?.length > 0" class="rate-extra-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'common.add' | translate}} {{extras[0].extraDesc[0].description}} <i class="fas fa-info-circle" aria-hidden="true"></i><hr /></h4>
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <mat-dialog-content>
    <div *ngIf="validationErrors.length" class="alert alert-danger" role="alert">
      <ul>
        <li *ngFor="let error of validationErrors">{{error.errorMessage}}</li>
      </ul>
    </div>
    <div class="seperator"></div>
    <div class="seperator"></div>
    <rate-extra-list [rateExtras]="extras" [baseCurrency]="baseCurrency" [selectedCurrency]="selectedCurrency" [rateDetail]="rateDetail" [mileageCap]="mileageCap" [totalFreeMiles]="totalFreeMiles" [parentForm]="formGroup" (extrasUpdated)="onExtrasUpdated($event)" [ngStyle]="{'background-color': 'inherit'}"></rate-extra-list>
  </mat-dialog-content>
  <mat-dialog-actions [align]="isMobileDevice ? 'center' : 'end'" class="actions">
    <button type="button" class="btn-main dlg-btn btn-orange" (click)="onAddClick()">{{'buttons.add' | translate}}</button>
    &nbsp;&nbsp;
    <button type="button" class="btn-main dlg-btn btn-white" [mat-dialog-close]="">{{'buttons.cancel' | translate}}</button>
  </mat-dialog-actions>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
