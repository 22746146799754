import { createSelector } from '@ngrx/store';
import { State, ISelectedRateState } from '../_base';

export const selectRateState = (state: State) => state.selectedRate;

export const getSelectedRate = createSelector(selectRateState, (state: ISelectedRateState) => state.selectedRate);

export const getRateSelectedStatus = createSelector(selectRateState, (state: ISelectedRateState) => state.isSelected);

export const getSelectedRateState = createSelector(
  getSelectedRate,
  getRateSelectedStatus,
  (rate, selected) => {
    return {
      selRate: rate,
      isSelected: selected
    };
  }
);
