import { Action } from '@ngrx/store';

import { VehicleDto } from 'src/app/core/models';

export const LOAD_VEHICLES = '[Vehicles] Load Vehicles';
export const LOAD_VEHICLES_FAIL = '[Vehicles] Load Vehicles Fail';
export const LOAD_VEHICLES_SUCCESS = '[Vehicles] Load Vehicles Success';

export const UPDATE_VEHICLES = '[Vehicles] Update Vehicles';

export const RESET_VEHICLES = '[Vehicles] Reset Vehicles';

export class LoadVehicles implements Action {
  readonly type = LOAD_VEHICLES;
  constructor(public lang: string) { }
}

export class LoadVehiclesFail implements Action {
  readonly type = LOAD_VEHICLES_FAIL;
  constructor(public payload: any) { }
}

export class LoadVehiclesSuccess implements Action {
  readonly type = LOAD_VEHICLES_SUCCESS;
  constructor(public payload: VehicleDto[]) { }
}

export class UpdateVehicles implements Action {
  readonly type = UPDATE_VEHICLES;
  constructor(public payload: VehicleDto[]) { }
}

export class ResetVehicles implements Action {
  readonly type = RESET_VEHICLES;
  constructor() { }
}

// action types
export type VehiclesAction = LoadVehicles | LoadVehiclesFail | LoadVehiclesSuccess | UpdateVehicles | ResetVehicles;
