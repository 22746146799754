import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap, exhaustMap, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State } from '../';

import { SET_SELECTED_RATE, SetSelectedRate, RESET_SELECTED_RATE, ResetSelectedRate, SET_SELECTED_RATE_FROM_REZ, SetSelectedRateFromRez } from './selected-rate.action';
import { UpdateSelectedRateStatus } from '../rate/rate.action';

import { Session, ShortRateExtra, RateDto } from 'src/app/core/models';
import { SessionService, ReservationService } from 'src/app/core/services';
import { of } from 'rxjs';

@Injectable()
export class SelectedRateEffects {
  constructor(
    private actions$: Actions,
    private _sessionService: SessionService,
    private _rezService: ReservationService,
    private store: Store<State>
  ) { }


  SetSelectedRate$ = createEffect(() => this.actions$
    .pipe(
      ofType(SET_SELECTED_RATE),
      map((action: SetSelectedRate) => action.payload),
      tap((rate) => {
        const _session = this._sessionService.getSession();

        if (rate && rate.id && _session) {

          let session: Session = {
            ..._session,
            selectedRateId: rate.id
          };

          if (!_session.selectedExtrasCodes || _session.selectedExtrasCodes.length <= 0) {
            const selExtras = rate.extraRates.reduce((extras: { code: string, qty: number, children: { code: string, qty: number }[] }[], extra: ShortRateExtra) => {
              return [
                ...extras,
                {
                  code: extra.code,
                  qty: extra.qty,
                  children: []
                }
              ];
            }, []);

            session = {
              ...session,
              selectedExtrasCodes: selExtras
            };
          }

          this._sessionService.setSession(session);

          setTimeout(() => { this.store.dispatch(new UpdateSelectedRateStatus(rate)); }, 1000);
        }

      })
  ), { dispatch: false });


  SetSelectedRateFromRez$ = createEffect(() => this.actions$
    .pipe(
      ofType(SET_SELECTED_RATE_FROM_REZ),
      map((action: SetSelectedRateFromRez) => { return { lang: action.language, params: action.params }; }),
      exhaustMap((obj) => {
        return this._rezService.getRateFromReservation(obj.lang, obj.params).pipe(
          map((result) => {
            const rate: RateDto = result.data as RateDto;
            return new SetSelectedRate(rate);
          })
        );
      }),
      catchError(error => of(new ResetSelectedRate()))
    ));


  ResetSelectedRate$ = createEffect(() => this.actions$
    .pipe(
      ofType(RESET_SELECTED_RATE),
      tap(() => {
        const _session = this._sessionService.getSession();
        const session: Session = {
          ..._session,
          selectedRateId: ""
        };

        this._sessionService.setSession(session);

      })
    ), { dispatch: false });
}
