<div class="verify-code-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'dlg-verify-code.title' | translate}}<hr /></h4>
  <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog(false)">
    <i class="fas fa-times" aria-hidden="true"></i>
  </button>
  <mat-dialog-content>
    <div class="row table-heading">
      <span [innerHTML]="'dlg-verify-code.conf-ph-title' | translate:{phoneNo:formattedPhoneNumber}"></span><br />
      <span class="small">{{'dlg-verify-code.conf-ph' | translate}}</span>
    </div>
    <div class="row phone">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <mat-form-field class="input-full-width">
          <mat-label>{{'common.code' | translate}}</mat-label>
          <input matInput placeholder="{{'common.code' | translate}}" [formControl]="code" maxlength="6" (keypress)="onKeyPress($event)" (paste)="onKeyUp($event)" (input)="onKeyUp($event)" required pattern="[\d]{0,6}" />
          <button matSuffix mat-button mat-stroked-button color="primary" class="small" type="button" aria-label="Resend" [disabled]="(counter$ | async) > 0" (click)="onResendCodeClick()">
            <ng-container *ngIf="counter$ | async as counter">{{counter}}</ng-container>
            <ng-container *ngIf="(counter$ | async) <= 0">
              {{resendBtnValue}}
            </ng-container>
          </button>
          <mat-error *ngIf="code.hasError('required')">
            <span [innerHTML]="'validations.req-code' | translate"></span>
          </mat-error>
          <mat-error *ngIf="code.hasError('pattern')">
            <span [innerHTML]="'validations.invalid-code' | translate"></span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="actions">
    <button type="button" class="btn-main dlg-btn btn-orange" (click)="onVerifyCodeClick()">{{'buttons.verify' | translate}}</button>
    &nbsp;&nbsp;
    <button type="button" class="btn-main dlg-btn btn-white" [mat-dialog-close]="false">{{'buttons.cancel' | translate}}</button>
  </mat-dialog-actions>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
