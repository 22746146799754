import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ServiceResult, AddReservationDto } from '../models';

import { ConfigService } from "./config.service";

@Injectable()
export class ReservationService {

  constructor(
    private _configService: ConfigService,
    private http: HttpClient) {
  }

  fetchReservation = (lang: string, params: { reservationNo: string, lastName: string }): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl('', { lang, reservationNo: params.reservationNo, lastName: params.lastName });
    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  fetchReservationById = (lang: string, id: string): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl(`/${id}`, { lang });
    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  addReservation = (rez: AddReservationDto): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl();
    return this.http.post<ServiceResult>(url, rez)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  updateReservation = (rez: AddReservationDto): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl();
    return this.http.put<ServiceResult>(url, rez)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  cancelReservation = (lang: string, params: { reservationNo: string, lastName: string }): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl('', { lang, reservationNo: params.reservationNo, lastName: params.lastName })
    return this.http.delete<ServiceResult>(url)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  fetchMyReservations = (lang: string): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl('/MyReservations', { lang });
    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  isRezInHighSeason = (reservationNo: string, lastName: string): Observable<boolean> => {

    const url = this._configService.getReservationApiUrl('/IsRezInHighSeason', { reservationNo, lastName: lastName });

    return this.http.get<ServiceResult>(url).pipe(
      map((result) => {
        return !!result?.data;
      }),
      catchError(e => throwError(() => e))
    );
  }

  isFreeCancellationAvailable = (reservationNo: string, lastName: string): Observable<boolean> => {

    const url = this._configService.getReservationApiUrl('/IsFreeCancellationAvailable', { reservationNo, lastName: lastName });

    return this.http.get<ServiceResult>(url).pipe(
      map((result) => {
        return !!result?.data;
      }),
      catchError(e => throwError(() => e))
    );
  }

  getRateFromReservation = (lang: string, params: { reservationNo: string, lastName: string }): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl('/getratefromreservation', { lang, reservationNo: params.reservationNo, lastName: params.lastName });

    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }
}
