import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatExpansionModule } from '@angular/material/expansion';

import { NotifyAreaComponent } from './notify-area.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatExpansionModule, TranslateModule],
  declarations: [NotifyAreaComponent],
  exports: [NotifyAreaComponent]
})
export class NotifyAreaModule { }
