<div class="imp-info">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
      <h3>{{'step4-confirm.imp-info-pickup' | translate}}</h3>
      <div class="required-docs">
        {{'step4-confirm.present-following' | translate}}
        <span class="card-heading">{{'step4-confirm.supported-cc-msg' | translate}}</span>
        <div class="cards" [ngClass]="{'text-center':(isMobileDevice$ | async)}">
          <span class="block">
            <img class="credit-cards" src="assets/images/confirmation/cards.png" alt="{{'common.ccs' | translate}}" />
          </span>
          <span class="block">
            <i class="fas fa-plus" aria-hidden="true"></i>
            <img src="assets/images/confirmation/driver-license-{{language}}.png" alt="{{'common.driver-lic' | translate}}" />
          </span>
          <span class="block">
            <i class="fas fa-plus" aria-hidden="true"></i>
            <img src="assets/images/confirmation/secondary-id-{{language}}.png" alt="{{'common.secondary-id' | translate}}" />
          </span>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <div class="seperator2x hidden-xs hidden-sm"></div>
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 card-block">
          <div class="acceptable">
            <div class="heading"><i class="fas fa-check" aria-hidden="true"></i><span>{{'common.acceptable' | translate}}</span></div>
            <div class="text">
              <span class="desc">{{'step4-confirm.regular-cc' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 card-block">
          <div class="not-acceptable">
            <div class="heading"><i class="fas fa-times" aria-hidden="true"></i><span>{{'common.not-acceptable' | translate}}</span></div>
            <div class="text">
              <span [innerHTML]="'step4-confirm.prepaid-cc-with-name' | translate"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 card-block">
          <img class="img-card" src="assets/images/confirmation/classic-visa.png" alt="{{'common.visa-classic' | translate}}" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 card-block">
          <img class="img-card" src="assets/images/confirmation/prepaid-with-name.png" alt="{{'step4-confirm.prepaid-cc-with-name-det' | translate}}" />
        </div>
      </div>
    </div>
  </div>
</div>
