import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { VehicleInfoModule } from '../vehicle-info';
import { ContainerSpinnerModule } from '../container-spinner';

import { RateUpgradeDialogComponent } from './dlg-rate-upgrade.component';
import { RateUpgradeDialogService } from './dlg-rate-upgrade.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, VehicleInfoModule, ContainerSpinnerModule, TranslateModule],
    declarations: [RateUpgradeDialogComponent],
    exports: [RateUpgradeDialogComponent],
    providers: [RateUpgradeDialogService]
})
export class RateUpgradeDialogModule { }
