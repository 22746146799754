import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { State, loadCurrentUserInfo } from 'src/app/store';

import { combineLatest, timer, BehaviorSubject, Observable } from 'rxjs';
import { filter, tap, take, first } from 'rxjs/operators';

import { CommonService, ConfigService, EmailService, UserService } from 'src/app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { ChangeEmailDto } from 'src/app/core/models';
import { NOTIFY_TYPES } from 'src/app/core/enums';

import { SuccessEvent } from 'src/app/core/events/dialog.events';
import { ChangeEmailDialogService } from './dlg-change-email.service';

@Component({
  selector: 'change-email-dialog',
  templateUrl: './dlg-change-email.component.html',
  styleUrls: ['./dlg-change-email.component.scss']
})
export class ChangeEmailDialogComponent implements OnInit {

  isMobileDevice: boolean = false;
  inputForm: UntypedFormGroup;
  dlgType: string = "ChangeEmail";
  autoComplete: string = "SorryNoAutoCompletePlease";
  resendEnabled: boolean = false;
  rentACarPageUrl: string = "";

  pwdFieldType: string = 'password';

  private userId: string = "";
  private email: string = "";
  private language: string = "en";

  isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isVerified$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  counter$: Observable<number>;

  private transGeneralErrMsg: string = "";
  private transEmailSentMsg: string = "";

  constructor(
    private _commonService: CommonService,
    private _emailService: EmailService,
    private _userService: UserService,
    private _dlgService: ChangeEmailDialogService,
    private _translate: TranslateService,
    private _router: Router,
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isMobileDevice = this._commonService.IsMobileDevice;
    this.dlgType = CommonService.isNullOrWhiteSpace(data.dlgType as string) ? this.dlgType : data.dlgType as string;
    this.userId = data.userId as string;
    this.email = data.email as string;
    this.language = data.lang as string;
  }

  ngOnInit(): void {
    this.buildForm();

    this._translate.get(
      [
        "validations.general-error-msg",
        "dlg-change-email.success-msg",
        "dlg-change-email.email-sent"
      ],
      {
        email: this.email
      }
    ).pipe(
      filter(t => t && t != ""),
      tap((t) => {
        this.transGeneralErrMsg = t["validations.general-error-msg"];
        this.transEmailSentMsg = t["dlg-change-email.email-sent"];
      }),
      take(1)
    ).subscribe();

    this.counter$ = this._commonService.accountConfirmEmailCounter$;
    this.counter$.pipe(
      tap((value) => {
        if (value === 0 && this.dlgType === "ConfirmEmail") {
          this.resendEmail();
        }
      }),
      take(1)
    ).subscribe();
  }

  private buildForm = () => {
    this.inputForm = this.fb.group({
      currentEmail: [{ value: this.email, disabled: true }],
      newEmail: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(ConfigService.EMAIL_REGEX),
        CommonService.confirmUnmatchValidator('currentEmail')
      ])],
      confEmail: ['', Validators.compose([
        Validators.required,
        CommonService.confirmMatchValidator('newEmail')
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],
    });
  };

  validateField = (event, fieldToValidate) => {
    this.inputForm.get(fieldToValidate).updateValueAndValidity();
  };

  onSubmit = () => {
    if (this.inputForm.valid && !CommonService.isNullOrWhiteSpace(this.email)) {
      this.setSpinner(true);

      const request: ChangeEmailDto = {
          currentEmail: this.inputForm.get('currentEmail').value,
          newEmail: this.inputForm.value.newEmail,
          password: this.inputForm.get('password').value
      };

      combineLatest([
        this._userService.changeEmail(request, this.language),
        timer(500), // To delay at least 500ms to see spinner animation.
      ],
        (result, _t) => result
      ).pipe(
        tap((result) => {
          if (result.data?.succeeded === true) {
            ////this._commonService.bottomSheetObs.next({ message: this.transSuccessMsg, notifyType: NOTIFY_TYPES.Success, duration: 10000 });
            ////this.closeDialog(this.inputForm.value.newEmail);
            this.email = this.inputForm.value.newEmail;
            this._dlgService.triggerEvent(new SuccessEvent(this.email));
            this._translate.get(["dlg-change-email.success-msg-n-sent", "dlg-change-email.email-sent"], { email: this.inputForm.value.newEmail }).pipe(
              filter(t => t && t != ""),
              tap((t) => {
                this.resendEmail(t["dlg-change-email.success-msg-n-sent"]);
                this.dlgType = "ConfirmEmail";
                this.transEmailSentMsg = t["dlg-change-email.email-sent"];
              }),
              take(1)
            ).subscribe();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        }),
        first()
      ).subscribe({

        error: (errorResp) => {
          this.setSpinner(false);
          if (errorResp.error && errorResp.error.statusCode === 40094) {
            this.showAlreadyExistError();
          }
          else if (errorResp.code == "401") {
            this.closeDialog();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        },
        complete: () => this.setSpinner(false)
      });
    }
  };

  private showAlreadyExistError = () => {
    this._translate.get(
      [
        "error-msgs.40094"
      ],
      {
        email: this.inputForm.value.newEmail
      }
    ).pipe(
      filter(t => t && t != ""),
      tap((t) => {
        this._commonService.bottomSheetObs.next({ message: t["error-msgs.40094"], notifyType: NOTIFY_TYPES.Error, duration: 7000 });
      }),
      take(1)
    ).subscribe();
  };

  private resendEmail = (emailSentMsg: string = "") => {
    combineLatest([
      this._userService.checkEmailVerifiedStatus(this.userId),
      timer(10),
    ],
      (isVerified, _t) => isVerified
    ).pipe(
      tap((isVerified) => {
        if (isVerified) {
          this.isVerified$.next(true);
          this.setSpinner(false);
          this.store.dispatch(loadCurrentUserInfo());
        }
        else {
          if (!CommonService.isNullOrWhiteSpace(this.email) && !CommonService.isNullOrWhiteSpace(this.userId)) {
            this.setSpinner(true);

            combineLatest([
              this._userService.confirmAccountEmail(this.userId, this.language),
              timer(1000), // To delay at least 1000ms to see spinner animation.
            ],
              (result, _t) => result
            ).pipe(
              filter(result => result.data),
              take(1),
              tap(result => result.data)
            ).subscribe({
              next : (data : any) => {
                this.setSpinner(false);
                if (!!data.succeeded && data.statusCode === "UserEmailVerified") {
                  this.isVerified$.next(true);
                  this._emailService.stopAccountConfirmEmailCounter();
                }
                else {
                  this.isVerified$.next(false);
                  this._commonService.bottomSheetObs.next({ message: !CommonService.isNullOrWhiteSpace(emailSentMsg) ? emailSentMsg : this.transEmailSentMsg, notifyType: NOTIFY_TYPES.Success, duration: 10000 });
                  this._emailService.startAccountConfirmEmailCounter(true);
                }
              },
              error: e => {
                this._commonService.bottomSheetObs.next({ message: `${this.transGeneralErrMsg} (${e.message})`, notifyType: NOTIFY_TYPES.Error, duration: 6000 });
              },
              complete: () => {
                this.isVerified$.next(false);
                this.setSpinner(false);
              }
            });
          }
        }
      }),
      first()
    ).subscribe();
  };

  onResendClick = () => {
    this.resendEmail();
  };

  onRentAVehClick = () => {
    this._router.navigateByUrl(this.rentACarPageUrl).then(() => { });
    this.closeDialog();
  };

  private setSpinner = (show: boolean = false) => {
    this.isProcessing$.next(show);
    this.dialogRef.disableClose = show;
  };

  closeDialog(): void {
    this.dialogRef.close(this.email);
  }
}
