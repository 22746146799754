
import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';


/**
 * Injectable service
 * @export
 * @class LoaderService
 */
@Injectable()
export class LoaderService {

  private started;

  /**
   * loader observer
   * @private
   * @type {Observer<any>}
   * @memberof LoaderService
   */
  private loaderObserver: Observer<any>;
  /**
   * loader observable
   * @type {Observable<any>}
   * @memberof LoaderService
   */
  public loaderObservable: Observable<any>;
  /**
   * Creates an instance of LoaderService.
   * @memberof LoaderService
   */
  constructor() {
    this.loaderObservable = new Observable(observer => {
      this.loaderObserver = observer;
    }
    ).pipe(share());
  }
  /**
   * To show loader
   * @memberof LoaderService
   */
  show() {
    if (this.loaderObserver) {
      this.loaderObserver.next(true);
      this.started = Date.now();
    }
  }
  /**
   * To hide loader
   * @memberof LoaderService
   */
  hide(immediate:boolean = false) {
    if (this.loaderObserver) {
      if (immediate) {
        this.loaderObserver.next(false);
      }
      else {
        const elapsed = Date.now() - this.started;
        const timeout = elapsed < 1000 ? 1000 : 0;

        setTimeout(() => {
          this.loaderObserver.next(false);
        }, timeout);
      }
    }
  }
}
