import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportantInfoComponent } from './important-info.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ImportantInfoComponent],
  exports: [ImportantInfoComponent]
})
export class ImportantInfoModule { }
