import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import { NotifyAreaModule } from '../notify-area';
import { ContainerSpinnerModule } from '../container-spinner';

import { ConfirmWithPwdDialogComponent } from './dlg-confirm-with-pwd.component';
import { ConfirmWithPwdDialogService } from './dlg-confirm-with-pwd.service';

import { TranslateModule } from '@ngx-translate/core';

import { CapsLockStateModule } from 'src/app/shared/directives/caps-lock-state';
import { AutoFocusModule } from 'src/app/shared/directives/auto-focus';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatExpansionModule,
        NotifyAreaModule,
        ContainerSpinnerModule,
        TranslateModule,
        CapsLockStateModule,
        AutoFocusModule
    ],
    declarations: [ConfirmWithPwdDialogComponent],
    exports: [ConfirmWithPwdDialogComponent],
    providers: [ConfirmWithPwdDialogService]
})
export class ConfirmWithPwdDialogModule { }
