import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Covid19VideoComponent } from './covid-19-video.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [Covid19VideoComponent],
  imports: [
    CommonModule, TranslateModule
  ],
  exports: [Covid19VideoComponent]
})
export class Covid19VideoModule { }
