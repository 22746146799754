import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { Store, select } from '@ngrx/store';
import { State, getUser, loadCurrentUserInfo } from 'src/app/store';

import { AppSettings, UserDto } from "src/app/core/models";

import { flyInOutSocial, flyInOutPhone, jello } from './animations';
import { CommonService } from 'src/app/core/services';
import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
import { PAGES } from 'src/app/core/enums';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  animations: [
    flyInOutSocial,
    flyInOutPhone,
    jello
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  @Output() switchLanguage = new EventEmitter<any>();
  @Input() appSettings: AppSettings;
  @Input() showRezNav: boolean = false;

  seletedCountryCode: string = "ca";

  showPhoneNumbers: boolean = false;
  showSocialLinks: boolean = false;

  fbJelloState: string = '';
  gpJelloState: string = '';
  twJelloState: string = '';

  readonly mainSiteUrl: string = CommonService.MainSiteUrl;

  homeUrl: string = '';
  registerPageUrl: string = '';
  loginPageUrl: string = '';
  myRezPageUrl: string = '';
  myProfilePageUrl: string = '';
  changePwdPageUrl: string = '';

  isMobileDevice$: Observable<boolean> = of(false);
  routeLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  user$: Observable<UserDto>;

  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _loc: Location,
    private _store: Store<State>
  ) {

    const routeEventSubs = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routeLoader$.next(true);
      }
      else if (event instanceof NavigationCancel || event instanceof NavigationEnd) {
        setTimeout(() => {
          this.routeLoader$.next(false);
        }, 1000);
      }
    });

    this.allSubs.add(routeEventSubs);

    // try to load user if logged in
    this._store.dispatch(loadCurrentUserInfo());
  }

  ngOnInit(): void {
    this.homeUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, this.appSettings.language)}`;
    this.registerPageUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.REGISTER, this.appSettings.language)}`;
    this.loginPageUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.LOGIN, this.appSettings.language)}`;
    this.myRezPageUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.MY_RESERVATIONS, this.appSettings.language)}`;
    this.myProfilePageUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.MY_PROFILE, this.appSettings.language)}`;
    this.changePwdPageUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.CHANGE_PASSWORD, this.appSettings.language)}`;

    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

    this.user$ = this._store.pipe(
      select(getUser)
    );
  }

  onSwitchLanguage(language: string) {
    if (this.appSettings.language != language)
      this.switchLanguage.emit(language);
  }

  getCountryFlagCode = (language: string) => {
    if (language == "fr") {
      return "fr";
    }
    else {
      return "ca";
    }
  };

  onSocialLinkMouseEnter = (name: string) => {
    if (name == 'fb' && !this.fbJelloState)
      this.fbJelloState = "jello";

    if (name == 'gp' && !this.gpJelloState)
      this.gpJelloState = "jello";

    if (name == 'tw' && !this.twJelloState)
      this.twJelloState = "jello";
  };

  resetAnimationState = (name: string) => {
    if (name == 'fb')
      this.fbJelloState = "";

    if (name == 'gp')
      this.gpJelloState = "";

    if (name == 'tw')
      this.twJelloState = "";
  };

  logout = async () => {
    await this._router.navigate(
      [`/${this._commonService.getTranslatedRouteByKey(PAGES.LOGIN, this.appSettings.language)}`],
      { queryParams: { returnurl: this._loc.path(), action: "logout" } });
  };

  ngOnDestroy(): void {
    this.allSubs.unsubscribe();
  }
}
