import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

import { tap, concatMap, exhaustMap, catchError, filter } from 'rxjs/operators';

import { Subscription, BehaviorSubject, of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Store, select } from '@ngrx/store';

import { NOTIFY_TYPES, STORE_STATUS_CODES, PAGES } from 'src/app/core/enums';

import { CommonService, ConfigService, UserService } from 'src/app/core/services';
import { ReCaptchaV3Service } from 'src/app/common/recaptcha';

import {
  State,
  loginUser,
  getCurrentUserState
} from 'src/app/store';

@Component({
  selector: 'login-dialog',
  templateUrl: './dlg-login.component.html',
  styleUrls: ['./dlg-login.component.scss']
})
export class LoginDialogComponent implements OnInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  isMobileDevice: boolean = false;
  inputForm: UntypedFormGroup;

  forgotPwdPageUrl: string = '';
  pwdFieldType: string = 'password';
  autoComplete: string = "SorryNoAutoCompletePlease";
  continueAsGuest: boolean = false;
  email: string = "";

  private language: string = "en";

  isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private transInvalidReCaptcha: string = "";

  constructor(
    private _commonService: CommonService,
    private _userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private _translate: TranslateService,
    private _router: Router,
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isMobileDevice = this._commonService.IsMobileDevice;
    this.language = data.lang as string;
    this.continueAsGuest = (data.continueAsGuest as boolean) === true;
    this.email = data.email as string;
  }

  ngOnInit(): void {
    this.forgotPwdPageUrl = "/" + this._commonService.getTranslatedRouteByKey(PAGES.FORGOT_PASSWORD, this.language);

    this.buildForm();

    const sub1 = this._translate.get(
      [
        "validations.invalid-recaptcha"
      ]
    ).pipe()
    .subscribe({
      next: t => {
        this.transInvalidReCaptcha = t["validations.invalid-recaptcha"]
      }
    });

    this.allSubs.add(sub1);
  }

  private buildForm = () => {
    this.inputForm = this.fb.group({
      email: [CommonService.isNullOrWhiteSpace(this.email) ? "" : this.email, Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(ConfigService.EMAIL_REGEX)
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])]
    });
  };

  confirmUnmatchValidator = (fieldName: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root instanceof UntypedFormGroup) {
        const orgValue: string = control.root.get(fieldName) ? control.root.get(fieldName).value : "";
        const confValue: string = control.value;

        if (!CommonService.isNullOrWhiteSpace(orgValue) && CommonService.isNullOrWhiteSpace(confValue)) {
          return { 'required': { value: true } };
        }
        else if (orgValue && orgValue != '' && confValue.toLowerCase() === orgValue.toLowerCase()) {
          return { 'matched': { value: confValue } };
        }
      }
      return null;
    };
  };

  onForgotPwdClick = () => {
    this.closeDialog("forgotPwd");

    this._router.navigateByUrl(this.forgotPwdPageUrl);
  };

  validateField = (_, fieldToValidate) => {
    this.inputForm.get(fieldToValidate).updateValueAndValidity();
  };

  onSubmit = (form) => {
    if (!this.inputForm.valid)
      return;

    this.isProcessing$.next(false);

    const sub1 = this.recaptchaV3Service.execute('login').pipe(
      concatMap((token) => { return this._userService.verifyRecaptchaV3Token(token); }),
      exhaustMap((isValid) => {
        if (!isValid) {
          this._commonService.bottomSheetObs.next({ message: this.transInvalidReCaptcha, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          return of(isValid);
        }

        this.store.dispatch(loginUser({ userName: form.email.trim(), password: form.password.trim(), rememberMe: false, language: this.language }));

        this.store.pipe(
          select(getCurrentUserState),
          filter(x => x.statusCode !== STORE_STATUS_CODES.INITIAL),
          tap((userState) => {
            if (userState.statusCode === STORE_STATUS_CODES.FAILED) {
              throw new Error(userState.responseStatus.status);
            }
            if (this.continueAsGuest) {
              this.closeDialog("loggedInAndContinue");
            }
            else {
              this.closeDialog("loggedIn");
            }
          }),
          catchError(e => throwError(() => e))
        ).subscribe({
          error: (e) => {
            this._commonService.bottomSheetObs.next({ notifyType: NOTIFY_TYPES.Error, message: e.message, duration: 7000 });
          },
          complete: () => {
            this.isProcessing$.next(false);
          }
        });

        return of(isValid);
      })
    ).subscribe();

    this.allSubs.add(sub1);

  };

  ngOnDestroy(): void {
    this.isProcessing$.next(false);

    this.allSubs.unsubscribe();
  }

  closeDialog(action): void {
    this.dialogRef.close(action);
  }
}
