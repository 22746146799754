import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { RateDetailsDialogComponent } from './dlg-rate-details.component';

import { TranslateModule } from '@ngx-translate/core';
import { CheckPluralModule } from 'src/app/shared/pipes/check-plural';

@NgModule({
    imports: [CommonModule, MatDialogModule, TranslateModule, CheckPluralModule],
    declarations: [RateDetailsDialogComponent],
    exports: [RateDetailsDialogComponent]
})
export class RateDetailsDialogModule { }
