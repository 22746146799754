import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfirmationLoaderComponent } from './confirmation-loader.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ConfirmationLoaderComponent],
  exports: [ConfirmationLoaderComponent]
})
export class ConfirmationLoaderModule { }
