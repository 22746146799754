import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';

import { CommonService } from '../core/services';
import { PAGES } from '../core/enums';

@Injectable({ providedIn: 'root' })
export class UserIdTokenCheckGuard  {

  constructor(
    private _router: Router,
    private _commonService: CommonService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (CommonService.isNullOrWhiteSpace(route.queryParams.userid) || CommonService.isNullOrWhiteSpace(route.queryParams.code)) {
      this._router.navigate([this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang)]);
      return of(false);
    }
    else {
      return of(true);
    }
  }
}
