<div #intl_tel_ctrl class="intl-tel-ctrl allow-dropdown" [formGroup]="parentFormGroup">
  <mat-form-field class="tel-ddl-country mat-select-no-underline ddl-country" [ngClass]="{'disabled':disabled}">
    <mat-select #ddlCountry [compareWith]="compareObjects" [(value)]="selectedCountry" (selectionChange)="selectCountry()" [disabled]="disabled" hideSingleSelectionIndicator>
      <mat-select-trigger>
        <span class="flag flag-{{selectedCountry?.twoLetterISOCode | lowercase}}"></span>&nbsp;&nbsp;<span class='dial-code'>+{{selectedCountry?.dialCode}}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let country of listOfPreferedCounties" [value]="country"><span class="flag flag-{{country.twoLetterISOCode | lowercase}}"></span> {{country.threeLetterISOCode}}</mat-option>
      <mat-option *ngFor="let country of listOfCounties$ | async" [value]="country"><span class="flag flag-{{country.twoLetterISOCode | lowercase}}"></span> {{country.threeLetterISOCode}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="tel-input">
    <mat-form-field class="tel-input-field" [ngClass]="{'disabled':disabled}">
      <input type="tel" matInput placeholder="{{label?.length > 0 ? label : ('common.telephone' | translate)}}" name="phone" autocomplete="tel-national" [maxlength]="maxLength" [formControlName]="formCtrlName" [valueModifier]="[viewToForm, formToView]" (keyup)="onKeyUp($event)" (keypress)="onKeyPress($event)" (paste)="onKeyUp($event)" (input)="onKeyUp($event)" [required]="required" [disabled]="disabled" />
      <mat-hint *ngIf="showChangePhoneButton" align="start"><a (click)="onChangePhClick()">{{'common.chng-phone' | translate}}</a></mat-hint>
      <ng-container matSuffix *ngIf="enableVerifyStatus">
        <span *ngIf="!isVerified" style="color:red"><i class="far fa-times-circle" aria-hidden="true" style="color:red"></i> {{'common.not-verified' | translate}}&nbsp;</span>
        <button *ngIf="!isVerified" mat-button mat-stroked-button color="primary" class="small" type="button" [attr.aria-label]="'common.verify-now' | translate" (click)="onVerifyNowClick()" onclick="blur();" tooltip="{{'common.verify-phone-tooltip' | translate}}" placement="bottom-right">{{'common.verify-now' | translate}}</button>
        <span *ngIf="isVerified" style="color:green"><i class="far fa-check-circle color-green" aria-hidden="true"></i> {{'common.verified' | translate}}</span>
      </ng-container>
      <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('required')">
        <span [innerHTML]="'validations.req-phone-no' | translate"></span>
      </mat-error>
      <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('invalidPhone')">
        <span [innerHTML]="'validations.invalid-phone' | translate"></span>
      </mat-error>
      <mat-error *ngIf="parentFormGroup?.get(formCtrlName).hasError('matched')">
        <span [innerHTML]="'validations.phone-must-not-match' | translate"></span>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="clear-both"></div>
</div>
