<div class="session-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'session.heading' | translate}}<hr /></h4>
  <mat-dialog-content>
    <div class="row">
      <div class="row margin-0">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-right icon">
          <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 notify-text">
          <span class="text">{{'session.expired-msg' | translate}}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="actions">
    <button type="button" class="btn-main btn-orange" (click)="onStartOverClick()">{{'buttons.ok' | translate}}</button>
  </mat-dialog-actions>
</div>
