import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { VehicleDto } from 'src/app/core/models';
import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/core/services';

@Component({
  selector: 'vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleInfoComponent implements OnInit {

  @Output() changeVehicleClick = new EventEmitter<string>();
  @Output() continueClick = new EventEmitter<string>();

  @Input() hideFeatures: boolean = false;
  @Input() hideFuelBlock: boolean = false;
  @Input() hideBtnsBlock: boolean = false;

  @Input() smClass: string = 'col-sm-6';

  @Input() selectedVehicle: VehicleDto;

  @Input() rezInfo: { confirmationNo: string, renterFullName: string };

  isMobileDevice$: Observable<boolean> = of(false);

  constructor(private _commonService:CommonService) { }

  ngOnInit() {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
  }

  onChangeVehClick = () => {
    this.changeVehicleClick.emit("");
  };

  onContClick = () => {
    this.continueClick.emit("");
  };
}
