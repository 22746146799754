import { Component, OnInit, AfterViewInit, HostListener, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { VehicleDto } from 'src/app/core/models';
import { CommonService } from 'src/app/core/services';

@Component({
  selector: 'vehicle-detail-sidebar',
  templateUrl: './vehicle-detail-sidebar.component.html',
  styleUrls: ['./vehicle-detail-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleDetailSidebarComponent implements OnInit, AfterViewInit {

  vehicleDetailFixed: boolean = false;
  private isRWidetStickedToTop: boolean = false;

  @Output() changeVehicleClick = new EventEmitter<string>();
  @Output() continueClick = new EventEmitter<string>();

  @Input() selectedVehicle: VehicleDto;
  @Input() rezInfo: { confirmationNo: string, renterFullName: string };
  @Input() containerHeightWithRespectTo: HTMLElement;

  @ViewChild('divVehicleDetailContainer', { static: true }) divVehicleDetailContainer: ElementRef;
  @ViewChild('divVehicleDetail', { static: true }) divVehicleDetail: ElementRef;

  constructor(private renderer: Renderer2, private _commonService: CommonService) { }

  ngOnInit() {
    this.renderer.listen('window', 'widgetStick', (e) => {
      this.isRWidetStickedToTop = e.detail.isStickedToTop;
    });
  }

  ngAfterViewInit(): void {
      this.setContainersHeight();
      this.renderer.removeClass(this.divVehicleDetailContainer.nativeElement, "collapse");
  }

  @HostListener("window:scroll", [])
  onWindowScroll = () => {

    this.setContainersHeight();
    if (!this._commonService.IsMobileDevice) {

      let divVDC = this.divVehicleDetailContainer.nativeElement;
      let divVD = this.divVehicleDetail.nativeElement;

      if (divVD && divVDC) {
        let divVDCRect = divVDC.getBoundingClientRect();
        let divVDRect = divVD.getBoundingClientRect();

        let topMargin: number = 10;

        if (this.isRWidetStickedToTop) {
          topMargin = 90;
        }

        if (Math.floor(divVDCRect.top) >= Math.floor(divVDRect.top) && Math.floor(divVDRect.top) >= topMargin) {
          this.vehicleDetailFixed = false;
          this.renderer.setStyle(divVD, 'top', '0');
          this.renderer.setStyle(divVD, 'bottom', '');
        }
        else if (Math.floor(divVDCRect.bottom) >= Math.floor(divVDRect.bottom) && Math.floor(divVDRect.top) >= topMargin) {
          this.vehicleDetailFixed = true;
          this.renderer.setStyle(divVD, 'top', topMargin + 'px');
          this.renderer.setStyle(divVD, 'bottom', '');
        }
        else if (Math.floor(divVDCRect.bottom) <= Math.floor(divVDRect.bottom)) {
          this.vehicleDetailFixed = false;
          this.renderer.setStyle(divVD, 'top', '');
          this.renderer.setStyle(divVD, 'bottom', '0');
        }
        else {
          this.vehicleDetailFixed = true;
          this.renderer.setStyle(divVD, 'top', topMargin + 'px');
          this.renderer.setStyle(divVD, 'bottom', '');
        }
      };
    }
  }

  private setContainersHeight = () => {
    let divRTC = this.containerHeightWithRespectTo;
    let divVDC = this.divVehicleDetailContainer.nativeElement;
    let divVD = this.divVehicleDetail.nativeElement;

    if (divVDC && divVD && divVDC.getBoundingClientRect().width > 0)
      this.renderer.setStyle(divVD, 'width', divVDC.getBoundingClientRect().width + 'px');

    if (this._commonService.IsMobileDevice && divVDC && divVD) {
      this.renderer.setStyle(divVDC, 'height', 'auto');
      this.renderer.setStyle(divVD, 'position', 'relative');
    }
    else if (divRTC && divVDC && divVD && Math.floor(divRTC.getBoundingClientRect().height) != Math.floor(divVDC.getBoundingClientRect().height)) {
      this.renderer.setStyle(divVDC, 'height', divRTC.getBoundingClientRect().height + 'px');
      this.renderer.setStyle(divVD, 'position', 'absolute');
    }
  };

  ContinueClick = (eventData) => {
    this.continueClick.emit(eventData);
  }

  ChangeVehicleClick = (eventData) => {
    this.changeVehicleClick.emit(eventData);
  };
}
