<div class="recommend-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'dlg-recommend.recom-offer' | translate}}<hr /></h4>
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <form [formGroup]="recommendationForm" (ngSubmit)="onSubmit(recommendationForm.value)" novalidate>
    <mat-dialog-content>
      <div class="row table-heading">
        <span>{{'dlg-recommend.send-via-email' | translate}}</span><br />
        <span class="small">{{'dlg-recommend.recom-frends' | translate}}</span>
      </div>
      <div class="row table-row-seperator">
      </div>
      <div class="row table-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <mat-form-field class="input-full-width">
            <mat-label>{{'dlg-recommend.recipient-email' | translate}}</mat-label>
            <input matInput placeholder="{{'dlg-recommend.recipient-email' | translate}}" formControlName="toEmail" name="email" autocomplete="email">
            <mat-error *ngIf="recommendationForm.controls['toEmail'].hasError('pattern')">
              <span [innerHTML]="'validations.invalid-email' | translate"></span>
            </mat-error>
            <mat-error *ngIf="recommendationForm.controls['toEmail'].hasError('required')">
              <span [innerHTML]="'validations.req-email' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row table-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <mat-form-field class="input-full-width">
            <mat-label>{{'dlg-recommend.your-name' | translate}}</mat-label>
            <input matInput placeholder="{{'dlg-recommend.your-name' | translate}}" formControlName="fromName" name="fname" autocomplete="given-name">
            <mat-error *ngIf="recommendationForm.controls['fromName'].hasError('required')">
              <span [innerHTML]="'validations.req-name' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row table-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <mat-form-field class="input-full-width">
            <mat-label>{{'dlg-recommend.your-email' | translate}}</mat-label>
            <input matInput placeholder="{{'dlg-recommend.your-email' | translate}}" formControlName="fromEmail" name="email" autocomplete="email">
            <mat-error *ngIf="recommendationForm.controls['fromEmail'].hasError('pattern')">
              <span [innerHTML]="'validations.invalid-email' | translate"></span>
            </mat-error>
            <mat-error *ngIf="recommendationForm.controls['fromEmail'].hasError('required')">
              <span [innerHTML]="'validations.req-email' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row table-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <mat-form-field class="input-full-width" hintLabel="{{'common.max-500-chr' | translate}}">
            <mat-label>{{'dlg-recommend.opt-note' | translate}}</mat-label>
            <textarea matInput #OptionalNote placeholder="{{'dlg-recommend.opt-note' | translate}}" formControlName="optionalNotes" rows="2" maxlength="500"></textarea>
            <mat-hint align="end">{{OptionalNote.value?.length || 0}}/500</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row table-row-seperator">
      </div>
      <div class="row table-row" [ngStyle]="{'padding':isMobileDevice ? '10px 0' : '0'}" [ngClass]="{'padding-top-10 padding-bottom-10':isMobileDevice}">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <mat-checkbox formControlName="sendACopy">{{'dlg-recommend.send-copy' | translate}}</mat-checkbox>
        </div>
        <div class="col-md-7 col-lg-7">
        </div>
      </div>
      <hr />
      <div class="seperator hidden-xs hidden-sm"></div>
      <div class="row dlg-footer">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 padding-0">
          <p [innerHTML]="'dlg-recommend.note' | translate"></p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 padding-0" [ngClass]="{'text-center':isMobileDevice,'text-right':!isMobileDevice}">
          <div class="button-outer">
            <button type="submit" mat-flat-button color="primary" class="btn-send">{{'buttons.send' | translate}}</button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </form>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
