import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleInfoModule } from '../vehicle-info'

import { VehicleDetailSidebarComponent } from './vehicle-detail-sidebar.component';
import { Covid19VideoModule } from '../covid19-video';

@NgModule({
  imports: [CommonModule, VehicleInfoModule, Covid19VideoModule],
  declarations: [VehicleDetailSidebarComponent],
  exports: [VehicleDetailSidebarComponent]
})
export class VehicleDetailSidebarModule { }
