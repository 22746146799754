import { Action } from '@ngrx/store';

import { RecommendationEmailDto } from 'src/app/core/models';

export const LOAD_RECOMMENDATION_EMAIL = '[RecommendationEmail] Load Recommendation Email';
export const LOAD_RECOMMENDATION_EMAIL_FAIL = '[RecommendationEmail] Load Recommendation Email Fail';
export const LOAD_RECOMMENDATION_EMAIL_SUCCESS = '[RecommendationEmail] Load Recommendation Email Success';

export class LoadRecommendationEmail implements Action {
  readonly type = LOAD_RECOMMENDATION_EMAIL;
  constructor(public id: string) { }
}

export class LoadRecommendationEmailFail implements Action {
  readonly type = LOAD_RECOMMENDATION_EMAIL_FAIL;
  constructor(public payload: any) { }
}

export class LoadRecommendationEmailSuccess implements Action {
  readonly type = LOAD_RECOMMENDATION_EMAIL_SUCCESS;
  constructor(public payload: RecommendationEmailDto) { }
}

// action types
export type RecommendationEmailAction = LoadRecommendationEmail | LoadRecommendationEmailFail | LoadRecommendationEmailSuccess;
