import { Component, OnInit, OnDestroy, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { RateDto, ExtraRateDto, RateDetailDto, Currency, ShortRateExtra, CurrencyRates } from 'src/app/core/models';

import { RateService, RateExtraService, CommonService, CurrencyService } from 'src/app/core/services';

import { Subscription, Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { State, getCurrencyRatesLoaded, getAppSettings, getCurrencyRates } from 'src/app/store';

import { TranslateService } from '@ngx-translate/core';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'rate-details-dialog',
  templateUrl: './dlg-rate-details.component.html',
  styleUrls: ['./dlg-rate-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateDetailsDialogComponent implements OnInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  isCurrencyRatesLoaded$: Observable<boolean> = of(false);

  currencies: Currency[] = [];
  prevSelectedCurrencyCode: string;
  selectedCurrency: Currency;

  orgRate: RateDto = null;
  rate: RateDto = null;
  rateExtras: ShortRateExtra[] = [];
  baseCurrency: string;

  isMobileDevice: boolean = false;
  allowChoose: boolean = true;

  private translations: any;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private _rateService: RateService,
    private _rateExtraService: RateExtraService,
    private _translateService: TranslateService,
    private store: Store<State>) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.allowChoose = data.allowChoose !== false;

    let rate = JSON.parse(JSON.stringify(data.rate)) as RateDto;

    const extraMiles = this._rateExtraService.calcExtraMileage4Rate([], rate.totalFreeMiles, rate.vehicle.mileageCap, rate.rateDetail.ratePerMonth, rate.vehicle.mileageUnit);

    rate = {
      ...rate,
      totalMilesIncluded: extraMiles.totalExtraMiles,
      totalMilesIncludedDisplay: extraMiles.totalMilesIncludedDisplay
    };

    this.rate = {
      ...rate
    };

    this.rateExtras = [
      ...rate.extraRates.filter(x => x.totalCharge > 0)
    ];
  }

  ngOnInit(): void {
    this.isCurrencyRatesLoaded$ = this.store.pipe(select(getCurrencyRatesLoaded));

    this.currencies = CurrencyService.getAvailableCurrencies();

    const subs1 = this._translateService.get(['common.free', 'common.hour', 'common.day', 'common.week', 'common.month', 'common.one-time']).subscribe(
      (translation) => {
        this.translations = translation;
      }
    );

    const subs2 = this.store.pipe(
      select(getAppSettings),
      tap((appSettings) => {
        this.selectedCurrency = JSON.parse(JSON.stringify(appSettings.selectedCurrency));
        this.baseCurrency = appSettings.baseCurrency;
      })
    ).subscribe();

    this.allSubs.add(subs1);
    this.allSubs.add(subs2);
  }

  getExtraRatePerPeriod = (extra: ExtraRateDto, rateDetail: RateDetailDto): string => {
    return this._rateExtraService.getExtraRatePerPeriod1(extra, rateDetail.ratePlan, this.selectedCurrency.code, this.translations);
  };

  convertCurrency = (rate: RateDto, selectedValue, selectedText) => {

    if (!this.orgRate || this.orgRate === null) {
      this.orgRate = JSON.parse(JSON.stringify(this.rate)) as RateDto;
    }

    this.prevSelectedCurrencyCode = this.selectedCurrency.code;

    if (selectedValue === this.baseCurrency) {
      this.rate = {
        ...this.orgRate
      };
      this.rateExtras = [
        ...this.rate.extraRates.filter(x => x.totalCharge > 0)
      ];
      this.selectedCurrency = { code: selectedValue, displayText: selectedText, description: selectedText };
    }
    else {
      this.store.pipe(
        select(getCurrencyRates),
        tap((currencyRates: CurrencyRates) => {

          if (currencyRates && currencyRates.base) {
            this.rate = {
              ...this._rateService.convertRateCurrency(currencyRates, this.rate, rate, this.prevSelectedCurrencyCode, selectedValue) as RateDto
            };
            this.rateExtras = [
              ...this.rate.extraRates.filter(x => x.totalCharge > 0)
            ];
            this.selectedCurrency = { code: selectedValue, displayText: selectedText, description: selectedText };
          }
        }),
        first()
      ).subscribe();
    }
  };

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }
}
