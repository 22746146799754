import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State, logoutUser } from '../store';

import { CommonService, AuthenticationService, LoggingService } from '../core/services';

import { PAGES, LOG_TYPES } from '../core/enums';
import { loadUserInfo$ } from './common';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private _commonService: CommonService,
    private _logger: LoggingService,
    private _authService: AuthenticationService,
    private router: Router,
    private store: Store<State>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this._authService.isAuthenticated()) {
      return loadUserInfo$(this.store).pipe(
        map((isLoaded) => {
          return isLoaded;
        }),
        tap((isLoaded) => {
          if (!isLoaded) {
            this.router.navigate([this._commonService.getTranslatedRouteByKey(PAGES.LOGIN, route.params.lang)], { queryParams: { returnurl: state.url } });
          }
        }),
        catchError((err) => {
          this._logger.log(LOG_TYPES.ERROR, err.message);
          this.router.navigate([this._commonService.getTranslatedRouteByKey(PAGES.LOGIN, route.params.lang)], { queryParams: { returnurl: state.url } });
          return of(false);
        })
      );
    }
    else {
      this.store.dispatch(logoutUser());
      this.router.navigate([this._commonService.getTranslatedRouteByKey(PAGES.LOGIN, route.params.lang)], { queryParams: { returnurl: state.url } });
      return of(false);
    }
  }
}
