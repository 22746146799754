import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService } from "./config.service";

import { ServiceResult } from '../models';

@Injectable()
export class CountryStateService {

  constructor(private _configService: ConfigService, private http: HttpClient) {
  }

  fetchAllCountries = (language: string): Observable<ServiceResult> => {

    const url = this._configService.getCountriesApiUrl('', { lang: language });

    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError((err: any) => throwError(() => err))
      );
  };
}
