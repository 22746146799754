import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { ContainerSpinnerModule } from '../container-spinner';

import { ChangeEmailDialogComponent } from './dlg-change-email.component';
import { ChangeEmailDialogService } from './dlg-change-email.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatInputModule, MatButtonModule, ContainerSpinnerModule, TranslateModule],
  declarations: [ChangeEmailDialogComponent],
  exports: [ChangeEmailDialogComponent],
  providers: [ChangeEmailDialogService]
})
export class ChangeEmailDialogModule { }
