import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { interval, Observable, of, Subscription } from 'rxjs';
import { tap, first, catchError, take, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State, getAppSettingsLanguage, getRatesLoading } from 'src/app/store';

import { fade } from 'src/app/shared/animations/animations';

import { CommonService, LoggingService } from 'src/app/core/services';
import { LoaderLiteService } from '../loader-lite';
import { LOG_TYPES, PAGES } from 'src/app/core/enums';
import { environment } from 'src/environments/environment';
import { TimeoutError } from 'src/app/core/error-handlers/timeout-error';

@Component({
  selector: 'rates-loader',
  templateUrl: './rates-loader.component.html',
  styleUrls: ['./rates-loader.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesLoaderComponent implements OnInit {

  isMobileDevice$: Observable<boolean> = of(false);
  ratesLoading$: Observable<boolean> = of(false);
  language: string = 'en';

  private subsTimeout$: Subscription = new Subscription();

  constructor(
    private _commonService: CommonService,
    private _logger: LoggingService,
    private _loaderService: LoaderLiteService,
    private _router: Router,
    private store: Store<State>
  ) { }

  ngOnInit(): void {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
    this.ratesLoading$ = this.store.pipe(
      select(getRatesLoading),
      tap((loading) => {

        this.subsTimeout$.unsubscribe();


        if (loading) {
          const _timeout = environment.requestTimeout / 1000;

          const sub1 = interval(1000).pipe(
            take(_timeout),

            map((val) => {
              if (val + 1 >= _timeout) {
                throw new TimeoutError('source : rates-loader.component');
              }
              return val + 1;
            }),
            catchError((err) => {
              if (err instanceof TimeoutError) {

                this._logger.log(LOG_TYPES.ERROR, err.message);

                this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.ERROR_408, this._commonService.getCurrentLanguage()));
              }
              else if (err instanceof Error) {
                console.log(err.message)
              }
              else {
                console.log(JSON.stringify(err));
              }
              return of(0);
            })
          ).subscribe();

          this.subsTimeout$.add(sub1);

          this._loaderService.hide(true);
        }
      })
    );

    const sub2 = this.store.pipe(
      select(getAppSettingsLanguage),
      first(),
      tap((lang) => {
        this.language = lang;
      })
    ).subscribe();

    this.subsTimeout$.add(sub2);
  }
}
