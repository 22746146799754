import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CurrencyPipe } from "@angular/common";

import { ServiceResult, RateExtra, MileageCap, RateDto, RateDetailDto, ExtraRateDto, ShortRateExtra, CurrencyRates, SelectedExtra } from '../models';

import { CommonService, ConfigService, CurrencyService } from ".";

@Injectable()
export class RateExtraService {

  private extraChildrenCollection: { [code: string]: { [key: string]: string } } = {};

  constructor(
    private _commonService: CommonService,
    private _configService: ConfigService,
    private http: HttpClient,
    private _currencyPipe: CurrencyPipe) {
  }

  fetchExtras = (lang: string, branch: string, vehicleClass: string, ageGroup: string, rateSpecificExtras: string = "", discountedExtras: string = ""): Observable<RateExtra[]> => {
    const url = this._configService.getRateExtraApiUrl('', { lang, branch, vehicleClass, ageGroup, rateSpecificExtras, discountedExtras });

    return this.http.get<ServiceResult>(url)
      .pipe(
        map(result => result.data as RateExtra[]),
        catchError(e => throwError(() => e))
      );
  };

  getExtraRatePerPeriod1 = (extra: ExtraRateDto, ratePlan: string, selectedCurrency: string, translations: any): string => {
    let result: string = "";

    if (extra) {

      let _extra: RateExtra = {} as RateExtra;

      _extra = {
        ..._extra,
        id: extra.code,
        code: extra.code,
        extraDesc: [{ language: extra.extraDesc[0].language, shortDescription: '', description: extra.extraDesc[0].description, notes: extra.extraDesc[0].notes, displayPrice: null, ribbonText: "", icon: "" }],
        calcType: extra.calcType,
        qty: extra.qty,
        amount: extra.unitPrice,
        tierAmount: {
          daily: ratePlan.toLowerCase() === "daily" ? extra.unitPrice : 0.0,
          weekly: ratePlan.toLowerCase() === "weekly" ? extra.unitPrice : 0.0,
          monthly: ratePlan.toLowerCase() === "monthly" ? extra.unitPrice : 0.0
        },
        totalCharge: extra.totalCharge
      }

      result = this.getExtraRatePerPeriod2(_extra, ratePlan, selectedCurrency, translations);
    }
    return result;
  };

  getExtraRatePerPeriod2 = (extra: RateExtra, ratePlan: string, selectedCurrency: string, translations: any): string => {
    let result: string = "";

    if (extra) {
      if (extra.isFree)
        result = (translations ? translations['common.free'] : "FREE!");
      else if (extra.extraDesc[0].displayPrice && extra.extraDesc[0].displayPrice.rateText != "")
        result = extra.extraDesc[0].displayPrice.rateText
      else {

        const extraAmt: number = extra.amount;
        const extraDailyAmt: number = extra.tierAmount.daily;
        const extraWeeklyAmt: number = extra.tierAmount.weekly;
        const extraMonthlyAmt: number = extra.tierAmount.monthly;

        if (extra.calcType.toLowerCase() == "daily")
          result = this._currencyPipe.transform(extraAmt, selectedCurrency, 'symbol-narrow', '1.2-2', 'en') + "/" + (translations ? translations['common.day'] : "day");
        else if (extra.calcType.toLowerCase() == "one time" && extra.code.toLowerCase() == "usa")
          result = this._currencyPipe.transform(extraAmt, selectedCurrency, 'symbol-narrow', '1.2-2', 'en') + "/" + (translations ? translations['common.day'] : "day");
        else if (extra.calcType.toLowerCase() == "one time")
          result = this._currencyPipe.transform(extraAmt, selectedCurrency, 'symbol-narrow', '1.2-2', 'en') + "/" + (translations ? translations['common.one-time'] : "one-time");
        else if (extra.calcType.toLowerCase() == "rate tier") {
          if (ratePlan.toLowerCase() == "daily")
            result = this._currencyPipe.transform(extraDailyAmt, selectedCurrency, 'symbol-narrow', '1.2-2', 'en') + "/" + (translations ? translations['common.day'] : "day");
          else if (ratePlan.toLowerCase() == "weekly")
            result = this._currencyPipe.transform(extraWeeklyAmt, selectedCurrency, 'symbol-narrow', '1.2-2', 'en') + "/" + (translations ? translations['common.week'] : "week");
          else if (ratePlan.toLowerCase() == "monthly")
            result = this._currencyPipe.transform(extraMonthlyAmt, selectedCurrency, 'symbol-narrow', '1.2-2', 'en') + "/" + (translations ? translations['common.month'] : "month");
        }
        else
          result = "";
      }
    }
    return result;
  };

  calcAllTaxesTotalCharge = (rate: RateDto, taxableAmount: number): number => {
    let taxesTotalCharge: number = 0.0;

    if (rate) {
      for (let i = 0; i < rate.taxes.length; i++) {
        let taxCharge = 0.0;
        if (rate.taxes[i].chargeType == "%")
          taxCharge = Number((taxableAmount * (rate.taxes[i].taxRate / 100)).toFixed(2));
        else
          taxCharge = rate.taxes[i].taxRate;

        taxesTotalCharge += taxCharge;
      }
    }

    return Number(taxesTotalCharge.toFixed(2));
  };

  setMileageExtras = (extra: RateExtra, mileageExtras: RateExtra[], totalFreeMiles: number, totalExtraMiles: number, mileageCapValue: number): RateExtra[] => {

    let updatedExtras: RateExtra[] = [];

    const currentExtra = { ...extra };
    const allMilageExtras = [...mileageExtras];

    if (currentExtra.autoChecked && currentExtra.extraMiles === -1) {
      for (let i = 0; i < allMilageExtras.length; i++) {
        updatedExtras = [...updatedExtras, { ...allMilageExtras[i], autoChecked: false, totalCharge: 0.0, totalChargeChildren: 0.0, isFaded: true }];
      }
    }
    else if (!currentExtra.autoChecked && currentExtra.extraMiles === -1) {
      for (let i = 0; i < allMilageExtras.length; i++) {
        updatedExtras = [...updatedExtras, { ...allMilageExtras[i], isFaded: false }];
      }
    }
    else if (currentExtra.extraMiles > 0) {
      for (let i = 0; i < allMilageExtras.length; i++) {
        if (currentExtra.code != allMilageExtras[i].code) {
          if (!allMilageExtras[i].autoChecked && (totalFreeMiles + Math.abs(totalExtraMiles) + allMilageExtras[i].extraMiles) > mileageCapValue)
            updatedExtras = [...updatedExtras, { ...allMilageExtras[i], isFaded: true }];
          else if (allMilageExtras[i].isFaded)
            updatedExtras = [...updatedExtras, { ...allMilageExtras[i], isFaded: false }];
        }
      }
    }
    return updatedExtras;
  };

  calcExtraMileage = (rateExtras: RateExtra[]): number => {
    if (rateExtras.find(x => x.extraMiles == -1 && x.autoChecked)) // if extra is an unlimited mileage extra
      return -1;
    else {

      const _milageExtras = [...rateExtras.filter(x => x.extraMiles > 0 && x.autoChecked)];

      let extraMiles: number = 0;
      for (let i = 0; i < _milageExtras.length; i++) {
        extraMiles += _milageExtras[i].extraMiles;
      }

      return extraMiles;
    }
  };

  calcExtraMileage4Rate = (rateExtras: RateExtra[], totalFreeMiles: number, mileageCap: MileageCap, rentalMonths: number, mileageUnit: string): { totalExtraMiles: number, totalMilesIncludedDisplay: string } => {
    mileageUnit = mileageUnit.toLowerCase();

    let totalMilesIncludedDisplay = "";

    let totalExtraMiles = this.calcExtraMileage(rateExtras);

    if (totalExtraMiles == -1) {
      totalExtraMiles = mileageCap.monthly * Math.max(1, rentalMonths);
      totalMilesIncludedDisplay = "Unlimited";
    }
    else if (totalExtraMiles > 0) {
      totalMilesIncludedDisplay = totalFreeMiles + mileageUnit + " + " + totalExtraMiles + mileageUnit;
    }
    else {
      totalExtraMiles = totalFreeMiles;
      totalMilesIncludedDisplay = totalFreeMiles + mileageUnit; //totalFreeMiles ? totalFreeMiles + mileageUnit : "";
    }

    return { totalExtraMiles: totalExtraMiles, totalMilesIncludedDisplay: totalMilesIncludedDisplay };
  };

  calcShortRateExtrasTotalCharge = (rateExtras: ShortRateExtra[]): number => {
    let totalExtrasCharge: number = 0.0;
    for (let i = 0; i < rateExtras.length; i++) {
      totalExtrasCharge += CommonService.isNotANumber(rateExtras[i].totalCharge) ? 0.0 : Number(rateExtras[i].totalCharge);
    }
    return Number(totalExtrasCharge.toFixed(2));
  };

  calcExtrasTotalChargeByGroup = (groupName: string, extras: RateExtra[]): number => {
    const extrasToProcess = [...extras.filter(x => x.autoChecked && x.groupName.toLowerCase() == groupName.toLowerCase())];
    return this.calcAllExtrasTotalCharge(extrasToProcess);
  };

  calcAllExtrasTotalCharge = (selectedExtras: RateExtra[]): number => {
    let totalExtrasCharge: number = 0.0;
    const extrasToProcess = [...selectedExtras.filter(x => !x.isCumulativeChild)]; // exclude cumulative children as their rate is already included in the parent's rate.

    if (extrasToProcess) {
      for (let i = 0; i < extrasToProcess.length; i++) {
        totalExtrasCharge += CommonService.isNotANumber(extrasToProcess[i].totalCharge) ? 0.0 : Number(extrasToProcess[i].totalCharge);
        totalExtrasCharge += CommonService.isNotANumber(extrasToProcess[i].totalChargeChildren) ? 0.0 : Number(extrasToProcess[i].totalChargeChildren);
      }
    }

    return Number(totalExtrasCharge.toFixed(2));
  };

  calcExtraChildrenTotalCharge = (rateDetail: RateDetailDto, parentExtra: RateExtra): number => {
    let totalCharge: number = 0.0;
    const selectedChildren = parentExtra.children.filter(x => x.autoChecked);
    for (let j = 0; j < selectedChildren.length; j++) {
      totalCharge += this.calcChargeableAmount4Extra(selectedChildren[j], rateDetail);
    }
    return totalCharge;
  };

  applyExtraValidation = (extra: RateExtra, allExtras: RateExtra[], rateDetail: RateDetailDto, mileageCap: MileageCap, totalFreeMiles: number): RateExtra[] => {

    let rateExtras = JSON.parse(JSON.stringify(allExtras)) as RateExtra[];
    let updatedExtras: RateExtra[] = [
      {
        ...extra,
        totalCharge: this.calcExtraTotalCharge(extra, extra.autoChecked, rateDetail),
        totalChargeChildren: this.calcExtrasChildrenTotalCharge(extra, rateDetail)
      }
    ];


    // Start -> Set Cumulative Properties //

    const updatedCumulativeExtras = [...this.setCumulativeExtras(extra, rateExtras, rateDetail)];

    if (updatedCumulativeExtras.length > 0)
      updatedExtras = [...updatedCumulativeExtras];

    // end -> Set Cumulative Properties //

    rateExtras = [...this.mergeExtrasByReplacing(rateExtras, updatedExtras)];

    // Start -> Set mileage extra properties //

    let totalExtraMiles: number = this.calcExtraMileage(rateExtras);

    const mileageExtras = rateExtras.filter(x => x.extraMiles > 0).sort((a, b) => { return b.extraMiles - a.extraMiles; }); /* desc = b-a */

    const mileageCapValue = mileageCap.monthly * Math.max(1, rateDetail.rentalMonths);

    const updatedMileageExtras = [...this.setMileageExtras(extra, mileageExtras, totalFreeMiles, totalExtraMiles, mileageCapValue)];
    updatedExtras = [...this.mergeExtrasByReplacing(updatedExtras, updatedMileageExtras)];

    // end -> Set mileage extra properties //

    return updatedExtras;
  };

  private mergeExtrasByReplacing = (toExtras: RateExtra[], fromExtras: RateExtra[]) => {

    const filteredExtras = toExtras.reduce((filteredExtras: RateExtra[], extra: RateExtra) => {
      const _extra = filteredExtras.find(x => x.code == extra.code);

      if (_extra)
        return [...filteredExtras];
      else
        return [...filteredExtras, extra];

    }, fromExtras);

    return filteredExtras;
  };

  setCumulativeExtras = (extra: RateExtra, rateExtras: RateExtra[], rateDetail: RateDetailDto): RateExtra[] => {
    const allRateExtras = [...rateExtras];

    let result: RateExtra[] = [];

    if (allRateExtras && allRateExtras.length > 0) {

      const currentExtra = { ...extra };

      if (currentExtra) {
        if (currentExtra.cumulative.length > 0) {
          const cumulativeChildren = allRateExtras.filter(x => currentExtra.cumulative.indexOf(x.code) != -1);
          result = [...result, ...this.selectAllCumulativeExtras(currentExtra, cumulativeChildren, rateDetail)];
        }
        else {
          const cumulativeParentExtras = allRateExtras.filter(x => x.cumulative.indexOf(currentExtra.code) != -1);

          for (let i = 0; i < cumulativeParentExtras.length; i++) {

            const cumulativeChildren = allRateExtras.filter(x => cumulativeParentExtras[i].cumulative.indexOf(x.code) != -1);

            if (!cumulativeChildren.find(x => x.autoChecked == false && x.code != currentExtra.code))
              result = [...result, ...this.selectAllCumulativeExtras({ ...cumulativeParentExtras[i], autoChecked: true }, cumulativeChildren, rateDetail)];
          }
        }
      }
    }
    return result;
  };

  selectAllCumulativeExtras = (cumulativeParentExtra: RateExtra, cumulativeChildren: RateExtra[], rateDetail: RateDetailDto): RateExtra[] => {

    let result: RateExtra[] = [];

    let cumulativeChildrenTotalCharge: number = 0.0;

    for (let i = 0; i < cumulativeChildren.length; i++) {

      const autoChecked: boolean = cumulativeChildren[i].autoApply ? true : cumulativeParentExtra.autoChecked || cumulativeChildren[i].isFree;

      const cumulativeChild = {
        ...cumulativeChildren[i],
        qty: cumulativeChildren[i].qty <= 0 && autoChecked ? 1 : cumulativeChildren[i].qty,
        totalCharge: 0.0,
        totalChargeChildren: 0.0,
        autoChecked: autoChecked,
        isFaded: cumulativeParentExtra.autoChecked,
        isCumulativeChild: cumulativeParentExtra.autoChecked // CumulativeChild should be considered cumulative child only if parent is selected
      };

      result = [
        ...result,
        cumulativeChild
      ];

      const cumulativeChildExtraTotalCharge = this.calcExtraTotalCharge(cumulativeChild, cumulativeChild.autoChecked, rateDetail);

      if (!CommonService.isNotANumber(cumulativeChildExtraTotalCharge)) {
        cumulativeChildrenTotalCharge += Number(cumulativeChildExtraTotalCharge);
      }
    }

    let cumulativeParentExtraTotalCharge = this.calcExtraTotalCharge(cumulativeParentExtra, true, rateDetail);

    if (!CommonService.isNotANumber(cumulativeParentExtraTotalCharge)) {
      cumulativeParentExtraTotalCharge = cumulativeChildrenTotalCharge;
    }

    result = [...result, { ...cumulativeParentExtra, totalCharge: cumulativeParentExtra.autoChecked ? cumulativeParentExtraTotalCharge : 0.0 }];

    return result.sort((a, b) => { return a.sortOrder - b.sortOrder; });
  };

  calcChargeableAmount4Extra = (extra: RateExtra, rateDetail: RateDetailDto): number => {
    const extraDay: number = extra.addExtraDayIfLate && rateDetail.rentalHours > 0 ? 1 : 0;

    let extraPrice: number = 0.0;
    if (extra.calcType.toLowerCase() == "daily") {
      extraPrice = (extra.amount * (rateDetail.totalRentalDays + extraDay)) * extra.qty;
      extraPrice = extra.maxAmount > 0 ? Math.min(extraPrice, extra.maxAmount * extra.qty) : extraPrice;
    }
    else if (extra.calcType.toLowerCase() == "one time")
      extraPrice = extra.maxAmount > 0 ? Math.min((extra.amount * extra.qty), extra.maxAmount * extra.qty) : extra.amount * extra.qty;
    else if (extra.calcType.toLowerCase() == "rate tier") {
      if (rateDetail.rentalMonths > 0) {
        extraPrice += (extra.tierAmount.monthly * rateDetail.rentalMonths) * extra.qty;
      }

      if (rateDetail.rentalWeeks > 0) {
        extraPrice += (extra.tierAmount.weekly * rateDetail.rentalWeeks) * extra.qty;
      }

      if (rateDetail.rentalDays) {
        extraPrice += (extra.tierAmount.daily * rateDetail.rentalDays) * extra.qty;
      }

      if (extraDay > 0) {
        extraPrice += extra.tierAmount.daily * extra.qty;
      }

      extraPrice = extra.maxAmount > 0 ? Math.min(extraPrice, extra.maxAmount * extra.qty) : extraPrice;
    }
    return Number(extraPrice.toFixed(2));
  };

  calcExtraTotalCharge = (extra: RateExtra, apply: boolean, rateDetail: RateDetailDto): number | string => {
    let result: number | string = 0.0;

    if (extra.extraDesc[0].displayPrice && extra.extraDesc[0].displayPrice.amountText != "")
      result = extra.extraDesc[0].displayPrice.amountText;
    else {
      if (apply) {
        result = this.calcChargeableAmount4Extra(extra, rateDetail);
      }
    }

    return result;
  };

  calcExtrasChildrenTotalCharge = (parentExtra: RateExtra, rateDetail: RateDetailDto): number => {
    let totalPrice: number = 0.0;
    if (parentExtra && parentExtra.autoChecked && parentExtra.children && parentExtra.children.length > 0) {
      const childExtras: RateExtra[] = parentExtra.children.filter(x => x.autoChecked == true);
      for (let i = 0; i < childExtras.length; i++) {
        totalPrice += this.calcChargeableAmount4Extra(childExtras[i], rateDetail);
      }
    }
    return Number(totalPrice.toFixed(2));
  };

  getUpdatedSelectedExtras = (selectedExtras: RateExtra[], rateDetail: RateDetailDto, mileageCap: MileageCap, totalFreeMiles: number): RateExtra[] => {

    const cumulativeParents = selectedExtras.filter(x => x.cumulative.length > 0 && x.autoChecked);

    // fix: filtering selectedextras by autocheck again due to mileage extras where we have to pass unselected extras as well from base.gaurd.loadSelectedExtras
    const selExtras: RateExtra[] = selectedExtras.filter(x => !x.isCumulativeChild && x.autoChecked).reduce((selExtras: RateExtra[], extra: RateExtra) => {

      let flag: boolean = true;
      for (let i = 0; i < cumulativeParents.length; i++) {
        if (!!cumulativeParents[i].cumulative.find(x => x.toLowerCase() == extra.code.toLowerCase())) {
          flag = false;
          break;
        }
      }

      if (extra.parent == "" && flag) {
        let _extra: RateExtra = {
          ...extra,
          totalCharge: this.calcExtraTotalCharge(extra, extra.autoChecked, rateDetail),
          totalChargeChildren: this.calcExtraChildrenTotalCharge(rateDetail, extra)
        };

        const _extras = this.applyExtraValidation(_extra, selectedExtras, rateDetail, mileageCap, totalFreeMiles);

        return [...selExtras, ..._extras];
      }
      else {
        return [...selExtras];
      }
    }, []);

    return selExtras.sort((a, b) => { return a.sortOrder - b.sortOrder; });
  };

  convertRateExtraCurrency = (currencyRates: CurrencyRates, fromRateExtra: RateExtra, toRateExtra: RateExtra, fromCurrency: string, toCurrency: string, rateDetail: RateDetailDto): RateExtra => {

    const childExtras: RateExtra[] = toRateExtra.children.reduce((childExtras: RateExtra[], childExtra) => {
      const fromChildExtra = fromRateExtra.children.find(x => x.code == childExtra.code);

      return [
        ...childExtras,
        this.convertExtraCurrency(currencyRates, fromChildExtra, childExtra, fromCurrency, toCurrency, rateDetail)
      ];

    },
      []);

    toRateExtra = {
      ...this.convertExtraCurrency(currencyRates, fromRateExtra, toRateExtra, fromCurrency, toCurrency, rateDetail),
      children: childExtras
    };

    return toRateExtra;
  };

  private convertExtraCurrency = (currencyRates: CurrencyRates, fromRateExtra: RateExtra, toRateExtra: RateExtra, fromCurrency: string, toCurrency: string, rateDetail: RateDetailDto): RateExtra => {
    return {
      ...fromRateExtra,
      autoChecked: toRateExtra.autoChecked,
      qty: toRateExtra.qty,
      amount: CurrencyService.convertAmount(currencyRates, fromRateExtra.amount, fromCurrency, toCurrency),
      tierAmount: {
        monthly: CurrencyService.convertAmount(currencyRates, fromRateExtra.tierAmount.monthly, fromCurrency, toCurrency),
        weekly: CurrencyService.convertAmount(currencyRates, fromRateExtra.tierAmount.weekly, fromCurrency, toCurrency),
        daily: CurrencyService.convertAmount(currencyRates, fromRateExtra.tierAmount.daily, fromCurrency, toCurrency)
      },
      maxAmount: CurrencyService.convertAmount(currencyRates, fromRateExtra.maxAmount, fromCurrency, toCurrency),
      minAmount: CurrencyService.convertAmount(currencyRates, fromRateExtra.minAmount, fromCurrency, toCurrency),
      totalCharge: CommonService.isNotANumber(fromRateExtra.totalCharge) ? fromRateExtra.totalCharge : CurrencyService.convertAmount(currencyRates, fromRateExtra.totalCharge, fromCurrency, toCurrency),
      totalChargeChildren: CurrencyService.convertAmount(currencyRates, fromRateExtra.totalChargeChildren, fromCurrency, toCurrency),
    };
  };

  transformExtra2ShortExtras = (extra: RateExtra, rateDetail: RateDetailDto): ShortRateExtra[] => {

    let shorExtras: ShortRateExtra[] = [];

    const totalCharge: number = this.calcChargeableAmount4Extra(extra, rateDetail);

    shorExtras = [
      ...shorExtras,
      {
        code: extra.code,
        extraDesc: extra.extraDesc,
        groupName: extra.groupName,
        qty: extra.qty,
        calcType: extra.calcType,
        totalCharge: totalCharge,
        unitPrice: (totalCharge / rateDetail.totalRentalDays)
      }
    ];

    if (extra.children.length > 0) {
      const selectedChildren: RateExtra[] = extra.children.filter(x => x.autoChecked);

      if (selectedChildren.length > 0) {
        for (let i = 0; i < selectedChildren.length; i++) {
          const totalChargeOfChild: number = this.calcChargeableAmount4Extra(selectedChildren[i], rateDetail);
          shorExtras = [
            ...shorExtras,
            {
              code: selectedChildren[i].code,
              extraDesc: selectedChildren[i].extraDesc,
              groupName: selectedChildren[i].groupName,
              qty: selectedChildren[i].qty,
              calcType: selectedChildren[i].calcType,
              totalCharge: totalChargeOfChild,
              unitPrice: (totalChargeOfChild / rateDetail.totalRentalDays)
            }
          ];
        }
      }
      else {
        shorExtras = [];
      }
    }

    return shorExtras;
  };

  getSelectedChildren = (extra: RateExtra) => {
    return extra.parent == "" ?
      this.extraChildrenCollection[extra.code] :
      extra.parent != "" ?
        this.extraChildrenCollection[extra.parent] :
        {};
  };

  addChildExtraToCollection = (childExtra: RateExtra, childId: string): { [key: string]: string } => {
    if (this.extraChildrenCollection[childExtra.parent]) {
      let parentExtra = this.extraChildrenCollection[childExtra.parent];
      parentExtra[childId] = childExtra.code;
    }
    else {
      this.extraChildrenCollection = {
        ...this.extraChildrenCollection,
        ...{
          [childExtra.parent]: {
            [childId]: childExtra.code
          }
        }
      };
    }

    return this.extraChildrenCollection[childExtra.parent];
  };

  removeAllExtraChildrenFromCollection = (parentExtraCode: string): { [key: string]: string } => {
    const { [parentExtraCode]: removed, ...rest } = this.extraChildrenCollection;
    this.extraChildrenCollection = rest;

    return this.extraChildrenCollection[parentExtraCode];
  };

  removeChildExtraFromCollection = (parentExtraCode: string, childId: string): { [key: string]: string } => {
    if (this.extraChildrenCollection[parentExtraCode]) {
      const { [childId]: removed, ...rest } = this.extraChildrenCollection[parentExtraCode];
      this.extraChildrenCollection[parentExtraCode] = rest;
    }

    return this.extraChildrenCollection[parentExtraCode];
  };

  convertExtraRatesToExtraCodes = (shortRateExtras: ShortRateExtra[], allExtras: RateExtra[]): SelectedExtra[] => {
    return shortRateExtras.reduce((selExtras: SelectedExtra[], extra: ShortRateExtra) => {

      const _extra = allExtras.find(x => x.code.toLowerCase() === extra.code.toLowerCase());

      if (_extra) {

        let children = [];
        if (_extra.children && _extra.children.length > 0) {
          children = _extra.children.reduce((selChExtras: { code: string, qty: number }[], extra: RateExtra) => {
            const _chExt = shortRateExtras.find(x => x.code.toLowerCase() === extra.code.toLowerCase());
            if (_chExt) {
              return [
                ...selChExtras,
                {
                  code: _chExt.code,
                  qty: _chExt.qty
                }
              ];
            }
            else {
              return [...selChExtras];
            }
          }, []);
        }

        return [
          ...selExtras,
          {
            code: extra.code,
            qty: extra.qty,
            children: children
          }
        ];
      }
      else {
        return [...selExtras];
      }

    }, []);
  };

  convertShortRateExtrasToRateExtras = (shortRateExtras: ShortRateExtra[], allExtras: RateExtra[]): RateExtra[] => {
    const selExtras = this.convertExtraRatesToExtraCodes(shortRateExtras, allExtras);

    return allExtras.reduce((extras: RateExtra[], extra: RateExtra) => {
      const _extra = selExtras.find(x => x.code === extra.code);
      const _shortExtra = shortRateExtras.find(x => x.code === extra.code);

      if (_extra) {
        let totalChildrenCount: number = 0;
        let totalChildrenCharge: number = 0;
        const children = extra.children.reduce((children: RateExtra[], child: RateExtra) => {
          const _child = _extra.children.find(x => x.code === child.code);
          const _shortChildExtra = shortRateExtras.find(x => x.code === _child.code);

          if (_child) {
            totalChildrenCount += _child.qty;
            totalChildrenCharge += _shortChildExtra ? _shortChildExtra.totalCharge : 0;
            return [
              ...children,
              {
                ...child,
                autoChecked: true,
                qty: _child.qty,
                totalCharge: _shortChildExtra ? _shortChildExtra.totalCharge : 0
              }
            ];
          }
          else {
            return [...children, child];
          }

        }, []);

        return [
          ...extras,
          {
            ...extra,
            autoChecked: extra.children.length > 0 ? totalChildrenCount > 0 : true,
            qty: totalChildrenCount > 0 ? totalChildrenCount : _extra.qty,
            children: children,
            totalCharge: _shortExtra.totalCharge,
            totalChargeChildren: totalChildrenCharge
          }
        ];
      }
      else {
        return [...extras];
      }

    }, []);
  };
}
