import { Injectable, isDevMode, Inject } from '@angular/core';
import { Params } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

import { BehaviorSubject, Subscription, Subject } from 'rxjs';

import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { DeviceDetectorService } from 'ngx-device-detector';

import { PAGES, NOTIFY_TYPES } from '../enums';
import { ErrorDto, RateParams } from '../models';

import { ConfigService } from './config.service';

declare const hj: any;

@Injectable({ providedIn: 'root' })
export class CommonService {

  currentLanguage$: BehaviorSubject<string> = new BehaviorSubject("en");
  sessionObs: BehaviorSubject<boolean> = new BehaviorSubject(false);
  snackbarObs: BehaviorSubject<{ message: string, action: string, config: MatSnackBarConfig }> = new BehaviorSubject({ message: "", action: "", config: {} });
  bottomSheetObs: BehaviorSubject<{ message: string, notifyType: NOTIFY_TYPES, duration: number, delay?: number }> = new BehaviorSubject({ message: "", notifyType: NOTIFY_TYPES.Hidden, duration: 5000, delay: 500 });

  routeLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  collapseWidget$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  forcedCollapseWidget$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  triggerWidgetFindBestRate$: Subject<{ [key: string]: string } | RateParams> = new Subject();

  accountConfirmEmailCounter$: BehaviorSubject<number> = new BehaviorSubject(0);
  accountConfirmPhoneCounter$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private deviceService: DeviceDetectorService,
    private _configService: ConfigService,
    private logger: NGXLogger
  ) {

    const LogsApiEndPoint = this._configService.getLogApiUrl();

    if (isDevMode()) {
      this.logger.updateConfig({ serverLoggingUrl: LogsApiEndPoint, serverLogLevel: NgxLoggerLevel.ERROR, level: NgxLoggerLevel.OFF });
    }
    else {
      this.logger.updateConfig({ serverLoggingUrl: LogsApiEndPoint, serverLogLevel: NgxLoggerLevel.ERROR, level: NgxLoggerLevel.WARN });
    }
  }

  getClientId = () => {
    return localStorage && !!localStorage.getItem("_cl_id") ? localStorage.getItem("_cl_id") : "";
  };

  getBrowserName = () => {
    return this.deviceService.browser.toLowerCase();
  };

  getDisableAutoCompleteValue = (): string => {
    if (!!["firefox", "ie"].find(x => x === this.deviceService.browser.toLowerCase())) {
      return "off";
    }
    else {
      return "SorryNoAutoCompletePlease";
    }
  };

  public static convertNumberToArray(val: number): number[] {
    if (val > 0) {
      let valArray: number[] = [];
      for (let i = 1; i <= val; i++)
        valArray.push(i);

      return valArray;
    }

    return [];
  };

  get conversionTrackCode(): string {
    let _tc: string = "";
    if (!!localStorage?.getItem("_re_tc")) {
      const tc: { code: string; validUntil: Date } = JSON.parse(localStorage.getItem("_re_tc") as string);

      if (tc?.validUntil && new Date() > new Date(tc?.validUntil)) {
        localStorage.removeItem("_re_tc");
      }
      else {
        _tc = tc.code;
      }
    }
    return _tc;
  }

  private _previousRoute: string = "";
  get previousRoute(): string {
    return this._previousRoute;
  }
  set previousRoute(value: string) {
    this._previousRoute = value;
  }

  public IsMobileDeviceAsObservable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isMobileDevice: boolean = false;
  get IsMobileDevice(): boolean {
    return this._isMobileDevice;
  }
  set IsMobileDevice(value: boolean) {
    this.IsMobileDeviceAsObservable.next(value);
    this._isMobileDevice = value;
  }

  private _deviceBreakpoint: string = "";
  get deviceBreakpoint(): string {
    return this._deviceBreakpoint;
  }
  set deviceBreakpoint(value: string) {
    this._deviceBreakpoint = value;
  }

  private _IsAnimationsDisabled: boolean = false;
  get IsAnimationsDisabled(): boolean {
    return this._IsAnimationsDisabled;
  }
  set IsAnimationsDisabled(value: boolean) {
    this._IsAnimationsDisabled = value;
  }

  public static isNotANumber(value): boolean {
    return isNaN(value);
  }

  public isSpinTheWheelAvailable(brCode: string): boolean {
    if (ConfigService.spinTheWheelBranches.find(x => x.toUpperCase() == brCode.toUpperCase()))
      return true;
    else
      return false;
  };

  public static isNullOrWhiteSpace(value: string): boolean {
    value = value + ''; // make sure its always treated as string
    return !value || value === null || value.trim() === '' || value.trim().toLowerCase() === 'undefined' || value.trim().toLowerCase() === 'null';
  };

  public static isValidSlashKeyPress(event: any): boolean {
    const key = event.key ? event.key : undefined;
    if (key) {
      return /^\/$/.test(key);
    }
    else { // Legacy Support
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode === 47) {
        return true;
      }
      return false;
    }
  };

  public static isValidNumericKeyPress(event: any): boolean {
    const key = event.key ? event.key : undefined;
    if (key) {
      return /^\d+$/.test(key);
    }
    else { // Legacy Support
      const charCode = (event.which) ? event.which : event.keyCode;
      const digit = String.fromCharCode(charCode);
      if (!/^\d+$/.test(digit)) {
        return false;
      }
      return true;
    }
  };

  public static isControlKeyPress(event: any): boolean {
    const key = event.key ? event.key : undefined;
    if (key) {
      return /^(Backspace|Tab|Enter|Escape|Delete)$/i.test(key) || /^[acv]$/i.test(key) && (event.ctrlKey === true || event.metaKey === true);
    }
    else { // Legacy Support
      const charCode = (event.which) ? event.which : event.keyCode;
      if ([8, 9, 13, 27, 46].find(x => x == charCode) ||
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        ([65, 97, 86, 118, 67, 99].find(x => x == charCode) && (event.ctrlKey === true || event.metaKey === true))) {
        return true;
      }
      return false;
    }
  };

  public isValidUrlForWidget(url: string) {
    const validUrls = this._configService.appRoutes.filter(x => x.showWidget == true).reduce((validUrls: string[], route) => {
      return [...validUrls, route.en_url, route.fr_url];
    }, []);

    if (validUrls.find(x => url.toLowerCase().indexOf(x.toLowerCase()) != -1)) {
      return true;
    }
    else {
      return false;
    }
  };

  public isProtectedRoute(url: string): boolean {

    if (CommonService.isNullOrWhiteSpace(url)) {
      return false;
    }

    const urls = this._configService.appRoutes.filter(x => x.requireAuth).reduce((validUrls: string[], route) => {
      return [...validUrls, route.en_url, route.fr_url];
    }, []);

    if (urls.find(x => url.toLowerCase().indexOf(x.toLowerCase()) != -1)) {
      return true;
    }
    else {
      return false;
    }
  };

  public tagInHotJar(...args: string[]): void {
    try {
      if (typeof hj == 'function') {
        hj('tagRecording', args);
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public static updateAllFormFieldValueAndValidity(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.updateValueAndValidity({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        CommonService.updateAllFormFieldValueAndValidity(control);
      }
    });
  };

  public static confirmMatchValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root instanceof UntypedFormGroup) {
        const orgValue: string = control.root.get(fieldName) ? control.root.get(fieldName).value : "";
        const confValue: string = control.value;

        if (!CommonService.isNullOrWhiteSpace(orgValue) && CommonService.isNullOrWhiteSpace(confValue)) {
          return { 'required': { value: true } };
        }
        else if (orgValue && orgValue != '' && confValue.toLowerCase() != orgValue.toLowerCase()) {
          return { 'unmatched': { value: confValue } };
        }
      }
      return null;
    };
  };

  public static confirmUnmatchValidator (fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root instanceof UntypedFormGroup) {
        const orgValue: string = control.root.get(fieldName) ? control.root.get(fieldName).value : "";
        const confValue: string = control.value;

        if (!CommonService.isNullOrWhiteSpace(orgValue) && CommonService.isNullOrWhiteSpace(confValue)) {
          return { 'required': { value: true } };
        }
        else if (orgValue && orgValue != '' && confValue.toLowerCase() === orgValue.toLowerCase()) {
          return { 'matched': { value: confValue } };
        }
      }
      return null;
    };
  };

  public getCurrentLanguage(): string {
    return this.currentLanguage$.value || "";
  };

  public getAvailableLanguages(): string[] {
    return [...ConfigService.availableLangs];
  };


  public getAllRoutesByKey(keys: PAGES[]): string[] {
    let result: string[] = [];
    for (let i = 0; i < keys.length; i++) {
      const obj = this._configService.appRoutes.find(x => x.key === keys[i]);
      if (obj) {
        result = Object.getOwnPropertyNames(obj).reduce((values: string[], prop) => {
          if (prop.indexOf("_url") >= 0) {
            return [...values, `/${obj[prop]}`];
          }
          return [...values];
        }, [...result]);
      }
    }
    return result;
  };

  public getTranslatedRouteByUrl (url: string, lang: string, params: Params): string {

    const urlWithoutQueryString = url.split("?")[0];
    const urlQueryString = url.split("?")[1];

    let urlToMatch = urlWithoutQueryString;
    let paramsValues: string[] = [];
    if (params) {
      Object.keys(params).forEach(function (key) {
        var value = params[key];
        urlToMatch = urlToMatch.replace("/" + value, "");
        paramsValues = [...paramsValues, value];
      });
    }

    const obj = this._configService.appRoutes.find(x => `/${x.en_url}`.indexOf(urlToMatch) >= 0 || `/${x.fr_url}`.indexOf(urlToMatch) >= 0);
    if (obj) {

      const urlSegments1 = urlWithoutQueryString.startsWith("/") ? urlWithoutQueryString.split("/") : ("/" + urlWithoutQueryString).split("/");
      const urlSegments2 = obj[lang + "_url"].startsWith("/") ? obj[lang + "_url"].split("/") : ("/" + obj[lang + "_url"]).split("/");

      let translatedUrl = "";
      for (let i = 0; i < urlSegments1.length; i++) {
        if (!CommonService.isNullOrWhiteSpace(urlSegments1[i])) {
          if (urlSegments2[i]) {
            urlSegments1[i] = urlSegments2[i];
          }

          const paramValue = paramsValues.find(x => x === urlSegments1[i]);
          if (paramValue) {
            translatedUrl += `/${paramValue}`;
          }
          else {
            translatedUrl += `/${urlSegments2[i] ? urlSegments2[i] : urlSegments1[i]}`;
          }
        }
      }

      return translatedUrl + (urlQueryString ? `?${urlQueryString}` : "");
    }
    else
      return url;
  };

  public getTranslatedRouteByKey(key: PAGES, lang: string = ""): string {
    if (CommonService.isNullOrWhiteSpace(lang)) {
      lang = this.getCurrentLanguage();
    }
    const obj = this._configService.appRoutes.find(x => x.key === key);
    if (obj)
      return obj[lang + "_url"];
    else
      return "";
  };

  static get MainSiteUrl(): string {
    return ConfigService.mainSiteUrl;
  }
  static get RezEngineSiteUrl(): string {
    return ConfigService.rezEngineSiteUrl;
  }

  private static S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  public static getNewGUID() {
    return (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substring(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase();
  };

  public static getErrorsFromHttpErrorResponse = (result: any): ErrorDto[] => {
    let errors: ErrorDto[] = [];


    if (result instanceof HttpErrorResponse) {
      if (result.error) {

        if (result.error.status) {
          const data = result.error.data ? result.error.data : result.error;
          if (data.data && data.data.length > 0) {
            errors = [
              ...errors,
              {
                code: `${data.statusCode}`,
                errorMessage: data.status
              }
            ];
          }
          else {
            errors = [
              ...errors,
              {
                code: `${data.statusCode}`,
                errorMessage: JSON.stringify(data)
              }
            ];
          }
        }
        else if (result.error.exceptionMessage) {
          errors = [
            ...errors,
            {
              code: "500",
              errorMessage: JSON.stringify(result.error.exceptionMessage)
            }
          ];
        }
        else if (result.error.error_description) {
          errors = [
            ...errors,
            {
              code: "400",
              errorMessage: JSON.stringify(result.error.error_description)
            }
          ];
        }
        else if (result.error.modelState) {
          errors = result.error.modelState.errors.reduce((errors: ErrorDto[], error) => {
            return [
              ...errors,
              {
                code: "400",
                errorMessage: JSON.stringify(error)
              }
            ];
          }, [...errors]);
        }
        else if (result.error.message) {
          errors = [
            ...errors,
            {
              code: "400",
              errorMessage: JSON.stringify(result.error.message)
            }
          ];
        }
        else {
          errors = [
            ...errors,
            {
              code: "400",
              errorMessage: JSON.stringify(result.error)
            }
          ];
        }

      }
    }
    else if (result instanceof Error) {
      errors.push({ code: "1", errorMessage: result.message });
    }
    else {
      errors.push({ code: "999", errorMessage: JSON.stringify(result) });
    }
    return errors;
  }

}
