import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { State } from './_base';

import { appSettingsReducer } from './app-settings/app-settings.reducer';
import { currencyRatesReducer, CurrencyRatesEffects } from './currency-rates';
import { countryReducer, CountriesEffects } from './country';
import { branchReducer, BranchesEffects } from './branch';
import { vehicleReducer, VehiclesEffects } from './vehicle';
import { rateReducer, RatesEffects } from './rate';
import { extrasReducer, ExtrasEffects } from './rate-extra';
import { selectedRateReducer,SelectedRateEffects } from './selected-rate';
import { rezReducer, RezEffects } from './reservation';
import { recommendationEmailReducer, RecommendationEmailEffects } from './recommendation-email';
import { userReducer, UsersEffects } from './user';
import { myReservationsReducer, MyReservationsEffects } from './my-reservations';

export const effects: any[] = [CountriesEffects, BranchesEffects, VehiclesEffects, RatesEffects, SelectedRateEffects, CurrencyRatesEffects, ExtrasEffects, RezEffects, RecommendationEmailEffects, UsersEffects, MyReservationsEffects];

export const reducers: ActionReducerMap<State> = {
  appSettings: appSettingsReducer,
  currencyRates: currencyRatesReducer,
  countries: countryReducer,
  branches: branchReducer,
  vehicles: vehicleReducer,
  rates: rateReducer,
  extras: extrasReducer,
  selectedRate: selectedRateReducer,
  reservation: rezReducer,
  recommendationEmail: recommendationEmailReducer,
  user: userReducer,
  myReservations: myReservationsReducer
};

export const getAppStoreState = createFeatureSelector<State>('appState');

export * from './_base';
export * from './app-settings';
export * from './country';
export * from './branch';
export * from './vehicle';
export * from './currency-rates';
export * from './rate';
export * from './rate-extra';
export * from './selected-rate';
export * from './reservation';
export * from './recommendation-email';
export * from './user';
export * from './my-reservations';
