import { createReducer, on, Action } from '@ngrx/store';

import * as UserActions from './user.action';

import { IUserState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';
import { ResponseStatus } from 'src/app/core/models';
import { HttpErrorResponse } from '@angular/common/http';

const initialState: IUserState = {
  user: null,
  statusCode: STORE_STATUS_CODES.INITIAL,
  responseStatus: null,
  processing: false,
  processed: false
};

const _userReducer = createReducer(
  initialState,

  on(UserActions.loginUser, state => (
    {
      ...initialState,
      processing: true
    })
  ),

  on(UserActions.loadCurrentUserInfo, state => (
    {
      ...state,
      statusCode: STORE_STATUS_CODES.INITIAL,
      responseStatus: null,
      processing: true,
      processed: false
    })
  ),

  on(UserActions.loadCurrentUserInfoSuccess, (state, { payload }) => {
    return {
      ...state,
      user: payload,
      statusCode: STORE_STATUS_CODES.SUCCESS,
      responseStatus: { status: "OK", statusCode: 200 },
      processing: false,
      processed: true
    };
  }),

  on(UserActions.loadCurrentUserInfoFail, (state, { payload }) => {
    let resStatus: ResponseStatus = {
      statusCode: 400, status: payload ? payload.toString() : "Bad Request"
    };

    if (payload && payload.error_description) {
      resStatus = {
        statusCode: 400, status: payload.error_description
      };
    }
    else if (payload instanceof HttpErrorResponse) {
      if (payload.error && payload.error.exceptionMessage) {
        resStatus = {
          statusCode: 500, status: payload.error.exceptionMessage
        };
      }
      else if (payload.error && payload.error.error_description) {
        resStatus = {
          statusCode: 400, status: payload.error.error_description
        };
      }
      else if (payload.error && payload.error.message) {
        resStatus = {
          statusCode: 400, status: payload.error.message
        };
      }
    }

    return {
      ...state,
      statusCode: STORE_STATUS_CODES.FAILED,
      responseStatus: resStatus,
      processing: false,
      processed: false
    }
  }),

  on(UserActions.resetCurrentUserInfo, state => ({ ...state, ...initialState }))
);

export function userReducer(state: IUserState, action: Action) {
  return _userReducer(state, action);
}
