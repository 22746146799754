import { Action } from '@ngrx/store';

import { BranchDto } from 'src/app/core/models';

// load Branches
export const LOAD_BRANCHES = '[Branches] Load Branches';
export const LOAD_BRANCHES_FAIL = '[Branches] Load Branches Fail';
export const LOAD_BRANCHES_SUCCESS = '[Branches] Load Branches Success';

export const RESET_BRANCHES = '[Branches] Reset Branches';

export class LoadBranches implements Action {
  readonly type = LOAD_BRANCHES;
  constructor(public payload: { lang: string, pickupBranchCode: string, returnBranchCode: string }) { }
}

export class LoadBranchesSuccess implements Action {
  readonly type = LOAD_BRANCHES_SUCCESS;
  constructor(public branches: BranchDto[], public pickupBranch: BranchDto, public returnBranch: BranchDto) { }
}

export class LoadBranchesFail implements Action {
  readonly type = LOAD_BRANCHES_FAIL;
  constructor(public payload: any) { }
}

export class ResetBranches implements Action {
  readonly type = RESET_BRANCHES;
}

// action types
export type BranchesAction = LoadBranches | LoadBranchesFail | LoadBranchesSuccess | ResetBranches;
