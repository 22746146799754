import { Pipe, PipeTransform, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Pipe({
  name: 'decodeHTML'
})
export class DecodeHTML implements PipeTransform {

  constructor(@Inject(DOCUMENT) private doc: Document) {
  }

  public transform(value: string): string {
    if (this.doc) {
      let div = this.doc.createElement("div");
      div.innerHTML = value;
      return div.innerText;
    }
    else if (typeof $ === "function") {
      return $('<textarea />').html(value).text();
    }
    else {
      return value;
    }
  }
}
