import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatRadioModule } from '@angular/material/radio';

import { VehicleInfoModule } from '../vehicle-info';

import { RentalSummarySidebarComponent } from './rental-summary-sidebar.component';
import { CoverageDialogModule } from '../dlg-coverage/dlg-coverage.module';

import { TranslateModule } from '@ngx-translate/core';

import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, MatRadioModule, CoverageDialogModule, VehicleInfoModule, TranslateModule, TooltipModule],
  declarations: [RentalSummarySidebarComponent],
  exports: [RentalSummarySidebarComponent]
})
export class RentalSummarySidebarModule { }
