import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State } from '../store';

import { SessionService, LoggingService } from '../core/services';
import { LoaderLiteService } from '../components';

import { RateParams } from '../core/models';
import { LOG_TYPES } from '../core/enums';
import { loadVehicles$, loadRates$, loadRecommendedRates$ } from './common';

@Injectable()
export class RentACarGuard  {

  constructor(
    private _logger: LoggingService,
    private _sessionService: SessionService,
    private _loaderService: LoaderLiteService,
    private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this._loaderService.show();

    const vehicleType: string = route.data && route.data.vehicletype ? route.data.vehicletype : "";

    const session = this._sessionService.getSession();

    const pickupLoc: string = route.queryParams.pickuploc ? route.queryParams.pickuploc : session && session.rateParams ? session.rateParams.pickupBranch : "";
    const pickupDate: string = route.queryParams.pickupdate ? route.queryParams.pickupdate : session && session.rateParams ? session.rateParams.pickupDate : "";
    const pickupTime: string = route.queryParams.pickuptime ? route.queryParams.pickuptime : session && session.rateParams ? session.rateParams.pickupTime : "";
    const returnLoc: string = route.queryParams.returnloc ? route.queryParams.returnloc : session && session.rateParams ? session.rateParams.returnBranch : "";
    const returnDate: string = route.queryParams.returndate ? route.queryParams.returndate : session && session.rateParams ? session.rateParams.returnDate : "";
    const returnTime: string = route.queryParams.returntime ? route.queryParams.returntime : session && session.rateParams ? session.rateParams.returnTime : "";
    const ageGroup: string = route.queryParams.agegroup ? route.queryParams.agegroup : session && session.rateParams ? session.rateParams.ageGroup : "";
    const promoCode: string = route.queryParams.promocode ? route.queryParams.promocode : session && session.rateParams ? session.rateParams.discountCode : "";
    const vehicleCode: string = route.queryParams.vehicleclass ? route.queryParams.vehicleclass : session && session.rateParams ? session.rateParams.vehicleClasses : "";
    //const vehicleType: string = route.data && route.data.vehicletype ? route.data.vehicletype : "";

    const rateParams: RateParams = {
      pickupBranch: pickupLoc.toUpperCase(),
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      returnBranch: returnLoc.toUpperCase(),
      returnDate: returnDate,
      returnTime: returnTime,
      ageGroup: ageGroup,
      discountCode: promoCode,
      vehicleClasses: vehicleCode,
      language: route.params.lang,
      vehicleType: vehicleType
    };

    this._sessionService.setSession(
      {
        ...this._sessionService.getSession(),
        rateParams: rateParams
      }
    );

    return this.checkStore(route.params.lang, route.params.recommendid, vehicleType).pipe(
      switchMap(() => {
        this._loaderService.hide(true); return of(true);
      }),
      catchError((err) => {
        this._loaderService.hide(true);
        this._logger.log(LOG_TYPES.ERROR, err.message);
        return of(false);
      })
    );
  }

  checkStore(lang: string, recommendId: string, vehicleType: string): Observable<boolean> {

    return loadVehicles$(lang, this.store).pipe(
      switchMap((vehiclesLoaded) => {
        if (vehiclesLoaded && recommendId && recommendId != "") {
          return loadRecommendedRates$(lang, recommendId, this._sessionService, this.store, vehicleType);
        }
        else {
          loadRates$(lang, this._sessionService.getSession(), this.store).pipe(first()).subscribe();
          return of(true);
        }
      })
    );

  }
}
