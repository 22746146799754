import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderLiteComponent } from './loader-lite.component';
import { LoaderLiteService } from './loader-lite.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [LoaderLiteComponent],
  exports: [LoaderLiteComponent],
  providers: [LoaderLiteService]
})
export class LoaderLiteModule { }
