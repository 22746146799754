import { Action } from '@ngrx/store';

import { RateParams, RatesResult, RateDto } from 'src/app/core/models';

// load Rates
export const LOAD_RATES = '[Rates] Load Rates';
export const LOAD_RATES_FAIL = '[Rates] Load Rates Fail';
export const LOAD_RATES_SUCCESS = '[Rates] Load Rates Success';
export const RESET_RATES = '[Rates] Reset Rates';

export const UPDATE_RATES = '[Rates] Update Rates';

export const UPDATE_RATES_TRANSLATION = '[Rates] Update Rates Translation';
export const UPDATE_RATES_TRANSLATION_SUCCESS = '[Rates] Update Rates Translation Success';

export const UPDATE_SELECTED_RATE_STATUS = '[Rates] Update Selected Rate Status';

export class LoadRates implements Action {
  readonly type = LOAD_RATES;
  constructor(public payload: RateParams) { }
}

export class LoadRatesFail implements Action {
  readonly type = LOAD_RATES_FAIL;
  constructor(public payload: any) { }
}

export class LoadRatesSuccess implements Action {
  readonly type = LOAD_RATES_SUCCESS;
  constructor(public payload: RatesResult) { }
}

export class ResetRates implements Action {
  readonly type = RESET_RATES;
}

export class UpdateRates implements Action {
  readonly type = UPDATE_RATES;
  constructor(public payload: RatesResult) { }
}

export class UpdateRatesTranslation implements Action {
  readonly type = UPDATE_RATES_TRANSLATION;
  constructor(public language: string, public rateParams: RateParams) { }
}

export class UpdateSelectedRateStatus implements Action {
  readonly type = UPDATE_SELECTED_RATE_STATUS;
  constructor(public updatedRate: RateDto) { }
}

// action types
export type RatesAction = LoadRates | LoadRatesFail | LoadRatesSuccess | ResetRates | UpdateRates | UpdateRatesTranslation | UpdateSelectedRateStatus;
