import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { CustomCheckboxComponent } from './custom-checkbox.component';

import { SafePipeModule } from 'src/app/shared/pipes/safe-content.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, SafePipeModule],
  declarations: [CustomCheckboxComponent],
  exports: [CustomCheckboxComponent]
})
export class CustomCheckboxModule { }
