import { Reservation, ResponseStatus } from 'src/app/core/models';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

export interface IMyReservationsState {
  readonly reservations: Reservation[];
  readonly responseStatus: ResponseStatus;
  readonly statusCode: STORE_STATUS_CODES;
  readonly processing: boolean;
  readonly processed: boolean;
}
