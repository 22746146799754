import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ServiceResult, RateParams, Notification } from '../models';
import { ConfigService } from "./config.service";

@Injectable()
export class NotificationService {

  constructor(private _configService: ConfigService, private http: HttpClient) {
  }

  getNotifications = (params: RateParams): Observable<Notification[]> => {
    const url = this._configService.getNotificationApiUrl();

    return this.http.post<ServiceResult>(url, params)
      .pipe(
        map((result) => {
          if (result.data && result.data.length > 0) {
            return result.data as Notification[];
          }
          return []
        }),
        catchError(e => throwError(() => e))
      );
  };
}
