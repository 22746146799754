<mat-expansion-panel #pnlNotification class="panel" [expanded]="isNotificationVisible$ | async">
  <div class="outer-div" [ngClass]="{'hidden':notifyType === 0,'normal':notifyType === 1,'info':notifyType === 2,'success':notifyType === 3,'error':notifyType === 4,'no-spacing':!!noSpacing}" [ngStyle]="{'background-color': notifyType === 5 ? bgColor : '','color': notifyType === 5 ? textColor : '' }">
    <div class="row margin-0">
      <div *ngIf="notification !== '' || errors?.length > 0" class="col-xs-2 col-sm-1 col-md-1 col-lg-1 text-right icon" [ngClass]="{'small':!!smallSize}">
        <i *ngIf="notifyType === 1 || notifyType === 4" class="fas fa-exclamation-triangle" aria-hidden="true"></i>
        <i *ngIf="notifyType === 2" class="fas fa-info-circle" aria-hidden="true"></i>
        <i *ngIf="notifyType === 3" class="fas fa-check" aria-hidden="true"></i>
        <i *ngIf="notifyType === 5" class="fas {{icon}}" aria-hidden="true" [ngStyle]="{'color': iconColor }"></i>
      </div>
      <div class="col-xs-9 col-sm-10 col-md-10 col-lg-10 notify-text" [ngClass]="{'small':!!smallSize, 'limit-height':!!limitHeight}">
        <div #fader class="fader" (click)="limitHeight = !limitHeight;"></div>
        <div *ngIf="notification.length > 0" [innerHTML]="notification"></div>

        <div *ngIf="errors?.length > 0" class="errors">
          <h5>
            {{'validations.errors-title' | translate}}
          </h5>
          <ul>
            <li *ngFor="let error of errors">
              <span [innerHTML]="error.errorMessage"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!!limitHeight && (notification.length > 0 || errors?.length > 0)">
      <a class="lnk-more" (click)="limitHeight = !limitHeight;">
        <i class="far fa-angle-down" aria-hidden="true"></i> {{'buttons.more' | translate | lowercase}} <i class="far fa-angle-down" aria-hidden="true"></i>
      </a>
    </ng-container>
    <span *ngIf="isDismissible" class="btn-close" (click)="this.isNotificationVisible$.next(false);"><i class="far fa-times-circle" aria-hidden="true"></i></span>
  </div>
</mat-expansion-panel>
