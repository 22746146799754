<div class="date-picker" [formGroup]="parentFormGroup">
  <mat-form-field datePickerField class="input-full-width">
    <mat-label>{{placeHolderKey | translate}}</mat-label>
    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="datePicker" [formControlName]="formCtrlName" (keypress)="onDatePickerKeyPress($event)" (dateInput)="onDateInput($event)">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="datePicker">
      <i matDatepickerToggleIcon class="far fa-calendar-day icon-button"></i>
    </mat-datepicker-toggle>
    <mat-datepicker [touchUi]="isTouchUI" #datePicker startView="multi-year" panelClass="date-picker"></mat-datepicker>
    <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('invalidCtrlValue')">
      <span [innerHTML]="'validations.invalid-expiry' | translate"></span>
    </mat-error>
    <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('required')">
      <span [innerHTML]="'validations.req-expiry' | translate"></span>
    </mat-error>
  </mat-form-field>
</div>
