import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';

import { ServiceResult } from '../models';

@Injectable()
export class IP2LocationService {

  constructor(private _configService: ConfigService, private http: HttpClient) {
  }

  public fetchIP2Country(ipAddress: string = ""): Observable<any> {

    const locInfo = JSON.parse(localStorage.getItem("ip2location"));

    if (locInfo?.fetchedAt) {
      const expiry = new Date(new Date(locInfo.fetchedAt).getTime() + (240 * 60000)); // 240 mins / 4 hours

      if (expiry >= new Date()) {
        return of(locInfo.data);
      }
    }

    if (this._configService.IsLocalHost) {
      const data = JSON.parse('{"country":{"iso_code":"CA","geoname_id":6251999,"names":{"en":"Canada","fr":"Canada"}},"maxmind":{"queries_remaining":1214161},"registered_country":{"iso_code":"CA","geoname_id":6251999,"names":{"fr":"Canada","en":"Canada"}}}');
      localStorage.setItem("ip2location", JSON.stringify({ data: data, fetchedAt: new Date() }));
      return of(data);
    }

    const url = this._configService.getLocationIpApiUrl(`/country/${ipAddress}`);

    return this.http.get<ServiceResult>(url).pipe(
      tap((result) => {
        if (result?.data) {
          localStorage.setItem("ip2location", JSON.stringify({ data: result.data, fetchedAt: new Date() }));
        }
      }),
      map((result) => {
        return result.data;
      }),
      catchError(e => throwError(() => e))
    );
  }
}
