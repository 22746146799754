import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { RateExtraModule } from '../rate-extra';
import { NotifyAreaModule } from '../notify-area';
import { ContainerSpinnerModule } from '../container-spinner';

import { RateExtraDialogComponent } from './dlg-rate-extra.component';
import { RateExtraDialogService } from './dlg-rate-extra.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, RateExtraModule, NotifyAreaModule, ContainerSpinnerModule, TranslateModule],
    declarations: [RateExtraDialogComponent],
    exports: [RateExtraDialogComponent],
    providers: [RateExtraDialogService]
})
export class RateExtraDialogModule { }
