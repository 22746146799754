import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CommonService } from '../services';
import { PAGES } from '../enums';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private ignoreUrls = [
    /\/v1\/account\/.+\/token/i,
    /\/v1\/account\/.+\/logout/i,
  ];

  constructor(
    private _commonService: CommonService,
    private _router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        let shouldRedirect: boolean = [401, 403].indexOf(err.status) === -1; // ignore 401 Unauthorized or 403 Forbidden response returned from api

        if (shouldRedirect) {
          shouldRedirect = ErrorInterceptor.shouldIgnoreUrl(this.ignoreUrls, request.url); // SK: need to review
        }

        if (shouldRedirect) {
          if (err instanceof HttpErrorResponse && err.status >= 400 && err.status < 500)
            this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.ERROR_404, this._commonService.getCurrentLanguage()));
          else
            this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.ERROR_500, this._commonService.getCurrentLanguage()));
        }

        return throwError(() => err);
      })
    );
  }

  private static shouldIgnoreUrl(ignoreUrls: RegExp[], url: string): boolean {
    let flag: boolean = false;
    for (let i = 0; i < ignoreUrls.length; i++) {
      if (ignoreUrls[i].test(url)) {
        //flag = false;
        break;
      }
    }

    return flag;
  }
}
