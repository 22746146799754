import { Action } from '@ngrx/store';

import { Country } from 'src/app/core/models';

// load Branches
export const LOAD_COUNTRIES = '[Countries] Load Countries';
export const LOAD_COUNTRIES_FAIL = '[Countries] Load Countries Fail';
export const LOAD_COUNTRIES_SUCCESS = '[Countries] Load Countries Success';


export class LoadCountries implements Action {
  readonly type = LOAD_COUNTRIES;
  constructor(public lang: string) { }
}

export class LoadCountriesFail implements Action {
  readonly type = LOAD_COUNTRIES_FAIL;
  constructor(public payload: any) { }
}

export class LoadCountriesSuccess implements Action {
  readonly type = LOAD_COUNTRIES_SUCCESS;
  constructor(public countries: Country[]) { }
}

// action types
export type CountriesAction = LoadCountries | LoadCountriesFail | LoadCountriesSuccess;
