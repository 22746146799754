import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { CommonService, CreditCardValidatorService } from 'src/app/core/services';

@Component({
  selector: 'cc-textbox',
  templateUrl: './cc-textbox.component.html',
  styleUrls: ['./cc-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CcTextboxComponent implements OnInit, OnDestroy {

  @ViewChild('cctextbox', { static: true }) ccTextbox: ElementRef;

  private allSubs: Subscription = new Subscription();
  
  @Input() parentFormGroup: UntypedFormGroup = null;
  @Input() formCtrlName: string = "";
  @Input() value: string = "";
  @Input() validationEnabled: boolean = false;

  @Output() ccNoEditClick = new EventEmitter<any>();
  @Output() ccMatchingTypesChange = new EventEmitter<string[]>();

  isCcInEditMode: boolean = false;

  constructor(private _commonService: CommonService, private _ccValidatorService: CreditCardValidatorService) { }

  ngOnInit() {

    if (!this.parentFormGroup) {
      this.parentFormGroup = new UntypedFormGroup({
        ccNo: new UntypedFormControl()
      });
      this.formCtrlName = "ccNo";
    }
    else {
      if (!this.value || this.value == "") {
        const value = this.parentFormGroup.get(this.formCtrlName).value;
        if (value)
          this.setValue(value);
      }
      else if (this.value != "" && this.value.length > 0) {
        this.setValue(this.value);

        if (this.value.length >= 4) {
          this.parentFormGroup.get(this.formCtrlName).disable();
        }
      }
    }

    if (this.validationEnabled) {
      this.parentFormGroup.get(this.formCtrlName).setValidators([
        Validators.required,
        Validators.maxLength(20),
        this.ccNoValidator()
      ]);

      this.parentFormGroup.get(this.formCtrlName).updateValueAndValidity();
    }
  }

  private setValue = (value: string) => {
    if (value) {
      this.value = value.replace(/[-\s\.]+/g, "");
    }

    if (!value || value == "") {
      this.ccMatchingTypesChange.emit([]);
    }
  };

  private ccNoValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value && control.value != "") {
        const isValid = this._ccValidatorService.validateCreditCard(control.value);
        return !isValid ? { 'invalidCC': { value: control.value } } : null;
      }
      return null;
    };
  };

  hasCCNoContainsXXXX = (ccNo): boolean => {
    if (ccNo && /(^([xX]{4}[\s?])+\d+)/.test(ccNo) && this.isCcInEditMode == false) {
      return true;
    }
    else {
      return false;
    }
  };

  CCNoEditClick = () => {
    const ctrl = this.parentFormGroup.get(this.formCtrlName);

    if (ctrl) {
      ctrl.setValue("");
      ctrl.enable();

      this.ccNoEditClick.emit();

      this.isCcInEditMode = true;
    }
  };

  onCCKeyup = (event: any) => {
    const ccMatchingTypes = [...this._ccValidatorService.getCreditCardMatchingTypes(event.target.value)];
    this.ccMatchingTypesChange.emit(ccMatchingTypes);
  };

  onCCKeyPress = (event) => {
    if (CommonService.isControlKeyPress(event))
      return true;

    if (CommonService.isValidNumericKeyPress(event)) {
      return true;
    }
    return false;
  };

  onCCKeyDown = (event) => {
    const value = this._ccValidatorService.formatBackCardNumber(event);
    if (value) {
      this.parentFormGroup.get(this.formCtrlName).setValue(value);
    }
  };

  onBlur = (event) => {
    this.parentFormGroup.get(this.formCtrlName).setValue(this._ccValidatorService.formatCardNumber(event.target.value));
  };

  onCCPaste = (event) => {
    setTimeout(() => {
      this.parentFormGroup.get(this.formCtrlName).setValue(this._ccValidatorService.formatCardNumber(event.target.value));
      const ccMatchingTypes = [...this._ccValidatorService.getCreditCardMatchingTypes(event.target.value)];

      this.ccMatchingTypesChange.emit(ccMatchingTypes);
    }, 100);
  };

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }
}
