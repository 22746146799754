import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { tap, first } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { State, getBranchesSource } from 'src/app/store';

import { BranchDto } from 'src/app/core/models';

@Component({
  selector: 'change-branch-dialog',
  templateUrl: './dlg-change-branch.component.html',
  styleUrls: ['./dlg-change-branch.component.scss']
})
export class ChangeBranchDialogComponent implements OnInit {

  isMobileDevice = false;

  pickupBranchCode = "TMR";
  returnBranchCode = "TMR";

  pickupBranches: BranchDto[] = [];
  returnBranches: BranchDto[] = [];

  filteredReturnBranches: BranchDto[] = [];

  prevSelPickupBranchCode = "";
  vehiclePickupAvailableAt = "";
  vehicleReturnAvailableAt = "";

  pickupBranchesToExclude = ["CST"];
  returnBranchesToExclude = ["CST"];

  constructor(
    private dialogRef: MatDialogRef<object>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store<State>) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.prevSelPickupBranchCode = data.pickupBranchCode as string;
    this.vehiclePickupAvailableAt = data.pickupAvailableAt as string;
    this.vehicleReturnAvailableAt = data.returnAvailableAt as string;
  }

  ngOnInit(): void {
    this.store.pipe(
      select(getBranchesSource),
      tap((branches) => {

        const groupCode: string = branches.find(x => x.code.toUpperCase() === this.prevSelPickupBranchCode.toUpperCase()).groupName[0].code;

        const pickupAvailableAt = this.vehiclePickupAvailableAt.split(",").filter(x => this.pickupBranchesToExclude.find(y => y.toUpperCase() !== x.toUpperCase()) !== undefined);
        const returnAvailableAt = this.vehicleReturnAvailableAt.split(",").filter(x => this.returnBranchesToExclude.find(y => y.toUpperCase() !== x.toUpperCase()) !== undefined);

        this.pickupBranches = branches.reduce((_branches, branch) => {
          if (pickupAvailableAt.find(x => x.toUpperCase() === branch.code.toUpperCase() && branch.groupName.find(x => x.code === groupCode) !== undefined)) {
            return [
              ..._branches,
              branch
            ];
          }
          else
            return [..._branches];
        }, []);

        this.setDefaultPickupBranch();

        this.returnBranches = branches.reduce((_branches, branch) => {

          if (returnAvailableAt.find(x => x.toUpperCase() === branch.code.toUpperCase() && branch.groupName.find(x => x.code === groupCode) !== undefined)) {
            return [
              ..._branches,
              branch
            ];
          }
          else
            return [..._branches];
        }, []);

        this.setFilteredReturnBranches(this.pickupBranchCode);
      }),
      first()
    ).subscribe();
  }

  setDefaultPickupBranch = () => {
    if (this.pickupBranches.find(x => x.code.toUpperCase() === "TMR"))
      this.pickupBranchCode = "TMR";
    else
      this.pickupBranchCode = this.pickupBranches[0].code;
  };

  setFilteredReturnBranches = (pickupBranchCode: string) => {
    if (pickupBranchCode.toUpperCase() === "CST" || pickupBranchCode.toUpperCase() === "PRT") {
      this.filteredReturnBranches = this.returnBranches.filter(x => x.code.toUpperCase() === "CST" || x.code.toUpperCase() === "PRT");
    }
    else {
      this.filteredReturnBranches = this.returnBranches.filter(x => x.code.toUpperCase() !== "CST" && x.code.toUpperCase() !== "PRT");
    }

    if (this.filteredReturnBranches.find(x => x.code.toUpperCase() === pickupBranchCode.toUpperCase()))
      this.returnBranchCode = pickupBranchCode;
    else if (this.filteredReturnBranches.find(x => x.code.toUpperCase() === "TMR"))
      this.returnBranchCode = "TMR";
    else
      this.returnBranchCode = this.filteredReturnBranches[0].code;
  };

  onPickupChange = (eventData) => {
    this.pickupBranchCode = eventData.value;
    this.setFilteredReturnBranches(eventData.value);
  };

  onReturnChange = (eventData) => {
    this.returnBranchCode = eventData.value;
  };

  onContinueClick = () => {
    this.dialogRef.close({ pickupLoc: this.pickupBranchCode, returnLoc: this.returnBranchCode });
  };

  closeDialog(): void {
    this.dialogRef.close();
  }
}
