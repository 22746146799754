import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Event } from 'src/app/core/events/dialog.events'
import { ProcessState, ErrorDto } from 'src/app/core/models';

@Injectable()
export class ConfirmWithPwdDialogService {

  public readonly events: Observable<Event> = new Subject<Event>();
  public readonly processState: Observable<ProcessState> = new Subject<ProcessState>();

  constructor() {
  }

  triggerEvent(event: Event): void { (this.events as Subject<Event>).next(event); }

  startProcess = (data: any = null): void => {
    return (this.processState as Subject<ProcessState>).next({ isStarted: true, isCompleted: false, data: data, errors: [] });
  }

  endProcess = (data: any = null, errors: ErrorDto[] = []): void => {
    return (this.processState as Subject<ProcessState>).next({ isStarted: false, isCompleted: true, data: data, errors: errors });
  }
}
