import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { MessageDialogComponent } from './dlg-message.component';

import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'src/app/shared/pipes/safe-content.module';

@NgModule({
    imports: [CommonModule, MatDialogModule, TranslateModule, SafePipeModule],
    declarations: [MessageDialogComponent],
    exports: [MessageDialogComponent]
})
export class MessageDialogModule { }
