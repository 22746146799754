import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';

import { SmsDialogComponent } from './dlg-sms.component';

import { IntlTelInputModule } from '../intl-tel-input';
import { ContainerSpinnerModule } from '../container-spinner';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, ReactiveFormsModule, IntlTelInputModule, ContainerSpinnerModule, TranslateModule],
    declarations: [SmsDialogComponent],
    exports: [SmsDialogComponent]
})
export class SmsDialogModule { }
