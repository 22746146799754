import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ServiceResult } from '../models';

import { ConfigService } from "./config.service";

@Injectable()
export class VehicleService {

  constructor(private _configService: ConfigService, private http: HttpClient) { }

  fetchAllVehicles(lang: string): Observable<ServiceResult> {
    const url = this._configService.getVehiclesApiUrl('', { lang });

    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError(
          (e: any) => {
            return throwError(() => e);
          })
      );
  }
}
