import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { CcTextboxComponent } from './cc-textbox.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatButtonModule, TranslateModule],
  declarations: [CcTextboxComponent],
  exports: [CcTextboxComponent]
})
export class CcTextboxModule { }
