import { Action } from '@ngrx/store';

import { CurrencyRates, Currency } from 'src/app/core/models';

export const LOAD_CURRENCY_RATES = '[CurrencyRates] Load Currency Rates';
export const LOAD_CURRENCY_RATES_FAIL = '[CurrencyRates] Load Currency Rates Fail';
export const LOAD_CURRENCY_RATES_SUCCESS = '[CurrencyRates] Load Currency Rates Success';

export class LoadCurrencyRates implements Action {
  readonly type = LOAD_CURRENCY_RATES;
  constructor(public payload: string) { }
}

export class LoadCurrencyRatesFail implements Action {
  readonly type = LOAD_CURRENCY_RATES_FAIL;
  constructor(public payload: any) { }
}

export class LoadCurrencyRatesSuccess implements Action {
  readonly type = LOAD_CURRENCY_RATES_SUCCESS;
  constructor(public payload: CurrencyRates) { }
}

// action types
export type CurrencyRatesAction = LoadCurrencyRates | LoadCurrencyRatesFail | LoadCurrencyRatesSuccess;
