import { UserDto, ResponseStatus } from 'src/app/core/models';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

export interface IUserState {
  readonly user: UserDto;
  readonly statusCode: STORE_STATUS_CODES;
  readonly responseStatus: ResponseStatus;
  readonly processing: boolean;
  readonly processed: boolean;
}
