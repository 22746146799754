import { Injectable } from '@angular/core';

import { Reservation, ShortDiscount } from '../models';

import { LoggingService } from '.';
import { LOG_TYPES } from '../enums';

declare const fbq: any;
declare let dataLayer;

@Injectable()
export class AnalyticsService {

  constructor(private _logger: LoggingService) { }

  googleAnalytics = (rez: Reservation, baseCurrency: string, adWordsConvLabel: string) => {
    try {
      if (dataLayer) {

        const disount: ShortDiscount =
          rez.rate.discount ? rez.rate.discount :
            {
              discountCode: "",
              displayLabel: "",
              discountValue: 0,
              discountType: "",
              extrasIncluded: []
            };

        let txnItems = [
          {
            item_id: rez.rate.vehicle.code,
            item_name: rez.rate.vehicle.vehicleDesc[0].modelDesc,
            affiliation: rez.rateParams.pickupBranch,
            coupon: disount.discountCode,
            discount: disount.discountValue,
            index: 0,
            item_category: rez.rate.vehicle.vehicleType,
            item_category2: rez.rate.vehicle.vehicleDesc[0].classDesc,
            price: (rez.rate.rateSummary.rateBeforeTaxes / rez.rate.rateDetail.totalRentalDays).toFixed(2),
            quantity: rez.rate.rateDetail.totalRentalDays
          }
        ];

        for (let i = 0; i < rez.rate.extraRates.length; i++) {
          txnItems.push(
            {
              item_id: rez.rate.extraRates[i].code,
              item_name: rez.rate.extraRates[i].extraDesc[0].description,
              affiliation: '',
              coupon: '',
              discount: 0,
              index: i + 1,
              item_category: 'Extras',
              item_category2: rez.rate.extraRates[i].groupName,
              price: rez.rate.extraRates[i].unitPrice.toFixed(2),
              quantity: rez.rate.extraRates[i].qty
            }
          );
        }

        // Clear the previous ecommerce object.
        dataLayer.push({ ecommerce: null });

        dataLayer.push({
          event: "purchase",

          //For E-Commerce GA4 Event (purchase)
          ecommerce: {
            transaction_id: rez.reservationNo,
            value: (rez.rate.rateSummary.totalCharges - rez.rate.rateSummary.totalTaxes).toFixed(2),
            tax: rez.rate.rateSummary.totalTaxes.toFixed(2),
            currency: baseCurrency,
            coupon: disount.discountCode,
            items: txnItems
          },

          // For Adwords conversion
          'AdWords_Conv_Label': adWordsConvLabel,
          'AdWords_Conv_Value': rez.rate.rateSummary.totalCharges.toFixed(2),
          'AdWords_Conv_OrderId': rez.reservationNo,

          // For Enhanced conversion
          'Enhanced_Conv_Cust_Email': rez.customer.email.toLowerCase(),
          'Enhanced_Conv_Cust_Phone': AnalyticsService.formatPhoneNo(rez.customer.phoneNo),
          'Enhanced_Conv_Cust_First_Name': rez.customer.firstName.toLowerCase(),
          'Enhanced_Conv_Cust_Last_Name': rez.customer.lastName.toLowerCase(),
          'Enhanced_Conv_Cust_Street': rez.customer.addresses[0].address1.toLowerCase(),
          'Enhanced_Conv_Cust_City': rez.customer.addresses[0].city.toLowerCase(),
          'Enhanced_Conv_Cust_State': rez.customer.addresses[0].state.toLowerCase(),
          'Enhanced_Conv_Cust_PostCode': rez.customer.addresses[0].postCode.toLowerCase(),
          'Enhanced_Conv_Cust_Country': rez.customer.addresses[0].country.toLowerCase(),
        });
      }
    } catch (e) {
      this._logger.log(LOG_TYPES.ERROR, e.message);
    }
  };

  facebookAnalytics = (rez: Reservation, baseCurrency: string) => {
    try {
      if (typeof fbq == 'function') {
        fbq('track', 'Purchase', {
          content_type: 'product',
          content_ids: [rez.rate.rateDetail.rateId, rez.reservationNo],
          content_category: rez.rate.vehicle.vehicleType,
          content_name: rez.rate.vehicle.vehicleDesc[0].modelDesc,
          value: rez.rate.rateSummary.totalCharges,
          currency: baseCurrency,
          confirmation_no: rez.reservationNo
        });
      }
    } catch (e) {
      this._logger.log(LOG_TYPES.ERROR, e.message);
    }
  };

  private static formatPhoneNo = (phone) => {
    if (phone) {
      return "+" + phone.replace(/[+\s()\-.]+/g, "")
    }
    else {
      return "";
    }
  };
}
