import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State, ResetRates, ResetExtras, ResetSelectedRate, ResetReservation } from '../store';

import { SessionService, CommonService, LoggingService } from '../core/services';
import { LoaderLiteService } from '../components';

import { Session } from '../core/models/session.model';
import { PAGES, LOG_TYPES } from '../core/enums';

import { loadBranches$, loadRateExtras4Rez$, loadReservation$ } from './common';

@Injectable()
export class RezIsLoadedGuard  {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
    private _commonService: CommonService,
    private _logger: LoggingService,
    private _loaderService: LoaderLiteService,
    private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this._loaderService.show();

    if (route.params.id != "") {
      return this.checkStore(route.params.lang, route.params.id).pipe(
        switchMap(() => {
          this._loaderService.hide();
          this._commonService.sessionObs.next(true);
          return of(true);
        }),
        catchError((err) => {
          this._loaderService.hide();
          this._logger.log(LOG_TYPES.ERROR, err.message);
          this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang)).then(
            (status) => {
              if (status) {
                this.store.dispatch(new ResetRates());
                this.store.dispatch(new ResetExtras());
              }
            }
          );
          return of(false);
        })
      );
    }
    else {
      this._loaderService.hide();
      this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang)).then(
        (status) => {
          if (status) {
            this.store.dispatch(new ResetReservation());
            this.store.dispatch(new ResetRates());
            this.store.dispatch(new ResetExtras());
            this.store.dispatch(new ResetSelectedRate());
          }
        }
      );

      return of(false);
    }
  }

  checkStore(lang: string, id: string): Observable<boolean> {

    return loadReservation$(lang, this._sessionService.getSession(), this.store, id).pipe(
      tap((obj) => {
        if (obj) {
          if (obj.responseStatus.statusCode === 40091 || obj.responseStatus.statusCode === 40092) {
            this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.CONFIRMATION, lang) + "/" + id);
          }
          else {

            this.store.dispatch(new ResetSelectedRate());

            let session: Session = {
              ...this._sessionService.getSession(),
              rateParams: null, //rez.reservation.rateParams,
              selectedRateId: "",
              isModify: false
            };

            this._sessionService.setSession(session);

            if (obj.reservation && obj.reservation.id) {
              session = {
                ...this._sessionService.getSession(),
                reservation: { reservationNo: obj.reservation.reservationNo, lastName: obj.reservation.customer.lastName }
              };

              this._sessionService.setSession(session);

              loadBranches$(obj.reservation.rateParams, this.store).pipe(first()).subscribe();
              loadRateExtras4Rez$(lang, this._sessionService, this.store, obj.reservation).pipe(first()).subscribe();
            }
          }
        }
      }),
      switchMap((obj) => {
        return of(obj.responseStatus.statusCode === 200);
      })
    );
  }
}
