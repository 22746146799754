import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { ContainerSpinnerModule } from '../container-spinner';

import { ConfirmDialogComponent } from './dlg-confirm.component';
import { ConfirmDialogService } from './dlg-confirm.service';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, ContainerSpinnerModule, TranslateModule],
    declarations: [ConfirmDialogComponent],
    exports: [ConfirmDialogComponent],
    providers: [ConfirmDialogService]
})
export class ConfirmDialogModule { }
