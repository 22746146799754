import { Provider, forwardRef, Directive, Input, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const MODIFIER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntlTelInputDirective),
  multi: true,
};

@Directive({
  selector: '[valueModifier]',
  providers: [MODIFIER_CONTROL_VALUE_ACCESSOR],
})
export class IntlTelInputDirective implements ControlValueAccessor {

  @Input() valueModifier: [Function, Function];

  private writeToForm;

  constructor(public _el: ElementRef) { }

  @HostListener('click', ['$event'])
  @HostListener('keyup', ['$event'])
  @HostListener('focusout', ['$event'])
  onInputEvent(event) {
    const viewToForm = this.valueModifier[0];
    this.writeToForm(viewToForm(event.target.value));
  }

  registerOnChange(fn: (value) => void) {
    this.writeToForm = fn;
  }

  registerOnTouched(fn) {
    // nothing to do
  }

  writeValue(value) {
    const formToView = this.valueModifier[1];
    this._el.nativeElement.value = formToView(value);
  }
}
