<mat-list>
  <mat-list-item>
    <span matListItemIcon *ngIf="notifyType === 2">
      <i class="fas fa-info-circle" aria-hidden="true"></i>
    </span>
    <span matListItemIcon *ngIf="notifyType === 3 || notifyType === 4">
      <svg *ngIf="notifyType === 3" class="circle success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="stroke" cx="26" cy="26" r="25" fill="none" />
        <path class="inner-shape" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
      <svg *ngIf="notifyType === 4" class="circle error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="stroke" cx="26" cy="26" r="25" fill="none" />
        <line class="inner-shape" fill="none" x1="12" y1="13" x2="40" y2="40" />
        <line class="inner-shape" fill="none" x1="40" y1="13" x2="12" y2="40" />
      </svg>
    </span>
    <span matListItemTitle *ngIf="notification !== ''" [innerHTML]="notification"></span>
    <button type="button" mat-icon-button (click)="dismiss()" matListItemMeta class="btn-dismiss">
      <i class="fas fa-times fa-lg" aria-hidden="true"></i>
    </button>
  </mat-list-item>
</mat-list>
