import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { Observable, of } from 'rxjs';
import { CommonService } from 'src/app/core/services';
import { VehicleDto } from 'src/app/core/models';

@Component({
  selector: 'vehicle-other-features',
  templateUrl: './vehicle-other-features.component.html',
  styleUrls: ['./vehicle-other-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleOtherFeaturesComponent implements OnInit {

  @Input() vehicle: VehicleDto = null;

  @Input() hideFuel: boolean = false;
  @Input() hideRange: boolean = true;
  @Input() hideLuggageCap: boolean = false;
  @Input() hidePassengerCap: boolean = false;
  @Input() hideElectric: boolean = false;
  @Input() hideTransmission: boolean = false;
  @Input() hideACInfo: boolean = false;
  @Input() hideMinDriverAge: boolean = false;

  isMobileDevice$: Observable<boolean> = of(false);

  constructor(private _commonService: CommonService) { }

  ngOnInit() {

    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

  }
}
