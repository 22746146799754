import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { combineLatest, timer, Observable, throwError } from 'rxjs';
import { filter, tap, take, catchError } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { CommonService, SmsService, UserService } from 'src/app/core/services';
import { NOTIFY_TYPES } from 'src/app/core/enums';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'verify-code-dialog',
  templateUrl: './dlg-verify-code.component.html',
  styleUrls: ['./dlg-verify-code.component.scss']
})
export class VerifyCodeDialogComponent implements OnInit {

  private userId: string = "";
  private phoneNumber: string = "";
  private language: string = "en";

  formattedPhoneNumber: string = "";

  isMobileDevice: boolean = false;
  showSpinner: boolean = false;

  counter$: Observable<number>;

  secRemaining: number;
  resendBtnValue: string;

  private transGeneralErrMsg: string = "";
  private transInvalidCode: string = "";
  private transCodeSentMsg: string = "";
  private transResendCode: string = "";
  private transSuccessMsg: string = "";

  code = new UntypedFormControl('', [Validators.required, Validators.pattern(/[\d]{0,6}/)]);

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private _smsService: SmsService,
    private _userService: UserService,
    private _translate: TranslateService
  ) {
    this.isMobileDevice = this._commonService.IsMobileDevice;
    this.userId = data.userId as string;
    this.phoneNumber = data.phoneNumber as string;
    this.formattedPhoneNumber = !!this.phoneNumber ? "+" + this.phoneNumber.replace(/[\+\s\(\)\-\.]+/g, "") : "";
    this.language = data.lang as string;
  }

  ngOnInit(): void {
    this.counter$ = this._commonService.accountConfirmPhoneCounter$;
    this.counter$.pipe(
      tap((value) => {
        if (value === 0) {
          this.resendCodeTimer();
        }
      }),
      take(1)
    ).subscribe();

    this._translate.get(
      [
        "validations.general-error-msg",
        "validations.invalid-code",
        "dlg-verify-code.code-sent",
        "dlg-verify-code.resend-code",
        "dlg-verify-code.success-msg"
      ],
      {
        phoneNo: this.formattedPhoneNumber
      }
    ).pipe(
      filter(t => t && t != ""),
      tap((t) => {
        this.transGeneralErrMsg = t["validations.general-error-msg"];
        this.transInvalidCode = t["validations.invalid-code"];
        this.transCodeSentMsg = t["dlg-verify-code.code-sent"];
        this.transResendCode = t["dlg-verify-code.resend-code"];
        this.transSuccessMsg = t["dlg-verify-code.success-msg"];

        this.resendBtnValue = this.transResendCode;
      }),
      take(1)
    ).subscribe();
  }

  onResendCodeClick = () => {
    this.resendCodeTimer();
  };

  private resendCodeTimer = () => {
    if (!CommonService.isNullOrWhiteSpace(this.phoneNumber) && !CommonService.isNullOrWhiteSpace(this.userId)) {
      this.setSpinner(true);

      combineLatest([
        this._userService.confirmPhoneNumber(this.language),
        timer(1000), // To delay at least 1000ms to see spinner animation.
      ],
        (result, _t) => result
      ).pipe(
        filter(result => !!result.data),
        take(1),
        tap((result) => {

          if (result.data?.succeeded) {
            this._commonService.bottomSheetObs.next({ message: this.transCodeSentMsg, notifyType: NOTIFY_TYPES.Success, duration: 10000 });
            this._smsService.startAccountConfirmPhoneCounter();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        })
      ).subscribe({
        complete: () => {
          this.setSpinner(false);
        },
        error: e => {
          let isAlreadyVerified: boolean = e.error?.statusCode === 40097;
          if (isAlreadyVerified) {
            this._commonService.bottomSheetObs.next({ message: this.transSuccessMsg, notifyType: NOTIFY_TYPES.Success, duration: 5000 });
            this.closeDialog(true);
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
          this.setSpinner(false);
        }
      });
    }
  };

  onKeyPress = (event) => {
    if (CommonService.isControlKeyPress(event))
      return true;

    if (CommonService.isValidNumericKeyPress(event)) {
      return true;
    }
    return false;
  };

  onKeyUp = (event) => {
    if (!!event.target.value && event.target.value.length >= 6) {
      this.onVerifyCodeClick();
    }
  };

  onVerifyCodeClick = () => {
    this.code.markAsTouched({ onlySelf: true });
    if (!CommonService.isNullOrWhiteSpace(this.phoneNumber) && !CommonService.isNullOrWhiteSpace(this.code.value) && !CommonService.isNullOrWhiteSpace(this.userId)) {

      this.setSpinner(true);

      combineLatest([
        this._userService.verifyPhoneNumber(this.userId, this.code.value),
        timer(1000), // To delay at least 1000ms to see spinner animation.
      ],
        (result, _t) => result
      ).pipe(
        ///filter(result => result?.data),
        take(1),
        tap((result) => {
          if (result.data?.succeeded) {
            this._commonService.bottomSheetObs.next({ message: this.transSuccessMsg, notifyType: NOTIFY_TYPES.Success, duration: 5000 });
            this.closeDialog(true);
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transInvalidCode, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        }),
        catchError(e => throwError(() => e))
      ).subscribe({
        complete: () => {
          this.setSpinner(false);
        },
        error: e => {
          if (e.error && e.error.statusCode === 40018) {
            this._commonService.bottomSheetObs.next({ message: this.transInvalidCode, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        }
      });
    }
  };

  private setSpinner = (show: boolean = false) => {
    this.showSpinner = show;
    this.dialogRef.disableClose = show;
  };

  closeDialog(success): void {
    this.dialogRef.close(success);
  }
}
