import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'cvv-dialog',
  templateUrl: './dlg-cvv.component.html',
  styleUrls: ['./dlg-cvv.component.scss']
})
export class CvvDialogComponent {

  isMobileDevice: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isMobileDevice = data.isMobileDevice as boolean;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
