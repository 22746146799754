import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State } from '../store';

import { CommonService, LoggingService } from '../core/services';
import { LoaderLiteService } from '../components';

import { PAGES, LOG_TYPES } from '../core/enums';
import { loadBranches$, loadRecommendationEmailData$ } from './common';

@Injectable()
export class RecommendationEmailGuard  {

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _logger: LoggingService,
    private _loaderService: LoaderLiteService,
    private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this._loaderService.show();

    if (route.params.id != "") {
      return this.checkStore(route.params.id).pipe(
        switchMap(() => {
          this._loaderService.hide();
          return of(true);
        }),
        catchError((err) => {
          this._loaderService.hide();
          this._logger.log(LOG_TYPES.ERROR, err.message);
          this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang));

          return of(false);
        })
      );
    }
    else {
      this._loaderService.hide();
      this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang));

      return of(false);
    }
  }

  checkStore(id: string): Observable<boolean> {
    return loadRecommendationEmailData$(id, this.store).pipe(
      tap((recEmail) => {
        if (recEmail.isLoaded) {
          loadBranches$(recEmail.recommendationEmail.rateParams, this.store).pipe(first()).subscribe();
        }
      }),
      switchMap((recEmail) => {
        return of(recEmail.isLoaded);
      })
    );
  }
}
