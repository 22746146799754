import { NgModule } from '@angular/core';

import { SnapengageComponent } from './snapengage.component';
import { SafePipeModule } from 'src/app/shared/pipes/safe-content.module';

@NgModule({
  imports: [SafePipeModule],
  declarations: [SnapengageComponent],
  exports: [SnapengageComponent]
})
export class SnapengageModule { }
