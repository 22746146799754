export enum LOCATION_TYPES {
  Pickup = 1,
  Return = 2
}

export enum VEHICLE_TYPES {
  CAR = 1,
  TRUCK = 2
}

export enum NOTIFY_TYPES {
  Hidden = 0,
  Normal = 1,
  Information = 2,
  Success = 3,
  Error = 4,
  custom = 5
}

export enum PAGES {
  RENT_A_CAR = 0,
  RENT_A_TRUCK = 1,
  VEHICLE_EXTRAS = 2,
  CUSTOMER_DETAILS = 3,
  CONFIRMATION = 4,
  CHECK_IN_START = 5,
  CHECK_IN = 6,
  VIEW_RESERVATION = 7,
  MODIFY_RESERVATION = 8,
  CANCEL_RESERVATION = 9,
  REQUEST_RECEIPT = 10,
  REQUEST_EXTENSION = 11,
  SESSION_EXPIRED = 12,
  ERROR_404 = 13,
  ERROR_500 = 14,
  PAGE_NOT_FOUND = 15,
  VIEW_CONFIRMATION_EMAIL = 16,
  VIEW_CANCELLATION_EMAIL = 17,
  VIEW_RECOMMENDATION_EMAIL = 18,
  REGISTER = 19,
  LOGIN = 20,
  MY_PROFILE = 21,
  CHANGE_PASSWORD = 22,
  FORGOT_PASSWORD = 23,
  PASSWORD_RESET = 24,
  VERIFY_EMAIL = 25,
  MY_RESERVATIONS = 26,
  VIEW_CHECKIN_REMINDER_EMAIL = 27,
  SPIN_THE_WHEEL = 28,
  ERROR_408 = 29,
}

export enum LOG_TYPES {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5
}

export enum STORE_STATUS_CODES {
  INITIAL = 0,
  SUCCESS = 200,
  FAILED = 500
}

export enum DATE_FORMAT_TYPES {
  SHORT = 0,
  LONG = 1,
  TIME = 3
}

export enum MESSAGE_BOX_BUTTONS {
  OkOnly = 0,
  CancelOnly = 1,
  OkCancel = 3,
  YesOnly = 4,
  NoOnly = 5,
  YesNo = 6
}

export enum RECOMMENDATION_TYPES {
  RateList = 0,
  Rate = 1
}

export enum REZ_STATUSES {
  Initiated = "Initiated",
  Posted = "Posted",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled"
}

export enum REZ_ACTIONS {
  Unknown = "Unknown",
  New = "New",
  Update = "Update",
  CheckIn = "CheckIn",
  Cancel = "Cancel"
}

export enum PAYMENT_MODE {
  CreditCard = "CreditCard",
  Stripe = "Stripe"
}
