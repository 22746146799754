<div class="rez-widget" [ngClass]="{'mobile':IsMobileDevice$ | async}">
  <div class="container">

    <div #rezWidget [@fadeIn]="(hideWidget$ | async) === true ? 'off' : 'on'" [@flash]="widgetFlashState$ | async" (@flash.start)="flashAnimationStarted($event)" (@flash.done)="resetAnimationState()" class="rezWidget" data-rate-call-method="angular" [ngStyle]="{'display':(hideWidget$ | async) ? 'none' : 'block'}" data-theme="white-2"></div>

    <div [@fadeIn] *ngIf="hideWidget$ | async" class="row selected" (click)="onChangeClick()">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0" matTooltip="{{'tooltips.rw-edit' | translate}}" [matTooltipClass]="{'mat-tooltip-override':true}">
        <div *ngIf="(pickupBranch$ | async) as pickupBranch" class="col-xs-12 col-sm-6 col-md-4 col-lg-4 rez-widget-col">
          <h2>{{'common.pickup' | translate | uppercase}}</h2>
          <p *ngIf="pickupBranch?.addresses?.length" class="branch">{{pickupBranch.addresses !== null ? pickupBranch.addresses[0].name : ""}}</p>
          <p *ngIf="(rateParams$ | async) as rateParams" class="datetime"><i class="far fa-calendar-day" aria-hidden="true"></i>&nbsp;{{formatDateTimeExact(rateParams?.pickupDate)}} &nbsp;&nbsp; <i class="far fa-clock" aria-hidden="true"></i>&nbsp;{{formatDateTime(rateParams?.pickupDate,rateParams?.pickupTime, ('common.lang' | translate)==="en"? "hh:mma": "HH:mm")}}</p>
        </div>
        <div *ngIf="(returnBranch$ | async) as returnBranch" class="col-xs-12 col-sm-6 col-md-4 col-lg-4 rez-widget-col border-left">
          <h2>{{'common.return' | translate | uppercase}}</h2>
          <p *ngIf="returnBranch?.addresses?.length" class="branch">{{returnBranch.addresses !== null ? returnBranch.addresses[0].name : ""}}</p>
          <p *ngIf="(rateParams$ | async) as rateParams" class="datetime"><i class="far fa-calendar-day" aria-hidden="true"></i>&nbsp;{{formatDateTimeExact(rateParams?.returnDate)}} &nbsp;&nbsp; <i class="far fa-clock" aria-hidden="true"></i>&nbsp;{{formatDateTime(rateParams?.returnDate,rateParams?.returnTime, ('common.lang' | translate)==="en"? "hh:mma": "HH:mm")}}</p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div class="row">
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 rez-widget-col border-left">
              <h2>{{'common.rental-period' | translate | uppercase}}</h2>
              <p *ngIf="(rateParams$ | async) as rateParams" class="branch">{{totalRentalDays}} {{totalRentalDays | checkplural:'common.days':'common.day' | translate}}</p>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 rez-widget-col text-right">
              <button type="button" class="btn-main btn-white small" (click)="onChangeClick()">{{'common.change' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
