<div class="confirm-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 *ngIf="showHeading" mat-dialog-title class="header">{{headingText | translate}}<hr /></h4>
  <mat-dialog-content>
    <div class="container-fluid">
      <i class="fas fa-exclamation-triangle fa-3x color-orange" aria-hidden="true" [ngClass]="{'fa-pull-left':!isSingleLineText || isMobileDevice, 'fa-3x':!isMobileDevice, 'fa-2x':isMobileDevice}"></i>
      <span class="text" [innerHTML]="confirmMsg | translate:params" [ngClass]="{'single-line':isSingleLineText}"></span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="customButtons?.length <= 0" align="center" class="actions">
    <button type="button" *ngIf="buttons === 0 || buttons === 3" class="btn-main btn-orange" (click)="onButtonClick('ok')">{{'buttons.ok' | translate}}</button>
    <button type="button" *ngIf="buttons === 4 || buttons === 6" class="btn-main btn-orange" (click)="onButtonClick('yes')">{{'buttons.yes' | translate}}</button>
    &nbsp;&nbsp;
    <button type="button" *ngIf="buttons === 1 || buttons === 3" class="btn-main btn-white" (click)="onButtonClick('cancel')">{{'buttons.cancel' | translate}}</button>
    <button type="button" *ngIf="buttons === 5 || buttons === 6" class="btn-main btn-white" (click)="onButtonClick('no')">{{'buttons.no' | translate}}</button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf="customButtons?.length > 0" align="center" class="actions customButtons">
    <button type="button" *ngFor="let button of customButtons" class="btn-main {{button.cssClass}}" [ngClass]="{'full-Width':button.fullWidth}" (click)="onButtonClick(button.returnCommandText)">
      <span *ngIf="button.isTextTranslateKey">{{button.text | translate}}</span>
      <span *ngIf="!button.isTextTranslateKey">{{button.text}}</span>
    </button>
  </mat-dialog-actions>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
