import { createSelector } from '@ngrx/store';
import { State, IMyReservationsState } from '../_base';

export const selectMyReservationsState = (state: State) => state.myReservations;

export const getMyReservations = createSelector(selectMyReservationsState, (state: IMyReservationsState) => state.reservations);
export const getMyReservationsResponseStatus = createSelector(selectMyReservationsState, (state: IMyReservationsState) => state.responseStatus);
export const getMyReservationsStatus = createSelector(selectMyReservationsState, (state: IMyReservationsState) => state.statusCode);
export const getMyReservationsProcessing = createSelector(selectMyReservationsState, (state: IMyReservationsState) => state.processing);
export const getMyReservationsProcessed = createSelector(selectMyReservationsState, (state: IMyReservationsState) => state.processed);

export const getMyReservationsState = createSelector(
  getMyReservationsProcessed,
  getMyReservationsStatus,
  getMyReservationsResponseStatus,
  getMyReservations,
  (processed, statusCode, resStatus,reservations) => {
    return {
      isProcessed: processed,
      statusCode: statusCode,
      responseStatus: resStatus,
      reservations: reservations
    };
  }
);

export const getMyReservationsLoadState = createSelector(
  getMyReservationsProcessing,
  getMyReservationsProcessed,
  getMyReservationsStatus,
  getMyReservationsResponseStatus,
  (processing, processed, statusCode, resStatus) => {
    return {
      processing: processing,
      processed: processed,
      statusCode: statusCode,
      responseStatus: resStatus
    };
  }
);
