import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services';
import { Observable } from 'rxjs';


@Component({
  selector: 'covid-19-video',
  templateUrl: './covid-19-video.component.html'
})
export class Covid19VideoComponent implements OnInit {

  readonly mainSiteUrl: string = CommonService.MainSiteUrl;
  readonly rezEngineSiteUrl: string = CommonService.RezEngineSiteUrl;

  IsMobileDevice$: Observable<boolean>;

  constructor(private _commonService: CommonService) { }

  ngOnInit() {
    this.IsMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
  }
}
