import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { RateExtraService } from 'src/app/core/services';
import { RateExtra, Currency, RateDetailDto, MileageCap } from 'src/app/core/models';

@Component({
  selector: 'rate-extra-list',
  templateUrl: './rate-extra-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateExtraListComponent implements OnInit {

  @Output() extrasUpdated = new EventEmitter<any>();

  @Input() baseCurrency: string = "";
  @Input() selectedCurrency: Currency = {} as Currency;

  //@Input() selectedRate: RateDto = null;
  @Input() rateExtras: RateExtra[] = [];
  @Input() rateDetail: RateDetailDto = null;
  @Input() mileageCap: MileageCap = null;
  @Input() totalFreeMiles: number = 0;

  @Input() parentForm: UntypedFormGroup;

  constructor(
    private _rateExtraService: RateExtraService
  ) { }

  ngOnInit() {
    // Start -> Prepare Selected Children struct //

    for (let k = 0; k < this.rateExtras.length; k++) {
      const extra = { ...this.rateExtras[k] };
      if (extra.children.length > 0) {
        this._rateExtraService.removeAllExtraChildrenFromCollection(extra.code);
        let counter: number = 0;

        for (let i = 0; i < extra.children.length; i++) {
          if (extra.children[i].autoChecked) {
            for (let j = 0; j < extra.children[i].qty; j++) {
              counter += 1;
              this._rateExtraService.addChildExtraToCollection(extra.children[i], "Child" + counter);
            }
          }
        }
      }
    }

    // end -> Prepare Children struct //
  }

  onSelectExtra = (eventData: { extra: RateExtra }) => {

    const extra = { ...eventData.extra };
    this.applyExtraValidation(extra);

  };

  private applyExtraValidation = (extra: RateExtra) => {
    let updatedExtras = this._rateExtraService.applyExtraValidation(extra, this.rateExtras, this.rateDetail, this.mileageCap, this.totalFreeMiles);

    updatedExtras = this.SetAnimationStatus4Extras(updatedExtras);

    this.extrasUpdated.emit(updatedExtras);
  };

  private SetAnimationStatus4Extras = (extras: RateExtra[]): RateExtra[] => {
    const allExtras = [...this.rateExtras];
    let updatedExtras: RateExtra[] = [];

    for (let i = 0; i < extras.length; i++) {
      if (allExtras.find(x => x.code == extras[i].code && x.autoChecked != extras[i].autoChecked && !extras[i].isFaded))
        updatedExtras = [...updatedExtras, { ...extras[i], isAnimationDisabled: false }];
      else
        updatedExtras = [...updatedExtras, { ...extras[i], isAnimationDisabled: true }];
    }

    return updatedExtras;
  };

}
