export class AddEvent {
  constructor(public data) { }
}

export class OkEvent {
  constructor(public data) { }
}

export class CancelEvent {
  constructor(public data) { }
}

export class YesEvent {
  constructor(public data) { }
}

export class NoEvent {
  constructor(public data) { }
}

export class AcceptEvent {
  constructor(public data) { }
}

export class SuccessEvent {
  constructor(public data) { }
}

export class FailEvent {
  constructor(public data) { }
}

export class ProcessStartedEvent {
  constructor(public data) { }
}

export class ProcessCompleteEvent {
  constructor(public data) { }
}

export type Event = AddEvent | OkEvent | CancelEvent | YesEvent | NoEvent | AcceptEvent | SuccessEvent | FailEvent | ProcessStartedEvent | ProcessCompleteEvent;
