import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { BranchDto } from 'src/app/core/models';
import { BranchService } from 'src/app/core/services';

import { LOAD_BRANCHES, LoadBranches, LoadBranchesSuccess, LoadBranchesFail } from './branch.action';


@Injectable()
export class BranchesEffects {
  constructor(
    private actions$: Actions,
    private branchService: BranchService
  ) { }


  loadBranches$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_BRANCHES),
      map((action: LoadBranches) => action.payload),
      switchMap(params => {
        return this.branchService
          .fetchAllBranches(params.lang)
          .pipe(
            map((branches) => {

              const _branches = branches.data as BranchDto[];

              let pickupBranch: BranchDto = _branches.find(x => x.code == params.pickupBranchCode);

              if (pickupBranch) {
                pickupBranch = {
                  ...pickupBranch,
                  isAirportBranch: this.branchService.isAirportBranch(pickupBranch),
                  hasShuttleService: this.branchService.hasShuttleService(pickupBranch)
                };
              }

              let returnBranch = _branches.find(x => x.code == params.returnBranchCode);

              if (returnBranch) {
                returnBranch = {
                  ...returnBranch,
                  isAirportBranch: this.branchService.isAirportBranch(returnBranch),
                  hasShuttleService: this.branchService.hasShuttleService(returnBranch)
                };
              }

              return new LoadBranchesSuccess(_branches, pickupBranch, returnBranch);
            }),
            catchError(error => of(new LoadBranchesFail(error)))
          );
      })
    ));
}
