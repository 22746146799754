<div #cctextbox class="cc-textbox" [formGroup]="parentFormGroup">
  <mat-form-field class="input-full-width">
    <mat-label>{{'common.card-no' | translate}}</mat-label>
    <input #ccNo *ngIf="!hasCCNoContainsXXXX(parentFormGroup?.controls[formCtrlName]?.value)" placeholder="{{'common.card-no' | translate}}" matInput type="text" [required]="validationEnabled" maxlength="20" [formControlName]="formCtrlName" name="cardnumber" autocomplete="cc-number" (keyup)="onCCKeyup($event)" (keypress)="onCCKeyPress($event)" (keydown)="onCCKeyDown($event)" (paste)="onCCPaste($event)" (blur)="onBlur($event)" />
    <input *ngIf="hasCCNoContainsXXXX(parentFormGroup?.controls[formCtrlName]?.value)" matInput readonly="readonly" type="text" [formControlName]="formCtrlName" autocomplete="off" />
    <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="CCNoEditClick()">
      <i class="fas fa-pencil icon-button" aria-hidden="true"></i>
    </button>
    <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('invalidCC')">
      <span [innerHTML]="'validations.invalid-cc-no' | translate"></span>
    </mat-error>
    <mat-error *ngIf="parentFormGroup?.controls[formCtrlName]?.hasError('required')">
      <span [innerHTML]="'validations.req-cc-no' | translate"></span>
    </mat-error>
  </mat-form-field>
</div>
