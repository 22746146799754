import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { interval, Observable, of, Subscription, throwError } from 'rxjs';
import { tap, first, map, take, catchError } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { State, getAppSettingsLanguage, getReservationProcessing } from 'src/app/store';

import { fade } from 'src/app/shared/animations/animations';
import { TimeoutError } from 'src/app/core/error-handlers/timeout-error';

import { LOG_TYPES, PAGES } from 'src/app/core/enums';

import { environment } from 'src/environments/environment';

import { CommonService, LoggingService } from 'src/app/core/services';

import { LoaderLiteService } from '../loader-lite';

@Component({
  selector: 'confirmation-loader',
  templateUrl: './confirmation-loader.component.html',
  styleUrls: ['./confirmation-loader.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationLoaderComponent implements OnInit, OnDestroy {

  isMobileDevice$: Observable<boolean> = of(false);
  rezProcessing$: Observable<boolean> = of(false);
  language: string = 'en';

  private allSubs$: Subscription = new Subscription();

  constructor(
    private _commonService: CommonService,
    private _logger: LoggingService,
    private _loaderService: LoaderLiteService,
    private _router: Router,
    private store: Store<State>
  ) { }

  ngOnInit(): void {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
    this.rezProcessing$ = this.store.pipe(
      select(getReservationProcessing),
      tap((processing) => {

        this.allSubs$.unsubscribe();

        if (processing) {
          const _timeout = environment.requestTimeout / 1000;
          const timeoutSub = interval(1000).pipe(
            map((val) => {
              if (val + 1 >= _timeout) {
                throw new TimeoutError();
              }
              return val;
            }),
            take(_timeout),
            catchError((err) => throwError(() => err))
          ).subscribe({
            error: (err: any) => {
              this._logger.log(LOG_TYPES.ERROR, `${err.message} in Posting Reservation`);

              if (err instanceof TimeoutError) {
                this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.ERROR_408, this._commonService.getCurrentLanguage()));
              }
            }
          });

          this.allSubs$.add(timeoutSub)

          this._loaderService.hide(true);
        }
      })
    );

    this.allSubs$.add(this.rezProcessing$.subscribe());

    const sub2 = this.store.pipe(
      select(getAppSettingsLanguage),
      tap((lang) => {
        this.language = lang;
      }),
      first()
    ).subscribe();

    this.allSubs$.add(sub2)
  }

  ngOnDestroy(): void {
    this.allSubs$.unsubscribe();
  }
}
