import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, finalize, first } from 'rxjs/operators';

import { UserDto, ServiceResult, UserLoginInfoDto } from '../models';

import { ConfigService } from "./config.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  rememberMeObs: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _configService: ConfigService, private http: HttpClient) { }

  set currentUser(value: UserLoginInfoDto) {
    localStorage.setItem('_re_user', JSON.stringify(value));
  }
  get currentUser(): UserLoginInfoDto {
    return <UserLoginInfoDto>JSON.parse(localStorage.getItem('_re_user'));
  }

  get IsRememberMe(): boolean {
    const rtoken = localStorage.getItem('_ref_token');
    return rtoken && rtoken !== null;
  }

  getAuthToken(): string {
    if (this.currentUser) {
      return this.currentUser.accessToken;
    }
    return '';
  }

  set RefreshToken(value: string) {
    if (value && value !== null && value !== "null" && value.length > 0) {
      localStorage.setItem('_ref_token', value);
    }
    else {
      localStorage.removeItem("_ref_token");
    }
  }
  get RefreshToken(): string {
    const rtoken = localStorage.getItem('_ref_token');
    if (rtoken && rtoken !== null) {
      return rtoken;
    }
    else if (this.currentUser?.refreshToken) {
      return this.currentUser.refreshToken;
    }
    return '';
  }

  isAuthenticated = (): boolean => {
    const _authToken = this.getAuthToken() + "";
    return !!_authToken && _authToken.trim().toLowerCase() !== "null" && _authToken.trim().toLowerCase() !== "undefined";
  };

  login = (userName: string, password: string, rememberMe: boolean, lang: string): Observable<any> => {
    const url = this._configService.getUserApiUrl('/token', { lang });
    const appId = this._configService.ClientAppId;

    const body = `username=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}&grant_type=password&client_id=${appId}`;
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };

    return this.http.post<any>(url, body, options)
      .pipe(
        map(res => {
          if (res.access_token && res.access_token.length > 0) {
            this.setCurrentUserLoginInfo(res);
            this.rememberMeObs.next(rememberMe);
            if (rememberMe) {
              this.RefreshToken = res.refresh_token;
            }
          }
          return res;
        }),
        catchError(e => throwError(() => e))
      );
  };

  refreshAuthToken = (lang: string): Observable<UserLoginInfoDto> => {
    if (this.RefreshToken && this.RefreshToken !== "") {
      const url = this._configService.getUserApiUrl('/token', { lang });
      const body = `refresh_token=${encodeURIComponent(this.RefreshToken)}&grant_type=refresh_token&client_id=${this._configService.ClientAppId}`;
      const options = {
        headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
      };

      return this.http.post<any>(url, body, options)
        .pipe(
          map(res => {

            if (res.access_token && res.access_token.length > 0) {
              return this.setCurrentUserLoginInfo(res);
            }

            return res;
          }),
          catchError((error: any) => throwError(() => error))
        );
    }
    else {
      return of(null);
    }
  };

  private setCurrentUserLoginInfo = (info: any): UserLoginInfoDto => {
    const userInfo: UserLoginInfoDto = {
      accessToken: info.access_token,
      tokenExpiration: info[".expires"],
      refreshToken: info.refresh_token,
      userId: info.userId
    };
    this.currentUser = userInfo;
    return userInfo;
  };

  getLoggedInUser = (): Observable<UserDto> => {
    if (this.isAuthenticated()) {
      const url = this._configService.getUserApiUrl('/userinfo');
      return this.http.get<ServiceResult>(url)
        .pipe(
          map((res) => {
            const user = res.data as UserDto;
            return user;
          }),
          catchError((e: any) => throwError(() => e))
        );
    }
    else {
      return of(null);
    }
  };

  logout = (): boolean => {
    this.rememberMeObs.next(false);

    this.logoutFromServer(this.getAuthToken(), this.RefreshToken).pipe(first()).subscribe();

    // remove user from local storage to log user out
    this.currentUser = null;
    this.RefreshToken = null;
    return this.getAuthToken() === "";
  };

  private logoutFromServer = (accessToken: string, refreshToken: string): Observable<boolean> => {
    if (accessToken != "") {
      const url = this._configService.getUserApiUrl('/logout', { refreshToken });

      const options = {
        headers: new HttpHeaders({'Authorization': `Bearer ${accessToken}`})
      };

      return this.http.post<ServiceResult>(url, {}, options)
        .pipe(
          map((res) => {
            return !!res.data?.succeeded;
          }),
          catchError((e: any) => throwError(() => e)),
          finalize(() => this.currentUser = null)
        );
    }

    return of(false);
  };
}
