<div class="rate-upgrade-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'dlg-upgrade.quick-upgrade' | translate}}<hr /></h4>
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <mat-dialog-content>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-md-push-6 col-lg-push-6">
        <div [@flipInY]="flipState$ | async" class="vehicle-det upgrade">
          <h4>{{'dlg-upgrade.upgradable-veh' | translate}} <span>{{'common.add' | translate}} {{upgradePrice | currency:baseCurrency:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.day' | translate}}</span></h4>
          <vehicle-info [selectedVehicle]="upgradeToVehicle" [hideBtnsBlock]="true"></vehicle-info>
          <div class="button">
            <button type="button" class="btn-main btn-orange normal" (click)="onUpgradeClick()">{{'buttons.yes-upgrade' | translate}}</button>
          </div>
        </div>
      </div>
      <div [@rotateIn]="rotateState$ | async" class="col-xs-12 col-sm-12 arrow visible-xs visible-sm"><i class="fas fa-arrow-up" aria-hidden="true"></i></div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-md-pull-6 col-lg-pull-6">
        <div [@flipInY]="flipState$ | async" class="vehicle-det current">
          <h4>{{'dlg-upgrade.curr-veh' | translate}}</h4>
          <vehicle-info [selectedVehicle]="currentVehicle" [hideBtnsBlock]="true"></vehicle-info>
          <div class="button">
            <button type="button" class="btn-main btn-white normal" [mat-dialog-close]="">{{'buttons.keep-curr' | translate}}</button>
          </div>
          <div [@rotateIn]="rotateState$ | async" class="arrow hidden-xs hidden-sm"><i class="fas fa-arrow-right" aria-hidden="true"></i></div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
