import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { ServiceResult } from '../models';

@Injectable()
export class CurrencyRatesService {

  constructor(private _configService: ConfigService, private http: HttpClient) { }

  fetchCurrencyRates(base_currency: string): Observable<any> {

    const symbols = ConfigService.currencies.map((currency) => currency.code).join();

    const url = this._configService.getCurrenciesApiUrl('/latest', { base_currency: base_currency, symbols: symbols });

    return this.http.get<ServiceResult>(url)
      .pipe(
        map((result) => {
          return result.data;
        }),
        catchError(e => throwError(() => e))
      );
  }
}
