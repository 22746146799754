import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { CreditCardValidatorService } from './credit-card-validator.service';

@Injectable()
export class FormValidatorService {
  constructor(private _ccValidatorService: CreditCardValidatorService) {
  }

  confirmEmailValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: object } => {
      if (control.root instanceof UntypedFormGroup) {
        const driverInfoGrp: UntypedFormGroup = (control.root as UntypedFormGroup).controls.driverInfo as UntypedFormGroup;

        const orgEmail: string = driverInfoGrp.get('email') ? driverInfoGrp.get('email').value : "";
        const confEmail: string = control.value;

        if (orgEmail && orgEmail !== '' && confEmail === '') {
          return { 'required': { value: true } };
        }
        else if (orgEmail && orgEmail !== '' && confEmail.toLowerCase() !== orgEmail.toLowerCase()) {
          return { 'unmatched': { value: confEmail } };
        }
      }
      return null;
    };
  };

  ccNoValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: object } => {
      if (control.value && control.value !== "") {
        const isValid = this._ccValidatorService.validateCreditCard(control.value);
        return !isValid ? { 'invalidCC': { value: control.value } } : null;
      }

      return null;
    };
  };
}
