import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';

import { RateDetailDto, Currency, RateExtra, MileageCap, ErrorDto } from 'src/app/core/models';

import { RateExtraService } from 'src/app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { tap, first } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State, getExtras } from 'src/app/store';

import { RateExtraDialogService } from './dlg-rate-extra.service';
import { AddEvent } from 'src/app/core/events/dialog.events';

@Component({
  selector: 'rate-extra-dialog',
  templateUrl: './dlg-rate-extra.component.html',
  styleUrls: ['./dlg-rate-extra.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateExtraDialogComponent implements OnInit {

  rateDetail: RateDetailDto;
  mileageCap: MileageCap = null;
  totalFreeMiles: number = 0;
  extras: RateExtra[] = [];

  baseCurrency: string;
  selectedCurrency: Currency;

  formGroup: UntypedFormGroup;

  validationErrors: ErrorDto[] = [];

  private orgExtra: RateExtra;

  private translations: any;

  isMobileDevice: boolean = false;

  showSpinner: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dlgService: RateExtraDialogService,
    private _rateExtraService: RateExtraService,
    private _translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private store: Store<State>) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.baseCurrency = data.baseCurrency;
    this.selectedCurrency = JSON.parse(JSON.stringify(data.selectedCurrency));

    this.rateDetail = data.rateDetail as RateDetailDto;
    this.mileageCap = data.mileageCap as MileageCap;
    this.totalFreeMiles = data.totalFreeMiles as number;
    this.orgExtra = JSON.parse(JSON.stringify(data.selectedExtra));

    this.orgExtra.qty = this.orgExtra.qty === 0 ? 1 : this.orgExtra.qty;

    const _extra: RateExtra = {
      ...this.orgExtra,
      autoApply: true,
      autoChecked: true,
      totalCharge: this._rateExtraService.calcExtraTotalCharge(this.orgExtra, true, this.rateDetail),
      totalChargeChildren: this._rateExtraService.calcExtraChildrenTotalCharge(this.rateDetail, this.orgExtra)
    };

    this.extras = [{ ..._extra }];

    this.setSpinner(false);
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({});
    const extraCode = this.orgExtra.code;

    if (extraCode) {
      const formGrp = this.formGroup.controls[extraCode] as UntypedFormGroup;
      if (!formGrp) {
        this.formGroup.addControl(extraCode, this.fb.group({}));
      }

      this._translateService.get(['validations.req-ext-' + extraCode.toLowerCase()]).pipe(
        tap((translation) => {
          this.translations = translation;
        }),
        first()
      ).subscribe();
    }
  }

  onExtrasUpdated = (updatedExtras: RateExtra[]) => {
    const _extra = updatedExtras.find(x => x.code.toLowerCase() == this.orgExtra.code.toLowerCase());
    this.extras = [{ ..._extra }];
  };

  onAddClick = () => {
    if (this.formGroup.valid) {
      this.setSpinner(true);
      this.store.pipe(
        select(getExtras),
        tap((extras) => {
          if (extras && extras.length > 0) {
            const updatedExtras = this._rateExtraService.applyExtraValidation({ ...this.extras[0], autoApply: this.orgExtra.autoApply }, extras, this.rateDetail, this.mileageCap, this.totalFreeMiles);
            this._dlgService.triggerEvent(new AddEvent(updatedExtras));
            //this.closeDialog(updatedExtras);
          }
        }),
        first()
      ).subscribe();
    }
    else {
      this.showErrors();
    }
  };

  private showErrors = () => {

    const extraCode = this.orgExtra.code;

    this.validationErrors = [];

    if (this.formGroup.get(extraCode)) {
      if ((this.formGroup.get(extraCode) as UntypedFormGroup).controls["childExtras"]) {
        ((this.formGroup.get(extraCode) as UntypedFormGroup).controls["childExtras"] as UntypedFormArray).controls.forEach((ctrl, index) => {
          const childCtrl = (ctrl as UntypedFormGroup).controls['Child' + (index + 1)];
          if (childCtrl.errors && childCtrl.errors.required) {
            this.validationErrors = [...this.validationErrors, { code: extraCode, errorMessage: this.translations['validations.req-ext-' + extraCode.toLowerCase()] }];
          }
        })
      }
    }
  };

  private setSpinner = (show: boolean = false) => {
    this.showSpinner = show;
    this.dialogRef.disableClose = show;
  };

  closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }
}
