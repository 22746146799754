import {
  ModuleWithProviders, NgModule,
  Optional, SkipSelf, ErrorHandler
} from '@angular/core';

import { httpInterceptorProviders } from './http-interceptors';

import { CommonModule, CurrencyPipe } from '@angular/common';
import { UrlSerializer } from "@angular/router";

import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { WINDOW_PROVIDERS } from './providers/window.provider';

import {
  CountryStateService,
  BranchService,
  RateService,
  RateExtraService,
  VehicleService,
  CurrencyRatesService,
  CreditCardValidatorService,
  FormValidatorService,
  ReservationService,
  FileService,
  SeoService,
  SessionService,
  RecommendationEmailService,
  ScrollToService,
  NewsLetterService,
  IP2LocationService,
  UserService,
  AuthenticationService,
  NotificationService,
  AnalyticsService,
  GoogleMapLoaderService,
  GOOGLE_MAP_API_KEY
} from './services';

import { LowerCaseUrlSerializer } from './services/lowercaseurlserializer.service';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { environment } from 'src/environments/environment';

import { RECAPTCHA_V3_SITE_KEY } from '../common/recaptcha';

@NgModule({
  imports: [CommonModule, LoggerModule.forRoot({ level: NgxLoggerLevel.OFF })],
  declarations: [],
  exports: [],
  providers: [WINDOW_PROVIDERS]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: GOOGLE_MAP_API_KEY,
          useValue: environment.google.apiKey
        },
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useValue: environment.google.captcha
        },
        {
          provide: ErrorHandler,
          useClass: ApplicationinsightsAngularpluginErrorService
        },
        httpInterceptorProviders,
        {
          provide: UrlSerializer,
          useClass: LowerCaseUrlSerializer
        },
        CountryStateService,
        BranchService,
        RateService,
        RateExtraService,
        VehicleService,
        CurrencyPipe,
        CurrencyRatesService,
        CreditCardValidatorService,
        FormValidatorService,
        ReservationService,
        FileService,
        SeoService,
        SessionService,
        RecommendationEmailService,
        ScrollToService,
        NewsLetterService,
        IP2LocationService,
        UserService,
        AuthenticationService,
        NotificationService,
        AnalyticsService,
        GoogleMapLoaderService
      ]
    };
  }

}
