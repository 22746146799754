import { CountriesAction, LOAD_COUNTRIES, LOAD_COUNTRIES_SUCCESS, LOAD_COUNTRIES_FAIL } from './country.action';
import { Country } from 'src/app/core/models';
import { ICountryState } from '../_base';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

const initialState: ICountryState = {
  countries: {},
  statusCode: STORE_STATUS_CODES.INITIAL,
  loading: false,
  loaded: false
};

export function countryReducer(state = initialState, action: CountriesAction): ICountryState {
  switch (action.type) {
    case LOAD_COUNTRIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_COUNTRIES_SUCCESS: {

      const countries = action.countries.reduce((_countries: { [id: string]: Country }, country) => {

        return {
          ..._countries,
          [country.twoLetterISOCode]: country
        };

      }, {});

      return {
        ...state,
        countries: countries,
        statusCode:STORE_STATUS_CODES.SUCCESS,
        loading: false,
        loaded: true
      };
    }

    case LOAD_COUNTRIES_FAIL: {
      return {
        ...state,
        statusCode:STORE_STATUS_CODES.FAILED,
        loading: false,
        loaded: false,
      };
    }
  }

  return state;
}
