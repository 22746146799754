import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RentACarGuard, VehicleExtrasGuard, CustomerDetailsGuard, ConfirmationGuard, CheckInGuard, RezIsLoadedGuard, RecommendationEmailGuard, AuthGuard, AnonymousOnlyAccessGuard, UserIdTokenCheckGuard } from './guards';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/en/rent-a-car', pathMatch: 'full' },
  { path: 'en', redirectTo: '/en/rent-a-car', pathMatch: 'full' },
  { path: 'fr', redirectTo: '/fr/location-voiture', pathMatch: 'full' },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'Globe Car Rental Montreal'
    },
    children: [
      {
        path: ':lang/rent-a-car',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'car', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/location-voiture',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'car', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/rent-a-car/:recommendid',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'car', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/location-voiture/:recommendid',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'car', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/rent-a-truck',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'truck', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/location-camion',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'truck', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/rent-a-truck/:recommendid',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'truck', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/location-camion/:recommendid',
        loadChildren: () => import('./views/step1-rentacar/rentacar.module').then(m => m.RentACarModule),
        data: { vehicletype: 'truck', depth: 1 },
        canActivate: [RentACarGuard]
      },
      {
        path: ':lang/additional-services',
        canActivate: [VehicleExtrasGuard],
        data: { depth: 2 },
        loadChildren: () => import('./views/step2-vehicle-extras/vehicle-extras.module').then(m => m.VehicleExtrasModule)
      },
      {
        path: ':lang/services-supplementaires',
        canActivate: [VehicleExtrasGuard],
        data: { depth: 2 },
        loadChildren: () => import('./views/step2-vehicle-extras/vehicle-extras.module').then(m => m.VehicleExtrasModule)
      },
      {
        path: ':lang/additional-services/:recommendid',
        canActivate: [VehicleExtrasGuard],
        data: { depth: 2 },
        loadChildren: () => import('./views/step2-vehicle-extras/vehicle-extras.module').then(m => m.VehicleExtrasModule)
      },
      {
        path: ':lang/services-supplementaires/:recommendid',
        canActivate: [VehicleExtrasGuard],
        data: { depth: 2 },
        loadChildren: () => import('./views/step2-vehicle-extras/vehicle-extras.module').then(m => m.VehicleExtrasModule)
      },
      {
        path: ':lang/driver-details',
        canActivate: [CustomerDetailsGuard],
        data: { depth: 3 },
        loadChildren: () => import('./views/step3-customer-details/customer-details.module').then(m => m.CustomerDetailsModule)
      },
      {
        path: ':lang/details-du-conducteur',
        canActivate: [CustomerDetailsGuard],
        data: { depth: 3 },
        loadChildren: () => import('./views/step3-customer-details/customer-details.module').then(m => m.CustomerDetailsModule)
      },
      {
        path: ':lang/confirmation',
        canActivate: [ConfirmationGuard],
        data: { depth: 4 },
        loadChildren: () => import('./views/step4-confirmation/confirmation.module').then(m => m.ConfirmationModule)
      },
      {
        path: ':lang/confirmation/:id',
        canActivate: [ConfirmationGuard],
        data: { depth: 4 },
        loadChildren: () => import('./views/step4-confirmation/confirmation.module').then(m => m.ConfirmationModule)
      },
      {
        path: ':lang/check-in/go',
        data: { depth: 5 },
        loadChildren: () => import('./views/check-in-start/check-in-start.module').then(m => m.CheckInStartModule)
      },
      {
        path: ':lang/enregistrement/go',
        data: { depth: 5 },
        loadChildren: () => import('./views/check-in-start/check-in-start.module').then(m => m.CheckInStartModule)
      },
      {
        path: ':lang/check-in/driver-details',
        canActivate: [CheckInGuard],
        data: { depth: 6 },
        loadChildren: () => import('./views/check-in/check-in.module').then(m => m.CheckInModule)
      },
      {
        path: ':lang/enregistrement/details-du-conducteur',
        canActivate: [CheckInGuard],
        data: { depth: 6 },
        loadChildren: () => import('./views/check-in/check-in.module').then(m => m.CheckInModule)
      },
      {
        path: ':lang/view-reservation',
        loadChildren: () => import('./views/view-rez-start/view-rez-start.module').then(m => m.ViewRezStartModule)
      },
      {
        path: ':lang/voir-reservation',
        loadChildren: () => import('./views/view-rez-start/view-rez-start.module').then(m => m.ViewRezStartModule)
      },
      {
        path: ':lang/modify-reservation',
        loadChildren: () => import('./views/modify-rez-start/modify-rez-start.module').then(m => m.ModifyRezStartModule)
      },
      {
        path: ':lang/modifier-reservation',
        loadChildren: () => import('./views/modify-rez-start/modify-rez-start.module').then(m => m.ModifyRezStartModule)
      },
      {
        path: ':lang/cancel-reservation',
        loadChildren: () => import('./views/cancel-rez-start/cancel-rez-start.module').then(m => m.CancelRezStartModule)
      },
      {
        path: ':lang/annuler-reservation',
        loadChildren: () => import('./views/cancel-rez-start/cancel-rez-start.module').then(m => m.CancelRezStartModule)
      },
      {
        path: ':lang/error/404',
        data: { errorCode: 404 },
        loadChildren: () => import('./views/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: ':lang/erreur/404',
        data: { errorCode: 404 },
        loadChildren: () => import('./views/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: ':lang/error/408',
        data: { errorCode: 408 },
        loadChildren: () => import('./views/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: ':lang/erreur/408',
        data: { errorCode: 408 },
        loadChildren: () => import('./views/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: ':lang/error/500',
        data: { errorCode: 500 },
        loadChildren: () => import('./views/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: ':lang/erreur/500',
        data: { errorCode: 500 },
        loadChildren: () => import('./views/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: ':lang/spin',
        loadChildren: () => import('./views/spin-the-wheel/spin-the-wheel.module').then(m => m.SpinTheWheelModule)
      },
      {
        path: ':lang/spin/:branch',
        loadChildren: () => import('./views/spin-the-wheel/spin-the-wheel.module').then(m => m.SpinTheWheelModule)
      },
      {
        path: ':lang/login',
        canActivate: [AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
      },
      {
        path: ':lang/connexion',
        canActivate: [AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
      },
      {
        path: ':lang/register',
        canActivate: [AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: ':lang/inscription',
        canActivate: [AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: ':lang/verify-email',
        canActivate: [UserIdTokenCheckGuard],
        loadChildren: () => import('./views/verify-email/verify-email.module').then(m => m.VerifyEmailModule)
      },
      {
        path: ':lang/valider-courriel',
        canActivate: [UserIdTokenCheckGuard],
        loadChildren: () => import('./views/verify-email/verify-email.module').then(m => m.VerifyEmailModule)
      },
      {
        path: ':lang/forgot-password',
        canActivate: [AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: ':lang/mot-de-passe-oublie',
        canActivate: [AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: ':lang/password-reset',
        canActivate: [UserIdTokenCheckGuard, AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/password-reset/password-reset.module').then(m => m.PasswordResetModule)
      },
      {
        path: ':lang/reinitialisation-mot-de-passe',
        canActivate: [UserIdTokenCheckGuard, AnonymousOnlyAccessGuard],
        loadChildren: () => import('./views/password-reset/password-reset.module').then(m => m.PasswordResetModule)
      },
      {
        path: ':lang/my-profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/my-profile/my-profile.module').then(m => m.MyProfileModule)
      },
      {
        path: ':lang/mon-profil',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/my-profile/my-profile.module').then(m => m.MyProfileModule)
      },
      {
        path: ':lang/my-profile/change-password',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: ':lang/mon-profil/changer-mot-de-passe',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: ':lang/my-profile/reservations',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/my-reservations/my-reservations.module').then(m => m.MyReservationsModule)
      },
      {
        path: ':lang/mon-profil/reservations',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/my-reservations/my-reservations.module').then(m => m.MyReservationsModule)
      }
    ]
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    data: {
      title: 'Globe Car Rental Montreal'
    },
    children: [
      {
        path: ':lang/email/confirmation/:id',
        loadChildren: () => import('./views/rez-confirm-email/rez-confirm-email.module').then(m => m.RezConfirmEmailModule),
        canActivate: [RezIsLoadedGuard]
      },
      {
        path: ':lang/courriel/confirmation/:id',
        loadChildren: () => import('./views/rez-confirm-email/rez-confirm-email.module').then(m => m.RezConfirmEmailModule),
        canActivate: [RezIsLoadedGuard]
      },
      {
        path: ':lang/email/cancellation/:id',
        loadChildren: () => import('./views/rez-cancel-email/rez-cancel-email.module').then(m => m.RezCancelEmailModule),
        canActivate: [RezIsLoadedGuard]
      },
      {
        path: ':lang/courriel/annulation/:id',
        loadChildren: () => import('./views/rez-cancel-email/rez-cancel-email.module').then(m => m.RezCancelEmailModule),
        canActivate: [RezIsLoadedGuard]
      },
      {
        path: ':lang/email/recommendation/:id',
        loadChildren: () => import('./views/rez-recommended-email/rez-recommended-email.module').then(m => m.RezRecommendedEmailModule),
        canActivate: [RecommendationEmailGuard]
      },
      {
        path: ':lang/courriel/recommandation/:id',
        loadChildren: () => import('./views/rez-recommended-email/rez-recommended-email.module').then(m => m.RezRecommendedEmailModule),
        canActivate: [RecommendationEmailGuard]
      },
      {
        path: ':lang/email/check-in/:id',
        loadChildren: () => import('./views/check-in-reminder-email/check-in-reminder-email.module').then(m => m.CheckInReminderEmailModule),
        canActivate: [RezIsLoadedGuard]
      },
      {
        path: ':lang/courriel/enregistrement/:id',
        loadChildren: () => import('./views/check-in-reminder-email/check-in-reminder-email.module').then(m => m.CheckInReminderEmailModule),
        canActivate: [RezIsLoadedGuard]
      }
    ]
  },
  //{
  //  path: ':lang/email/confirmation/:id',
  //  canActivate: [RezConfirmEmailGuard],
  //  loadChildren: () => import('./views/rez-confirm-email/rez-confirm-email.module').then(m => m.RezConfirmEmailModule)
  //},
  //{ path: ':lang/email/confirmation/:id', component: RezConfirmEmailComponent, canActivate: [RezConfirmEmailGuard] },
  //{ path: ':lang/courriel/confirmation/:id', component: RezConfirmEmailComponent, canActivate: [RezConfirmEmailGuard] },
  //{ path: ':lang/email/cancellation/:id', component: RezCancelEmailComponent, canActivate: [RezConfirmEmailGuard] },
  //{ path: ':lang/courriel/annulation/:id', component: RezCancelEmailComponent, canActivate: [RezConfirmEmailGuard] },
  //{ path: ':lang/email/recommendation/:id', component: RezRecommendedEmailComponent, canActivate: [RecommendationEmailGuard] },
  //{ path: ':lang/courriel/recommandation/:id', component: RezRecommendedEmailComponent, canActivate: [RecommendationEmailGuard] },
  { path: 'en/email/confirmation', redirectTo: 'en/view-reservation' },
  { path: 'fr/courriel/confirmation', redirectTo: 'fr/voir-reservation' },
  { path: 'en/email/cancellation', redirectTo: 'en/cancel-reservation' },
  { path: 'fr/courriel/annulation', redirectTo: 'fr/annuler-reservation' },
  { path: 'en/email/recommendation', redirectTo: 'en/rent-a-car' },
  { path: 'fr/courriel/recommandation', redirectTo: 'fr/location-voiture' },
  { path: 'en/email/check-in', redirectTo: 'en/view-reservation' },
  { path: 'fr/courriel/enregistrement', redirectTo: 'fr/voir-reservation' },
  { path: 'en/check-in', redirectTo: 'en/check-in/go', pathMatch: 'full' },
  { path: 'fr/enregistrement', redirectTo: 'fr/enregistrement/go', pathMatch: 'full' },
  { path: '**', redirectTo: '/en/error/404?r=1' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
