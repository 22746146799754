import { SelectedRateAction, SET_SELECTED_RATE, UPDATE_SELECTED_RATE_TRANSLATION, SET_SELECTED_RATE_SUCCESS, RESET_SELECTED_RATE } from './selected-rate.action';
import { RateDto } from 'src/app/core/models';
import { ISelectedRateState } from '../_base';

const initialState: ISelectedRateState = {
  selectedRate: {} as RateDto,
  isSelected: false
};

export function selectedRateReducer(state = initialState, action: SelectedRateAction): ISelectedRateState {
  switch (action.type) {

    case SET_SELECTED_RATE: {

      if (action.payload && action.payload.id) {
        const rate: RateDto = {
          ...action.payload,
          totalMilesIncluded: action.payload.totalMilesIncluded ? action.payload.totalMilesIncluded : action.payload.totalFreeMiles,
          totalMilesIncludedDisplay: action.payload.totalMilesIncludedDisplay ? action.payload.totalMilesIncludedDisplay : action.payload.totalFreeMiles.toString() + action.payload.vehicle.mileageUnit.toLowerCase()
        };

        return {
          ...state,
          selectedRate: { ...rate },
          isSelected: true
        };
      }
      else {
        return { ...initialState };
      }
    }

    case SET_SELECTED_RATE_SUCCESS: {
      return state;
    }

    case RESET_SELECTED_RATE: {
      return { ...initialState };
    }

    case UPDATE_SELECTED_RATE_TRANSLATION: {

      if (state && state.selectedRate && state.selectedRate.id) {

        const rate: RateDto = {
          ...state.selectedRate,
          vehicle: action.vehicle
        };

        return {
          ...state,
          selectedRate: rate
        };
      }
      else
        return state;
    }
  }

  return state;
}
