import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from '@angular/router';

import { filter, tap, take, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State, loadCurrentUserInfo } from 'src/app/store';

import { CommonService, EmailService, UserService } from 'src/app/core/services';
import { combineLatest, timer, BehaviorSubject, Observable } from 'rxjs';
import { NOTIFY_TYPES, PAGES } from 'src/app/core/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'confirm-acc-email-dialog',
  templateUrl: './dlg-confirm-acc-email.component.html',
  styleUrls: ['./dlg-confirm-acc-email.component.scss']
})
export class ConfirmAccEmailDialogComponent implements OnInit {

  isMobileDevice: boolean = false;
  resendEnabled: boolean = false;

  rentACarPageUrl: string = "";

  private userId: string = "";
  private email: string = "";
  private language: string = "en";

  isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isVerified$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  counter$: Observable<number>;

  private transGeneralErrMsg: string = "";
  private transEmailSentMsg: string = "";

  constructor(
    private _commonService: CommonService,
    private _emailService: EmailService,
    private _userService: UserService,
    private _translate: TranslateService,
    private _router: Router,
    private store: Store<State>,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isMobileDevice = this._commonService.IsMobileDevice;
    this.userId = data.userId as string;
    this.email = data.email as string;
    this.language = data.lang as string;
  }

  ngOnInit(): void {

    this.rentACarPageUrl = `/${this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, this.language)}`

    this.counter$ = this._commonService.accountConfirmEmailCounter$;
    this.counter$.pipe(
      tap((value) => {
        if (value === 0) {
          this.resendEmail();
        }
      }),
      take(1)
    );

    this._translate.get(
      [
        "validations.general-error-msg",
        "dlg-confirm-acc-email.email-sent"
      ],
      {
        email: this.email
      }
    ).pipe(
      filter(t => t && t != ""),
      tap((t) => {
        this.transGeneralErrMsg = t["validations.general-error-msg"];
        this.transEmailSentMsg = t["dlg-confirm-acc-email.email-sent"];
      }),
      take(1)
    )

    this.setSpinner(false);
  }

  private resendEmail = () => {
    combineLatest([
      this._userService.checkEmailVerifiedStatus(this.userId),
      timer(10), // To delay at least 1000ms to see spinner animation.
    ],
      (isVerified, _t) => isVerified
    ).pipe(
      tap((isVerified) => {
        if (isVerified) {
          this.isVerified$.next(true);
          this.setSpinner(false);
          this.store.dispatch(loadCurrentUserInfo());
        }
        else {
          if (!CommonService.isNullOrWhiteSpace(this.email) && !CommonService.isNullOrWhiteSpace(this.userId)) {
            this.setSpinner(true);

            combineLatest([
              this._userService.confirmAccountEmail(this.userId, this.language),
              timer(1000), // To delay at least 1000ms to see spinner animation.
            ],
              (result, _t) => result
            ).pipe(
              filter(result => result?.data),
              take(1)
            ).subscribe({
              next: result => {
                this.setSpinner(false);
                if (result.data.succeeded && result.data.data.statusCode === "UserEmailVerified") {
                  this.isVerified$.next(true);
                }
                else {
                  this.isVerified$.next(false);
                  this._commonService.bottomSheetObs.next({ message: this.transEmailSentMsg, notifyType: NOTIFY_TYPES.Success, duration: 10000 });
                }
              },
              complete: () => {
                this.setSpinner(false);
                this.isVerified$.next(false);
              }
            });

            this._emailService.startAccountConfirmEmailCounter();
          }
        }
      }),
      first()
    ).subscribe();
  };

  onResendClick = () => {
    this.resendEmail();
  };

  onRentAVehClick = async () => {
    await this._router.navigateByUrl(this.rentACarPageUrl);
    this.closeDialog();
  };

  private setSpinner = (show: boolean = false) => {
    this.isProcessing$.next(show);
    this.dialogRef.disableClose = show;
  };

  closeDialog(): void {
    this.dialogRef.close();
  }
}
