import { Component, OnInit, OnDestroy, NgZone, ChangeDetectionStrategy, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { Subscription, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { tap, first } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import {
  State,
  LoadRates,
  ResetRates,
  LoadBranches,
  getPickupBranch,
  getReturnBranch,
  SetAppSettingsBaseCurrency,
  getAppSettingsLanguage,
  getRateParams,
  getRatesLoaded,
  getAppSettingsWidgetFlashAnimationState,
  SetAppSettingsWidgetFlashAnimationState,
  getRatesLoading
} from 'src/app/store';

import { WINDOW } from 'src/app/core/providers/window.provider';

import { PAGES, LOG_TYPES } from 'src/app/core/enums';
import { RateParams, BranchDto } from 'src/app/core/models';
import { fadeIn, flash } from 'src/app/shared/animations/animations';

import { CommonService, GoogleMapLoaderService, LoggingService, TimeService } from 'src/app/core/services';

declare const widgetJs: any;
declare const fbq: any;

declare const $: any;

@Component({
  selector: 'rez-widget',
  templateUrl: './rez-widget.component.html',
  styleUrls: ['./rez-widget.component.scss'],
  animations: [
    fadeIn,
    flash
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RezWidgetComponent implements OnInit, OnDestroy {

  private allSubscription: Subscription = new Subscription();

  prevSelectedBranchCode: string = "";

  prevSelectedRateParams: RateParams;
  rateParams$: Observable<RateParams>;
  pickupBranch$: Observable<BranchDto>;
  returnBranch$: Observable<BranchDto>;

  currentLang: string = "en";
  totalRentalDays: number = 1;

  @ViewChild('rezWidget', { static: true }) rezWidget: ElementRef;

  hideWidget$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  widgetFlashState$: Observable<string>;

  IsMobileDevice$: Observable<boolean>;

  widgetRef: any;

  constructor(private _ngZone: NgZone,
    @Inject(WINDOW) private window: Window,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private readonly _logger: LoggingService,
    private readonly _gms: GoogleMapLoaderService,
    private store: Store<State>) {
    this.window['ngComponentRef'] = { component: this, zone: this._ngZone };
  }

  ngOnInit(): void {
    this.IsMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

    this.pickupBranch$ = this.store.pipe(
      select(getPickupBranch),
      tap((pickupBranch) => {
        const exists = pickupBranch && pickupBranch.code && pickupBranch.code != "";
        if (exists) {
          if (this.prevSelectedBranchCode != pickupBranch.code) {
            this.store.dispatch(new SetAppSettingsBaseCurrency(pickupBranch.baseCurrency));
          }
        }
      })
    );
    this.returnBranch$ = this.store.pipe(select(getReturnBranch));
    this.rateParams$ = this.store.pipe(
      select(getRateParams),
      tap((rateParams) => {
        if (rateParams) {
          this.totalRentalDays = TimeService.getNoOfDays(rateParams.pickupDate, rateParams.returnDate);
          if (this.totalRentalDays <= 0) {
            this.totalRentalDays = 1;
          }
        }
      })
    );

    this.loadAppSettings();

    const subs0 = this._commonService.forcedCollapseWidget$.pipe(
      tap((value) => {
        this.hideWidget$.next(value);
      })
    ).subscribe();

    this.allSubscription.add(subs0);

    const combined = combineLatest([
      this.store.pipe(select(getRatesLoaded)),
      this.store.pipe(select(getRatesLoading)),
      this._commonService.collapseWidget$
    ],
      (loaded, loading, collapseWidget) => {
        return { loaded: loaded, loading: loading, collapseWidget: collapseWidget };
      }
    );

    const subs1 = combined.pipe(
      tap((obj) => {
        if (!obj.loaded && !obj.loading) {
          this.hideWidget$.next(false);
        }
        else {
          this.hideWidget$.next(obj.collapseWidget);
        }
      })
    ).subscribe();

    this.allSubscription.add(subs1);

    const subs2 = this._commonService.triggerWidgetFindBestRate$.pipe()
    .subscribe({
      next: value => {
        if (this.widgetRef && value && (value as RateParams).pickupBranch !== undefined) {
          this.widgetRef.setParams(value);
          //this.widgetRef.triggerFindBestRate();
          this.ngWidgetCallback(value as RateParams, null);
        }
        else if (this.widgetRef && value) {
          this.widgetRef.triggerFindBestRate(value);
        }
      }
    });

    this.allSubscription.add(subs2);
  }

  private loadAppSettings = () => {
    const subs = this.store.pipe(
      select(getAppSettingsLanguage),
      tap((language) => {
        if (language && language.length > 0) {
          this.currentLang = language;
          if (this.rezWidget && widgetJs) {
            //this.mapsAPILoader.load();
            this.widgetRef = new widgetJs.rwMain(this.rezWidget.nativeElement);

            if (this._route.snapshot.firstChild && this._route.snapshot.firstChild.data.vehicletype && this._route.snapshot.firstChild.data.vehicletype != "") {
              this.widgetRef.init(language, this._route.snapshot.firstChild.data.vehicletype);
            }
            else {
              this.widgetRef.init(language);
            }
          }
        }
      })
    ).subscribe();

    this.widgetFlashState$ = this.store.pipe(select(getAppSettingsWidgetFlashAnimationState));
    this.allSubscription.add(subs);
  };

  formatDateTimeExact = (dt): string => {
    if (dt) {
      return TimeService.formatDateTimeExact(dt, "YYYYMMDD", "DD/MM/YYYY");
    }
    else {
      return "";
    }
  };

  formatDateTime = (date, time, format): string => {
    if (date && time && format) {
      return TimeService.formatDateTime(date + time, format);
    }
    else {
      return "";
    }
  };

  ngWidgetCallback = (rParams: RateParams, data: { [key: string]: string }): void => {
    //let vehicleType: string = "car";

    //if (this.prevSelectedRateParams && this.prevSelectedRateParams.vehicleType) {
    //  if (this._route.snapshot.firstChild && this._route.snapshot.firstChild.data.vehicletype && this.prevSelectedRateParams.vehicleType != this._route.snapshot.firstChild.data.vehicletype) {
    //    vehicleType = this._route.snapshot.firstChild.data.vehicletype;
    //  }
    //  else {
    //    vehicleType = this.prevSelectedRateParams.vehicleType;
    //  }
    //}
    //else if (this._route.snapshot.firstChild && this._route.snapshot.firstChild.data.vehicletype) {
    //  vehicleType = this._route.snapshot.firstChild.data.vehicletype;
    //}

    //rParams = {
    //  ...rParams,
    //  vehicleType: vehicleType
    //};

    if (data && data.classCode) {
      rParams = {
        ...rParams,
        vehicleClasses: data.classCode
      };
    }

    this.store.dispatch(new LoadBranches({ lang: rParams.language, pickupBranchCode: rParams.pickupBranch, returnBranchCode: rParams.returnBranch }));

    this.window['ngComponentRef'].zone.run(() => {
      this.store.dispatch(new ResetRates());
      this.store.dispatch(new LoadRates(rParams));

      this.store.pipe(select(getRatesLoaded),
        tap((loaded) => {
          this.hideWidget$.next(loaded);
          this._commonService.sessionObs.next(true);
        }),
        first((x) => x == true)
      ).subscribe();
    });

    if (JSON.stringify(this.prevSelectedRateParams) != JSON.stringify(rParams)) {
      try {
        if (typeof fbq == 'function') {
          fbq('track', 'Search', { search_string: JSON.stringify(rParams) });
        }
      }
      catch (e) {
        this._logger.log(LOG_TYPES.ERROR, e.message);
      }
    }

    this.prevSelectedBranchCode = this.prevSelectedRateParams ? this.prevSelectedRateParams.pickupBranch : "";

    this.prevSelectedRateParams = { ...rParams };

    let _url = PAGES.RENT_A_CAR;
    if (rParams.vehicleType == 'truck') {
      _url = PAGES.RENT_A_TRUCK;
    }

    const url = this._commonService.getTranslatedRouteByKey(_url, this.currentLang);
    this._router.navigateByUrl(url);
  };

  flashAnimationStarted = (event) => {
    if (event.toState == "flash")
      this.onChangeClick();
  };

  resetAnimationState = () => {
    this.store.dispatch(new SetAppSettingsWidgetFlashAnimationState(""));
  };

  onChangeClick = () => {
    this.hideWidget$.next(false);

    this.store.pipe(
      select(getRateParams),
      tap((params) => {
        this.widgetRef.setParams(params);
        if (typeof $ === "function") {
          $('#rw-txtPickupAndReturnLoc').focus();
        }
      }),
      first()
    ).subscribe();
  };

  ngOnDestroy(): void {
    this.window['ngComponentRef'] = null;

    if (this.allSubscription)
      this.allSubscription.unsubscribe();
  }
}
