import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { Observable, of } from 'rxjs';

import { CommonService } from 'src/app/core/services';

@Component({
  selector: 'important-info',
  templateUrl: './important-info.component.html',
  styleUrls: ['./important-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportantInfoComponent implements OnInit {

  @Input() language: string = "en";

  isMobileDevice$: Observable<boolean> = of(false);

  constructor(private _commonService: CommonService) { }

  ngOnInit(): void {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
  }
}
