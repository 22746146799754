import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { YesEvent, NoEvent, OkEvent, AcceptEvent, CancelEvent } from 'src/app/core/events/dialog.events';
import { ConfirmDialogService } from './dlg-confirm.service';
import { MESSAGE_BOX_BUTTONS } from 'src/app/core/enums';
import { HtmlButton } from 'src/app/core/models';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './dlg-confirm.component.html',
  styleUrls: ['./dlg-confirm.component.scss']
})
export class ConfirmDialogComponent {

  isMobileDevice = false;

  showHeading = true;
  headingText = "dlg-confirm.heading";
  confirmMsg = "dlg-confirm.confirm-msg-default";
  buttons: MESSAGE_BOX_BUTTONS = MESSAGE_BOX_BUTTONS.YesNo;
  customButtons: HtmlButton[] = [];
  params = {};
  isSingleLineText = false;

  showSpinner = false;

  constructor(
    private dialogRef: MatDialogRef<object>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _dlgService: ConfirmDialogService
  ) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.showHeading = data.showHeading !== undefined ? data.showHeading as boolean : this.showHeading;

    if (data.dialogType && data.dialogType === "cancel-rez") {
      this.headingText = "dlg-confirm.heading-cancel";
      this.confirmMsg = "dlg-confirm.confirm-msg-cancel-rez";
    }
    else if (data.dialogType && data.dialogType === "cancel-rez-hs") {
      this.headingText = "dlg-confirm.heading-cancel";
      this.confirmMsg = "dlg-confirm.confirm-msg-cancel-rez-hs";
    }
    else if (data.dialogType && data.dialogType === "min-drv-age-req") {
      this.confirmMsg = "dlg-confirm.confirm-msg-min-drv-age-req";
      this.params = this.data.params;
    }
    else if (data.dialogType && data.dialogType === "inv-underage-fee") {
      this.confirmMsg = "dlg-confirm.confirm-msg-inv-underage-fee";
      this.params = this.data.params;
    }

    this.buttons = data.buttons ? data.buttons as MESSAGE_BOX_BUTTONS : this.buttons;
    this.customButtons = data.customButtons ? data.customButtons as HtmlButton[] : [];

    this.isSingleLineText = data.isSingleLineText as boolean === true ? true : false;

    this.setSpinner(false);
  }

  onButtonClick = (val) => {
    if (val === "yes") {
      this._dlgService.triggerEvent(new YesEvent(val));
    }
    else if (val === "ok") {
      this._dlgService.triggerEvent(new OkEvent(val));
    }
    else if (val === "accept") {
      this._dlgService.triggerEvent(new AcceptEvent(val));
    }
    else if (val === "cancel") {
      this._dlgService.triggerEvent(new CancelEvent(val));
    }
    else if (val === "no") {
      this._dlgService.triggerEvent(new NoEvent(val));
    }
    else {
      this._dlgService.triggerEvent(new OkEvent(val));
    }
    this.setSpinner(true);
  };

  private setSpinner = (show = false) => {
    this.showSpinner = show;
    this.dialogRef.disableClose = show;
  };

  closeDialog(dialogResult): void {
    this.dialogRef.close(dialogResult);
  }
}
