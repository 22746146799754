<ng-container *ngIf="dlgType === 'ChangePhone'">
  <div class="change-phone-dialog" [ngClass]="{'mobile':isMobileDevice}">
    <h4 mat-dialog-title class="header">{{'dlg-change-phone.title' | translate}}<hr /></h4>
    <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog()">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
    <form [formGroup]="inputForm" (ngSubmit)="onSubmit()" novalidate>
      <mat-dialog-content>
        <div class="input-box">
          <div class="form-field-box">
            <intl-tel-input [label]="'dlg-change-phone.curr-phone' | translate" [parentFormGroup]="inputForm" formCtrlName="currentPhone" [disabled]="true"></intl-tel-input>
          </div>
          <div class="seperator"></div>
          <div class="form-field-box">
            <intl-tel-input [label]="'dlg-change-phone.new-phone' | translate" [parentFormGroup]="inputForm" formCtrlName="newPhone" (valueChange)="onPhoneNumberChange($event)" [required]="true"></intl-tel-input>
          </div>
          <div class="seperator"></div>
          <div class="form-field-box">
            <mat-form-field class="input-full-width">
              <mat-label>{{'common.password' | translate}}</mat-label>
              <input matInput [type]="pwdFieldType" placeholder="{{'common.password' | translate}}" formControlName="password" autocomplete="current-password" spellcheck="false" tabindex="0" [attr.aria-label]="'common.password' | translate" name="password" autocapitalize="off" required>
              <mat-error *ngIf="inputForm.get('password').hasError('required')">
                <span [innerHTML]="'validations.req-pwd' | translate"></span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="seperator"></div>
      </mat-dialog-content>
      <mat-dialog-actions align="center" class="actions">
        <button type="submit" class="btn-main btn-orange normal">{{'buttons.ok' | translate}}</button>
        &nbsp;&nbsp;
        <button type="button" class="btn-main btn-white normal" (click)="closeDialog()">{{'buttons.cancel' | translate}}</button>
      </mat-dialog-actions>
    </form>
    <container-spinner [showSpinner]="isProcessing$ | async" diameter="70"></container-spinner>
  </div>
</ng-container>
<ng-container *ngIf="dlgType === 'ConfirmPhone'">
  <div class="change-phone-dialog" [ngClass]="{'mobile':isMobileDevice}">
    <h4 mat-dialog-title class="header">{{'dlg-verify-code.title' | translate}}<hr /></h4>
    <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog()">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
    <mat-dialog-content>
      <div class="row table-heading">
        <span [innerHTML]="'dlg-verify-code.conf-ph-title' | translate:{phoneNo:formattedPhoneNumber}"></span><br />
        <span class="small">{{'dlg-verify-code.conf-ph' | translate}}</span>
      </div>
      <div class="row phone">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <mat-form-field class="input-full-width">
            <mat-label>{{'common.code' | translate}}</mat-label>
            <input matInput placeholder="{{'common.code' | translate}}" [formControl]="code" maxlength="6" (keypress)="onKeyPress($event)" (paste)="onKeyUp($event)" (input)="onKeyUp($event)" required pattern="[\d]{0,6}" />
            <button matSuffix mat-button mat-stroked-button color="primary" class="small" type="button" aria-label="Resend" [disabled]="(counter$ | async) > 0" (click)="onResendCodeClick()">
              <ng-container *ngIf="counter$ | async as counter">{{counter}}</ng-container>
              <ng-container *ngIf="(counter$ | async) <= 0">
                {{resendBtnValue}}
              </ng-container>
            </button>
            <mat-error *ngIf="code.hasError('required')">
              <span [innerHTML]="'validations.req-code' | translate"></span>
            </mat-error>
            <mat-error *ngIf="code.hasError('pattern')">
              <span [innerHTML]="'validations.invalid-code' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="actions">
      <button type="button" class="btn-main dlg-btn btn-orange" (click)="onVerifyCodeClick()">{{'buttons.verify' | translate}}</button>
      &nbsp;&nbsp;
      <button type="button" class="btn-main dlg-btn btn-white" (click)="closeDialog()">{{'buttons.cancel' | translate}}</button>
    </mat-dialog-actions>
    <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
  </div>
</ng-container>
