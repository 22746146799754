import { trigger, state, transition, animate, style, keyframes, query, stagger } from '@angular/animations';

export let flyInOutSocial = trigger('flyInOutSocial', [
  state('out', style({ opacity: 0, display: 'none', width: '0' })),
  state('in', style({ opacity: 1, display: 'block', width: '120px' })),
  transition('out => in', [
    animate('0.3s ease-out',
      keyframes([
        style({ opacity: 0, display: 'block', width: '0', transform: 'translate3d(100%, 0, 0)', offset: 0 }),
        style({ opacity: 1, width: '120px', transform: 'none', offset: 1 }),
      ])
    )
  ]),
  transition('in => out', [
    animate('0.3s 0.5s ease-in',
      keyframes([
        style({ opacity: 1, width: '120px', offset: 0 }),
        style({ opacity: 0, display: 'none', width: '0', transform: 'translate3d(100%, 0, 0)', offset: 1 }),
      ])
    )
  ])
]);

export let flyInOutPhone = trigger('flyInOutPhone', [
  state('out', style({ opacity: 0, display: 'none', width: '0' })),
  state('in', style({ opacity: 1, display: 'block', width: '752px' })),
  transition('out => in', [
    animate('0.3s ease-out',
      keyframes([
        style({ opacity: 0, display: 'block', width: '0', transform: 'translate3d(100%, 0, 0)', offset: 0 }),
        style({ opacity: 1, width: '752px', transform: 'none', offset: 1 }),
      ])
    )
  ]),
  transition('in => out', [
    animate('0.3s 0.5s ease-in',
      keyframes([
        style({ opacity: 1, width: '752px', offset: 0 }),
        style({ opacity: 0, display: 'none', width: '0', transform: 'translate3d(100%, 0, 0)', offset: 1 }),
      ])
    )
  ])
]);

export let jello = trigger('jello', [
  transition('* => jello', [
    animate('0.5s', keyframes([
      style({ transform: 'translate3d(0,0,0)', offset: 0.111 }),
      style({ transform: 'skewX(-12.5deg) skewY(-12.5deg)', offset: 0.222 }),
      style({ transform: 'skewX(6.25deg) skewY(6.25deg)', offset: 0.333 }),
      style({ transform: 'skewX(-3.125deg) skewY(-3.125deg)', offset: 0.444 }),
      style({ transform: 'skewX(1.5625deg) skewY(1.5625deg)', offset: 0.555 }),
      style({ transform: 'skewX(-0.78125deg) skewY(-0.78125deg)', offset: 0.666 }),
      style({ transform: 'skewX(0.390625deg) skewY(0.390625deg)', offset: 0.777 }),
      style({ transform: 'skewX(-0.1953125deg) skewY(-0.1953125deg)', offset: 0.888 })
    ]))
  ])
]);
