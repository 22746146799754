import { Component, AfterViewChecked, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { VehicleDto, Currency } from 'src/app/core/models';

import { rotateIn, flipInY } from 'src/app/shared/animations/animations';
import { BehaviorSubject } from 'rxjs';
import { RateUpgradeDialogService } from './dlg-rate-upgrade.service';
import { YesEvent } from 'src/app/core/events/dialog.events';

@Component({
  selector: 'rate-upgrade-dialog',
  templateUrl: './dlg-rate-upgrade.component.html',
  styleUrls: ['./dlg-rate-upgrade.component.scss'],
  animations: [
    rotateIn,
    flipInY
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateUpgradeDialogComponent implements AfterViewChecked {

  rotateState$: BehaviorSubject<string> = new BehaviorSubject("");
  flipState$: BehaviorSubject<string> = new BehaviorSubject("");

  baseCurrency: string;
  selectedCurrency: Currency;

  currentVehicle: VehicleDto;
  upgradeToVehicle: VehicleDto;

  upgradePrice: number;

  isMobileDevice: boolean = false;

  showSpinner: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dlgService: RateUpgradeDialogService
  ) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.baseCurrency = data.baseCurrency;
    this.selectedCurrency = JSON.parse(JSON.stringify(data.selectedCurrency));
    this.currentVehicle = JSON.parse(JSON.stringify(data.currentVehicle));
    this.upgradeToVehicle = JSON.parse(JSON.stringify(data.upgradeToVehicle));
    this.upgradePrice = JSON.parse(JSON.stringify(data.upgradePrice));

    this.setSpinner(false);
  }

  onUpgradeClick = () => {
    this.setSpinner(true);
    this._dlgService.triggerEvent(new YesEvent(this.upgradeToVehicle));
    //this.dialogRef.close(this.upgradeToVehicle);
  };

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.rotateState$.next("rotate");
      this.flipState$.next("flip");
    }, 50);
  }

  private setSpinner = (show: boolean = false) => {
    this.showSpinner = show;
    this.dialogRef.disableClose = show;
  };

  closeDialog(): void {
    this.dialogRef.close();
  }
}
