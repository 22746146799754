import { Component, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import { Subscription, BehaviorSubject, timer, of } from 'rxjs';
import { LoaderLiteService } from './loader-lite.service';
import { fade } from 'src/app/shared/animations/animations';
import { tap, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'loader-lite',
  templateUrl: './loader-lite.component.html',
  styleUrls: ['./loader-lite.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderLiteComponent implements OnDestroy {

  @Input() showText: boolean = true;
  @Input() circlesType: string = "progress1";

  private allSubs: Subscription = new Subscription();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _loaderService: LoaderLiteService
  ) {
    this.setupLoader();
  }

  private setupLoader = () => {
    const subs = this._loaderService.loaderObservable.pipe(
      switchMap((show) => {
        if (show) {
          return timer(100).pipe(
            tap(() => this.loading$.next(true)),
            map(() => true)
          );
        }
        else {
          this.loading$.next(false);
          return of(false);
        }
      })
    ).subscribe();

    this.allSubs.add(subs);
  };

  ngOnDestroy(): void {
    this.allSubs.unsubscribe();
  }
}
