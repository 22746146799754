import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'session-dialog',
  templateUrl: './dlg-session-expired.component.html',
  styleUrls: ['./dlg-session-expired.component.scss']
})
export class SessionExpiredDialogComponent {

  isMobileDevice: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.isMobileDevice = data.isMobileDevice as boolean;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onStartOverClick = () => {
    this.closeDialog();
  };
}
