<div class="row vehicle-detail" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
  <div *ngIf="selectedVehicle.vehicleDesc[0].ribbonText && selectedVehicle.vehicleDesc[0].ribbonText.length > 0" class="ribbon">
    <span *ngFor="let text of selectedVehicle.vehicleDesc[0].ribbonText" [ngStyle]="{color:text.color}">
      {{text.text}}
    </span>
    <span class="ribbon-right"></span>
  </div>
  <div class="col-xs-12 {{smClass}} col-md-12 col-lg-12 text-center padding-0">
    <img class="img-car" src="assets/images/cars/{{selectedVehicle.vehicleDesc[0].image | lowercase}}" alt="{{selectedVehicle.code}} - {{selectedVehicle.vehicleDesc[0].modelDesc}}" />
  </div>
  <div class="col-xs-12 {{smClass}} col-md-12 col-lg-12 car-details padding-0">
    <span class="car-size">{{selectedVehicle.vehicleDesc[0].classDesc}}</span>
    <h4>{{selectedVehicle.vehicleDesc[0].modelDesc}}</h4>

    <div *ngIf="!hideFeatures" class="features-outer">
      <vehicle-other-features [vehicle]="selectedVehicle" hideFuel="true" [hideRange]="true" hideLuggageCap="true" hidePassengerCap="true"></vehicle-other-features>
    </div>

    <div *ngIf="!hideFuelBlock" class="row fuel-outer section">
      <div class="col-xs-7 col-sm-7 col-md-8 col-lg-8 padding-right-0" [ngClass]="{'fuel':!selectedVehicle.isElectric,'range':selectedVehicle.isElectric}">
        <ng-container *ngIf="!selectedVehicle.isElectric">
          <div>
            <span><strong>{{'common.city' | translate}}</strong>&nbsp;&nbsp;{{selectedVehicle.mileageCity}}l/100{{selectedVehicle.mileageUnit.toLowerCase()}}</span>
          </div>
          <div>
            <span><strong>{{'common.highway' | translate}}</strong>&nbsp;&nbsp;{{selectedVehicle.mileageHighway}}l/100{{selectedVehicle.mileageUnit.toLowerCase()}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedVehicle.isElectric">
          <i class="fas fa-tachometer-alt fa-2x" aria-hidden="true"></i>&nbsp;&nbsp;<span class="text">{{selectedVehicle.mileageRange}}{{selectedVehicle.mileageUnit.toLowerCase()}}&nbsp;{{'common.mileage-range' | translate}}</span>
        </ng-container>
      </div>
      <div class="col-xs-5 col-sm-5 col-md-4 col-lg-4 pull-right padding-right-0 right">
        <div *ngIf="!selectedVehicle.isElectric" class="seperator"></div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0 text-left">
          <i class="fas fa-user fa-2x" aria-hidden="true"></i>&nbsp;&nbsp;<span class="text">{{selectedVehicle.passengerCapacity}}</span>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0">
          <span *ngIf="selectedVehicle.tonsCapacity && selectedVehicle.tonsCapacity !== ''">
            <img src="assets/images/rent-a-car/ton.png" alt="tons Capacity" style="width:22px;" />&nbsp;&nbsp;<span class="text" style="font-size:1.25em;">{{selectedVehicle.tonsCapacity}}</span>
          </span>
          <span *ngIf="!selectedVehicle.tonsCapacity || selectedVehicle.tonsCapacity === ''">
            <i class="fas fa-suitcase fa-2x" aria-hidden="true"></i>&nbsp;&nbsp;<span class="text">{{selectedVehicle.luggageCapacity}}</span>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="rezInfo && rezInfo.confirmationNo !==''" class="section">
      <div class="box">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div *ngIf="rezInfo.renterFullName && rezInfo.renterFullName !== ''">
              <strong>{{rezInfo.renterFullName}}</strong>
            </div>
            <span>{{'common.conf-number' | translate}}:</span>&nbsp;<span class="conf-num">{{rezInfo.confirmationNo}}</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!hideBtnsBlock" class="row section">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left padding-0">
        <button type="button" class="btn-main btn-white" (click)="onChangeVehClick()">{{'buttons.chng-veh' | translate}}</button>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right padding-0">
        <button type="button" class="btn-main btn-orange" (click)="onContClick()">{{'buttons.continue' | translate}}&nbsp;<i class="fas fa-arrow-right" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
</div>
