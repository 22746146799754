import { select, Store } from "@ngrx/store";
import { catchError, filter, Observable, of, switchMap, take, tap } from "rxjs";
import { RECOMMENDATION_TYPES } from "../../core/enums";
import { RecommendationEmailDto } from "../../core/models";
import { SessionService } from "../../core/services";
import { getRecommendationEmail, LoadRecommendationEmail, State } from "../../store";
import { loadRates$ } from "./load-rates";

export const loadRecommendedRates$ = (lang: string, recommendId: string, sessionService: SessionService, store: Store<State>, vehicleType = ""): Observable<boolean> => {
  return store.pipe(
    select(getRecommendationEmail),
    tap((recEmail: RecommendationEmailDto) => {
      if (!recEmail || !recEmail.rateParams || recEmail.rateParams === null || recEmail.id !== recommendId) {
        store.dispatch(new LoadRecommendationEmail(recommendId));
      }
    }),
    filter(recEmail => recEmail && recEmail.rateParams && recEmail.rateParams !== null && recEmail.id === recommendId),
    switchMap((recEmail) => {
      const params = recEmail.rateParams;
      const rateId = recEmail.type.toLowerCase() === RECOMMENDATION_TYPES[RECOMMENDATION_TYPES.Rate].toLowerCase() ? recEmail.rates[0].rateId : "";
      let session = sessionService.getSession();

      if (session === null) {
        session = {};
      }

      sessionService.setSession(
        {
          ...session,
          rateParams: {
            ...params,
            vehicleType: vehicleType !== "" ? vehicleType : params.vehicleType
          },
          modifiedOn: new Date(),
          selectedRateId: rateId,
          isModify: false
        }
      );

      return loadRates$(lang, sessionService.getSession(), store);
    }),
    take(1),
    catchError(_ => {
      return of(false);
    })
  );
};
