import { Component, OnInit, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { first, filter, tap, take, exhaustMap, switchMap, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { CommonService, SmsService } from 'src/app/core/services';
import { combineLatest, timer, of } from 'rxjs';
import { NOTIFY_TYPES } from 'src/app/core/enums';

@Component({
  selector: 'sms-dialog',
  templateUrl: './dlg-sms.component.html',
  styleUrls: ['./dlg-sms.component.scss']
})
export class SmsDialogComponent implements OnInit {

  phoneNumber: string = "";
  confirmationNo: string = "";
  pickupLoc: string = "";
  pickupDateTime: string = "";

  toPhoneNo: string = "";

  isMobileDevice: boolean = false;

  showSpinner: boolean = false;

  private transGeneralErrMsg: string = "";
  private transInvalidPhone: string = "";

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private _smsService: SmsService,
    private _translate: TranslateService
  ) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.confirmationNo = data.confirmationNo;
    this.pickupLoc = data.pickupLoc;
    this.pickupDateTime = data.pickupDateTime;
    this.phoneNumber = data.phoneNumber as string;
    this.toPhoneNo = this.phoneNumber;
  }

  ngOnInit(): void {

    this._translate.get(
      [
        "validations.general-error-msg",
        "validations.invalid-phone-msg",
      ]
    ).pipe(
      filter(t => t && t != ""),
      tap((t) => {
        this.transGeneralErrMsg = t["validations.general-error-msg"];
        this.transInvalidPhone = t["validations.invalid-phone-msg"];
      }),
      take(1)
    ).subscribe();
  }

  onValueChange = (phoneNumber: string) => {
    if (phoneNumber != "") {
      this.toPhoneNo = "+" + phoneNumber.replace(/[\+\s\(\)\-\.]+/, "");
    }
    else {
      this.toPhoneNo = "";
    }
  };

  onSendSmsClick = () => {
    if (this.toPhoneNo && this.toPhoneNo != "") {

      this.setSpinner(true);

      this._smsService.validatePhoneNumber(this.toPhoneNo).pipe(
        exhaustMap((isPhoneValid) => {
          if (isPhoneValid) {
            return this._translate.get(
              [
                "dlg-sms.conf-sms-msg",
                "dlg-sms.sms-sent-msg"
              ],
              {
                "confirmationNo": this.confirmationNo,
                "pickupLoc": this.pickupLoc,
                "pickupDateTime": this.pickupDateTime
              }
            ).pipe(
              filter(t => t && t != ""),
              take(1),
              switchMap((t) => {
                return combineLatest([
                  this._smsService.sendSms({ to: this.toPhoneNo, messageBody: t["dlg-sms.conf-sms-msg"] }),
                  timer(1000), // To delay at least 1000ms to see spinner animation.
                ],
                  (result, _t) => result
                ).pipe(
                  filter(result => result && result.data && result.data != ""),
                  take(1),
                  map((result) => {
                    if (result.data.toLowerCase() == "sent") {
                      this._commonService.snackbarObs.next({ message: t["dlg-sms.sms-sent-msg"], action: "", config: { duration: 10000, panelClass: "success" } });
                      return "sent";
                    }
                    else {
                      return "notsent";
                    }
                  })
                );
              })
            );
          }
          else {
            return of("InvalidPhone");
          }
        }),
        first(),
        tap((res) => {
          if (res === "sent") {
            this.closeDialog();
          }
          else if (res === "InvalidPhone") {
            this._commonService.bottomSheetObs.next({ message: this.transInvalidPhone, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
            this.setSpinner(false);
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
            this.setSpinner(false);
          }
        })
      ).subscribe({
        error: e => {
          this.setSpinner(false);
          if (e.code == "401") {
            this.closeDialog();
          }
          else {
            this._commonService.bottomSheetObs.next({ message: this.transGeneralErrMsg, notifyType: NOTIFY_TYPES.Error, duration: 7000 });
          }
        }
      });
    }
  };

  private setSpinner = (show: boolean = false) => {
    this.showSpinner = show;
    this.dialogRef.disableClose = show;
  };

  closeDialog(): void {
    this.dialogRef.close();
  }
}
