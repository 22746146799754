import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoBlockComponent } from './info-block.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [InfoBlockComponent],
  exports: [InfoBlockComponent]
})
export class InfoBlockModule { }
