import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MESSAGE_BOX_BUTTONS } from 'src/app/core/enums';
import { HtmlButton } from 'src/app/core/models';

@Component({
  selector: 'message-dialog',
  templateUrl: './dlg-message.component.html',
  styleUrls: ['./dlg-message.component.scss']
})
export class MessageDialogComponent {

  isMobileDevice = false;
  heading = "";
  icon = "";
  message = "";
  buttons: MESSAGE_BOX_BUTTONS = MESSAGE_BOX_BUTTONS.OkOnly;
  customButtons: HtmlButton[] = [];
  isSingleLineText = false;
  isDismissible: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<object>,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.heading = data.heading as string;
    this.icon = data.icon as string;
    this.message = data.message as string;
    this.buttons = data.buttons as MESSAGE_BOX_BUTTONS;
    this.customButtons = data.customButtons as HtmlButton[];
    this.isSingleLineText = data.isSingleLineText as boolean === true ? true : false;
    this.isDismissible = data.isDismissible as boolean === true ? true : false;
  }

  closeDialog(dialogResult): void {
    this.dialogRef.close(dialogResult);
  }

  onButtonClick = (val) => {
    this.closeDialog(val);
  };
}
