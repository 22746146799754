import { select, Store } from "@ngrx/store";
import { catchError, combineLatest, filter, first, map, Observable, of, Subscription, switchMap, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { RateParams, Session } from "../../core/models";
import { getRatesLoaded, getRatesResult, getRatesStatus, LoadRates, ResetRates, State, UpdateRatesTranslation } from "../../store";
import { loadBranches$ } from "./load-branches";
import { timeout$ } from "./timeout";

const subsLoadingTimeout$: Subscription = new Subscription();

export const loadRates$ = (lang: string, session: Session, store: Store<State>, rateParams?: RateParams): Observable<boolean> => {
  let _rateParams: RateParams = null;

  if (rateParams && rateParams !== null && rateParams.pickupBranch) {
    _rateParams = {
      ...rateParams,
      language: lang
    };
  }
  else if (session && session.rateParams !== null && session.rateParams.pickupBranch) {
    _rateParams = {
      ...session.rateParams,
      language: lang
    };
  }

  if (_rateParams === null) {
    store.dispatch(new ResetRates());
    return of(false);
  }

  const combined$ = combineLatest([
    store.pipe(select(getRatesLoaded)),
    store.pipe(select(getRatesStatus))
  ],
    (loaded, status) => {
      return {
        isLoaded: loaded,
        status: status
      };
    }
  );

  return combined$.pipe(
    tap((obj) => {
      if (!obj.isLoaded && obj.status !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(new ResetRates());
        store.dispatch(new LoadRates(_rateParams));
        loadBranches$(_rateParams, store).pipe(first()).subscribe();

        subsLoadingTimeout$.add(timeout$("Request timed out in loading rates.").subscribe());
      }
    }),
    filter((obj) => obj.isLoaded || obj.status === STORE_STATUS_CODES.FAILED),
    take(1),
    switchMap((obj) => {
      subsLoadingTimeout$.unsubscribe();

      if (obj.status === STORE_STATUS_CODES.FAILED) {
        return of(false);
      }
      else {
        return store.pipe(
          select(getRatesResult),
          tap((ratesRes) => {
            if (lang !== ratesRes.rateParams.language) {
              store.dispatch(new UpdateRatesTranslation(lang, _rateParams));
              loadBranches$(_rateParams, store).pipe(first()).subscribe();
            }
          }),
          filter(ratesRes => ratesRes && ratesRes.rateParams.language === lang),
          map(() => obj.isLoaded),
          take(1)
        );
      }
    }),
    catchError(_ => {
      return of(false);
    })
  );
};
