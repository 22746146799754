import { Injectable, Inject } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { PAGES } from '../enums';
import { CommonService } from './common.service';

@Injectable()
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    private _commonService: CommonService,
    @Inject(DOCUMENT) private doc,
  ) {
  }

  setHtmlDocLang = (lang: string) => {
    if (this.doc && lang && this.doc.documentElement.lang != lang) {
      this.doc.documentElement.lang = lang;
    }
  };

  getHtmlDocLang = (): string => {
    if (this.doc) {
      return this.doc.documentElement.lang;
    }
    return "";
  };

  setPageTitle(title: string) {
    this.title.setTitle(title);
  }
  getPageTitle() {
    return this.title.getTitle();
  }

  createMetaTag(definition: MetaDefinition) {
    this.meta.addTag(definition, false);
    this.meta.updateTag(definition);
  }

  removeMetaTag(selector: string) {
    this.meta.removeTag(selector);
  }

  createLinkForCanonicalURL() {

    if (this.doc) {
      const canonical: HTMLLinkElement = this.doc.querySelector("link[rel='canonical']");
      if (canonical) {
        canonical.setAttribute('href', this.doc.URL);
      }
      else {
        let linkCanonical: HTMLLinkElement = this.doc.createElement('link');
        linkCanonical.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(linkCanonical);
        linkCanonical.setAttribute('href', this.doc.URL);
      }
    }
  }

  createLinksForAlternateURL(page: PAGES) {
    if (this.doc) {
      const enUrl: string = this.doc.baseURI + this._commonService.getTranslatedRouteByKey(page, 'en');

      const alternateEn: HTMLLinkElement = this.doc.querySelector("link[rel='alternate'][hreflang='en']");
      if (alternateEn) {
        if (enUrl) {
          alternateEn.setAttribute('href', enUrl);
        }
      }
      else if (enUrl) {
        let linkAlternateEn: HTMLLinkElement = this.doc.createElement('link');
        linkAlternateEn.setAttribute('rel', 'alternate');
        linkAlternateEn.setAttribute('hreflang', 'en');
        this.doc.head.appendChild(linkAlternateEn);
        linkAlternateEn.setAttribute('href', enUrl);
      }


      const frUrl: string = this.doc.baseURI + this._commonService.getTranslatedRouteByKey(page, 'fr');
      const alternateFr: HTMLLinkElement = this.doc.querySelector("link[rel='alternate'][hreflang='fr']");
      if (alternateFr) {
        if (frUrl) {
          alternateFr.setAttribute('href', frUrl);
        }
      }
      else if (frUrl) {
        const linkAlternateFr: HTMLLinkElement = this.doc.createElement('link');
        linkAlternateFr.setAttribute('rel', 'alternate');
        linkAlternateFr.setAttribute('hreflang', 'fr');
        this.doc.head.appendChild(linkAlternateFr);
        linkAlternateFr.setAttribute('href', frUrl);
      }
    }
  }
}
