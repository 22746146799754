import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RatesLoaderComponent } from './rates-loader.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [RatesLoaderComponent],
  exports: [RatesLoaderComponent]
})
export class RatesLoaderModule { }
