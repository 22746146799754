import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { RateExtra, Currency, RateDetailDto, MileageCap } from 'src/app/core/models';

import { RateExtraService } from 'src/app/core/services';
import { CoverageDialogService } from './dlg-coverage.service';

import { tap, filter, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State, getExtrasByGroup, getCurrencyRates } from 'src/app/store';
import { AddEvent } from 'src/app/core/events/dialog.events';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'coverage-dialog',
  templateUrl: './dlg-coverage.component.html',
  styleUrls: ['./dlg-coverage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoverageDialogComponent implements OnInit {

  private orgExtras: RateExtra[] = [];

  coverageExtras: RateExtra[] = [];
  rateDetail: RateDetailDto;
  mileageCap: MileageCap = null;
  totalFreeMiles: number = 0;

  updatedExtras: RateExtra[] = [];

  baseCurrency: string;
  selectedCurrency: Currency;

  formGroup: UntypedFormGroup;

  isMobileDevice: boolean = false;

  showSpinner: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dlgService: CoverageDialogService,
    private _rateExtraService: RateExtraService,
    private fb: UntypedFormBuilder,
    private store: Store<State>) {

    this.isMobileDevice = data.isMobileDevice as boolean;
    this.baseCurrency = data.baseCurrency;
    this.selectedCurrency = JSON.parse(JSON.stringify(data.selectedCurrency));
    this.rateDetail = data.rateDetail as RateDetailDto;
    this.mileageCap = data.mileageCap as MileageCap;
    this.totalFreeMiles = data.totalFreeMiles as number;

    this.setSpinner(false);
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({});

    combineLatest([
      this.store.pipe(select(getCurrencyRates)),
      this.store.pipe(select(getExtrasByGroup("coverage")))],
      (currencyRates, coverageExtras) => {
        return {
          currencyRates: currencyRates,
          coverageExtras: coverageExtras
        };
      }
    ).pipe(
      filter(obj => obj?.coverageExtras && obj.coverageExtras?.length > 0),
      take(1),
      tap((obj) => {

        this.orgExtras = JSON.parse(JSON.stringify(obj.coverageExtras));

        const _coverageExtras = this.orgExtras.reduce((_coverageExtras: RateExtra[], orgExtra: RateExtra) => {

          if (this.baseCurrency !== this.selectedCurrency.code && obj.currencyRates) {
            orgExtra = this._rateExtraService.convertRateExtraCurrency(obj.currencyRates, orgExtra, orgExtra, this.baseCurrency, this.selectedCurrency.code, this.rateDetail);
          }

          orgExtra.qty = orgExtra.qty === 0 ? 1 : orgExtra.qty;

          const formGrp = this.formGroup.controls[orgExtra.code] as UntypedFormGroup;

          if (!formGrp)
            this.formGroup.addControl(orgExtra.code, this.fb.group({}));

          if (orgExtra.autoChecked) {
            return [
              ..._coverageExtras,
              {
                ...orgExtra,
                autoApply: true,
                totalCharge: this._rateExtraService.calcExtraTotalCharge(orgExtra, true, this.rateDetail),
                totalChargeChildren: this._rateExtraService.calcExtraChildrenTotalCharge(this.rateDetail, orgExtra)
              }
            ];
          }
          else
            return [
              ..._coverageExtras,
              orgExtra
            ];
        }, []);

        this.coverageExtras = [..._coverageExtras];
      })
    ).subscribe();

    //this.store.pipe(
    //  select(getExtrasByGroup("coverage")),
    //  filter(extras => extras && extras.length > 0),
    //  take(1),
    //  tap((extras) => {

    //    this.orgExtras = JSON.parse(JSON.stringify(extras));

    //    const _coverageExtras = this.orgExtras.reduce((_coverageExtras: RateExtra[], orgExtra: RateExtra) => {

    //      orgExtra.qty = orgExtra.qty === 0 ? 1 : orgExtra.qty;

    //      const formGrp = this.formGroup.controls[orgExtra.code] as FormGroup;

    //      if (!formGrp)
    //        this.formGroup.addControl(orgExtra.code, this.fb.group({}));

    //      if (orgExtra.autoChecked) {
    //        return [
    //          ..._coverageExtras,
    //          {
    //            ...orgExtra,
    //            autoApply: true,
    //            totalCharge: this._rateExtraService.calcExtraTotalCharge(orgExtra, true, this.rateDetail),
    //            totalChargeChildren: this._rateExtraService.calcExtraChildrenTotalCharge(this.rateDetail, orgExtra)
    //          }
    //        ];
    //      }
    //      else
    //        return [
    //          ..._coverageExtras,
    //          orgExtra
    //        ];
    //    }, []);

    //    this.coverageExtras = [..._coverageExtras];
    //  })
    //).subscribe();
  }

  onExtrasUpdated = (updatedExtras: RateExtra[]) => {
    this.updatedExtras = updatedExtras.reduce((extras: RateExtra[], extra: RateExtra) => {
      if ((extra.autoApply || extra.autoChecked) && !extra.isFree && extra.totalCharge === 0) {
        return [
          ...extras,
          {
            ...extra,
            totalCharge: this._rateExtraService.calcExtraTotalCharge(extra, true, this.rateDetail),
            totalChargeChildren: this._rateExtraService.calcExtraChildrenTotalCharge(this.rateDetail, extra)
          }
        ];
      }
      else {
        return [...extras, extra];
      }
    }, []);

    const filteredExtras = this.coverageExtras.filter(x => this.updatedExtras.map(y => y.code).indexOf(x.code) == -1);

    const sortedExtras = [...filteredExtras, ...this.updatedExtras].sort((a, b) => { return a.sortOrder - b.sortOrder; });

    this.coverageExtras = [
      ...sortedExtras
    ];
  };

  onAddClick = () => {
    if (this.updatedExtras.length > 0) {
      this.setSpinner(true);

      const updatedExtras = this.updatedExtras.reduce((extras: RateExtra[], extra: RateExtra) => {
        const _extra = this.orgExtras.find(x => x.code == extra.code);
        if (_extra && _extra.id) {
          return [
            ...extras,
            {
              ...extra,
              autoApply: _extra.autoApply
            }
          ];
        }
        else {
          return [...extras, extra];
        }

      }, []);

      this._dlgService.triggerEvent(new AddEvent(updatedExtras));
      //this.closeDialog(updatedExtras);
    }
  };

  private setSpinner = (show: boolean = false) => {
    this.showSpinner = show;
    this.dialogRef.disableClose = show;
  };

  closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }
}
