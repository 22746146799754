import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { CommonService } from 'src/app/core/services';
import { Observable, of } from 'rxjs';

import pkgInfo from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterComponent implements OnInit {

  readonly mainSiteUrl: string = CommonService.MainSiteUrl;
  currentYear: number = 2022;
  currentVersion: string = "1.8.0";

  isMobileDevice$: Observable<boolean> = of(false);

  constructor(private _commonService: CommonService) {
  }

  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear();
    this.currentVersion = pkgInfo.version;

    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
  }
}
