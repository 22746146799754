<div *ngIf="rate" class="rate-det-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <div mat-dialog-title class="dialog-title">
    <div class="row dialog-header">
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <img class="img-car" src="assets/images/cars/{{rate.vehicle.vehicleDesc[0].image | lowercase}}" alt="{{rate.vehicle.code}} - {{rate.vehicle.vehicleDesc[0].modelDesc}}" />
      </div>
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 veh-class-desc" [ngClass]="{'padding-left-0':isMobileDevice}">
            {{rate.vehicle.vehicleDesc[0].classDesc}}
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" [ngClass]="{'padding-left-0':isMobileDevice}">
            <h4>{{rate.vehicle.vehicleDesc[0].modelDesc}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <mat-dialog-content class="dialog-content">
    <div class="row table-heading">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{'common.details' | translate}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0">{{'common.rate' | translate}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">TOTAL</div>
    </div>
    <div *ngIf="rate.rateDetail.rentalMonths > 0" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{rate.rateDetail.rentalMonths}} {{rate.rateDetail.rentalMonths | checkplural:'common.months':'common.month' | translate}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0">{{rate.rateDetail.ratePerMonth | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.month' | translate}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{(rate.rateDetail.rentalMonths * rate.rateDetail.ratePerMonth) | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</div>
    </div>
    <div *ngIf="rate.rateDetail.rentalWeeks > 0" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{rate.rateDetail.rentalWeeks}} {{rate.rateDetail.rentalWeeks | checkplural:'common.weeks':'common.week' | translate}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0">{{rate.rateDetail.ratePerWeek | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.week' | translate}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{(rate.rateDetail.rentalWeeks * rate.rateDetail.ratePerWeek) | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</div>
    </div>
    <div *ngIf="rate.rateDetail.rentalDays > 0" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{rate.rateDetail.rentalDays}} {{rate.rateDetail.rentalDays | checkplural:'common.days':'common.day' | translate}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0">{{rate.rateDetail.ratePerDay | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.day' | translate}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{(rate.rateDetail.rentalDays * rate.rateDetail.ratePerDay) | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</div>
    </div>
    <div *ngIf="rate.rateDetail.rentalHours > 0" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{rate.rateDetail.rentalHours}} {{rate.rateDetail.rentalHours | checkplural:'common.hours':'common.hour' | translate}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0">{{rate.rateDetail.ratePerHour | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.hour' | translate}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{(rate.rateDetail.rentalHours * rate.rateDetail.ratePerHour) | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</div>
    </div>
    <hr />
    <div class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{'common.incl-mileage' | translate}}<span *ngIf="rate.rateDetail.perMileRate > 0">&nbsp;({{rate.rateDetail.perMileRate | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}/{{'common.extra-km' | translate}})</span></div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0 no-word-wrap">{{rate.totalMilesIncludedDisplay?.toLowerCase() === "unlimited" ? ('common.unlimited' | translate | titlecase) : (rate.vehicle.mileageCap.daily | number:'1.0-0') + rate.vehicle.mileageUnit.toLowerCase() + "/" + ('common.day' | translate) }}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{rate.totalMilesIncludedDisplay}}</div>
    </div>
    <div *ngFor="let extra of rateExtras" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{extra.extraDesc[0].description }}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0 no-word-wrap">{{getExtraRatePerPeriod(extra, rate.rateDetail)}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{extra.totalCharge | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</div>
    </div>
    <div *ngIf="rate.discountCodeResult" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{rate.discountCodeResult.displayLabel}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0 no-word-wrap">{{rate.discountCodeResult.discountCode}}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right color-maroon">
        <span *ngIf="rate.rateSummary.totalDiscount === 0">{{'common.applied' | translate}}</span>
        <span *ngIf="rate.rateSummary.totalDiscount !== 0">{{rate.rateSummary.totalDiscount | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</span>
      </div>
    </div>
    <div *ngFor="let tax of rate.taxes" class="row table-row">
      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">{{tax.taxDesc[0].displayText}}</div>
      <div class="col-xs-5 col-sm-2 col-md-2 col-lg-2 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0">{{tax.taxRate/100 | percent:'1.2-2' }}</div>
      <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right">{{tax.totalCharge | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}}</div>
    </div>

    <div class="row table-row-total">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label>{{'common.veh-subtotal' | translate}}</label>
        <div class="dropup">
          <button class="btn btn-default dropdown-toggle btn-currency" [disabled]="(isCurrencyRatesLoaded$ | async) === false" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span data-bind="label">{{selectedCurrency.displayText}}</span>
            <span>&nbsp;&nbsp;<i class="fas fa-chevron-down" aria-hidden="true"></i></span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li *ngFor="let currency of currencies"><a (click)="convertCurrency(rate, currency.code, currency.displayText)">{{currency.displayText}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right total"><span *ngIf="selectedCurrency.code !== baseCurrency">{{'common.estimated' | translate}}</span> {{rate.rateSummary.totalCharges | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}</div>
    </div>
    <ng-container *ngIf="allowChoose">
      <div class="row choose-this">
        <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-sm-push-7 col-md-push-7 col-lg-push-7 padding-0" [ngClass]="{'text-center':isMobileDevice,'text-right':!isMobileDevice}">
          <button type="button" class="btn-choose-this" [mat-dialog-close]="rate">{{'buttons.choose-this-veh' | translate}}</button>
        </div>
        <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 col-sm-pull-5 col-md-pull-5 col-lg-pull-5 padding-0" [ngClass]="{'padding-top-10':isMobileDevice}">
          <p *ngIf="selectedCurrency.code !== baseCurrency">** {{'common.cnvrt-prices-msg' | translate}}</p>&nbsp;
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!allowChoose">
      <div class="row choose-this">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-0" [ngClass]="{'padding-top-10':isMobileDevice}">
          <p *ngIf="selectedCurrency.code !== baseCurrency">** {{'common.cnvrt-prices-msg' | translate}}</p>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
</div>
