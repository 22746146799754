import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleInfoComponent } from './vehicle-info.component';
import { VehicleOtherFeaturesModule } from '../vehicle-other-features';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, VehicleOtherFeaturesModule, TranslateModule],
  declarations: [VehicleInfoComponent],
  exports: [VehicleInfoComponent]
})
export class VehicleInfoModule { }
