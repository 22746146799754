import { NgModule } from '@angular/core';

import { NanorepComponent } from './nanorep.component';
import { SafePipeModule } from 'src/app/shared/pipes/safe-content.module';

@NgModule({
  imports: [SafePipeModule],
  declarations: [NanorepComponent],
  exports: [NanorepComponent]
})
export class NanorepModule { }
