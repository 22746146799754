import { Action } from '@ngrx/store';

import { RateDto, VehicleDto } from 'src/app/core/models';

export const SET_SELECTED_RATE = '[SelectedRate] Set Selected Rate';
export const SET_SELECTED_RATE_FROM_REZ = '[SelectedRate] Set Selected Rate From Reservation';
export const SET_SELECTED_RATE_SUCCESS = '[SelectedRate] Set Selected Rate Success';
export const RESET_SELECTED_RATE = '[SelectedRate] Reset Selected Rate';
export const UPDATE_SELECTED_RATE_TRANSLATION = '[SelectedRate] Update Selected Rate Translations';

export class SetSelectedRate implements Action {
  readonly type = SET_SELECTED_RATE;
  constructor(public payload: RateDto) { }
}

export class SetSelectedRateFromRez implements Action {
  readonly type = SET_SELECTED_RATE_FROM_REZ;
  constructor(public language: string, public params: { reservationNo: string, lastName: string }) { }
}

export class SetSelectedRateSuccess implements Action {
  readonly type = SET_SELECTED_RATE_SUCCESS;
  constructor() { }
}

export class ResetSelectedRate implements Action {
  readonly type = RESET_SELECTED_RATE;
  constructor() { }
}

export class UpdateSelectedRateTranslation implements Action {
  readonly type = UPDATE_SELECTED_RATE_TRANSLATION;
  constructor(public vehicle: VehicleDto) { }
}

// action types
export type SelectedRateAction = SetSelectedRate | SetSelectedRateSuccess | ResetSelectedRate | UpdateSelectedRateTranslation;
