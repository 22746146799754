import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { MatDatepicker } from '@angular/material/datepicker';

import { CommonService } from '../../core/services';

import * as moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { Moment } from 'moment';

@Component({
  selector: 'month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthPickerComponent implements OnInit {

  @Input() parentFormGroup: UntypedFormGroup = null;
  @Input() formCtrlName: string = "";
  @Input() placeHolderKey: string = "";
  @Input() minDate: Date = new Date(new Date().getFullYear() - 100, 1, 1);
  @Input() maxDate: Date = new Date(new Date().getFullYear() + 100, 11, 31);
  @Input() isTouchUI: boolean = false;

  constructor() {}

  ngOnInit() {

    if (!this.parentFormGroup) {
      this.parentFormGroup = new UntypedFormGroup({
        datePicker: new UntypedFormControl()
      });
      this.formCtrlName = "datePicker";
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.parentFormGroup.get(this.formCtrlName).setValue(ctrlValue);
    this.parentFormGroup.get(this.formCtrlName).updateValueAndValidity();
    datepicker.close();
  }

  onMonthPickerKeyPress = (event) => {
    return CommonService.isControlKeyPress(event) || CommonService.isValidNumericKeyPress(event) || CommonService.isValidSlashKeyPress(event);
  };
}
