import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { RateExtraListComponent } from './rate-extra-list.component';
import { RateExtraItemComponent } from './rate-extra-item.component';

import { SafePipeModule } from 'src/app/shared/pipes/safe-content.module';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatExpansionModule, MatCheckboxModule, MatSelectModule, MatProgressSpinnerModule,  SafePipeModule, TranslateModule],
  declarations: [RateExtraListComponent, RateExtraItemComponent],
  exports: [RateExtraListComponent, RateExtraItemComponent]
})
export class RateExtraModule { }
