import { BranchesAction, LOAD_BRANCHES, LOAD_BRANCHES_SUCCESS, LOAD_BRANCHES_FAIL, RESET_BRANCHES } from './branch.action';
import { BranchDto } from 'src/app/core/models';
import { IBranchState } from '../_base';

const initialState: IBranchState = {
  branchesSource: [],
  pickupBranch: {} as BranchDto,
  returnBranch: {} as BranchDto,
  loading: false,
  loaded: false
};

export function branchReducer(state = initialState, action: BranchesAction): IBranchState {
  switch (action.type) {
    case LOAD_BRANCHES: {
      return {
        ...state,
        loading: true
      };
    }

    case LOAD_BRANCHES_SUCCESS: {

      return {
        ...state,
        branchesSource: action.branches,
        pickupBranch: action.pickupBranch,
        returnBranch: action.returnBranch,
        loading: false,
        loaded: true
      };
    }

    case LOAD_BRANCHES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case RESET_BRANCHES: {
      return {
        ...initialState
      };
    }
  }

  return state;
}
