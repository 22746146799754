import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { Observable, of } from 'rxjs';

import { CommonService } from '../../core/services';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements OnInit {

  @Input() parentFormGroup: UntypedFormGroup = null;
  @Input() formCtrlName: string = "";
  @Input() placeHolderKey: string = "";
  @Input() minDate: Date = new Date(new Date().getFullYear() - 100, 1, 1);
  @Input() maxDate: Date = new Date(new Date().getFullYear() + 100, 11, 31);
  @Input() isTouchUI: boolean = false;

  @Output() dateChange = new EventEmitter<Date>();

  constructor() {}

  ngOnInit() {
    if (!this.parentFormGroup) {
      this.parentFormGroup = new UntypedFormGroup({
        datePicker: new UntypedFormControl()
      });
      this.formCtrlName = "datePicker";
    }
  }

  onDatePickerKeyPress = (event) => {
    return CommonService.isControlKeyPress(event) || CommonService.isValidNumericKeyPress(event) || CommonService.isValidSlashKeyPress(event);
  };

  onDateInput = (event: MatDatepickerInputEvent<Date>) => {
    this.dateChange.emit(event.value);
  };
}
