import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';

import * as UserActions from './user.action';
import { AuthenticationService } from 'src/app/core/services';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService
  ) { }

  loginUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loginUser),
    exhaustMap(action => {
      return this.authService
        .login(action.userName, action.password, action.rememberMe, action.language)
        .pipe(
          map((res) => {
            if (res.access_token && res.access_token.length > 0) {
              return UserActions.loadCurrentUserInfo();
            }
            else {
              return UserActions.loadCurrentUserInfoFail({ payload: "login failed" });
            }
          }),
          catchError((error) => {
            return of(UserActions.loadCurrentUserInfoFail({ payload: error }));
          })
        );
    })
  ));

  getLoggedInUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadCurrentUserInfo),
    exhaustMap(action => {
      return this.authService
        .getLoggedInUser()
        .pipe(
          map((user) => {
            if (user && user != null) {
              return UserActions.loadCurrentUserInfoSuccess({ payload: user });
            }
            else {
              return UserActions.loadCurrentUserInfoFail({ payload: "Not authenticated!" });
            }
          }),
          catchError((error) => {
            return of(UserActions.loadCurrentUserInfoFail({ payload: error }));
          })
        );
    })
  ));

  logoutUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.logoutUser),
    exhaustMap(action => {
      var isLoggedout = this.authService.logout();
      return of(isLoggedout).pipe(
        map((isSuccess) => {
          if (isSuccess) {
            return UserActions.resetCurrentUserInfo();
          }
          else {
            return UserActions.loadCurrentUserInfo();
          }
        }),
        catchError((error) => {
          return of(UserActions.loadCurrentUserInfoFail({ payload: error }));
        })
      );
    })
  ));
}
