import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import { ChangeBranchDialogComponent } from './dlg-change-branch.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule, MatRadioModule],
  declarations: [ChangeBranchDialogComponent],
  exports: [ChangeBranchDialogComponent]
})
export class ChangeBranchDialogModule { }
