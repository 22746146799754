import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { VehicleDto } from 'src/app/core/models';

import { VehicleService } from 'src/app/core/services';

import { LOAD_VEHICLES, LoadVehicles, LoadVehiclesSuccess, LoadVehiclesFail } from './vehicle.action';

@Injectable()
export class VehiclesEffects {
  constructor(
    private actions$: Actions,
    private vehicleService: VehicleService
  ) { }


  loadVehicles$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_VEHICLES),
      map((action: LoadVehicles) => action.lang),
      switchMap(lang => {
        return this.vehicleService
          .fetchAllVehicles(lang)
          .pipe(
            map((result) => {
              const _vehicles = result.data as VehicleDto[];
              return new LoadVehiclesSuccess(_vehicles);
            }),
            catchError(
              (error) => {
                return of(new LoadVehiclesFail(error));
              })
          );
      })
    ));
}
