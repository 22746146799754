import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[CapsLockState]',
  //inputs: ['text: CapsLockText', 'placement: CapsLockPlacement','disabled: CapsLockDisabled'],
  //host: {
  //  '(focusin)': 'onFocusIn($event)',
  //  '(focusout)': 'onFocusOut($event)',
  //  '(click)': 'onClick($event)'
  //}
})
export class CapsLockStateDirective {

  @Input() CapsLockDisabled = false;
  @Input() CapsLockText = "";
  @Input() CapsLockPlacement = "top";

  capsLockTipElement: any;

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private doc) { }

  @HostListener('focusin', ['$event'])
  onFocusIn = (event) => {
    if (!this.capsLockTipElement && this.CapsLockText && this.CapsLockText != "") {
      this.create();
      this.show(event);
    }
  };

  @HostListener('focusout', ['$event'])
  onFocusOut(_event) {
    this.hide();
  }

  @HostListener('click', ['$event'])
  onClick = (event) => {
    this.show(event);
  };

  create() {
    this.capsLockTipElement = this.doc.createElement('div');
    this.capsLockTipElement.className += "capslock-tooltip tooltip " + this.CapsLockPlacement;

    const capsLockTipArrow = this.doc.createElement('div');
    capsLockTipArrow.className = "tooltip-arrow red";

    const capsLockTipInner = this.doc.createElement('div');
    capsLockTipInner.className = "tooltip-inner red";
    capsLockTipInner.innerHTML = this.CapsLockText;

    this.capsLockTipElement.appendChild(capsLockTipArrow);
    this.capsLockTipElement.appendChild(capsLockTipInner);

    if (this.elementRef && this.elementRef !== null && this.elementRef.nativeElement && this.elementRef.nativeElement !== null) {
      var el = this.elementRef.nativeElement.querySelector("input[type='password']");

      el.onkeyup = (event) => {
        this.show(event);
      };

      this.elementRef.nativeElement.appendChild(this.capsLockTipElement);
    }
  }

  show(event) {
    let inputType = event.target.type;
    if (inputType !== "password" && event.target.querySelector("input[type='password']")) {
      inputType = event.target.querySelector("input[type='password']").type;
    }

    if (this.capsLockTipElement && inputType === "password" && !this.CapsLockDisabled) {
      if (event.getModifierState && event.getModifierState('CapsLock')) {
        this.capsLockTipElement.className += " show-tip";
      }
      else {
        this.hide();
      }
    }
    else {
      this.hide();
    }
  }

  hide() {
    if (this.capsLockTipElement) {
      this.capsLockTipElement.classList.remove("show-tip");
    }
  }
}
