import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { TranslateModule } from '@ngx-translate/core';
import { CapsLockStateModule } from 'src/app/shared/directives/caps-lock-state';
import { AutoFocusModule } from 'src/app/shared/directives/auto-focus';

import { ContainerSpinnerModule } from '../container-spinner';
import { LoginDialogComponent } from './dlg-login.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatButtonModule, ContainerSpinnerModule, TranslateModule, CapsLockStateModule, RouterModule, AutoFocusModule],
    declarations: [LoginDialogComponent],
    exports: [LoginDialogComponent]
})
export class LoginDialogModule { }
