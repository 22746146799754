import { select, Store } from "@ngrx/store";
import { catchError, filter, map, Observable, of, switchMap, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { getRatesResultState, getSelectedRate, getVehicles, SetSelectedRate, State, UpdateSelectedRateTranslation } from "../../store";

export const loadSelectedRate$ = (lang: string, selectedRateId: string, store: Store<State>): Observable<boolean> => {
  return store.pipe(select(getRatesResultState)).pipe(
    filter((ratesResult) => (ratesResult.availableRates && ratesResult.availableRates.length > 0) || ratesResult.status.statusCode !== 20000 || ratesResult.reqStatus === STORE_STATUS_CODES.FAILED),
    switchMap((ratesResult) => {
      if (ratesResult.status.statusCode !== 20000 || ratesResult.reqStatus === STORE_STATUS_CODES.FAILED) {
        return of(false);
      }

      const selectedRate = ratesResult.availableRates.find(x => x.id === selectedRateId);

      if (!selectedRate || selectedRate === null) {
        return of(false);
      }

      return store.pipe(
        select(getSelectedRate),
        tap((rate) => {
          if (!rate || !rate.id || rate.vehicle.code !== selectedRate.vehicle.code || rate.vehicle.vehicleDesc[0].language !== selectedRate.vehicle.vehicleDesc[0].language) {
            store.dispatch(new SetSelectedRate(selectedRate));
          }
        }),
        filter((rate) => rate && rate.id !== undefined && rate.id !== "" && rate.vehicle.vehicleDesc[0].language === selectedRate.vehicle.vehicleDesc[0].language),
        take(1)
      ).pipe(
        switchMap((rate) => {
          if (rate.vehicle.vehicleDesc[0].language === lang) {
            return of(rate.id !== undefined && rate.id !== "");
          }

          return store.pipe(
            select(getVehicles),
            filter((vehicles) => vehicles && vehicles.length > 0 && vehicles[0].vehicleDesc[0].language === lang),
            tap((vehicles) => {
              const veh = {
                ...vehicles.find(x => x.id === rate.vehicle.id)
              };

              if (veh && veh.id) {
                store.dispatch(new UpdateSelectedRateTranslation(veh));
              }
            }),
            map(() => rate.id !== undefined && rate.id !== ""),
            take(1)
          );
        })
      );
    }),
    catchError(_ => {
      return of(false);
    })
  );
};
