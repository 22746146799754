import { select, Store } from "@ngrx/store";
import { catchError, combineLatest, distinctUntilChanged, filter, map, Observable, of, Subscription, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { SessionService } from "../../core/services";
import { getExtrasStatus, getSelectedRate, LoadExtras, State } from "../../store";
import { timeout$ } from "./timeout";

const subsLoadingTimeout$: Subscription = new Subscription();

export const loadRateExtras$ = (lang: string, sessionService: SessionService, store: Store<State>): Observable<boolean> => {
  let session = sessionService.getSession();

  if (!session || session === null || session.rateParams === null) {
    return of(false);
  }

  let extrasLoadedFor = "";
  const combined = combineLatest([
    store.pipe(select(getSelectedRate), distinctUntilChanged((x, y) => {
      return JSON.stringify(x) === JSON.stringify(y);
    })),
    store.pipe(select(getExtrasStatus))
  ],
    (rate, status) => {
      return {
        selectedRate: rate,
        status: status
      };
    }
  );

  return combined.pipe(
    filter(obj => obj.selectedRate && obj.selectedRate.id !== undefined && obj.status && obj.status.isLoading === false),
    tap((obj) => {

      session = sessionService.getSession();
      extrasLoadedFor = (session.rateParams.pickupBranch + obj.selectedRate.vehicle.code + session.rateParams.ageGroup).toLowerCase();
      if ((!obj.status.isLoaded || lang !== obj.status.currentLanguage || !session.extrasLoadedFor || session.extrasLoadedFor.toLowerCase() !== extrasLoadedFor) && session.rateParams.pickupBranch && obj.status.statusCode !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(new LoadExtras(
          {
            lang: lang,
            branchCode: session.rateParams.pickupBranch,
            vehicleCode: obj.selectedRate.vehicle.code,
            ageGroup: session.rateParams.ageGroup,
            rateSpecificExtraCodes: obj.selectedRate.extraRates.filter(x => x.groupName.toLowerCase() === "rate").map(x => x.code).join(),
            preselectedExtraCodes: obj.selectedRate.extraRates.filter(x => x.groupName.toLowerCase() !== "rate" && x.totalCharge > 0).map(x => x.code + ":" + x.qty),
            discountedExtraCodes: obj.selectedRate.discountCodeResult ? obj.selectedRate.discountCodeResult.extrasIncluded.join() : "",
            rateDetail: obj.selectedRate.rateDetail,
            totalFreeMiles: obj.selectedRate.totalFreeMiles,
            mileageCapValue: obj.selectedRate.vehicle.mileageCap.monthly * Math.max(1, obj.selectedRate.rateDetail.rentalMonths)
          }
        ));

        subsLoadingTimeout$.add(timeout$("Request timed out in loading rate extras.").subscribe());
      }
    }),
    filter((obj) => (obj.status.isLoaded && lang === obj.status.currentLanguage && session.extrasLoadedFor && session.extrasLoadedFor.toLowerCase() === extrasLoadedFor) || obj.status.statusCode === STORE_STATUS_CODES.FAILED),
    map((obj) => {

      subsLoadingTimeout$.unsubscribe();

      if (obj.status.statusCode === STORE_STATUS_CODES.FAILED) {
        return false;
      }
      else {
        return obj.status.isLoaded;
      }
    }),
    take(1),
    catchError(_ => {
      return of(false);
    })
  );
};
