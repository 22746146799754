import { Injectable, isDevMode, Inject } from '@angular/core';

import { TimeService } from './time.service';

@Injectable({ providedIn: 'root' })
export class CalendarService {


   constructor() { }

   public static getYahooCalendarUrl(data) {
      var yahooCalendarUrl = 'http://calendar.yahoo.com/?v=60&view=d&type=20';

      var duration = TimeService.getHoursDuration(data.startDate, data.endDate);

      yahooCalendarUrl += '&TITLE=' + data.title;
      yahooCalendarUrl += '&ST=' + data.startDate + '&DUR=' + duration;
      yahooCalendarUrl += '&DESC=' + data.description;
      yahooCalendarUrl += '&in_loc=' + data.location;

      return yahooCalendarUrl;
  };

  public static getMicrosoftCalendarUrl(data) {
    var microsoftCalendarUrl = 'http://calendar.live.com/calendar/calendar.aspx?rru=addevent';
    microsoftCalendarUrl += '&summary=' + data.title;
    microsoftCalendarUrl += '&dtstart=' + data.startDate + '&dtend=' + data.endDate;
    microsoftCalendarUrl += '&description=' + data.description;
    microsoftCalendarUrl += '&location=' + data.location;

    return microsoftCalendarUrl;
  };

  public static getGoogleCalendarUrl(data) {
    var googleCalendarUrl = 'https://www.google.com/calendar/render?action=TEMPLATE';
    googleCalendarUrl += '&text=' + data.title;
    googleCalendarUrl += '&dates=' + data.startDate + '/' + data.endDate;
    googleCalendarUrl += '&details=' + data.description;
    googleCalendarUrl += '&location=' + data.location;

    return googleCalendarUrl;
  };

  public static getIcsCalendar(data) {
    return [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'CLASS:PUBLIC',
      'DESCRIPTION:' + this.formatIcsText(data.description, 500),
      'DTSTART:' + data.startDate,
      'DTEND:' + data.endDate,
      'LOCATION:' + this.formatIcsText(data.location, 150),
      'SUMMARY:' + this.formatIcsText(data.title, 100),
      'TRANSP:TRANSPARENT',
      'END:VEVENT',
      'END:VCALENDAR',
      'UID:' + this.getUid(),
      'DTSTAMP:' + TimeService.getTimeCreated(),
      'PRODID:globecar-addtocalendar'
    ].join('\n');
  };


  /**
   * Removes line breaks and ensures that the string is no
   * longer than maxLength chars (or 75 chars if none specified).
   *
   * @param  {String} s         string to sanitize
   * @param  {Number} maxLength index of string to truncate at
   * @return {String}
   */
  public static formatIcsText(str, maxLength) {
    if (!str) {
      return '';
    }
    str = str.replace(/\n/g, '\\n');
    str = str.substring(0, maxLength);

    return str;
  };


  /**
   * The name of the file will be the event title with alphanumeric chars
   * having the extension `.ics`.
   *
   * @param  {String} icsData
   * @return {Blob}
   */
  public static getIcsBlob(icsData) {
    return new Blob([icsData], {
      type: 'application/octet-stream'
    });
  };

  /**
   * Transforms given string to be valid file name.
   *
   * @param  {String} title
   * @return {String}
   */
  public static getIcsFileName(title) {
    if (!title) {
      return 'event.ics';
    }
    return `${title.replace(/[^\w ]+/g, '')}.ics`;
  };



  /**
   * Returns a random base 36 hash for iCal UID.
   *
   * @return {String}
   */
  public static getUid() {
    return Math.random().toString(36).substring(2);
  };
}
