import { createSelector } from '@ngrx/store';
import { State, IExtrasState } from '../_base';
import { RateExtra } from 'src/app/core/models';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

export const selectExtrasState = (state: State) => state.extras;

export const getExtrasEntitiesSource = createSelector(selectExtrasState, (extrasState: IExtrasState) => extrasState.entitiesSource);

export const getExtrasSource = createSelector(getExtrasEntitiesSource, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getExtrasEntities = createSelector(selectExtrasState, (extrasState: IExtrasState) => extrasState.entities);

export const getExtrasCurrentLang = createSelector(getExtrasEntities, (entities) => {
  const extra = Object.keys(entities).map(id => entities[id])[0];
  return extra ? extra.extraDesc[0].language : "";
});

export const getExtras = createSelector(getExtrasEntities, (entities) => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getExtrasStatusCode = createSelector(selectExtrasState, (extrasState: IExtrasState) => extrasState.statusCode);
export const getExtrasLoading = createSelector(selectExtrasState, (extrasState: IExtrasState) => extrasState.loading);
export const getExtrasLoaded = createSelector(selectExtrasState, (extrasState: IExtrasState) => extrasState.loaded);

export const getExtrasStatus = createSelector(
  getExtrasStatusCode,
  getExtrasLoading,
  getExtrasLoaded,
  getExtrasCurrentLang,
  (statusCode: STORE_STATUS_CODES, loading: boolean, loaded: boolean, lang: string) => {
    return {
      statusCode: statusCode,
      isLoading: loading,
      isLoaded: loaded,
      currentLanguage: lang
    };
  });

export const getExtrasByGroup = (groupName: string) => {
  return createSelector(getExtrasEntities, (entities) => {
    return Object.keys(entities).map(id => entities[id]).filter((extra) => extra.groupName.toLowerCase() === groupName.toLowerCase() || groupName === '');
  });
};

export const getExtraSourceById = (id: string) => {
  return createSelector(getExtrasEntitiesSource, (entities) => {
    return entities[id];
  });
};

export const getExtraById = (id: string) => {
  return createSelector(getExtrasEntities, (entities) => {
    return entities[id];
  });
};

export const getExtraMilesTotal = createSelector(getExtras, (extras) => {
  return extras.filter((extra) => extra.autoChecked && extra.extraMiles > 0).reduce((a, b) => { return a + b.extraMiles; }, 0);
});

export const getSelectedExtras = createSelector(getExtras, (extras) => {
  return extras.filter((extra) => extra.autoChecked);
});

export const getUnSelectedExtras = createSelector(getExtras, (extras) => {
  return extras.filter((extra) => !extra.autoChecked && !extra.isFree);
});

export const getSelectedExtrasByGroup = (groupName: string) => {
  return createSelector(getExtrasEntities, (entities) => {
    return Object.keys(entities).map(id => entities[id]).filter((extra) => extra.groupName === groupName && extra.autoChecked);
  });
};

export const getUnSelectedExtrasByGroup = (groupName: string) => {
  return createSelector(getExtrasEntities, (entities) => {
    return Object.keys(entities).map(id => entities[id]).filter((extra) => extra.groupName === groupName && !extra.autoChecked);
  });
};

export const getSelectedExtrasSource = createSelector(getExtras, getExtrasEntitiesSource, (extras, entities) => {

  const selectedExtras = [...extras.filter((extra) => extra.autoChecked)];
  const selectedExtrasSource = selectedExtras.reduce((selectedExtrasSource: RateExtra[], extra: RateExtra) => {

    if (entities[extra.id]) {
      const children = extra.children.reduce((childExtras: RateExtra[], childExtra: RateExtra) => {
        const _childExtra = entities[extra.id].children.find(x => x.id === childExtra.id);
        if (_childExtra) {
          return [
            ...childExtras,
            {
              ..._childExtra,
              autoChecked: childExtra.autoChecked,
              isCumulativeChild: childExtra.isCumulativeChild,
              isFaded: childExtra.isFaded,
              qty: childExtra.qty
            }
          ];
        }
        else
          return [...childExtras];
      }, []);

      return [
        ...selectedExtrasSource,
        {
          ...entities[extra.id],
          autoChecked: extra.autoChecked,
          isCumulativeChild: extra.isCumulativeChild,
          isFaded: extra.isFaded,
          qty: extra.qty,
          children: children
        }
      ];
    }
    else {
      return [...selectedExtrasSource];
    }
  }, []);
  return selectedExtrasSource;
});
