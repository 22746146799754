import { Component, OnInit, OnChanges, Input, ChangeDetectionStrategy, SimpleChanges, OnDestroy, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

import { ErrorDto } from 'src/app/core/models';
import { NOTIFY_TYPES } from 'src/app/core/enums';
import { Subscription, BehaviorSubject, of, timer } from 'rxjs';
import { tap, switchMap,map } from 'rxjs/operators';

@Component({
  selector: 'notify-area',
  templateUrl: './notify-area.component.html',
  styleUrls: ['./notify-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifyAreaComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  private allSubs: Subscription = new Subscription();

  @Input() notifyType: NOTIFY_TYPES = NOTIFY_TYPES.Hidden;
  @Input() isDismissible: boolean = true;
  @Input() notification: string = "";
  @Input() errors: ErrorDto[] = [];
  @Input() autoHide: number = 0;
  @Input() smallSize: boolean = false;
  @Input() noSpacing: boolean = false;
  @Input() limitHeight: boolean = false;

  @Input() bgColor: string = "";
  @Input() textColor: string = "";
  @Input() icon: string = "";
  @Input() iconColor: string = "inherit";

  @ViewChild('pnlNotification', { read: ElementRef, static: true }) eleContent: ElementRef;
  @ViewChild('fader', { static: true }) fader: ElementRef;

  isNotificationVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.autoHide > 0) {
      const subs = this.isNotificationVisible$.pipe(
        switchMap((value) => {
          if (value) {
            return timer(this.autoHide).pipe(map(x => value));
          }
          else {
            return of(value);
          }
        }),
        tap((value) => {
          if (value) {
            this.isNotificationVisible$.next(false);
          }
        })
      ).subscribe();

      this.allSubs.add(subs);
    }
  }

  ngAfterViewInit(): void {
    if (this.limitHeight && this.eleContent && this.eleContent.nativeElement && this.eleContent.nativeElement.getBoundingClientRect().height < 200) {
      this.limitHeight = false;
    }
    else {
      const divFader = this.fader.nativeElement;
      if (divFader) {
        const rgb = this.hexToRGB(this.bgColor);
        this.renderer.setStyle(divFader, 'background-image', `-webkit-linear-gradient(rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0) -10px, ${this.bgColor})`);
        this.renderer.setStyle(divFader, 'background-image', `-moz-linear-gradient(rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0) -10px, ${this.bgColor})`);
        this.renderer.setStyle(divFader, 'background-image', `-ms-linear-gradient(rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0) -10px, ${this.bgColor})`);
        this.renderer.setStyle(divFader, 'background-image', `-o-linear-gradient(rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0) -10px, ${this.bgColor})`);
        this.renderer.setStyle(divFader, 'background-image', `linear-gradient(rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0) -10px, ${this.bgColor})`);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.notification && changes.notification.currentValue != "") || (changes.errors && this.errors && this.errors.length > 0))
      this.isNotificationVisible$.next(true);
    else
      this.isNotificationVisible$.next(false);
  }

  private hexToRGB = (hex) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16);

    return [r, g, b];

  //return '#' +
  //  ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
  //  ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
  //  ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }
}
