import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CommonService } from './common.service';

export const GOOGLE_MAP_API_KEY = new InjectionToken<string>('google-map-api-key');

@Injectable({ providedIn: 'root' })
export class GoogleMapLoaderService {

  private isApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isApiLoaded$ = this.isApiLoaded.asObservable();

  constructor(@Inject(GOOGLE_MAP_API_KEY) apiKey: string, httpClient: HttpClient, private _commonService: CommonService) {
    const lang = _commonService.getCurrentLanguage();

    httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&language=${lang}&libraries=places`, 'callback').pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      )
      .subscribe({
        next: () => {
          this.isApiLoaded.next(true);
        },
        error: (error) => {
          console.log('google maps api cannot be loaded', error);
        }
      });
  }
}
