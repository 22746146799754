import { RatesResult, RateDto, VehicleDto, ResponseStatus } from 'src/app/core/models';
import { STORE_STATUS_CODES } from 'src/app/core/enums';

export interface IRateState {
  readonly ratesResultSource: RatesResult;
  readonly ratesResult: RatesResult;
  readonly availableRates: { [id: string]: RateDto };
  readonly limitedAvailableRates: { [id: string]: VehicleDto };
  readonly restrictedVehiclesByAge: { [id: string]: VehicleDto };
  readonly soldOutVehicles: { [id: string]: VehicleDto };
  readonly responseStatus: ResponseStatus;
  readonly statusCode: STORE_STATUS_CODES;
  readonly loaded: boolean;
  readonly loading: boolean;
  readonly updating: boolean;
  readonly updated: boolean;
}
