import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'snapengage',
  templateUrl: './snapengage.component.html'
})
export class SnapengageComponent implements OnChanges {

  @Input() id: string;
  @Input() enabled: boolean;
  @ViewChild('element', { static: true }) public viewElement: ElementRef;

  htmlSnippet: string = '';
  public element: any;

  constructor(@Inject(DOCUMENT) private doc) { }

  ngOnChanges(changes: SimpleChanges) {

    const isIdChanged: boolean = changes.id && changes.id.currentValue != changes.id.previousValue || changes.id.firstChange;

    if (this.enabled && isIdChanged)
      this.appendHTMLSnippetToDOM();
  }

  public appendHTMLSnippetToDOM() {

    if (this.doc) {

      if (this.doc.body) {
        const designstudio = this.doc.querySelector("div[id='designstudio']");
        const designstudioMinimize = this.doc.querySelector("div[id='designstudio-minimize']");
        const designstudioButton = this.doc.querySelector("div[id='designstudio-button']");

        if (designstudio)
          this.doc.body.removeChild(designstudio);

        if (designstudioMinimize)
          this.doc.body.removeChild(designstudioMinimize);

        if (designstudioButton)
          this.doc.body.removeChild(designstudioButton);
      }

      const script = `<!-- begin SnapEngage code -->
                    <script type="text/javascript" id="${this.id}">
                      (function() {
                        var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
                        se.src = '//storage.googleapis.com/code.snapengage.com/js/${this.id}.js';
                        var done = false;
                        se.onload = se.onreadystatechange = function() {
                          if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
                            done = true;
                            /* Place your SnapEngage JS API code below */
                            /* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
                          }
                        };
                        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
                      })();
                    </script>
                    <!-- end SnapEngage code -->`;

      this.element = this.viewElement.nativeElement;
      const fragment = document.createRange().createContextualFragment(script);
      this.element.innerHTML = "";
      this.element.appendChild(fragment);
    }
  }
}
