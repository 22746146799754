import { Action } from '@ngrx/store';

import { RateExtra, RateDetailDto } from 'src/app/core/models';

// load Extras
export const LOAD_EXTRAS = '[Extras] Load Extras';
export const LOAD_EXTRAS_FAIL = '[Extras] Load Extras Fail';
export const LOAD_EXTRAS_SUCCESS = '[Extras] Load Extras Success';
export const RESET_EXTRAS = '[Extras] Reset Extras';

export const UPDATE_EXTRAS = '[Extras] Update Extra';
export const REMOVE_EXTRAS = '[Extras] Remove Extra';

export const EXTRAS_ACTION_COMPLETE = '[Extras] Extras Action Complete';

//export const UPDATE_EXTRAS_TRANSLATION = '[Extras] Update Extras Translation';
//export const UPDATE_EXTRAS_TRANSLATION_SUCCESS = '[Extras] Update Extras Translation Success';

export class LoadExtras implements Action {
  readonly type = LOAD_EXTRAS;
  constructor(
    public payload: {
      lang: string,
      branchCode: string,
      vehicleCode: string,
      ageGroup: string,
      rateSpecificExtraCodes?: string,
      discountedExtraCodes?: string,
      preselectedExtraCodes?: string[],
      isUpdateTranslationOnly?: boolean,
      rateDetail?: RateDetailDto,
      totalFreeMiles?: number,
      mileageCapValue?:number
    }
  ) { }
}

export class LoadExtrasFail implements Action {
  readonly type = LOAD_EXTRAS_FAIL;
  constructor(public payload: any) { }
}

export class LoadExtrasSuccess implements Action {
  readonly type = LOAD_EXTRAS_SUCCESS;
  constructor(public payload: RateExtra[], public preselectedExtraCodes: string[] = [], public isUpdateTranslationOnly: boolean = false) { }
}

export class ResetExtras implements Action {
  readonly type = RESET_EXTRAS;
}

export class UpdateExtras implements Action {
  readonly type = UPDATE_EXTRAS;
  constructor(public payload: RateExtra[]) { }
}

export class RemoveExtras implements Action {
  readonly type = REMOVE_EXTRAS;
  constructor(public payload: RateExtra[]) { }
}

export class ExtrasActionComplete implements Action {
  readonly type = EXTRAS_ACTION_COMPLETE;
  constructor() { }
}

//export class UpdateExtrasTranslation implements Action {
//  readonly type = UPDATE_EXTRAS_TRANSLATION;
//  constructor(public payload: { lang: string, branchCode: string, vehicleCode: string, ageGroup: string, rateSpecificExtraCodes?: string, discountedExtraCodes?: string, preselectedExtraCodes?: string[] }) { }
//}

//export class UpdateExtrasTranslationSuccess implements Action {
//  readonly type = UPDATE_EXTRAS_TRANSLATION_SUCCESS;
//  constructor(public payload: RateExtra[]) { }
//}

// action types
export type ExtrasAction = LoadExtras | LoadExtrasFail | LoadExtrasSuccess | ResetExtras | UpdateExtras | RemoveExtras | ExtrasActionComplete;// | UpdateExtrasTranslation | UpdateExtrasTranslationSuccess;
