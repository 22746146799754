import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Country } from 'src/app/core/models';
import { CountryStateService } from 'src/app/core/services';

import { LOAD_COUNTRIES, LoadCountries, LoadCountriesSuccess, LoadCountriesFail } from './country.action';

@Injectable()
export class CountriesEffects {
  constructor(
    private actions$: Actions,
    private countryService: CountryStateService
  ) { }


  loadCountries$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_COUNTRIES),
      map((action: LoadCountries) => action.lang),
      switchMap((lang) => {
        return this.countryService
          .fetchAllCountries(lang)
          .pipe(
            map((counties) => {

              const _counties = counties.data as Country[];

              return new LoadCountriesSuccess(_counties);
            }),
            catchError(error => of(new LoadCountriesFail(error)))
          );
      })
    ));
}
