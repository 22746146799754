import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';

import * as MyReservationsActions from './my-reservations.action';
import { ReservationService, CommonService, TimeService } from 'src/app/core/services';
import { Reservation } from 'src/app/core/models';

import * as moment from 'moment';

@Injectable()
export class MyReservationsEffects {
  constructor(
    private actions$: Actions,
    private commonService: CommonService,
    private rezService: ReservationService
  ) { }

  loadMyRez$ = createEffect(() => this.actions$.pipe(
    ofType(MyReservationsActions.loadMyReservations),
    exhaustMap(action => {
      return this.rezService
        .fetchMyReservations(action.language)
        .pipe(
          map(result => {
            const reservations = (result.data as Reservation[]).filter(x => x.status !== "Expired");

            if (reservations && reservations.length > 0) {
              for (let i = 0; i < reservations.length; i++) {
                reservations[i].daysToPickup = TimeService.getNoOfDays(moment().format("YYYYMMDD"), reservations[i].rateParams.pickupDate);
              }
            }

            return MyReservationsActions.loadMyReservationsSuccess({ payload: reservations });
          }),
          catchError(error => {
            return of(MyReservationsActions.loadMyReservationsFail({ payload: error }));
          })
        );
    })
  ));
}
