import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';

import { CvvDialogComponent } from './dlg-cvv.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule],
  declarations: [CvvDialogComponent],
  exports: [CvvDialogComponent]
})
export class CvvDialogModule { }
