import { createSelector } from '@ngrx/store';
import { State, IAppSettingsState } from '../_base';

export const getAppSettingsState = (state: State) => state.appSettings;

export const getAppSettings = createSelector(getAppSettingsState, (state: IAppSettingsState) => state);
export const getAppSettingsAvailableLangs = createSelector(getAppSettingsState, (state: IAppSettingsState) => state.availableLangs);
export const getAppSettingsLanguage = createSelector(getAppSettingsState, (state: IAppSettingsState) => state.language);
export const getAppSettingsSelectedCurrency = createSelector(getAppSettings, (state: IAppSettingsState) => state.selectedCurrency);
export const getAppSettingsShowRezWidget = createSelector(getAppSettings, (state: IAppSettingsState) => state.showRezWidget);
export const getAppSettingsWidgetFlashAnimationState = createSelector(getAppSettings, (state: IAppSettingsState) => state.widgetFlashAnimationState);
