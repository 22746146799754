import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckPluralPipe } from './check-plural.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CheckPluralPipe],
  exports: [CheckPluralPipe]
})
export class CheckPluralModule { }
