import { ReservationAction, LOAD_RESERVATION, LOAD_RESERVATION_SUCCESS, LOAD_RESERVATION_FAIL, CREATE_RESERVATION, UPDATE_RESERVATION, CANCEL_RESERVATION, CANCEL_RESERVATION_SUCCESS, CANCEL_RESERVATION_FAIL, UPDATE_RESERVATION_TRANSLATION, RESET_RESERVATION, UPDATE_RESERVATION_COMPLETE, CREATE_RESERVATION_SUCCESS, CREATE_RESERVATION_FAIL, LOAD_RESERVATION_BY_ID, UPDATE_RESERVATION_FAIL } from './reservation.action';
import { Reservation, ResponseStatus, RateParams, ShortVehicleClass } from 'src/app/core/models';
import { IReservationState } from '../_base';
import { REZ_ACTIONS, STORE_STATUS_CODES } from 'src/app/core/enums';

const initialState: IReservationState = {
  reservationSource: {} as Reservation,
  reservation: {} as Reservation,
  responseStatus: null,
  statusCode: STORE_STATUS_CODES.INITIAL,
  rezAction: REZ_ACTIONS.Unknown,
  processed: false,
  processing: false
};

export function rezReducer(state = initialState, action: ReservationAction): IReservationState {

  switch (action.type) {
    case LOAD_RESERVATION:
    case LOAD_RESERVATION_BY_ID:
      {
        return {
          ...state,
          responseStatus: null,
          statusCode: STORE_STATUS_CODES.INITIAL,
          processing: true,
          processed: false
        };
      }

    case LOAD_RESERVATION_SUCCESS:
    case CREATE_RESERVATION_SUCCESS:
      {
        if (action.payload && action.payload != null) {
          return {
            ...state,
            processing: false,
            processed: true,
            responseStatus: { status: "OK", statusCode: 200 },
            statusCode: STORE_STATUS_CODES.SUCCESS,
            reservationSource: JSON.parse(JSON.stringify(action.payload)),
            reservation: action.payload,
            rezAction: action.type == CREATE_RESERVATION_SUCCESS ? action.rezAction : REZ_ACTIONS.Unknown,
          };
        }
        else {
          return {
            ...state,
            responseStatus: { status: "No Content", statusCode: 204 },
            statusCode: STORE_STATUS_CODES.FAILED,
            processing: false,
            processed: true
          };
        }
      }

    case LOAD_RESERVATION_FAIL:
      {
        const responseStatus = action.payload as ResponseStatus;
        if (responseStatus && responseStatus.statusCode) {
          return {
            ...initialState,
            responseStatus: { ...responseStatus },
            statusCode: STORE_STATUS_CODES.FAILED,
            processing: false,
            processed: true
          };
        }
        else {
          return {
            ...state,
            responseStatus: { status: "Not Found", statusCode: 404 },
            statusCode: STORE_STATUS_CODES.FAILED,
            processing: false,
            processed: true
          };
        }
      }

    case RESET_RESERVATION: {
      return {
        ...initialState
      };
    }

    case CREATE_RESERVATION: {
      return {
        ...state,
        processing: true,
        processed: false
      };
    }

    case CREATE_RESERVATION_FAIL: {
      let responseStatus = action.payload as ResponseStatus;
      if (responseStatus && responseStatus.statusCode) {
        responseStatus = { ...responseStatus };
      }
      else {
        responseStatus = { status: "failed", statusCode: 400 };
      }
      return {
        ...initialState,
        responseStatus: responseStatus,
        processing: false,
        processed: false
      };
    }

    case UPDATE_RESERVATION: {
      return {
        ...state,
        responseStatus: {} as ResponseStatus,
        processing: true,
        processed: false
      };
    }

    case UPDATE_RESERVATION_FAIL: {
      let responseStatus = action.payload as ResponseStatus;
      if (responseStatus.statusCode) {
        responseStatus = { ...responseStatus };
      }
      else {
        responseStatus = { status: "failed", statusCode: 400 };
      }

      return {
        ...state,
        responseStatus: responseStatus,
        processing: false,
        processed: true
      };
    }

    case UPDATE_RESERVATION_COMPLETE: {
      return {
        ...state,
        reservationSource: JSON.parse(JSON.stringify(action.payload.reservation)),
        reservation: action.payload.reservation,
        responseStatus: action.payload.status,
        statusCode: STORE_STATUS_CODES.SUCCESS,
        rezAction: action.rezAction,
        processing: false,
        processed: true
      };
    }

    case CANCEL_RESERVATION: {
      return {
        ...state,
        responseStatus: {} as ResponseStatus,
        processing: true,
        processed: false
      };
    }

    case CANCEL_RESERVATION_SUCCESS: {
      return {
        ...initialState,
        responseStatus: action.payload,
        rezAction: REZ_ACTIONS.Cancel,
        processing: false,
        processed: true
      };
    }

    case CANCEL_RESERVATION_FAIL: {
      return {
        ...state,
        responseStatus: { status: "Not Found", statusCode: 404 },
        processing: false,
        processed: true
      };
    }

    case UPDATE_RESERVATION_TRANSLATION: {

      if (state && state.reservation && state.reservation.rate) {

        const rateParams: RateParams = {
          ...state.reservation.rateParams,
          language: action.language
        };

        const veh: ShortVehicleClass = {
          ...state.reservation.rate.vehicle,
          vehicleDesc: action.vehicle.vehicleDesc
        };

        return {
          ...state,
          reservation: {
            ...state.reservation,
            rate: {
              ...state.reservation.rate,
              vehicle: veh
            },
            rateParams: rateParams
          }
        };
      }
      else
        return state;
    }
  }

  return state;
}
