<div *ngIf="extra !== null" class="extra-item" [formGroup]="extraFormGroup" [ngClass]="{'mobile':(isMobileDevice$ | async)}">

  <div *ngIf="extra.isFaded" class="faded-overlay"></div>

  <div class="row table-row" [ngClass]="{'line-height-50':(extra.extraDesc[0].icon && extra.extraDesc[0].icon !== ''),'line-height-40':extra.extraDesc[0].icon === ''}">

    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 padding-left-0">
      <mat-checkbox #chk_extra id="chk_extra_{{extra.code}}" [checked]="extra.autoChecked" [disabled]="extra.autoApply" (change)="onExtraClick(chk_extra.checked);">
        <!--<img *ngIf="extra.extraDesc[0].icon && extra.extraDesc[0].icon != ''" src="assets/images/vehicle-extras/{{extra.extraDesc[0].icon | lowercase}}" alt="{{extra.extraDesc[0].description}}" class="img-extra hidden-xs hidden-sm" />-->
        <span *ngIf="extra.extraDesc[0].icon && extra.extraDesc[0].icon !== ''" class="ext-icon icon-{{extra.extraDesc[0].icon}} hidden-xs hidden-sm"></span>
      </mat-checkbox>
      <div *ngIf="extra.maxCount > 1" class="pnl-range">
        <i class="fas fa-minus-square" aria-hidden="true" (click)="changeQty('-',extra?.qty);"></i>
        <span class="number">{{extra?.qty}}</span>
        <i class="fas fa-plus-square" aria-hidden="true" (click)="changeQty('+',extra?.qty);"></i>&nbsp;&nbsp;
      </div>
      <span [ngClass]="{'extra-label':true, 'font-weight-bold':extra.autoChecked}" (click)="chk_extra.checked = extra.autoApply ? true : !chk_extra.checked; onExtraClick(chk_extra.checked);">
        {{extra.extraDesc[0].description}}
      </span>
      <span *ngIf="extra.extraDesc[0].ribbonText && extra.extraDesc[0].ribbonText !== ''" class="ribbon hidden-xs hidden-sm">
        <span class="ribbon-arrow"></span>
        {{extra.extraDesc[0].ribbonText}}
      </span>
    </div>

    <div class="col-xs-6 col-sm-2 col-md-2 col-lg-2 no-word-wrap">
      <span class="rate" *ngIf="(extra.isFaded && extra.isCumulativeChild) === false">
        {{getExtraRatePerPeriod(extra)}}
      </span>
    </div>

    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 text-right padding-right-0">
      <div *ngIf="isCalc" class="spinner">
        <mat-spinner color="primary" mode="indeterminate" diameter="16"></mat-spinner>
      </div>
      <div *ngIf="!isCalc" [ngClass]="{'text-bold':extra.autoChecked}">
        <span *ngIf="(extra.isFaded && extra.isCumulativeChild) === false">
          {{getExtraRateTotalCharge(extra)}}
        </span>
        <span *ngIf="extra.isFaded && extra.isCumulativeChild">{{'step4-confirm.incl' | translate | titlecase}}!</span>
      </div>
    </div>

  </div>

  <mat-expansion-panel class="panel" [expanded]="chk_extra.checked" (afterCollapse)="afterCollapse()" (afterExpand)="afterExpand()" hideToggle>
    <!--<div [@.disabled]="extra.isAnimationDisabled" style="overflow:hidden">
    <div [@slideUpDown]="extraInfoSlide" (@slideUpDown.done)="animationDone($event)">-->
    <div *ngIf="extra.autoChecked && extra.children.length > 0" class="row table-row" [ngClass]="{'line-height-50':(extra.extraDesc[0].icon && extra.extraDesc[0].icon !== '')}">
      <div class="row">
        <div class="col-sm-1 col-md-1 col-lg-1 hidden-xs text-left padding-0">
          <img src="assets/images/vehicle-extras/child-extra-arrow.png" alt="arrow" class="child-extra-arrow" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
          <div *ngFor="let num of convertNumberToArray(extra?.qty)" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 padding-0" formArrayName="childExtras">
            <div class="pull-left">
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle ddlBtn-extra" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <span data-value="num" data-bind="label">{{getSelectedChildExtraText('Child' + num)}}</span>
                  <span>&nbsp;&nbsp;<i class="fas fa-chevron-down" aria-hidden="true"></i></span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdown">
                  <li *ngFor="let childExtra of extra.children"><a (click)="onChildExtraClick(childExtra, 'Child' + num);">{{childExtra.extraDesc[0].shortDescription}}</a></li>
                </ul>
              </div>
            </div>
            <label class="text-right pull-left padding-left-10">{{getSelectedChildExtraRate('Child' + num)}}</label>
          </div>
        </div>
        <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 text-right padding-0">
          <div *ngIf="isCalcChild" class="spinner">
            <mat-spinner color="primary" mode="indeterminate" diameter="16"></mat-spinner>
          </div>
          <div *ngIf="!isCalcChild" [ngClass]="{'text-bold':extra.autoChecked}">
            {{extra.totalChargeChildren | currency:selectedCurrency.code:'symbol-narrow':'1.2':('common.lang' | translate)}} {{selectedCurrency.code}}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="extra.autoChecked && extra.extraDesc[0].notes.length > 0" class="row table-row">
      <div [innerHTML]="extra.extraDesc[0].notes | decodeHTML" class="text"></div>
    </div>
    <!--</div>
  </div>-->
  </mat-expansion-panel>
  <hr *ngIf="!isLast" />
</div>
