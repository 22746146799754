import { createSelector } from '@ngrx/store';
import { State, IBranchState } from '../_base';

export const getBranchesState = (state: State) => state.branches;

export const getBranchesSource = createSelector(getBranchesState, (state: IBranchState) => state.branchesSource);

export const getPickupBranch = createSelector(getBranchesState, (state: IBranchState) => state.pickupBranch);
export const getReturnBranch = createSelector(getBranchesState, (state: IBranchState) => state.returnBranch);
export const getBranchesLoading = createSelector(getBranchesState, (state: IBranchState) => state.loading);
export const getBranchesLoaded = createSelector(getBranchesState, (state: IBranchState) => state.loaded);
