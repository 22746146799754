import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';

import { fade } from 'src/app/shared/animations/animations';

@Component({
  selector: 'container-spinner',
  templateUrl: './container-spinner.component.html',
  styleUrls: ['./container-spinner.component.scss'],
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerSpinnerComponent implements OnInit  {

  @Input() showSpinner: boolean = false;
  @Input() hideBackground: boolean = false;

  @Input() diameter: number = 100;

  objExp: { [klass: string]: any; } = {};

  constructor() {
  }

  ngOnInit(): void {
    this.objExp = {
      ...this.objExp,
      "width.px": this.diameter,
      "height.px": this.diameter
    };
  }
}
