import { createSelector } from '@ngrx/store';
import { State, IReservationState } from '../_base';

export const selectReservationState = (state: State) => state.reservation;

export const getReservationSource = createSelector(selectReservationState, (state: IReservationState) => state.reservationSource);
export const getReservation = createSelector(selectReservationState, (state: IReservationState) => state.reservation);
export const getReservationResponseStatus = createSelector(selectReservationState, (state: IReservationState) => state.responseStatus);
export const getReservationStatus = createSelector(selectReservationState, (state: IReservationState) => state.statusCode);
export const getReservationProcessing = createSelector(selectReservationState, (state: IReservationState) => state.processing);
export const getReservationProcessed = createSelector(selectReservationState, (state: IReservationState) => state.processed);
export const getReservationAction = createSelector(selectReservationState, (state: IReservationState) => state.rezAction);

export const getReservationState = createSelector(
  getReservationProcessed,
  getReservationStatus,
  getReservationResponseStatus,
  getReservation,
  (processed, statusCode , resStatus, reservation) => {
    return {
      isProcessed: processed,
      statusCode: statusCode,
      responseStatus: resStatus,
      reservation: reservation
    };
  }
);
