import { HttpErrorResponse } from '@angular/common/http';
import { CommonService, LoggingService } from '../services';
import { LOG_TYPES, PAGES } from '../enums';
import { ExceptionDto } from '../models';
import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TimeoutError } from './timeout-error';

export class GlobalErrorHandler implements IErrorService {
  private errors: { [errorDesc: string]: { count: number, firstErrorAt: Date, lastErrorAt: Date } } = {};

  constructor(private injector: Injector) { }

  handleError(error: any): void {
    const errorCount = this.errors[error.toString()] && Number(this.errors[error.toString()].count) ? Number(this.errors[error.toString()].count) + 1 : 1;

    this.errors = {
      ...this.errors,
      [error.toString()]: {
        count: errorCount,
        firstErrorAt: errorCount <= 1 ? new Date() : this.errors[error.toString()].firstErrorAt,
        lastErrorAt: new Date()
      }
    };

    const currDate = new Date();
    const prevDate = this.errors[error.toString()].firstErrorAt;
    const dtDiffInSeconds = ((currDate.getTime() - prevDate.getTime()) / 1000);

    if (errorCount <= 1 || dtDiffInSeconds > 2) {
      this.errors[error.toString()].firstErrorAt = new Date();
      this.logError(error);
    }

    console.error(error);

    if (errorCount > 20 && dtDiffInSeconds <= 2) {
      this.nagigateToErrorPage(PAGES.ERROR_500);
    }

    if (error instanceof TimeoutError) {
      this.nagigateToErrorPage(PAGES.ERROR_408);
    }
  }

  logError = (error: any) => {
    if (error?.message) {
      const logger = this.injector.get(LoggingService);
      if (error instanceof HttpErrorResponse) {
        const ex: ExceptionDto = {
          baseMessage: error.statusText,
          errorCode: error.status.toString(),
          message: error.message,
          type: error.type ? error.type.toString() : error.name,
          source: error.url,
          text: error.error ? error.error.toString() : "",
          moduleName: "",
          methodName: "",
          moduleVersion: ""
        };
        logger.log(LOG_TYPES.ERROR, error.message, ex);
      }
      else {
        const ex: ExceptionDto = {
          baseMessage: error.name,
          errorCode: "",
          message: error.message,
          type: error.name,
          source: error.fileName,
          text: error.stack,
          moduleName: "",
          methodName: "",
          moduleVersion: ""
        };
        logger.log(LOG_TYPES.ERROR, error.message, ex);
      }
    }
  };

  nagigateToErrorPage = (page: PAGES) => {
    const commonService = this.injector.get(CommonService);
    const router = this.injector.get(Router);
    const zone = this.injector.get(NgZone);

    zone.run(() => {
      this.errors = {};
      void router.navigateByUrl(commonService.getTranslatedRouteByKey(page, commonService.getCurrentLanguage()));
    });
  };
}
