import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { Observable, of } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { State, getCurrencyRatesLoaded, SetAppSettingsSelectedCurrency, getAppSettingsSelectedCurrency } from 'src/app/store';

import { Currency } from 'src/app/core/models';

import { CommonService, CurrencyService } from 'src/app/core/services';

@Component({
  selector: 'rez-page-header',
  templateUrl: './rez-page-header.component.html',
  styleUrls: ['./rez-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RezPageHeaderComponent implements OnInit {

  isMobileDevice$: Observable<boolean>;
  isCurrencyRatesLoaded$: Observable<boolean> = of(false);
  selectedCurrency$: Observable<Currency> = of(null);

  currencies: Currency[] = [];

  @Output() currencyChange = new EventEmitter<Currency>();
  @Output() recommendThisClick = new EventEmitter<string>();

  @Input() showBtnRecommend: boolean = true;
  @Input() pageTitle: string;

  constructor(private _commonService: CommonService, private store: Store<State>) { }

  ngOnInit() {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;

    this.isCurrencyRatesLoaded$ = this.store.pipe(select(getCurrencyRatesLoaded));
    this.selectedCurrency$ = this.store.pipe(select(getAppSettingsSelectedCurrency));

    this.currencies = CurrencyService.getAvailableCurrencies();
  }



  onCurrencyClick = (selectedValue: string, selectedText: string, previouslySelectedCurrency: Currency) => {
    if (selectedValue != previouslySelectedCurrency.code) {
      this.store.dispatch(new SetAppSettingsSelectedCurrency({ code: selectedValue, displayText: selectedText, description: selectedText }));
      this.currencyChange.emit({ code: selectedValue, displayText: selectedText, description: selectedText });
    }
  };

  onRecommendThisClick = () => {
    this.recommendThisClick.emit("");
  }
}
