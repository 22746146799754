import { createSelector } from '@ngrx/store';
import { State, IRecommendationEmailState } from '../_base';

export const getRecommendationEmailState = (state: State) => state.recommendationEmail;

export const getRecommendationEmail = createSelector(getRecommendationEmailState, (state: IRecommendationEmailState) => state.RecommendationEmail);

export const getRecommendationEmailRateParams = createSelector(getRecommendationEmailState, (state: IRecommendationEmailState) => state.RecommendationEmail ? state.RecommendationEmail.rateParams : null);
export const getRecommendationEmailRates = createSelector(getRecommendationEmailState, (state: IRecommendationEmailState) => state.RecommendationEmail.rates);

export const getRecommendationEmailStatus = createSelector(getRecommendationEmailState, (state: IRecommendationEmailState) => state.statusCode);
export const getRecommendationEmailLoading = createSelector(getRecommendationEmailState, (state: IRecommendationEmailState) => state.loading);
export const getRecommendationEmailLoaded = createSelector(getRecommendationEmailState, (state: IRecommendationEmailState) => state.loaded);
