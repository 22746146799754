import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';
import { RezPageHeaderComponent } from './rez-page-header.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TooltipModule, TranslateModule],
  declarations: [RezPageHeaderComponent],
  exports: [RezPageHeaderComponent]
})
export class RezPageHeaderModule { }
