import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ServiceResult, BranchDto } from '../models';

import { ConfigService } from "./config.service";

@Injectable()
export class BranchService {

  constructor(private _configService: ConfigService, private http: HttpClient) {
  }

  fetchAllBranches(language: string): Observable<ServiceResult> {

    const url = this._configService.getBranchApiUrl('', { lang: language });

    return this.http.get<ServiceResult>(url).pipe();
  }

  isHighSeason(branchIdOrCode: string, pDateTime: string): Observable<boolean> {

    const url = this._configService.getBranchApiUrl('/IsHighSeason', { branchIdOrCode: branchIdOrCode, pickupDateTime: pDateTime });

    return this.http.get<ServiceResult>(url).pipe(
      map((result) => {
        return result && !!result.data;
      })
    );
  }

  isAirportBranch = (branch: BranchDto): boolean => {
    return !!branch?.branchFunctions.find((x) => { return x.toLowerCase() === "airport" })
  }

  hasShuttleService = (branch: BranchDto): boolean => {
    return !!branch?.branchFunctions.find((x) => { return x.toLowerCase() === "shuttle" })
  };
}

