import { Component, Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';

import { SafePipe } from 'src/app/shared/pipes/safe-content.pipe';
import { CommonService } from '../../../core/services';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Directive({
  selector: '[tooltip]',
  providers: [SafePipe]
})

export class TooltipDirective {

  constructor(private elementRef: ElementRef, private _commonService: CommonService, private dialog: MatDialog) { }

  tooltipElement: any;
  elemPosition: any;
  tooltipOffset: number = 8;
  hideTimeoutId: any;
  showTimeoutId: any;

  dialogRef: MatDialogRef<TooltipInfoDialog, any>;

  @Input() tooltip = "";
  @Input() placement = "bottom-left";
  @Input() delay = 0;
  @Input() showDelay = 0;
  @Input() hideDelay = 300;
  @Input() zIndex = false;
  @Input() maxWidth = "250px";
  @Input() events: string = "";

  isClickEnabled: boolean = true;
  isFocusEnabled: boolean = true;
  isMouseEnterEnabled: boolean = true;
  isMouseMoveEnabled: boolean = true;

  toggleShow: boolean = false;

  //@HostListener('click')
  //onClick() {
  //  console.log("clicked");
  //  if (this.isClickEnabled) {
  //    this.toggleShow = !this.toggleShow;
  //    if (this.toggleShow)
  //      this.onMouseEnter();
  //    else
  //      this.onMouseLeave();
  //  }
  //}

  @HostListener('focusin')
  @HostListener('mousemove')
  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.isMouseEnterEnabled || this.isFocusEnabled || this.isMouseMoveEnabled) {
      if (this._commonService.IsMobileDevice && this.tooltip && this.tooltip != "") {
        this.createTooltipDialog();
      }
      else if (!this.tooltipElement && this.tooltip && this.tooltip != "") {
        this.getElemPosition();
        this.createTooltip();
        this.setPosition();
        this.show();
      }
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onMouseLeave() {
    setTimeout(() => {
      if (!this.toggleShow)
        this.hide();
    }, this.hideDelay);
  }

  getElemPosition() {
    this.elementRef.nativeElement.style.cursor = "pointer";
    this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  createTooltipDialog() {
    if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
      return;
    }

    this.dialogRef = this.dialog.open(TooltipInfoDialog, {
      disableClose: true,
      autoFocus: false,
      data: {
        message: this.tooltip
      }
    });
  }

  createTooltip() {
    this.showDelay = this.delay || this.showDelay;
    this.tooltipElement = document.createElement('span');
    this.tooltipElement.className += "ng-tooltip ng-tooltip-" + this.placement;
    this.tooltipElement.innerHTML = this.tooltip; //$('<div/>').html(this.tooltip);

    if (this.events && this.events != "") {
      let _events = this.events.toLowerCase().split(",");

      this.isClickEnabled = false;
      this.isFocusEnabled = false;
      this.isMouseEnterEnabled = false;
      this.isMouseMoveEnabled = false;

      if (_events.find(x => x == "click"))
        this.isClickEnabled = true;

      if (_events.find(x => x == "focus"))
        this.isFocusEnabled = true;

      if (_events.find(x => x == "mouseenter"))
        this.isMouseEnterEnabled = true;

      if (_events.find(x => x == "mousemove"))
        this.isMouseMoveEnabled = true;
    }

    if (this.isMouseEnterEnabled) {
      this.tooltipElement.onmouseenter = () => {
        this.toggleShow = true;
        this.onMouseEnter();
      };

      this.tooltipElement.onmouseleave = () => {
        this.toggleShow = false;
        this.onMouseLeave();
      };
    }

    if (this.zIndex) this.tooltipElement.style.zIndex = this.zIndex;

    if (this.maxWidth != "250px") this.tooltipElement.style.maxWidth = this.maxWidth;

    let arrowOuter = document.createElement('span');
    arrowOuter.className = "arrow-outer";

    let arrow = document.createElement('span');
    arrow.className = "arrow";

    arrowOuter.appendChild(arrow);

    this.tooltipElement.appendChild(arrowOuter);

    document.body.appendChild(this.tooltipElement);
  }

  show() {
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    this.showDelay = this.delay || this.showDelay;
    this.showTimeoutId = setTimeout(() => {
      if (this.tooltipElement) {
        this.tooltipElement.className += " ng-tooltip-show";
      }
    }, this.showDelay);
  }

  hide() {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (this.tooltipElement) {
      this.tooltipElement.classList.remove("ng-tooltip-show");
      this.hideTimeoutId = setTimeout(() => {
        this.tooltipElement.parentNode.removeChild(this.tooltipElement);
        this.tooltipElement = null;
      }, this.hideDelay);
    }
  }

  setPosition() {
    let elemHeight = this.elementRef.nativeElement.offsetHeight;
    let elemWidth = this.elementRef.nativeElement.offsetWidth;
    let tooltipHeight = this.tooltipElement.clientHeight;
    let tooltipWidth = this.tooltipElement.offsetWidth;
    let scrollY = window.pageYOffset;

    let arrowOuter = this.tooltipElement.querySelector('.arrow-outer');

    if (this.placement == 'top-left') {
      this.tooltipElement.style.top = ((this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) - 12) + 'px';
      this.tooltipElement.style.left = (this.elemPosition.left - 27) + 'px';
    }
    else if (this.placement == 'bottom-left') {
      this.tooltipElement.style.top = (this.elemPosition.top + scrollY) + (elemHeight + 5) + this.tooltipOffset + 'px';
      this.tooltipElement.style.left = (this.elemPosition.left - 27) + 'px';
    }
    else if (this.placement == 'top-right') {
      this.tooltipElement.style.top = (this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) + 'px';
      this.tooltipElement.style.right = this.elemPosition.right + 'px';
    }
    else if (this.placement == 'bottom-right') {
      this.tooltipElement.style.top = ((this.elemPosition.top + scrollY) + elemHeight + this.tooltipOffset + 5) + 'px';
      this.tooltipElement.style.left = (this.elemPosition.right - tooltipWidth + 27) + 'px';
    }
    else {
      if (this.placement == 'top') {
        this.tooltipElement.style.top = ((this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) - 12) + 'px';
      }

      if (this.placement == 'bottom') {
        this.tooltipElement.style.top = ((this.elemPosition.top + scrollY) + elemHeight + this.tooltipOffset + 5) + 'px';
      }

      if (this.placement == 'top' || this.placement == 'bottom') {
        this.tooltipElement.style.left = (this.elemPosition.left + elemWidth / 2) - tooltipWidth / 2 + 'px';
        arrowOuter.style.left = ((tooltipWidth / 2) - 20) + 'px';
      }

      if (this.placement == 'left') {
        this.tooltipElement.style.left = this.elemPosition.left - tooltipWidth - this.tooltipOffset + 'px';
      }

      if (this.placement == 'right') {
        this.tooltipElement.style.left = (this.elemPosition.left + elemWidth + this.tooltipOffset + 5) + 'px';
      }

      if (this.placement == 'left' || this.placement == 'right') {
        this.tooltipElement.style.top = (this.elemPosition.top + scrollY) + (elemHeight / 2) - (tooltipHeight / 2) + 'px';
        arrowOuter.style.top = ((tooltipHeight / 2) - 20) + 'px';
      }
    }
  }
}

@Component({
  selector: 'tooltip-information-dialog',
  template: `<h4 mat-dialog-title class="header text-center"><i class="fas fa-info-circle fa-3x" aria-hidden="true"></i></h4>
             <button type="button" mat-icon-button mat-dialog-close aria-label="close" class="btn-close">
              <i class="fas fa-times" aria-hidden="true"></i>
             </button>
             <mat-dialog-content>
              <div class="text" [innerHTML]="message"></div>
              <br>
              <div class="text-center"><button type="button" class="btn-main btn-orange" mat-dialog-close>{{'buttons.ok' | translate}}</button></div>
             </mat-dialog-content>`,
  styles: ['.btn-close { position: absolute; right: 5px; top: 5px; color: #ff6a00; z-index: 1; padding: 2px 0 0; } .header { padding-top:40px; color:#4582c3; } .text { text-align: justify; }'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslateModule],
})
export class TooltipInfoDialog {
  message = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.message = data.message as string;
  }
}
