import { select, Store } from "@ngrx/store";
import { catchError, combineLatest, filter, map, Observable, of, take, tap } from "rxjs";
import { RateParams } from "../../core/models";
import { getBranchesLoaded, getPickupBranch, getReturnBranch, LoadBranches, ResetBranches, State } from "../../store";

export const loadBranches$ = (rateParams: RateParams, store: Store<State>): Observable<boolean> => {

  if (!rateParams || rateParams === null) {
    store.dispatch(new ResetBranches());
    return of(false);
  }

  const combined = combineLatest([
    store.pipe(select(getBranchesLoaded)),
    store.pipe(select(getPickupBranch)),
    store.pipe(select(getReturnBranch))
  ],
    (loaded, pBranch, rBranch) => {
      return {
        branchesLoaded: loaded,
        pickupBranch: pBranch,
        returnBranch: rBranch
      };
    }
  );

  return combined.pipe(
    tap((args) => {
      if (
        !args.branchesLoaded ||
        (args.branchesLoaded && args.pickupBranch && args.pickupBranch.code !== undefined && args.pickupBranch.code !== rateParams.pickupBranch) ||
        (args.branchesLoaded && args.pickupBranch && args.returnBranch.code !== undefined && args.returnBranch.code !== rateParams.returnBranch) ||
        (args.branchesLoaded && args.pickupBranch && args.pickupBranch.code !== undefined && args.pickupBranch.addresses[0].language !== rateParams.language)
      ) {
        store.dispatch(new LoadBranches(
          {
            lang: rateParams.language,
            pickupBranchCode: rateParams.pickupBranch,
            returnBranchCode: rateParams.returnBranch
          }
        ));
      }
    }),
    filter(args => args.branchesLoaded && args.pickupBranch && args.pickupBranch.code === rateParams.pickupBranch && args.returnBranch.code === rateParams.returnBranch && args.pickupBranch.code !== undefined && args.pickupBranch.addresses[0].language === rateParams.language),
    map(args => args.branchesLoaded),
    take(1),
    catchError(_ => {
      return of(false);
    })
  );
};
