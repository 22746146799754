import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, HostListener, Renderer2, ChangeDetectorRef, OnChanges, SimpleChanges, AfterViewChecked, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import { Subscription, Observable, of } from 'rxjs';
import { switchMap, tap, first, filter, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State, getCountries } from 'src/app/store';

import { CommonService, IP2LocationService } from 'src/app/core/services';

import { Country } from 'src/app/core/models';

@Component({
  selector: 'intl-tel-input',
  templateUrl: './intl-tel-input.component.html',
  styleUrls: ['./intl-tel-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntlTelInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('intl_tel_ctrl', { static: true }) intl_tel_ctrl: ElementRef;

  private allSubs: Subscription = new Subscription();

  private ctrlValueChangesSubs: Subscription;

  listOfCounties$: Observable<Country[]>;

  listOfPreferedCounties: Country[] = [];

  selectedCountry: Country = null;

  @Output() valueChange = new EventEmitter<string>();
  @Output() verifyClick = new EventEmitter<string>();
  @Output() changePhoneClick = new EventEmitter<any>();

  @Input() label: string = "";
  @Input() dropdownItemCount: number = 5;
  @Input() value: string = "";
  @Input() parentFormGroup: UntypedFormGroup = null;
  @Input() formCtrlName: string = "";
  @Input() required: boolean = false;
  @Input() showChangePhoneButton: boolean = false;
  @Input() enableVerifyStatus: boolean = false;
  @Input() isVerified: boolean = false;
  @Input() customError: string = "";
  @Input() disabled: boolean = false;

  //localValue$: Subject<string> = new Subject();
  localValue: string = "";
  maxLength: number = 16;

  constructor(
    private _commonService: CommonService,
    private _ip2locService: IP2LocationService,
    private store: Store<State>,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!this.parentFormGroup) {
      let phoneFormControl: UntypedFormControl = new UntypedFormControl();

      if (this.required) {
        phoneFormControl = new UntypedFormControl([''], [Validators.required]);
      }

      if (this.disabled) {
        phoneFormControl.disable();
      }

      this.parentFormGroup = new UntypedFormGroup({
        telephone: phoneFormControl
      });
      this.formCtrlName = "telephone";
    }

    if (!this.value || this.value === "") {
      const value = this.parentFormGroup.get(this.formCtrlName).value;
      if (value)
        this.setValue(value);
    }
    else if (this.value !== "" && this.value.length > 0) {
      this.parentFormGroup.get(this.formCtrlName).setValue(this.value);
    }

    this.ctrlValueChangesSubs = this.parentFormGroup.get(this.formCtrlName).valueChanges.subscribe((value) => {
      const ctrl = this.parentFormGroup.get(this.formCtrlName);

      if (!value && !ctrl.touched) {
        ctrl.markAsTouched({ onlySelf: true });
      }
      //this.setValue(value);
    });

    const sub1 = this.store.pipe(
      select(getCountries),
      filter((countries) => countries && countries.length > 0),
      tap((countries) => {
        if (!this.selectedCountry) {
          const sub2 = this._ip2locService.fetchIP2Country().pipe(
            tap((data) => {
              if (data && countries.find((x) => { return x.twoLetterISOCode.toLowerCase() === data.country.iso_code.toLowerCase() })) {
                this.selectedCountry = { ...countries.find((x) => { return x.twoLetterISOCode.toLowerCase() === data.country.iso_code.toLowerCase() }) };
              }
              else {
                this.selectedCountry = { ...countries.find((x) => { return x.twoLetterISOCode.toLowerCase() === 'ca' }) };
              }

              this.cd.markForCheck();
            }),
            first()
          ).subscribe();

          this.allSubs.add(sub2);
        }
      }),
      take(1)
    ).subscribe();

    this.allSubs.add(sub1);

    this.listOfCounties$ = this.store.pipe(
      select(getCountries),
      switchMap((countries) => {
        const exists = !!(countries && countries.length > 0);

        if (exists) {
          this.listOfPreferedCounties = [...countries.filter(x => x.isPrefered === true)];

          return of([...countries.filter(x => x.isPrefered === false)]);
        }
        else {
          return of([]);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.setSize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customError?.firstChange) {
      if (changes.customError.currentValue.length > 0) {
        this.parentFormGroup.get(this.formCtrlName).clearValidators();

        let validators = [
          this.invalidValidator(this.parentFormGroup.get(this.formCtrlName).value)
        ];

        if (this.required) {
          validators = [...validators, Validators.required];
        }

        this.parentFormGroup.get(this.formCtrlName).setValidators(validators);
      }
      else {
        this.parentFormGroup.get(this.formCtrlName).clearValidators();
        if (this.required) {
          this.parentFormGroup.get(this.formCtrlName).setValidators(Validators.required);
        }
      }
      this.parentFormGroup.get(this.formCtrlName).updateValueAndValidity();
    }
    else if (changes?.value && !changes.value.firstChange && changes.value.currentValue !== changes.value.previousValue) {
      this.parentFormGroup.get(this.formCtrlName).setValue(changes.value.currentValue);
    }
  }

  invalidValidator = (value: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: object } | null => {
      const invalid = control.value && control.value.length > 0 && control.value === value;
      return invalid ? { 'invalidPhone': { value: control.value } } : null;
    };
  };

  private setValue = (value: string) => {
    this.localValue = "";
    if (value) {
      if (value.length > this.maxLength) {
        value = value.substring(0, Math.min(value.length, this.maxLength));
      }

      const re = /(\+?)(\d+)(-)(\d.*)/;
      if (re.test(value)) {
        const dialCode = value.replace(re, "$2");

        this.store.pipe(
          select(getCountries),
          filter((countries) => countries && countries.length > 0),
          tap((countries) => {
            const country = countries.find(x => x.dialCode === Number(dialCode));
            if (country) {
              this.selectedCountry = { ...country };
            }
          }),
          take(1)
        ).subscribe();

        const tel = value.replace(re, "$4");
        this.localValue = tel.replace(/[-\s\.]+/, "");
      }
      else {
        this.localValue = value.replace(/[-\s\.]+/, "");
      }
    }
  };

  compareObjects(o1: Country, o2: Country): boolean {
    return o1 && o2 && o1.twoLetterISOCode === o2.twoLetterISOCode && o1.id === o2.id;
  }

  selectCountry = () => {
    if (this.intl_tel_ctrl) {
      const telInput = this.intl_tel_ctrl.nativeElement.querySelector('input[type="tel"]');
      const _value = this.getValue(telInput.value);
      this.parentFormGroup.get(this.formCtrlName).setValue(_value);
      this.valueChange.emit(_value);
    }
  };

  onKeyUp = (event) => {
    this.valueChange.emit(this.getValue(event.target.value));
  };

  onKeyPress = (event) => {
    if (event.target.value && event.target.value.length >= (this.maxLength - 4)) { // maxLength - 4 is to omit country code e.g. 966-##########
      return false;
    }

    if (CommonService.isControlKeyPress(event) || CommonService.isValidNumericKeyPress(event)) {
      return true;
    }

    return false;
  };

  onVerifyNowClick = () => {
    if (this.intl_tel_ctrl) {
      const telInput = this.intl_tel_ctrl.nativeElement.querySelector('input[type="tel"]');
      this.verifyClick.emit(this.getValue(telInput.value));
    }
  };

  onChangePhClick = () => {
    this.changePhoneClick.emit('');
  };

  private getValue = (telNo: string): string => {
    let value = telNo && telNo.length > 0 ? this.selectedCountry.dialCode + "-" + telNo.replace(/[+().\-\s]+/, "") : "";
    if (value.length > this.maxLength) {
      value = value.substring(0, Math.min(value.length, this.maxLength));
      this.setValue(value);
    }
    return value;
  };

  private setSize = () => {
    setTimeout(() => {
      if (this.intl_tel_ctrl) {
        const telCtrl = this.intl_tel_ctrl.nativeElement;
        const telInput = this.intl_tel_ctrl.nativeElement.querySelector('.tel-input');
        const telCntry = this.intl_tel_ctrl.nativeElement.querySelector('.tel-ddl-country');

        if (telCtrl && telInput && telCntry) {
          this.renderer.setStyle(telInput, 'width', (telCtrl.getBoundingClientRect().width - telCntry.getBoundingClientRect().width - 5) + 'px');
        }
      }
    }, 150);
  };

  viewToForm = (text: string) => {
    return this.getValue(text);
  };

  formToView = (text: string) => {
    this.setValue(text);
    return this.localValue;
  };

  @HostListener("window:resize", [])
  onWindowResize = () => {
    this.setSize();
  };

  ngOnDestroy(): void {
    if (this.ctrlValueChangesSubs)
      this.ctrlValueChangesSubs.unsubscribe();
  }
}
