import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';

import { EmailDialogComponent } from './dlg-email.component';
import { NotifyAreaModule } from '../notify-area';

import { ContainerSpinnerModule } from '../container-spinner';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatInputModule, MatChipsModule, NotifyAreaModule, ContainerSpinnerModule, TranslateModule],
    declarations: [EmailDialogComponent],
    exports: [EmailDialogComponent]
})
export class EmailDialogModule { }
