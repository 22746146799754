import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subscription, Observable, timer } from 'rxjs';
import { tap, take } from 'rxjs/operators';

import { ServiceResult, SmsMessage } from '../models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class EmailService {

  private readonly ACC_CONFIRM_EMAIL_COUNTER_VALUE: number = 90;


  accountConfirmEmailCounter$: BehaviorSubject<number> = new BehaviorSubject(0);
  private accountConfirmEmailCounterSubs: Subscription;

  constructor(
    private http: HttpClient,
    private _configService: ConfigService,
  ) { }

  sendConfirmationEmail = (language: string, rezNo: string, lastName: string, toEmails: string): Observable<ServiceResult> => {
    const url = this._configService.getReservationApiUrl('/sendconfirmationemail', { lang: language, reservationNo: rezNo, lastName: lastName, toEmails: toEmails });

    return this.http.get<ServiceResult>(url);
  };

  sendCancellationEmailNonWeb = (language: string, rezNo: string, lastName: string): Promise<ServiceResult> => {
    const url = this._configService.getReservationApiUrl('/sendcancellationemailnonweb', { lang: language, reservationNo: rezNo, lastName: lastName });

    return this.http.get<ServiceResult>(url).toPromise();
  };

  public startAccountConfirmEmailCounter(reset: boolean = false) {
    if (this.accountConfirmEmailCounter$.value === 0 || reset === true) {
      this.stopAccountConfirmEmailCounter();

      this.accountConfirmEmailCounter$.next(this.ACC_CONFIRM_EMAIL_COUNTER_VALUE);
      this.accountConfirmEmailCounterSubs = timer(1000, 1000).pipe(
        tap(() => {
          this.accountConfirmEmailCounter$.next(this.accountConfirmEmailCounter$.value - 1);
        }),
        take(this.ACC_CONFIRM_EMAIL_COUNTER_VALUE)
      ).subscribe({
        complete: () => {
          this.accountConfirmEmailCounter$.next(0);
        }
      });
    }
  };

  public stopAccountConfirmEmailCounter() {
    if (this.accountConfirmEmailCounterSubs) {
      this.accountConfirmEmailCounterSubs.unsubscribe();
      this.accountConfirmEmailCounter$.next(0);
    }
  };
}
