<div class="confirm-with-pwd-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <h4 mat-dialog-title class="header">{{'dlg-confirm-with-pwd.heading-default' | translate}}<hr /></h4>
  <mat-dialog-content>
    <mat-expansion-panel class="panel" [expanded]="step === 0">
      <div class="seperator"></div>
      <div class="row margin-0">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-right icon">
          <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 notify-text">
          <span class="text">{{ confirmMsg | translate}}</span>
        </div>
      </div>
      <div class="seperator"></div>
    </mat-expansion-panel>
    <mat-expansion-panel class="panel" [expanded]="step === 1">
      <ng-container *ngIf="step === 1">
        <notify-area [notifyType]="(notify$ | async)?.notifyType" [notification]="(notify$ | async)?.notification" [errors]="(notify$ | async)?.validationErrors" [smallSize]="true" [noSpacing]="true"></notify-area>
        <p class="notify-text">
          <span class="text">{{'dlg-confirm-with-pwd.enter-pwd' | translate}}</span>
        </p>
        <div class="form-field-box" CapsLockState CapsLockText="{{'common.caps-on' | translate}}">
          <mat-form-field class="input-full-width">
            <mat-label>{{'common.password' | translate}}</mat-label>
            <input matInput [type]="pwdFieldType" placeholder="{{'common.password' | translate}}" [formControl]="password" autocomplete="current-password" spellcheck="false" tabindex="0" [attr.aria-label]="'common.password' | translate" name="password" autocapitalize="off" required [autofocus]="true" />
            <mat-error *ngIf="password.hasError('required')">
              <span [innerHTML]="'validations.req-pwd' | translate"></span>
            </mat-error>
            <span matPrefix class="color-orange"><i class="fas fa-key fa-fw" aria-hidden="true"></i>&nbsp;</span>
            <button type="button" *ngIf="password.value" matSuffix mat-icon-button [attr.aria-label]="'common.show-hide-pwd' | translate" (click)="pwdFieldType = pwdFieldType == 'password' ? 'text' : 'password'">
              <i *ngIf="pwdFieldType === 'text'" class="far fa-eye icon-button" aria-hidden="true"></i>
              <i *ngIf="pwdFieldType === 'password'" class="far fa-eye-slash icon-button" aria-hidden="true"></i>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="actions">
    <button type="button" class="btn-main btn-orange small" (click)="onButtonClick(step)">{{(step === 0 ? 'buttons.yes': 'buttons.ok') | translate}}</button>
    &nbsp;&nbsp;
    <button type="button" class="btn-main btn-white small" (click)="onButtonClick(-1)">{{(step === 0 ? 'buttons.no' : 'buttons.cancel') | translate}}</button>
  </mat-dialog-actions>
    <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
