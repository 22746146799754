<footer class="footer" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <a href="{{mainSiteUrl}}{{'links.smoke-free' | translate}}" target="_blank" rel="noopener noreferrer">
            <span class="no-smoking" [innerHTML]="'page-footer.smoke-free' | translate"></span>
          </a>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 cert">
          <div class="bbb">
          </div>
          <span class="occq">
          </span>
          <div class="geo-trust">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-links">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <h5>{{'page-footer.about-globe' | translate}}</h5>

          <a href="{{mainSiteUrl}}{{'links.our-promises' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.our-promises' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.contact-us' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.contact-us' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.careers' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.careers' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.newsletter-signup' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.newsletter-signup' | translate}}</a>
          <a href="{{'links.reserve-your-car' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.reserve-your-car' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.buy-a-car' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.buy-a-car' | translate}}</a>
          <a href="{{'links.truck-rental' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.truck-rental' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.pp' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.pp' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.terms' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.terms' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.globe-goes-green' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.globe-goes-green' | translate}}</a>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <h5>{{'page-footer.helpzone' | translate}}</h5>

          <a href="{{mainSiteUrl}}{{'links.faq' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.faq' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.car-ins-rep' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.car-ins-rep' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.our-locs' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.our-locs' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.need-ins' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.need-ins' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.unl-kms' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.unl-kms' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.ppf' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.ppf' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.adddr' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.adddr' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.young-dr' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.young-dr' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.child-seat' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.child-seat' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.go-usa' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.go-usa' | translate}}</a>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <h5>{{'page-footer.car-rental-deals' | translate}}</h5>

          <a href="{{mainSiteUrl}}{{'links.free-gps' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.free-gps' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.roadside-assistance' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.roadside-assistance' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.lib-cvrg' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.lib-cvrg' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.rental-monthly' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.rental-monthly' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.rental-weekly' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.rental-weekly' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.rental-corp' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.rental-corp' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.vip' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.vip' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.deals' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.deals' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.faq' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.rental-tips' | translate}}</a>
          <a href="{{mainSiteUrl}}{{'links.car-share' | translate}}" target="_blank" rel="noopener noreferrer">{{'page-footer.car-share' | translate}}</a>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <h5>{{'page-footer.new-to-globe' | translate}}</h5>
          <a href="{{mainSiteUrl}}{{'links.learn-more' | translate}}" class="btn-learn-more">
            {{'page-footer.learn-more' | translate}} &nbsp;&nbsp;<i class="fas fa-arrow-right" aria-hidden="true"></i>
          </a>
          <!--<div class="social">
            <h5 class="pull-left">FOLLOW US</h5>
            <a class="pull-left" href="{{'links.fb' | translate}}" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook" aria-hidden="true"></i></a>
            <a class="pull-left" href="{{'links.gp' | translate}}" target="_blank" rel="noopener noreferrer"><i class="fab fa-google-plus" aria-hidden="true"></i></a>
            <a class="pull-left" href="{{'links.tt' | translate}}" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter" aria-hidden="true"></i></a>
          </div>
          <div class="social">
            <script type="text/javascript">
              (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id))
                  return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/en_GB/all.js#xfbml=1";
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));

            </script>
            <div style="" data-width="450" data-show-faces="true" data-send="false" data-layout="button_count"
                 data-href="https://www.facebook.com/globecar" data-font="verdana" data-action="like"
                 class="fb-like fb_iframe_widget" fb-xfbml-state="rendered" fb-iframe-plugin-query="action=like&amp;app_id=&amp;font=verdana&amp;href=http%3A%2F%2Fwww.facebook.com%2Fglobecar&amp;layout=button_count&amp;locale=en_GB&amp;sdk=joey&amp;send=false&amp;show_faces=true&amp;width=450">
              <span style="vertical-align: bottom; width: 87px; height: 20px;">
                <iframe width="450px" scrolling="no" height="1000px" frameborder="0" name="f1dc410ed83f3"
                        allowtransparency="true" title="fb:like Facebook Social Plugin" style="border: medium none; visibility: visible; width: 87px; height: 20px;"
                        src="https://www.facebook.com/plugins/like.php?action=like&amp;app_id=&amp;channel=https%3A%2F%2Fs-static.ak.facebook.com%2Fconnect%2Fxd_arbiter%2F63KoCqPoniC.js%3Fversion%3D40%23cb%3Df163da21db479f8%26domain%3Dwww.globecar.com%26origin%3Dhttps%253A%252F%252Fwww.globecar.com%252Ff3aca1dc782ff6%26relation%3Dparent.parent&amp;font=verdana&amp;href=http%3A%2F%2Fwww.facebook.com%2Fglobecar&amp;layout=button_count&amp;locale=en_GB&amp;sdk=joey&amp;send=false&amp;show_faces=true&amp;width=450"
                        class=""></iframe>
              </span>
            </div>
          </div>-->
          <div class="phone">
            <ul>
              <li><i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp;{{'common.toll-free' | translate}}:&nbsp;<strong><a href="tel:+18665045623">1-866-50-GLOBE</a></strong> (45623)</li>
              <li><i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp;{{'page-header.montreal' | translate}}:&nbsp;<strong><a href="tel:+15147330988">(514) 733-0988</a></strong></li>
              <li><i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp;Toronto:&nbsp;<strong><a href="tel:+14169005944">(416) 900-5944</a></strong></li>
              <li><i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp;{{'page-header.canada-and-usa' | translate}}:&nbsp;<strong><a href="tel:+18665045623">1 (866) 504-5623</a></strong></li>
              <li><i class="fas fa-phone-alt" aria-hidden="true"></i>&nbsp;{{'page-header.france-and-europe' | translate}}:&nbsp;<strong><a href="tel:+0033182880494">00-331-8288-0494</a></strong></li>
              <li><i class="fas fa-comment-dots" aria-hidden="true"></i>&nbsp;SMS:&nbsp;<strong><a href="sms:+15146001018">(514) 600-1018</a></strong></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="car hidden-xs">

      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="logo {{'lang' | translate}}">&nbsp;</div>
      <div class="slogan">
        {{'common.drive-4-less' | translate}}<sup>TM</sup>
      </div>
    </div>
  </div>
  <div class="footer-copyrights">
    <div class="container">
      <a href="{{mainSiteUrl}}{{'links.sitemap' | translate}}" target="_blank" rel="noopener noreferrer" class="link">
        <strong>{{'page-footer.sitemap' | translate}}</strong>
      </a>
      <a href="{{mainSiteUrl}}{{'links.terms' | translate}}" target="_blank" rel="noopener noreferrer" class="link">
        <strong>{{'page-footer.terms' | translate}}</strong>
      </a>
      <a href="{{mainSiteUrl}}{{'links.pp' | translate}}" target="_blank" rel="noopener noreferrer" class="link">
        <strong>{{'page-footer.pp' | translate}}</strong>
      </a>
      <span>
        <br class="hidden-md hidden-lg" />Copyright &copy; <a href="{{mainSiteUrl}}">Globe Car Rental Inc.</a>&nbsp;{{currentYear}}<span class="hidden-xs hidden-sm">&nbsp;|&nbsp;</span><br class="hidden-md hidden-lg" />{{'page-footer.made-with' | translate}}&nbsp;<i class='fas fa-heart color-orange' aria-hidden='true'></i>&nbsp;{{'page-footer.in-montreal' | translate}}
        <span class="pull-right color-grey">v{{currentVersion}}</span>
      </span>
    </div>
  </div>
</footer>
