import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State, logoutUser } from '../store';

import { CommonService, LoggingService } from '../core/services';
import { PAGES, LOG_TYPES } from '../core/enums';
import { loadUserInfo$ } from './common';

@Injectable({ providedIn: 'root' })
export class AnonymousOnlyAccessGuard  {
  constructor(
    private _commonService: CommonService,
    private _logger: LoggingService,
    private router: Router,
    private store: Store<State>
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const forcedLogoutUrls = [
      ...this._commonService.getAllRoutesByKey([PAGES.PASSWORD_RESET])
    ];
    if (!CommonService.isNullOrWhiteSpace(forcedLogoutUrls.find(x => x.indexOf(route.url.join("/")) >= 0)) || route.queryParams.action === "logout") {
      this.store.dispatch(logoutUser());
      return of(true);
    }
    else {
      return loadUserInfo$(this.store).pipe(
        tap((isLoaded) => {
          if (isLoaded) {
            void this.router.navigateByUrl(`/${this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang)}`);
          }
        }),
        map((isLoaded) => {
          return !isLoaded;
        }),
        catchError((err) => {
          this._logger.log(LOG_TYPES.ERROR, err.message);
          void this.router.navigateByUrl(`/${this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, route.params.lang)}`);
          return of(false);
        })
      );
    }
  }
}
