import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, HostListener, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCheckboxComponent implements OnInit, AfterViewInit {

  @ViewChild('checkbox', { static: true }) checkbox: ElementRef;

  @Input() isChecked = false;
  @Input() label: string;
  @Input() textAlign = "left";
  @Input() parentFormGroup: UntypedFormGroup = null;
  @Input() formCtrlName = "";

  @Output() checkChange = new EventEmitter<any>();

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    if (!this.parentFormGroup || this.parentFormGroup === null) {
      this.parentFormGroup = new UntypedFormGroup({
        customCheckbox: new UntypedFormControl()
      });
      this.formCtrlName = "customCheckbox";
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setSize();
    }, 500);
  }

  onCheckChange = (isChecked: boolean) => {
    this.checkChange.emit(isChecked);
  };

  private setSize = () => {
    const checkbox = this.checkbox.nativeElement;
    const checkboxCtrl = this.checkbox.nativeElement.querySelector('.checkbox-ctrl');
    const checkboxLabel = this.checkbox.nativeElement.querySelector('.checkbox-label');

    if (checkbox && checkboxCtrl && checkboxLabel) {
      this.renderer.setStyle(checkboxLabel, 'width', (checkbox.getBoundingClientRect().width - checkboxCtrl.getBoundingClientRect().width - 10) + 'px');
      this.renderer.setStyle(checkbox, 'height', checkboxLabel.getBoundingClientRect().height + 'px');
    }
  };

  @HostListener("window:resize", [])
  onWindowResize = () => {
    this.setSize();
  };
}
