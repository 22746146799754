<app-header [appSettings]="appSettings$ | async" [showRezNav]="showRezNav" (switchLanguage)="onSwitchLanguage($event)"></app-header>

<div class="app-body">
  <rez-widget *ngIf="showWidget"></rez-widget>
  <main class="main" [@routeAnimation]="getDepth(myOutlet)">
    <router-outlet #myOutlet="outlet"></router-outlet>
  </main>
  <div class="device-xs visible-xs"></div>
  <div class="device-sm visible-sm"></div>
  <div class="device-md visible-md"></div>
  <div class="device-lg visible-lg"></div>
</div>
<app-footer></app-footer>

<nanorep [id]="'external-tags.nanorep-id' | translate" [enabled]="false"></nanorep>
<snapengage [id]="'external-tags.snapengage-id' | translate" [enabled]="true"></snapengage>
