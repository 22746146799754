import { createAction, props } from '@ngrx/store';
import { UserDto } from 'src/app/core/models';

export const loginUser = createAction(
  '[User] Login User',
  props<{ userName: string, password: string, rememberMe: boolean, language: string }>()
);

export const loadCurrentUserInfo = createAction(
  '[User] Load Current User Info'
);

export const loadCurrentUserInfoSuccess = createAction(
  '[User] Load Current User Info Success',
  props<{ payload: UserDto }>()
);

export const loadCurrentUserInfoFail = createAction(
  '[User] Load Current User Info Fail',
  props<{ payload: any }>()
);

export const resetCurrentUserInfo = createAction(
  '[User] Reset Current User Info'
);

export const logoutUser = createAction(
  '[User] Logout User'
);
