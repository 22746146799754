import { Injectable, Inject  } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NGXLogger } from 'ngx-logger';


import { WINDOW } from '../providers/window.provider';

import { LOG_TYPES } from '../enums';
import { ExceptionDto } from '../models';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggingService {

  private ignoreExceptions = [
    /ChunkLoadError/i,
    /StaticInjectorError/i,
    /Cannot\sset\sproperty\sscrollRestoration\sof/i
  ];

  constructor(
    @Inject(WINDOW) private window: Window,
    private logger: NGXLogger) {  }

  public log(level: LOG_TYPES, message, exception: ExceptionDto = null) {

    if (this.ignoreExceptions[0].test(message) && this.window && this.window.location) {
      this.window.location.reload();
    }

    const isLog: boolean = !!message && !this.ignoreExceptions[0].test(message) && !this.ignoreExceptions[1].test(message) && !this.ignoreExceptions[2].test(message);


    if (isLog === true) {

      const data = { Logger: environment.rentalApi.key };

      switch (level) {
        case 0:
          console.trace(message);
          this.logger.trace(message, data);
          break;
        case 1:
          console.debug(message);
          this.logger.debug(message, data);
          break;
        case 2:
          console.info(message);
          this.logger.info(message, data);
          break;
        case 3:
          console.log(message);
          this.logger.log(message, data);
          break;
        case 4:
          console.warn(message);
          this.logger.warn(message, data);
          break;
        case 5:
          if (exception && exception != null)
            this.logger.error(message, exception, data);
          else
            this.logger.error(message, data);
          console.error(message);
          break;
        default:
          console.log(message);
          this.logger.trace(message, data);
      }
    }
  }
}
