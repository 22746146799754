import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { getFileNameFromResponseContentDisposition, saveFile } from '../helpers/file-download.helper';

@Injectable()
export class FileService {
  constructor(
    private _configService: ConfigService,
    private http: HttpClient
  ) { }

  downloadFile(fileName: string, file: Blob) {
    //const url = `${this._configService.webApiHostUrl}/properties/${propertyId}/files/${fileId}`;

    //// Process the file downloaded
    //this.http.get(url, { responseType: 'blob'}).subscribe(res => {
    //  const fileName = getFileNameFromResponseContentDisposition(res);
    //  saveFile(res.blob(), fileName);
    //});

    saveFile(file, fileName);
  }
}
