import { CurrencyRatesAction, LOAD_CURRENCY_RATES, LOAD_CURRENCY_RATES_SUCCESS, LOAD_CURRENCY_RATES_FAIL } from './currency-rates.action';
import { CurrencyRates } from 'src/app/core/models';
import { ICurrencyRatesState } from '../_base';

const initialState: ICurrencyRatesState = {
  currencyRates: {} as CurrencyRates,
  loaded: false,
  loading: false
};

export function currencyRatesReducer(state = initialState, action: CurrencyRatesAction): ICurrencyRatesState {
  switch (action.type) {
    case LOAD_CURRENCY_RATES: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_CURRENCY_RATES_SUCCESS: {
      if (action.payload && action.payload.rates) {
        return {
          ...state,
          loading: false,
          loaded: true,
          currencyRates: action.payload
        };
      }
      else {
        return {
          ...initialState
        };
      }
    }

    case LOAD_CURRENCY_RATES_FAIL: {
      return {
        ...state,
        ...initialState
      };
    }
  }

  return state;
}
