import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './safe-content.pipe';
import { DecodeHTML } from './decode-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SafePipe, DecodeHTML],
  exports: [SafePipe, DecodeHTML]
})
export class SafePipeModule { }
