import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

import { CurrencyRatesService } from 'src/app/core/services';

import { LOAD_CURRENCY_RATES, LoadCurrencyRates, LoadCurrencyRatesSuccess, LoadCurrencyRatesFail } from './currency-rates.action';

@Injectable()
export class CurrencyRatesEffects {
  constructor(
    private actions$: Actions,
    private currencyRatesService: CurrencyRatesService
  ) { }


  loadRates$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_CURRENCY_RATES),
      map((action: LoadCurrencyRates) => action.payload),
      switchMap(baseCurrency => {
        return this.currencyRatesService
          .fetchCurrencyRates(baseCurrency)
          .pipe(
            map(data => new LoadCurrencyRatesSuccess(data)),
            catchError(error => of(new LoadCurrencyRatesFail(error)))
          );
      })
    ));
}
