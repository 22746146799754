<div class="cvv-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <h4 mat-dialog-title class="header">{{'dlg-cvv.cvv-no' | translate}}<hr /></h4>
  <mat-dialog-content>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <p class="text">{{'dlg-cvv.cvv-note' | translate}}</p>
      </div>
    </div>
    <div class="seperator hidden-xs"></div>
    <div class="row cards">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center column">
        <img src="/assets/images/cvv-dialog/visa.jpg" alt="{{'dlg-cvv.visa-mc' | translate}}" />
        <div>
          <h5>{{'dlg-cvv.visa-mc' | translate}}</h5>
          <span>{{'dlg-cvv.visa-mc-digit-info' | translate}}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center column">
        <img src="/assets/images/cvv-dialog/amex.jpg" alt="{{'dlg-cvv.amex' | translate}}" />
        <div>
          <h5>{{'dlg-cvv.amex' | translate}}</h5>
          <span>{{'dlg-cvv.amex-digit-info' | translate}}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
