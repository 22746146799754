<div class="sms-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <span class="btn-close" (click)="closeDialog()"><i class="fas fa-times" aria-hidden="true"></i></span>
  <h4 mat-dialog-title class="header">{{'dlg-sms.conf-sms' | translate}}<hr /></h4>
  <mat-dialog-content>
    <div class="row table-heading">
      <span>{{'dlg-sms.send-conf-sms' | translate}}</span><br />
      <span class="small">{{'dlg-sms.conf-cell' | translate}}</span>
    </div>
    <div class="row phone">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <intl-tel-input telInputWidth="450px" dropdownItemCount="3" [value]="phoneNumber" [required]="true" (valueChange)="onValueChange($event)"></intl-tel-input>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="actions">
    <button type="button" class="btn-main dlg-btn btn-orange" (click)="onSendSmsClick()">{{'buttons.send' | translate}}</button>
    &nbsp;&nbsp;
    <button type="button" class="btn-main dlg-btn btn-white" [mat-dialog-close]="">{{'buttons.cancel' | translate}}</button>
  </mat-dialog-actions>
  <container-spinner [showSpinner]="showSpinner" diameter="70"></container-spinner>
</div>
