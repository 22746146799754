import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommonService } from 'src/app/core/services';
import { NOTIFY_TYPES } from 'src/app/core/enums';

@Component({
  selector: 'notify-bottom',
  templateUrl: './notify-bottom.component.html',
  styleUrls: ['./notify-bottom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifyBottomComponent implements OnInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  notifyType: NOTIFY_TYPES = NOTIFY_TYPES.Normal;
  notification: string = "";

  constructor(private _commonService: CommonService, private _bottomSheetRef: MatBottomSheetRef<NotifyBottomComponent>) { }

  ngOnInit(): void {
    const subs = this._commonService.bottomSheetObs.pipe(
      tap((args) => {
        if (args && args.message && args.message != "") {
          this.notifyType = args.notifyType;
          this.notification = args.message;
        }
      })
    ).subscribe();

    this.allSubs.add(subs);
  }

  dismiss = () => {
    this._bottomSheetRef.dismiss();
  };

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }

}
