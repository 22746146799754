import { Injectable } from '@angular/core';

import { PAGES, DATE_FORMAT_TYPES } from '../enums';

import { Currency } from '../models';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {

  static readonly mainSiteUrl: string = environment.mainSiteUrl;

  static readonly rezEngineSiteUrl: string = environment.rezEngineSiteUrl;

  static readonly globeCdnUrl: string = environment.cdnUrl;

  static readonly webApiHostUrl: string = environment.rentalApi.hostUrl;

  public static readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static readonly PWD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*£€?_]{8,30}$/;
  public static readonly ALL_REZ_NO_REGEX = /^(WPQ[a-z\d]{7})$|^(\d{6}c)$/i;
  public static readonly WEB_REZ_NO_REGEX = /^(WPQ[a-z\d]{7})$/i;
  public static readonly NON_WEB_REZ_NO_REGEX = /^(\d{6}C)$/i;


  public static readonly availableLangs: string[] = ['en', 'fr'];
  public static readonly spinTheWheelBranches: string[] = ["EVO", "HEC", "DLG", "ATW", "BER", "TMR", "YUL", "STL"];

  public static readonly dateTimeFormats: { lang: string; type: DATE_FORMAT_TYPES; format: string }[] = [
    {
      lang: "en",
      type: DATE_FORMAT_TYPES.SHORT,
      format: "DD/MM/YYYY hh:mm A"
    },
    {
      lang: "en",
      type: DATE_FORMAT_TYPES.LONG,
      format: "dddd DD/MM/YYYY hh:mm A"
    },
    {
      lang: "en",
      type: DATE_FORMAT_TYPES.TIME,
      format: "h:mma"
    },
    {
      lang: "fr",
      type: DATE_FORMAT_TYPES.SHORT,
      format: "DD/MM/YYYY HH[h]mm"
    },
    {
      lang: "fr",
      type: DATE_FORMAT_TYPES.LONG,
      format: "dddd DD/MM/YYYY HH[h]mm"
    },
    {
      lang: "fr",
      type: DATE_FORMAT_TYPES.TIME,
      format: "HH[h]mm"
    }
  ];

  public static readonly currencies: Currency[] = [
    { code: "CAD", displayText: "CAD $", description: "Canadian Dollars" },
    { code: "USD", displayText: "USD $", description: "US Dollars" },
    { code: "EUR", displayText: "EUR €", description: "Euros" },
    { code: "AUD", displayText: "AUD $", description: "Australian Dollar" },
    { code: "CNY", displayText: "CNY ¥", description: "Chinese Yuan" },
    { code: "GBP", displayText: "GBP £", description: "Pound Sterling" },
    { code: "HKD", displayText: "HKD $", description: "Hong Kong Dollar" },
    { code: "JPY", displayText: "JPY ¥", description: "Japanese Yen" },
    { code: "MXN", displayText: "MXN $", description: "Mexican Peso" },
    { code: "RUB", displayText: "RUB ₽", description: "Russian Rouble" }
  ];

  // Default Request Header
  //httpOptions: { headers: HttpHeaders } = { headers: null };

  // All routes as per app-routing.module
  appRoutes = [
    {
      key: PAGES.RENT_A_CAR,
      en_url: 'en/rent-a-car',
      fr_url: 'fr/location-voiture',
      showWidget: true,
      requireAuth: false
    },
    {
      key: PAGES.RENT_A_TRUCK,
      en_url: 'en/rent-a-truck',
      fr_url: 'fr/location-camion',
      showWidget: true,
      requireAuth: false
    },
    {
      key: PAGES.VEHICLE_EXTRAS,
      en_url: 'en/additional-services',
      fr_url: 'fr/services-supplementaires',
      showWidget: true,
      requireAuth: false
    },
    {
      key: PAGES.CUSTOMER_DETAILS,
      en_url: 'en/driver-details',
      fr_url: 'fr/details-du-conducteur',
      showWidget: true,
      requireAuth: false
    },
    {
      key: PAGES.CONFIRMATION,
      en_url: 'en/confirmation',
      fr_url: 'fr/confirmation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.CHECK_IN_START,
      en_url: 'en/check-in/go',
      fr_url: 'fr/enregistrement/go',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.CHECK_IN,
      en_url: 'en/check-in/driver-details',
      fr_url: 'fr/enregistrement/details-du-conducteur',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.VIEW_RESERVATION,
      en_url: 'en/view-reservation',
      fr_url: 'fr/voir-reservation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.MODIFY_RESERVATION,
      en_url: 'en/modify-reservation',
      fr_url: 'fr/modifier-reservation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.CANCEL_RESERVATION,
      en_url: 'en/cancel-reservation',
      fr_url: 'fr/annuler-reservation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.REQUEST_RECEIPT,
      en_url: 'en/request-receipt',
      fr_url: 'fr/demander-recu',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.REQUEST_EXTENSION,
      en_url: 'en/request-extension',
      fr_url: 'fr/demander-extension',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.SESSION_EXPIRED,
      en_url: 'en/session-expired',
      fr_url: 'fr/session-expiree',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.ERROR_404,
      en_url: 'en/error/404',
      fr_url: 'fr/erreur/404',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.ERROR_408,
      en_url: 'en/error/408',
      fr_url: 'fr/erreur/408',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.ERROR_500,
      en_url: 'en/error/500',
      fr_url: 'fr/erreur/500',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.PAGE_NOT_FOUND,
      en_url: 'en/page-not-found',
      fr_url: 'fr/page-not-found',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.VIEW_CONFIRMATION_EMAIL,
      en_url: 'en/email/confirmation',
      fr_url: 'fr/courriel/confirmation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.VIEW_CHECKIN_REMINDER_EMAIL,
      en_url: 'en/email/check-in',
      fr_url: 'fr/courriel/enregistrement',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.VIEW_CANCELLATION_EMAIL,
      en_url: 'en/email/cancellation',
      fr_url: 'fr/courriel/annulation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.VIEW_RECOMMENDATION_EMAIL,
      en_url: 'en/email/recommendation',
      fr_url: 'fr/courriel/recommandation',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.REGISTER,
      en_url: 'en/register',
      fr_url: 'fr/inscription',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.LOGIN,
      en_url: 'en/login',
      fr_url: 'fr/connexion',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.MY_PROFILE,
      en_url: 'en/my-profile',
      fr_url: 'fr/mon-profil',
      showWidget: false,
      requireAuth: true
    },
    {
      key: PAGES.CHANGE_PASSWORD,
      en_url: 'en/my-profile/change-password',
      fr_url: 'fr/mon-profil/changer-mot-de-passe',
      showWidget: false,
      requireAuth: true
    },
    {
      key: PAGES.FORGOT_PASSWORD,
      en_url: 'en/forgot-password',
      fr_url: 'fr/mot-de-passe-oublie',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.PASSWORD_RESET,
      en_url: 'en/password-reset',
      fr_url: 'fr/reinitialisation-mot-de-passe',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.VERIFY_EMAIL,
      en_url: 'en/verify-email',
      fr_url: 'fr/valider-courriel',
      showWidget: false,
      requireAuth: false
    },
    {
      key: PAGES.MY_RESERVATIONS,
      en_url: 'en/my-profile/reservations',
      fr_url: 'fr/mon-profil/reservations',
      showWidget: false,
      requireAuth: true
    },
    {
      key: PAGES.SPIN_THE_WHEEL,
      en_url: 'en/spin',
      fr_url: 'fr/spin',
      showWidget: false,
      requireAuth: false
    }
  ];

  constructor() { }

  get ClientAppId(): string {
    return environment.rentalApi.clientAppId
  }

  get RequestTimeout(): number {
    return environment.requestTimeout;
  }

  get SessionTimeout(): number {
    return environment.sessionTimeout;
  }

  get IsProduction(): boolean {
    return environment.production;
  }

  get ApplicationInsightsKey(): string {
    return environment.appInsights.instrumentationKey;
  }

  get TenantId(): string {
    return environment.rentalApi.key;
  }

  get IsLocalHost(): boolean {
    return environment.isLocalhost;
  }

  getBranchApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.branchesApi, path, params)
  }

  getCountriesApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.countriesApi, path, params)
  }

  getCurrenciesApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.currencyRates, path, params)
  }

  getLocationIpApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.ip2Location, path, params)
  }

  getLogApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.logsApi, path, params)
  }

  getNewsletterApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.newsletterSubscription, path, params)
  }

  getNotificationApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.notificationApi, path, params)
  }

  getRentalRatesApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.ratesApi, path, params)
  }

  getRateExtraApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.rateExtrasApi, path, params)
  }

  getRecommendApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.recommendationEmailApi, path)
  }

  getReservationApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.reservationsApi, path, params)
  }

  getSecurityApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.securityApi, path, params)
  }

  getSmsApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.smsServiceApi, path, params)
  }

  getValidatorApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.validatorApi, path, params)
  }

  getVehiclesApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.rentalApi.endPoints.vehiclesApi, path, params)
  }

  getUserApiUrl(path: string = '', params: any = null) {
    return this.getUrl(environment.authApi.endPoints.userApi, path, params)
  }


  private getUrl(api: string, path: string, params: any = null) {
    let qs = this.toQueryString(params);
    return `${environment.rentalApi.hostUrl}${api}/${environment.rentalApi.key}${path}${qs}`
  }

  private toQueryString(params: any): string {

    if (params == null) return '';

    let result = Object.keys(params).map(function (key) {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');

    if (result?.length > 0) result = `?${result}`

    return result;

  }
}
