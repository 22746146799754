import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {

    // make url lowercase except parameter values because a value could be a case sensitive user token

    let urlSegments = url.split('?');
    urlSegments[0] = urlSegments[0].toLowerCase();

    if (urlSegments.length === 2) {

      let queryParams = urlSegments[1].split('&');

      for (let i = 0; i < queryParams.length; i++) {
        let keyValue = queryParams[i].split('=');
        queryParams[i] = `${keyValue[0].toLowerCase()}=${keyValue[1]}`;
      }

      urlSegments[1] = queryParams.join('&');

      url = urlSegments.join("?");
    }
    else {
      url = urlSegments[0];
    }

    return super.parse(url);
  }
}
