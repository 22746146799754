import { select, Store } from "@ngrx/store";
import { catchError, combineLatest, distinctUntilChanged, filter, map, Observable, of, take, tap } from "rxjs";
import { RateDto, RateExtra, Session } from "../../core/models";
import { RateExtraService, RateService } from "../../core/services";
import { getExtras, getSelectedRate, SetSelectedRate, State, UpdateExtras } from "../../store";

export const loadSelectedExtras$ = (session: Session, store: Store<State>, _rateService: RateService, _rateExtService: RateExtraService): Observable<boolean> => {
  if (!session || session === null || session.selectedExtrasCodes === null) {
    return of(false);
  }

  const combined = combineLatest([
    store.pipe(select(getSelectedRate), distinctUntilChanged((x, y) => {
      return JSON.stringify(x) === JSON.stringify(y);
    })),
    store.pipe(select(getExtras))
  ],
    (rate, extras) => {
      return { selectedRate: rate, allExtras: extras };
    }
  );

  return combined.pipe(
    tap((obj) => {
      if (obj.allExtras && obj.allExtras.length > 0) {
        let selectedExtras = [...[], ...obj.allExtras.filter(x => !x.isFree && x.groupName.toLowerCase() !== "rate" && (x.autoApply || x.autoChecked))];
        const unselectedExtras = [...[], ...session.selectedExtrasCodes.filter(x => selectedExtras.map(y => y.code).indexOf(x.code) === -1)];

        selectedExtras = unselectedExtras.reduce((extras: RateExtra[], extra) => {
          const missingExtra: RateExtra = obj.allExtras.find(x => x.code === extra.code);
          if (missingExtra) {
            const _children = missingExtra.children.reduce((children: RateExtra[], childExtra: RateExtra) => {
              const child = extra.children.find(x => x.code === childExtra.code);
              if (child) {
                return [
                  ...children,
                  {
                    ...childExtra,
                    autoChecked: true,
                    qty: child.qty
                  }
                ];
              }
              else {
                return [...children, childExtra];
              }
            }, []);

            return [
              ...extras,
              {
                ...missingExtra,
                autoChecked: true,
                qty: extra.qty,
                children: _children
              }
            ];
          }
          else {
            return [
              ...extras
            ];
          }

        }, [...selectedExtras]);

        if (selectedExtras && selectedExtras.length > 0) {
          selectedExtras = [...selectedExtras, ...obj.allExtras.filter(x => !x.isFree && x.groupName.toLowerCase() !== "rate" && selectedExtras.map(y => y.code).indexOf(x.code) === -1)];
          const _selectedExtras = _rateExtService.getUpdatedSelectedExtras(selectedExtras, obj.selectedRate.rateDetail, obj.selectedRate.vehicle.mileageCap, obj.selectedRate.totalFreeMiles);
          store.dispatch(new SetSelectedRate(_rateService.getUpdatedRate(obj.selectedRate, _selectedExtras) as RateDto));
          store.dispatch(new UpdateExtras(_selectedExtras));
        }
      }
    }),
    filter(obj => obj.allExtras && obj.allExtras.length > 0),
    map(obj => obj.allExtras && obj.allExtras.length > 0),
    take(1),
    catchError(_ => {
      return of(false);
    })
  );
};
