import { catchError, interval, map, Observable, of, take } from "rxjs";
import { environment } from "../../../environments/environment";
import { TimeoutError } from "../../core/error-handlers/timeout-error";

export const timeout$ = (errMessage: string): Observable<boolean> => {
  const _timeout = environment.requestTimeout / 1000;
  return interval(1000).pipe(
    map((val) => {
      if (val + 1 >= _timeout) {
        throw new TimeoutError(errMessage);
      }
      return val + 1 >= _timeout;
    }),
    take(_timeout),
    catchError((err) => {
      if (err instanceof TimeoutError) {
        throw err;
      }
      return of(true);
    })
  );
};
