import { Action } from '@ngrx/store';
import { REZ_ACTIONS } from 'src/app/core/enums';

import { Reservation, AddReservationDto, VehicleDto } from 'src/app/core/models';

export const LOAD_RESERVATION = '[Reservations] Load Reservation';
export const LOAD_RESERVATION_BY_ID = '[Reservations] Load Reservation By Id';
export const LOAD_RESERVATION_FAIL = '[Reservations] Load Reservation Fail';
export const LOAD_RESERVATION_SUCCESS = '[Reservations] Load Reservation Success';

export const RESET_RESERVATION = '[Reservations] Reset Reservation';

export const CREATE_RESERVATION = '[Reservations] Create Reservation';
export const CREATE_RESERVATION_FAIL = '[Reservations] Create Reservation Fail';
export const CREATE_RESERVATION_SUCCESS = '[Reservations] Create Reservation Success';

export const UPDATE_RESERVATION = '[Reservations] Update Reservation';
export const UPDATE_RESERVATION_FAIL = '[Reservations] Update Reservation Fail';
export const UPDATE_RESERVATION_COMPLETE = '[Reservations] Update Reservation Complete';

export const CANCEL_RESERVATION = '[Reservations] Cancel Reservation';
export const CANCEL_RESERVATION_SUCCESS = '[Reservations] Cancel Reservation Success';
export const CANCEL_RESERVATION_FAIL = '[Reservations] Cancel Reservation Fail';

export const UPDATE_RESERVATION_TRANSLATION = '[Reservations] Update Reservation Translations';

export class LoadReservation implements Action {
  readonly type = LOAD_RESERVATION;
  constructor(public language: string, public payload: { reservationNo: string, lastName: string }) { }
}

export class LoadReservationById implements Action {
  readonly type = LOAD_RESERVATION_BY_ID;
  constructor(public language: string, public id: string) { }
}

export class LoadReservationFail implements Action {
  readonly type = LOAD_RESERVATION_FAIL;
  constructor(public payload: any) { }
}

export class LoadReservationSuccess implements Action {
  readonly type = LOAD_RESERVATION_SUCCESS;
  constructor(public payload: Reservation) { }
}

export class ResetReservation implements Action {
  readonly type = RESET_RESERVATION;
  constructor() { }
}

export class CreateReservation implements Action {
  readonly type = CREATE_RESERVATION;
  constructor(public payload: AddReservationDto, public baseCurrency: string) { }
}

export class CreateReservationFail implements Action {
  readonly type = CREATE_RESERVATION_FAIL;
  constructor(public payload: any) { }
}

export class CreateReservationSuccess implements Action {
  readonly type = CREATE_RESERVATION_SUCCESS;
  constructor(public payload: Reservation, public rezAction: REZ_ACTIONS) { }
}

export class UpdateReservation implements Action {
  readonly type = UPDATE_RESERVATION;
  constructor(public payload: AddReservationDto, public baseCurrency: string) { }
}

export class UpdateReservationFail implements Action {
  readonly type = UPDATE_RESERVATION_FAIL;
  constructor(public payload: any) { }
}

export class UpdateReservationComplete implements Action {
  readonly type = UPDATE_RESERVATION_COMPLETE;
  constructor(public payload: any, public rezAction: REZ_ACTIONS) { }
}

export class CancelReservation implements Action {
  readonly type = CANCEL_RESERVATION;
  constructor(public language: string, public payload: { reservationNo: string, lastName: string }) { }
}

export class CancelReservationSuccess implements Action {
  readonly type = CANCEL_RESERVATION_SUCCESS;
  constructor(public payload: any) { }
}

export class CancelReservationFail implements Action {
  readonly type = CANCEL_RESERVATION_FAIL;
  constructor(public payload: any) { }
}

export class UpdateReservationTranslation implements Action {
  readonly type = UPDATE_RESERVATION_TRANSLATION;
  constructor(public language: string, public vehicle: VehicleDto) { }
}

// action types
export type ReservationAction =
  LoadReservation |
  LoadReservationById |
  LoadReservationFail |
  LoadReservationSuccess |
  ResetReservation |
  CreateReservation |
  CreateReservationFail |
  CreateReservationSuccess |
  UpdateReservation |
  UpdateReservationFail |
  UpdateReservationComplete |
  CancelReservation |
  CancelReservationSuccess |
  CancelReservationFail |
  UpdateReservationTranslation;
