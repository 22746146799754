<ng-container *ngIf="dlgType === 'ChangeEmail'">
  <div class="confirm-acc-email-dialog" [ngClass]="{'mobile':isMobileDevice}">
    <h4 mat-dialog-title class="header">{{'dlg-change-email.title' | translate}}<hr /></h4>
    <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog()">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
    <form [formGroup]="inputForm" (ngSubmit)="onSubmit()" novalidate>
      <mat-dialog-content>
        <div class="note">
          <i class="fas fa-exclamation-triangle fa-3x fa-pull-left" aria-hidden="true"></i><span>{{'dlg-change-email.note' | translate}}</span>
        </div>
        <div class="input-box">
          <div class="form-field-box">
            <mat-form-field class="input-full-width readonly">
              <mat-label>{{'dlg-change-email.curr-email-adr' | translate}}</mat-label>
              <input matInput type="email" placeholder="{{'dlg-change-email.curr-email-adr' | translate}}" formControlName="currentEmail" name="email">
            </mat-form-field>
          </div>
          <div class="seperator"></div>
          <div class="form-field-box">
            <mat-form-field class="input-full-width">
              <mat-label>{{'dlg-change-email.new-email-adr' | translate}}</mat-label>
              <input matInput type="email" placeholder="{{'dlg-change-email.new-email-adr' | translate}}" formControlName="newEmail" name="email" autocomplete="email" maxlength="100" required (keyup)="validateField($event,'confEmail')">
              <mat-error *ngIf="inputForm.get('newEmail').hasError('matched')">
                <span [innerHTML]="'validations.email-must-not-match' | translate"></span>
              </mat-error>
              <mat-error *ngIf="inputForm.get('newEmail').hasError('pattern')">
                <span [innerHTML]="'validations.invalid-email' | translate"></span>
              </mat-error>
              <mat-error *ngIf="inputForm.get('newEmail').hasError('required')">
                <span [innerHTML]="'validations.req-email' | translate"></span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="seperator"></div>
          <div class="form-field-box">
            <mat-form-field class="input-full-width">
              <mat-label>{{'common.conf-email' | translate}}</mat-label>
              <input matInput type="email" placeholder="{{'common.conf-email' | translate}}" formControlName="confEmail" oncontextmenu="return false;" oncut="return false;" oncopy="return false;" onpaste="return false;" [autocomplete]="autoComplete" required>
              <mat-error *ngIf="inputForm.get('confEmail').hasError('unmatched')">
                <span [innerHTML]="'validations.conf-email-not-match' | translate"></span>
              </mat-error>
              <mat-error *ngIf="inputForm.get('confEmail').hasError('required')">
                <span [innerHTML]="'validations.req-email' | translate"></span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="seperator"></div>
          <div class="form-field-box">
            <mat-form-field class="input-full-width">
              <mat-label>{{'common.password' | translate}}</mat-label>
              <input matInput [type]="pwdFieldType" placeholder="{{'common.password' | translate}}" formControlName="password" autocomplete="current-password" spellcheck="false" tabindex="0" [attr.aria-label]="'common.password' | translate" name="password" autocapitalize="off" required>
              <mat-error *ngIf="inputForm.get('password').hasError('required')">
                <span [innerHTML]="'validations.req-pwd' | translate"></span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="seperator"></div>
      </mat-dialog-content>
      <mat-dialog-actions align="center" class="actions">
        <button type="submit" class="btn-main btn-orange normal">{{'buttons.ok' | translate}}</button>
        &nbsp;&nbsp;
        <button type="button" class="btn-main btn-white normal" (click)="closeDialog()">{{'buttons.cancel' | translate}}</button>
      </mat-dialog-actions>
    </form>
    <container-spinner [showSpinner]="isProcessing$ | async" diameter="70"></container-spinner>
  </div>
</ng-container>

<ng-container *ngIf="dlgType === 'ConfirmEmail'">
  <div class="confirm-acc-email-dialog" [ngClass]="{'mobile':isMobileDevice}">
    <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog()">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
    <ng-container *ngIf="(isVerified$ | async) === false">
      <h4 mat-dialog-title class="header">{{'dlg-confirm-acc-email.title' | translate}}<hr /></h4>
      <mat-dialog-content>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p class="text">
              <span class="fa-stack fa-5x stack-icon">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-envelope-open fa-stack-1x fa-inverse"></i>
              </span>
            </p>
            <h2>{{'dlg-confirm-acc-email.heading' | translate}}</h2>
            <p class="text">{{'dlg-confirm-acc-email.text1' | translate}}<br />{{'dlg-confirm-acc-email.text2' | translate}}</p>
            <hr />
            <p class="text">
              <button type="button" mat-button color="primary" (click)="onResendClick()" [disabled]="(counter$ | async) > 0">{{'dlg-confirm-acc-email.resend' | translate}}</button>
              <span *ngIf="counter$ | async as counter" class="color-orange">{{counter}}</span>
            </p>
          </div>
        </div>
      </mat-dialog-content>
    </ng-container>
    <ng-container *ngIf="isVerified$ | async">
      <h4 mat-dialog-title class="header">{{'verify-email.success-note2' | translate}}<hr /></h4>
      <mat-dialog-content>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p class="text">
              <span class="fa-stack fa-5x stack-icon">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-thumbs-up fa-stack-1x fa-inverse"></i>
              </span>
            </p>
            <h2 [innerHTML]="'verify-email.success-note1' | translate"></h2>
            <p [innerHTML]="'verify-email.success-note2' | translate" class="text"></p>
            <hr />
            <p class="text">
              <button type="button" class="btn-main btn-white normal" (click)="onRentAVehClick()">{{'buttons.rent-a-car' | translate}}</button>
            </p>
          </div>
        </div>
      </mat-dialog-content>
    </ng-container>
    <container-spinner [showSpinner]="isProcessing$ | async" diameter="70"></container-spinner>
  </div>

</ng-container>
