import { select, Store } from "@ngrx/store";
import { Subscription, combineLatest, filter, map, Observable, of, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { RecommendationEmailDto } from "../../core/models";
import { getRecommendationEmail, getRecommendationEmailStatus, LoadRecommendationEmail, State } from "../../store";

export const loadRecommendationEmailData$ = (id: string, store: Store<State>): Observable<{ isLoaded: boolean; recommendationEmail: RecommendationEmailDto }> => {
  if (!id || id === "") {
    return of({ isLoaded: false, recommendationEmail: null });
  }

  const combined$ = combineLatest([
    store.pipe(select(getRecommendationEmail)),
    store.pipe(select(getRecommendationEmailStatus))
  ],
    (recEmail, status) => {
      return { recEmail: recEmail, status: status };
    }
  );

  return combined$.pipe(
    tap((obj) => {
      if ((obj?.recEmail?.id !== id) && obj.status !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(new LoadRecommendationEmail(id));
      }
    }),
    filter(obj => obj?.recEmail?.id === id || obj.status === STORE_STATUS_CODES.FAILED),
    map(obj => { return { isLoaded: obj.recEmail?.id === id, recommendationEmail: obj.recEmail }; }),
    take(1)
  );
};
