import { Action } from '@ngrx/store';

import { AppSettings, Currency } from 'src/app/core/models';

export const SET_APP_SETTINGS = '[AppSettings] Set App Settings';

export const SET_APP_SETTINGS_AVAILABLE_LANGUAGES = '[AppSettings] Set App Settings Available Languages';
export const SET_APP_SETTINGS_LANGUAGE = '[AppSettings] Set App Settings Language';
export const SET_APP_SETTINGS_BASE_CURRENCY = '[AppSettings] Set App Settings Base Currency';
export const SET_APP_SETTINGS_SELECTED_CURRENCY = '[AppSettings] Set App Settings Selected Currency';
export const SET_APP_SETTINGS_SHOW_REZ_WIDGET = '[AppSettings] Set App Settings Show Rez Widget';
export const SET_APP_SETTINGS_WIDGET_FLASH_ANIMATION_STATE = '[AppSettings] Set App Settings Widget Flash Animation State';

export class SetAppSettings implements Action {
  readonly type = SET_APP_SETTINGS;
  constructor(public payload: AppSettings) { }
}

export class SetAppSettingsAvailableLanguages implements Action {
  readonly type = SET_APP_SETTINGS_AVAILABLE_LANGUAGES;
  constructor(public languages: string[]) { }
}

export class SetAppSettingsLanguage implements Action {
  readonly type = SET_APP_SETTINGS_LANGUAGE;
  constructor(public language: string) { }
}

export class SetAppSettingsBaseCurrency implements Action {
  readonly type = SET_APP_SETTINGS_BASE_CURRENCY;
  constructor(public currencyCode: string) { }
}

export class SetAppSettingsSelectedCurrency implements Action {
  readonly type = SET_APP_SETTINGS_SELECTED_CURRENCY;
  constructor(public currency: Currency) { }
}

export class SetAppSettingsShowRezWidget implements Action {
  readonly type = SET_APP_SETTINGS_SHOW_REZ_WIDGET;
  constructor(public showWidget: boolean) { }
}

export class SetAppSettingsWidgetFlashAnimationState implements Action {
  readonly type = SET_APP_SETTINGS_WIDGET_FLASH_ANIMATION_STATE;
  constructor(public animationState: string) { }
}

// action types
export type AppSettingsAction = SetAppSettings | SetAppSettingsLanguage | SetAppSettingsAvailableLanguages | SetAppSettingsBaseCurrency | SetAppSettingsSelectedCurrency | SetAppSettingsShowRezWidget | SetAppSettingsWidgetFlashAnimationState;
