import { Component, OnDestroy, Inject, Injector } from '@angular/core'; ////ApplicationRef
import { DOCUMENT } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { Subscription } from 'rxjs';
import { tap, first } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../environments/environment';

import { GlobalErrorHandler } from './core/error-handlers/global-error-handler';

import { State, getAppSettings, SetAppSettingsLanguage } from './store';

import { CommonService, MyMonitoringService  } from './core/services';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><loader-lite></loader-lite>'
})
export class AppComponent implements OnDestroy {

  private allSubs: Subscription = new Subscription();

  title = 'Reserve a car in Montreal | Globe Car Rental';

  constructor(
    @Inject(DOCUMENT) private doc,
    private injector: Injector,
    private _translate: TranslateService,
    private _commonService: CommonService,
    private _router: Router,
    private store: Store<State>,
    private myMonitoringService: MyMonitoringService,
    updates: SwUpdate//,
    //appRef: ApplicationRef
  ) {

    const angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        //extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this._router, errorServices: [new GlobalErrorHandler(injector)] }
        }
      }
    });
    appInsights.loadAppInsights();

    updates.checkForUpdate().then(updateAvailable => {
      if (updateAvailable) {
        updates.activateUpdate().then(() => {
          this.doc.location.reload();
        });

      }
    });

    //appRef.isStable.pipe(
    //  first(stable => stable),
    //  tap(stable => console.log('App is stable now')),
    //  //switchMap(() => interval(1000))
    //).subscribe();

    if (localStorage && !(!!localStorage.getItem("_cl_id"))) {
      localStorage.setItem("_cl_id", AppComponent.getNewGUID());
    }
    //console.log("-->", localStorage.getItem("_cl_id"));

    this._translate.setDefaultLang('en');

    const sub1 = this._router.events.pipe(
      tap((event) => {
        if (event instanceof NavigationStart) {

          this.store.pipe(
            select(getAppSettings),
            tap((appSettings) => {

              const routeLang: string = event.url.substring(1, 3);

              if (appSettings && appSettings.language != routeLang && appSettings.availableLangs && appSettings.availableLangs.find(lang => lang == routeLang)) {
                this._commonService.currentLanguage$.next(routeLang);
                this._translate.use(routeLang)
                this.store.dispatch(new SetAppSettingsLanguage(routeLang));
              }

              const urlTree = this._router.parseUrl(event.url);
              if (urlTree.queryParams.tc && localStorage !== undefined) {
                const expiry = new Date();
                expiry.setDate(expiry.getDate() + 15); // add 15 days
                localStorage.setItem("_re_tc", JSON.stringify({ code: urlTree.queryParams.tc, validUntil: expiry }));
              }
            }),
            first()
          ).subscribe();
        }
      }),
      first()
    ).subscribe();

    this.allSubs.add(sub1);
  }

  ngOnDestroy(): void {
    this.allSubs.unsubscribe();
  }

  private static S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };

  private static getNewGUID() {
    return (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substring(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase();
  };
}
