import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';

import { RezWidgetComponent } from './rez-widget.component';

import { TranslateModule } from '@ngx-translate/core';
import { CheckPluralModule } from 'src/app/shared/pipes/check-plural';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    CheckPluralModule
  ],
  declarations: [RezWidgetComponent],
  exports: [RezWidgetComponent]
})
export class RezWidgetModule { }
