import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { DATE_FORMAT_TYPES } from '../enums';

import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class TimeService {

  private static readonly months: { [lang: string]: string[] } = {
    "en": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "fr": [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ]
  };

   constructor() { }


  public static isValidDateTime = (datetime: string, format: string): boolean => {
    let result: boolean = false;
    if (datetime && datetime.length > 0) {
      const m = moment(datetime, format, true);
      result = !!m.isValid();
      const invalidAt = m.invalidAt();
      //if (!result && invalidAt >= 0) {
      //  this.log(LOG_TYPES.ERROR, datetime + " is not valid and it is invalid at " + invalidAt);
      //}
    }
    return result;
  };

  public static getListOfDaysInMonths(month: string, year: number, fullMonthIfEmpty: boolean = false, restrictToMonthPassed: boolean = false): string[] {

    let daysInMonth: number[] = TimeService.convertNumberToArray(moment(month + ' ' + year, "MM YYYY").daysInMonth());

    if (daysInMonth.length <= 0 && fullMonthIfEmpty) { // if empty set default list to full 31 days
      daysInMonth = TimeService.convertNumberToArray(31);
    }
    else if (restrictToMonthPassed) {

      const currentMonth = moment().format("YYYYMM");
      const selectedMonth = moment(year + month, "YYYYMM").format("YYYYMM");

      if (currentMonth === selectedMonth) {
        daysInMonth = daysInMonth.filter(x => x >= moment().date());
      }
    }

    const days: string[] = daysInMonth.map((x) => {
      return (x < 10) ? "0" + x : "" + x;
    });

    return days;
  };

  public static getMonthNames(lang: string): string[] {
    return this.months[lang && lang != "" ? lang : "en"];
  };

  public static getAllMonths(lang: string, year: number, restrictToMonthPassed: boolean = false): { monthCode: string, monthName: string }[] {
    const monthNames = this.getMonthNames(lang);

    if (year && year > 0 && restrictToMonthPassed) {
      let selectedDate = moment().year(year);
      return Object.keys(monthNames).filter(
        x => Number(selectedDate.month(Number(x)).format("YYYYMM")) >= Number(moment().format("YYYYMM"))
      ).map(
        (value) => {
          let val = (Number(value) + 1 < 10) ? "0" + (Number(value) + 1) : "" + (Number(value) + 1);
          return {
            monthCode: val,
            monthName: monthNames[value]
          }
        });
    }
    else {
      return Object.keys(monthNames).map(
        (value) => {
          let val = (Number(value) + 1 < 10) ? "0" + (Number(value) + 1) : "" + (Number(value) + 1);
          return {
            monthCode: val,
            monthName: monthNames[value]
          }
        });
    }
  };

  public static getCurrentDateTime(toFormat: string = "YYYYMMDDHHmm"): string {
    return moment().format(toFormat);
  };

  public static formatDateTime(datetime: string, format: string, locale = "en"): string {
    moment.locale(locale);
    if (!datetime || datetime == undefined || datetime == "")
      return "";
    return moment(datetime, "YYYYMMDDHHmm").format(format);
  };

  public static formatDateTimeExact = (datetime: string, fromFormat: string, toFormat: string): string => {
    if (!datetime || datetime == undefined || datetime == "")
      return "";

    return moment(datetime, fromFormat).format(toFormat);
  };

  public static formatDateTimeInISO(datetime: string, _tz?: string): string {
    if (!datetime || datetime == undefined || datetime == "")
      return "";

    //if (!_tz || _tz == undefined || _tz == "") {
    return moment(datetime, "YYYYMMDDHHmm").toISOString();
    //}
    //else {
    //  return moment.tz(moment(datetime, "YYYYMMDDHHmm").toDate(), _tz).toISOString();
    //}
  };

  public static formatDateTimeWithPredefined (datetime: string, formatType: DATE_FORMAT_TYPES, locale = "en"): string {
    moment.locale(locale);
    if (!datetime || datetime == undefined || datetime == "")
      return "";

    const format = ConfigService.dateTimeFormats.find(x => x.lang == locale && x.type == formatType).format;

    const date: string = moment(datetime, "YYYYMMDDHHmm").format(format);

    return date[0].toUpperCase() + date.substring(1);
  };

  public static formatTime24(time: string | number, format = "hh:mm A", toText = "-"): string {

    if (!time || time == undefined || time == "" || time == 0)
      return "";

    if ((typeof time) == "number" || (time as string).indexOf("-") == -1) {
      return moment(time, "HHmm").format(format);
    }
    else {
      const _time = time as string;
      if (_time.indexOf("-") > 0) {
        return moment(_time.split("-")[0], "HHmm").format(format) + " " + toText + " " + moment(_time.split("-")[1], "HHmm").format(format);
      }
    }

    return "invalid";
  };

  public static formatTime24ToAmPm (time: string | number, toText = "-"): string {

    if (!time || time == undefined || time == "" || time == 0)
      return "";

    if ((typeof time) == "number" || (time as string).indexOf("-") == -1) {
      return moment(time, "HHmm").format("hh:mm a");
    }
    else {
      const _time = time as string;
      if (_time.indexOf("-") > 0) {
        return moment(_time.split("-")[0], "HHmm").format("h:mm A") + " " + toText + " " + moment(_time.split("-")[1], "HHmm").format("h:mm A");
      }
    }

    return "invalid";
  };

  public static formatTimeTo24(time: string | number, toText: string = "-"): string {

    if (!time || time == undefined || time == "" || time == 0)
      return "";

    if ((typeof time) == "number" || (time as string).indexOf("-") == -1) {
      return moment(time, "HHmm").format("H:mm");
    }
    else {
      const _time = time as string;
      if (_time.indexOf("-") > 0) {
        return moment(_time.split("-")[0], "HHmm").format("H:mm") + " " + toText + " " + moment(_time.split("-")[1], "HHmm").format("H:mm");
      }
    }

    return "invalid";
  };

  public static formatTimeWithPredefined (time: string | number, formatType: DATE_FORMAT_TYPES, toText = "-", locale = "en"): string {
    moment.locale(locale);
    if (!time || time === undefined || time === "" || time === 0)
      return "";

    const format = ConfigService.dateTimeFormats.find(x => x.lang === locale && x.type === formatType).format;

    if ((typeof time) === "number" || (time as string).indexOf("-") === -1) {
      return moment(time, "HHmm").format(format);
    }
    else {
      const _time = time as string;
      if (_time.indexOf("-") > 0) {
        return moment(_time.split("-")[0], "HHmm").format(format) + " " + toText + " " + moment(_time.split("-")[1], "HHmm").format(format);
      }
    }

    return "invalid";
  };

  public static getDateTime(): Date {
    return moment().toDate();
  };

  public static getDateTimeString(): string {
    return moment().format("YYYYMMDDHHmm");
  };

  public static getNoOfDays(startDate: string, endDate: string): number {
    if (startDate === endDate) {
      return 0;
    }
    else {
      const pdt = moment(startDate, "YYYYMMDD");
      const rdt = moment(endDate, "YYYYMMDD");
      const noOfDays: number = rdt.diff(pdt, 'days');
      return noOfDays > 0 ? noOfDays : 1;
    }
  };


  /**
   * Return 12-hour format to 24-hour.
   *
   * @param  {Number} hours
   * @return {String}
   */
  public static getMilitaryHours(hours) {
    if (hours % 1 === 0.5) {
      return `${Math.floor(hours)}30`;
    }
    return `${Math.round(hours)}00`;
  };

  /**
   * Gets the duration between dates.
   *
   * @param  {String} startDate
   * @param  {String} endDate
   * @param  {Number} timezone
   * @return {String}
   */
  public static getHoursDuration(startDate: any, endDate: any, timezone = null) {
    var start = moment(startDate);
    var end = moment(endDate);

    if (timezone) {
      start.utcOffset(timezone);
      end.utcOffset(timezone);
    }

    var hours = moment
      .duration(end.diff(start))
      .asHours();

    return TimeService.getMilitaryHours(hours);
  };

  /**
   * Format time as a universal timestamp format w.r.t. the given timezone.
   *
   * @param  {String} timestamp timestamp as YYYYMMDDHHmm
   * @param  {String} timezone  tz offset (in minutes) (optional)
   * @return {String}
   */
  public static toUniversalTime(timestamp: any, timezone: any = null): string {
    let dt = moment(timestamp, "YYYYMMDDHHmm");

    if (timezone) {
      dt.utcOffset(timezone);
    }
    return dt.format('YYYYMMDDTHHmmss');
  };

  /**
   * Returns a universal timestamp of current time.
   *
   * @return {String}
   */
  public static getTimeCreated(): string {
    return moment().format('YYYYMMDDTHHmmss');
  };

  private static convertNumberToArray = (val: number): number[] => {
    if (val > 0) {
      let valArray: number[] = [];
      for (let i = 1; i <= val; i++)
        valArray.push(i);

      return valArray;
    }

    return [];
  };

}
