import { Reservation, ResponseStatus } from 'src/app/core/models';
import { REZ_ACTIONS, STORE_STATUS_CODES } from 'src/app/core/enums';

export interface IReservationState {
  readonly reservationSource: Reservation;
  readonly reservation: Reservation;
  readonly responseStatus: ResponseStatus;
  readonly statusCode: STORE_STATUS_CODES;
  readonly rezAction: REZ_ACTIONS;
  readonly processing: boolean;
  readonly processed: boolean;
}
