import { trigger, state, transition, animate, style, keyframes, query, stagger, group } from '@angular/animations';

const optional = { optional: true };
//export function animationEnabled(fromState: string, toState: string, element: any, params: { [key: string]: any }): boolean {
//  // notice that `element` and `params` are also available here
//  return !params.disabled; //toState == 'yes-please-animate';
//}

export const routeAnimation = trigger('routeAnimation', [

  transition('1 => 2, 2 => 3, 3 => 4, 1 => 3', [
    style({ height: '!' }),
    query(':enter', style({ transform: 'translateX(100%)' })),
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
    // animate the leave page away
    group([
      query(':leave', [
        animate('0.4s 0.1s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)' })),
      ]),
      // and now reveal the enter
      query(':enter', animate('0.4s 0.1s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' }))),
    ]),
  ]),
  transition('4 => 3, 3 => 2, 2 => 1, 3 => 1, 4 => 2', [
    style({ height: '!' }),
    query(':enter', style({ transform: 'translateX(-100%)' })),
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
    // animate the leave page away
    group([
      query(':leave', [
        animate('0.4s 0.1s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)' })),
      ]),
      // and now reveal the enter
      query(':enter', animate('0.4s 0.1s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' }))),
    ]),
  ])

]);

export let listAnimation = trigger('listAnimation', [
  transition("* <=> *", [
    group([
      query(':enter', [
        style({ opacity: 0 }),
        stagger(50, [
          animate('0.3s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))
        ])
      ], optional),
      // **** the following commented because it was causing an issue with some pages like if you navigate from rent-a-car page to login page
      //query(':leave', [ 
      //  stagger(50, [
      //    animate('300ms ease-out', keyframes([
      //      style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
      //      style({ opacity: .5, transform: 'translateY(0px)', offset: 0.3 }),
      //      style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
      //    ]))
      //  ])
      //], optional),
    ])
  ])
]);

export let fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [
    animate('300ms ease-in')
  ]),
  transition(':leave', [
    animate('300ms ease-out')
  ])
]);

export let fadeWithDelay = trigger('fadeWithDelay', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [
    animate('300ms 2s ease-in')
  ]),
  transition(':leave', [
    animate('300ms 2s ease-out')
  ])
]);

export let fadeInWithDelay = trigger('fadeInWithDelay', [
  state('void', style({ opacity: 0 })),
  transition(':enter', [
    animate('300ms 2s ease-in')
  ])
]);

export let fadeOutWithDelay = trigger('fadeOutWithDelay', [
  state('void', style({ opacity: 0 })),
  transition(':leave', [
    animate('300ms 2s ease-out')
  ])
]);

//export let fadeIn = trigger('fadeIn', [
//  state('void', style({ opacity: 0 })),
//  transition(':enter', [
//    animate('300ms ease-in')
//  ])
//]);

export let fadeOut = trigger('fadeOut', [
  state('void', style({ opacity: 0 })),
  transition(':leave', [
    animate('300ms ease-out')
  ])
]);

export let fadeInOut = trigger('fadeInOut', [
  state('off', style({ opacity: 0, display: 'none' })),
  state('on', style({ opacity: 1, display: 'block' })),
  transition('off => on', [
    animate('0.3s ease-in', keyframes([
      style({ opacity: 0, display: 'block' }),
      style({ opacity: 1 }),
    ]))
  ]),
  transition('on => off', [
    animate('0.3s ease-out', keyframes([
      style({ opacity: 1 }),
      style({ opacity: 1, display: 'none' }),
    ]))
  ])
]);

export let fadeIn = trigger('fadeIn', [
  state('off', style({ opacity: 0 })),
  state('on', style({ opacity: 1 })),
  transition('off => on', [
    animate('0.3s ease-in', keyframes([
      style({ opacity: 0 }),
      style({ opacity: 1 }),
    ]))
  ]),
  transition('void => *', [
    animate('0.3s ease-in', keyframes([
      style({ opacity: 0 }),
      style({ opacity: 1 }),
    ]))
  ])
]);

export let flyInOut = trigger('flyInOut', [
  state('out', style({ opacity: 0, display: 'none' })),
  state('in', style({ opacity: 1, display: 'block' })),
  state('leftOut', style({ opacity: 0, display: 'none' })),
  state('leftIn', style({ opacity: 1, display: 'block' })),
  transition('out => in', [
    animate('0.5s ease-out', keyframes([
      style({ opacity: 0, display: 'block', transform: 'translate3d(100%,0,0)', offset: 0 }),
      style({ opacity: 1, transform: 'translate3d(-15px,0,0)', offset: 0.5 }),
      style({ opacity: 1, transform: 'translate3d(0,0,0)', offset: 1.0 }),
    ]))
  ]),
  transition('in => out', [
    animate('0.5s ease-in', keyframes([
      style({ opacity: 1, transform: 'translate3d(0,0,0)', offset: 0 }),
      style({ opacity: 1, transform: 'translate3d(-15px,0,0)', offset: 0.5 }),
      style({ opacity: 0, display: 'none', transform: 'translate3d(100%,0,0)', offset: 1.0 })
    ]))
  ]),
  transition('leftOut => leftIn', [
    animate('0.5s ease-out', keyframes([
      style({ opacity: 0, display: 'block', transform: 'translate3d(-100%,0,0)', offset: 0 }),
      style({ opacity: 1, transform: 'translate3d(15px,0,0)', offset: 0.5 }),
      style({ opacity: 1, transform: 'translate3d(0,0,0)', offset: 1.0 }),
    ]))
  ]),
  transition('leftIn => leftOut', [
    animate('0.5s 0.5s ease-in', keyframes([
      style({ opacity: 1, transform: 'translate3d(0,0,0)', offset: 0 }),
      style({ opacity: 1, transform: 'translate3d(15px,0,0)', offset: 0.5 }),
      style({ opacity: 0, display: 'none', transform: 'translate3d(-100%,0,0)', offset: 1.0 })
    ]))
  ]),
  transition('void => *', [
    animate('0.5s ease-out', keyframes([
      style({ opacity: 0, display: 'block', transform: 'translateX(100%)', offset: 0 }),
      style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.3 }),
      style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
    ]))
  ]),
  transition('* => void', [
    animate('0.5s ease-in', keyframes([
      style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
      style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
      style({ opacity: 0, display: 'none', transform: 'translateX(100%)', offset: 1.0 })
    ]))
  ])
]);

export let slideUpDown = trigger('slideUpDown', [
  state('up', style({ opacity: 0, display: 'none' })),
  state('down', style({ opacity: 1, display: 'block' })),
  transition('up => down, void => down', [
    animate('0.2s ease-out', keyframes([
      style({ opacity: 0, display: 'block', transform: 'translate3d(0, -120%, 0)', offset: 0 }),
      style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 1.0 })
    ]))
  ]),
  transition('down => up, down => void', [
    animate('0.2s ease-in', keyframes([
      style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 0 }),
      style({ opacity: 0, display: 'none', transform: 'translate3d(0, -120%, 0)', offset: 1.0 })
    ]))
  ])
]);

export let flash = trigger('flash', [
  transition('* => flash', [
    animate('0.5s ease-in', keyframes([
      style({ opacity: 1, offset: 0 }),
      style({ opacity: 0, offset: 0.25 }),
      style({ opacity: 1, offset: 0.5 }),
      style({ opacity: 0, offset: 0.75 }),
      style({ opacity: 1, offset: 1 })
    ]))
  ])
]);

export let pulse = trigger('pulse', [
  transition('* => pulse', [
    animate('0.5s ease-in', keyframes([
      style({ transform: 'scale3d(1, 1, 1)', offset: 0 }),
      style({ transform: 'scale3d(1.05, 1.05, 1.05)', offset: 0.5 }),
      style({ transform: 'scale3d(1, 1, 1)', offset: 1.0 })
    ]))
  ])
]);

export let shakeHorizontal = trigger('shakeHorizontal', [
  transition('* => shake', [
    animate('0.8s ease-in', keyframes([
      style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.1 }),
      style({ transform: 'translate3d(10px, 0, 0)', offset: 0.2 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.3 }),
      style({ transform: 'translate3d(10px, 0, 0)', offset: 0.4 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.5 }),
      style({ transform: 'translate3d(10px, 0, 0)', offset: 0.6 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.7 }),
      style({ transform: 'translate3d(10px, 0, 0)', offset: 0.8 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.9 }),
      style({ transform: 'translate3d(0, 0, 0)', offset: 1 })
    ]))
  ])
]);

export let shakeVertical = trigger('shakeVertical', [
  transition('* => shake', [
    animate('0.8s ease-in', keyframes([
      style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
      style({ transform: 'translate3d(0,-10px, 0)', offset: 0.1 }),
      style({ transform: 'translate3d(0, 10px, 0)', offset: 0.2 }),
      style({ transform: 'translate3d(0,-10px, 0)', offset: 0.3 }),
      style({ transform: 'translate3d(0, 10px, 0)', offset: 0.4 }),
      style({ transform: 'translate3d(0,-10px, 0)', offset: 0.5 }),
      style({ transform: 'translate3d(0, 10px, 0)', offset: 0.6 }),
      style({ transform: 'translate3d(0,-10px, 0)', offset: 0.7 }),
      style({ transform: 'translate3d(0, 10px, 0)', offset: 0.8 }),
      style({ transform: 'translate3d(0,-10px, 0)', offset: 0.9 }),
      style({ transform: 'translate3d(0, 0, 0)', offset: 1 })
    ]))
  ])
]);

export let bounceInRight = trigger('bounceInRight', [
  transition('* => bounce', [
    animate('0.8s', keyframes([
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: 0, transform: 'translate3d(100px, 0,  0)', offset: 0 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: 1, transform: 'translate3d(-25px, 0, 0)', offset: .6 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', transform: 'translate3d(10px, 0, 0)', offset: .75 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', transform: 'translate3d(-5px, 0, 0)', offset: .9 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', transform: 'none', offset: 1 }),
    ]))
  ])
]);

export let bounceInleft = trigger('bounceInleft', [
  transition('void => bounce', [
    animate('0.5s', keyframes([
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: 0, transform: 'translate3d(-100px, 0,  0)', offset: 0 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', opacity: 1, transform: 'translate3d(25px, 0, 0)', offset: .6 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', transform: 'translate3d(-10px, 0, 0)', offset: .75 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', transform: 'translate3d(5px, 0, 0)', offset: .9 }),
      style({ animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', transform: 'none', offset: 1 })
    ]))
  ])
]);

export let rotateIn = trigger('rotateIn', [
  state('*', style({ opacity: 0 })),
  state('rotate', style({ opacity: 1 })),
  transition('* => rotate', [
    animate('1s ease-out', keyframes([
      style({ opacity: 0, transformOrigin: 'center', transform: 'rotate3d(0, 0, 1, -180deg)' }),
      style({ opacity: 1, transformOrigin: 'center', transform: 'translate3d(0, 0, 0)' })
    ]))
  ])
]);

export let tada = trigger('tada', [
  transition('* => tada', [
    animate('1s', keyframes([
      style({ transform: 'scale3d(1, 1, 1)', offset: 0 }),
      style({ transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)', offset: .1 }),
      style({ transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)', offset: .2 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)', offset: .3 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)', offset: .4 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)', offset: .5 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)', offset: .6 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)', offset: .7 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)', offset: .8 }),
      style({ transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)', offset: .9 }),
      style({ transform: 'scale3d(1, 1, 1)', offset: 1 })
    ]))
  ])
]);

export let flipInY = trigger('flipInY', [
  state('*', style({ opacity: 0 })),
  state('flip', style({ backfaceVisibility: 'visible !important' })),
  transition('* => flip', [
    animate('1s', keyframes([
      style({ opacity: 0, transform: 'perspective(600px) rotate3d(0, 1, 0, 90deg)', animationTimingFunction: 'ease-in', offset: 0 }),
      style({ transform: 'perspective(600px) rotate3d(0, 1, 0, -20deg)', animationTimingFunction: 'ease-in', offset: .4 }),
      style({ opacity: 1, transform: 'perspective(600px) rotate3d(1, 0, 0, 10deg)', offset: .6 }),
      style({ transform: 'perspective(600px) rotate3d(0, 1, 0, -5deg)', offset: .8 }),
      style({ transform: 'perspective(600px)', offset: 1 })
    ]))
  ])
]);

export const zoomOut = trigger('zoomOut', [
  transition('* => zoomout', [
    animate('0.5s', keyframes([
      style({ opacity: 1, offset: 0 }),
      style({ opacity: 0, transform: 'scale3d(.3, .3, .3)', offset: .5 }),
      style({ opacity: 0, offset: 1 }),
    ]))
  ])
]);
