<div *ngIf="showRezNav" class="rez-nav hidden-xs">
  <div class="step" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span>
      <span class="icon">1</span>
      <a *ngIf="!confirmationLink.isActive" [routerLink]="'rez-nav.step1-link' | translate:{vehicleType:vehicleType}" (click)="linkClicked()">{{'rez-nav.step1-text' | translate}}</a>
      <span *ngIf="confirmationLink.isActive">{{'rez-nav.step1-text' | translate}}</span>
    </span>
  </div>
  <div class="step" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span>
      <span class="icon">2</span>
      <a *ngIf="!confirmationLink.isActive" [routerLink]="'rez-nav.step2-link' | translate" (click)="linkClicked()">{{'rez-nav.step2-text' | translate}}</a>
      <span *ngIf="confirmationLink.isActive">{{'rez-nav.step2-text' | translate}}</span>
    </span>
  </div>
  <div class="step" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span>
      <span class="icon">3</span>
      <a *ngIf="!confirmationLink.isActive" [routerLink]="'rez-nav.step3-link' | translate" (click)="linkClicked()">{{'rez-nav.step3-text' | translate}}</a>
      <span *ngIf="confirmationLink.isActive">{{'rez-nav.step3-text' | translate}}</span>
    </span>
  </div>
  <div class="step" #confirmationLink="routerLinkActive" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span>
      <span class="icon">4</span>
      <!--<a [routerLink]="'rez-nav.step4-link' | translate">{{'rez-nav.step4-text' | translate}}</a>-->
      <span>{{'rez-nav.step4-text' | translate}}</span>
    </span>
  </div>
</div>

<div *ngIf="showRezNav" class="rez-nav-mobile hidden-lg hidden-md hidden-sm">
  <div class="step" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span class="icon">1</span>
    <a *ngIf="!confirmationLink.isActive" [routerLink]="'rez-nav.step1-link' | translate:{vehicleType:vehicleType}" (click)="linkClicked()"><span class="number">1.</span>{{'common.vehicle' | translate}}</a>
    <span *ngIf="confirmationLink.isActive"><span class="number">1.</span>{{'common.vehicle' | translate}}</span>
    <i class="fas fa-chevron-right" aria-hidden="true"></i>
  </div>
  <div class="step" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span class="icon">2</span>
    <a *ngIf="!confirmationLink.isActive" [routerLink]="'rez-nav.step2-link' | translate" (click)="linkClicked()"><span class="number">2.</span>{{'common.rate' | translate | titlecase }}</a>
    <span *ngIf="confirmationLink.isActive"><span class="number">2.</span>{{'common.rate' | translate | titlecase }}</span>
    <i class="fas fa-chevron-right" aria-hidden="true"></i>
  </div>
  <div class="step" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span class="icon">3</span>
    <a *ngIf="!confirmationLink.isActive" [routerLink]="'rez-nav.step3-link' | translate" (click)="linkClicked()"><span class="number">3.</span>{{'common.driver' | translate}}</a>
    <span *ngIf="confirmationLink.isActive"><span class="number">3.</span>{{'common.driver' | translate}}</span>
    <i class="fas fa-chevron-right" aria-hidden="true"></i>
  </div>
  <div class="step" #confirmationLink="routerLinkActive" routerLinkActive="active" [routerLinkActiveOptions]="rlao">
    <span>
      <span class="icon">4</span>
      <!--<a [routerLink]="'rez-nav.step4-link' | translate">{{'rez-nav.step4-text' | translate}}</a>-->
      <span><span class="number">4.</span>{{'common.confirm' | translate}}</span>
    </span>
  </div>
</div>
