import { Injectable } from '@angular/core';

import { Session } from '../models/session.model';
import { Observable, of } from 'rxjs';

import { ConfigService } from './config.service';

@Injectable()
export class SessionService {

  constructor(private _configService: ConfigService) {
  }

  getSessionId = (): string => {
    const session = this.getSession();
    if (session) {
      return session.id;
    }
    return "";
  };

  getSession = (): Session => {

    if (this.isSessionValid())
      return JSON.parse(sessionStorage.getItem('_gc_session'));
    else {
      this.setSession(null);
      return null;
    }

  };

  getSessionAsObservable = (): Observable<Session> => {
    if (this.isSessionValid())
      return of(JSON.parse(sessionStorage.getItem('_gc_session')));
    else {
      this.setSession(null);
      return of(null);
    }
  };

  setSession = (session: Session): boolean => {
    if (session) {
      sessionStorage.setItem('_gc_session', JSON.stringify({ ...session, modifiedOn: new Date() }));
      return true;
    }
    else {
      return this.removeSession();
    }
  };

  removeSession = (): boolean => {
    sessionStorage.removeItem('_gc_session');
    return false;
  };

  isSessionValid = (): boolean => {
    const session: Session = JSON.parse(sessionStorage.getItem('_gc_session'));

    if (session && session.modifiedOn) {
      const expiry = new Date(new Date(session.modifiedOn).getTime() + (this._configService.SessionTimeout * 60000));

      if (expiry >= new Date())
        return true;
      else {
        return false;
      }
    }
    else
      return false;
  };
}
