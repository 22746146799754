import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ServiceResult, RecommendOfferDto } from '../models';

import { ConfigService } from "./config.service";

@Injectable()
export class RecommendationEmailService {

  constructor(private _configService: ConfigService, private http: HttpClient) {
  }

  fetchRecommendationEmail(id: string): Observable<ServiceResult> {
    const url = this._configService.getRecommendApiUrl(`/${id}`);

    return this.http.get<ServiceResult>(url)
      .pipe(
        catchError(e => throwError(() => e))
      );
  }

  SendRecommendationEmail = (offerDto: RecommendOfferDto): Observable<ServiceResult> => {

    const url = this._configService.getRecommendApiUrl();

    return this.http.post<ServiceResult>(url, offerDto)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };
}
