import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RatesResult, RateDto, RateParams, RateExtra, TaxDto, ServiceResult, ShortRate, ShortRateExtra, VehicleDto, CurrencyRates } from '../models';

import { CommonService, ConfigService, CurrencyService, RateExtraService , TimeService } from ".";


@Injectable()
export class RateService {

  constructor(
    private _configService: ConfigService,
    private _commonService: CommonService,
    private _rateExtraService: RateExtraService,
    private http: HttpClient) {
  }

  fetchRates = (rParams: RateParams): Observable<ServiceResult> => {

    const url = this._configService.getRentalRatesApiUrl();

    return this.http.post<ServiceResult>(url, rParams)
      .pipe(
        catchError(e => throwError(() => e))
      );
  };

  convertCurrency4VehiclesStartingPrice = (currencyRates: CurrencyRates, vehicles: VehicleDto[], baseCurrency: string, selectedCurrency: string): VehicleDto[] => {

    let result: VehicleDto[] = [];

    if (vehicles && vehicles.length > 0) {
      vehicles = JSON.parse(JSON.stringify(vehicles));

      for (let i = 0; i < vehicles.length; i++) {

        if (vehicles[i].startingPrice) {
          vehicles[i].startingPrice.monthly = CurrencyService.convertAmount(currencyRates, vehicles[i].startingPrice.monthly, baseCurrency, selectedCurrency);
          vehicles[i].startingPrice.weekly = CurrencyService.convertAmount(currencyRates, vehicles[i].startingPrice.weekly, baseCurrency, selectedCurrency);
          vehicles[i].startingPrice.daily = CurrencyService.convertAmount(currencyRates, vehicles[i].startingPrice.daily, baseCurrency, selectedCurrency);
          vehicles[i].startingPrice.hourly = CurrencyService.convertAmount(currencyRates, vehicles[i].startingPrice.hourly, baseCurrency, selectedCurrency);
        }

      }

      result = vehicles;
    }

    return result;
  };

  convertRatesCurrency = (currencyRates: CurrencyRates, ratesRex: RatesResult, selectedCurrency: string): RatesResult => {

    let _ratesResult = null;

    if (ratesRex) {
      ratesRex = JSON.parse(JSON.stringify(ratesRex));

      const availableRates = ratesRex.availableRates;
      for (let i = 0; i < availableRates.length; i++) {
        if (availableRates[i] && ratesRex.baseCurrency !== selectedCurrency)
          availableRates[i] = this.convertRateCurrency(currencyRates, availableRates[i], availableRates[i], ratesRex.baseCurrency, selectedCurrency) as RateDto;
      }

      const limitedAvailableRates = ratesRex.limitedAvailableRates;
      for (let i = 0; i < limitedAvailableRates.length; i++) {
        const vehicle = limitedAvailableRates[i];

        if (vehicle && ratesRex.baseCurrency !== selectedCurrency) {
          vehicle.startingPrice.hourly = CurrencyService.convertAmount(currencyRates, vehicle.startingPrice.hourly, ratesRex.baseCurrency, selectedCurrency);
          vehicle.startingPrice.daily = CurrencyService.convertAmount(currencyRates, vehicle.startingPrice.daily, ratesRex.baseCurrency, selectedCurrency);
          vehicle.startingPrice.weekly = CurrencyService.convertAmount(currencyRates, vehicle.startingPrice.weekly, ratesRex.baseCurrency, selectedCurrency);
          vehicle.startingPrice.monthly = CurrencyService.convertAmount(currencyRates, vehicle.startingPrice.monthly, ratesRex.baseCurrency, selectedCurrency);
        }
      }

      _ratesResult = ratesRex;
    }

    return _ratesResult;
  };

  convertRateCurrency(currencyRates: CurrencyRates, fromRate: RateDto | ShortRate, toRate: RateDto | ShortRate, fromCurrency: string, toCurrency: string): RateDto | ShortRate {

    fromRate = JSON.parse(JSON.stringify(fromRate));
    toRate = JSON.parse(JSON.stringify(toRate));

    if (toRate.rateDetail) {
      toRate.rateDetail.ratePerMonth = CurrencyService.convertAmount(currencyRates, fromRate.rateDetail.ratePerMonth, fromCurrency, toCurrency);
      toRate.rateDetail.ratePerWeek = CurrencyService.convertAmount(currencyRates, fromRate.rateDetail.ratePerWeek, fromCurrency, toCurrency);
      toRate.rateDetail.ratePerDay = CurrencyService.convertAmount(currencyRates, fromRate.rateDetail.ratePerDay, fromCurrency, toCurrency);
      toRate.rateDetail.ratePerHour = CurrencyService.convertAmount(currencyRates, fromRate.rateDetail.ratePerHour, fromCurrency, toCurrency);
      toRate.rateDetail.perMileRate = CurrencyService.convertAmount(currencyRates, fromRate.rateDetail.perMileRate, fromCurrency, toCurrency);
    }

    if (toRate.extraRates) {
      for (const element of toRate.extraRates) {
        const _extra = fromRate.extraRates.find(function (ext) { return ext.code === element.code });

        if (_extra) {
          element.unitPrice = CurrencyService.convertAmount(currencyRates, _extra.unitPrice, fromCurrency, toCurrency);
          element.totalCharge = CurrencyService.convertAmount(currencyRates, _extra.totalCharge, fromCurrency, toCurrency);
        }
      }
    }

    if (toRate.taxes) {
      for (const element of toRate.taxes) {
        const _tax = fromRate.taxes.find(function (t) { return t.code === element.code });
        if (_tax)
          element.totalCharge = CurrencyService.convertAmount(currencyRates, _tax.totalCharge, fromCurrency, toCurrency);
      }
    }

    if (toRate.rateSummary) {
      toRate.rateSummary.rateBeforeTaxes = CurrencyService.convertAmount(currencyRates, fromRate.rateSummary.rateBeforeTaxes, fromCurrency, toCurrency);
      toRate.rateSummary.totalDiscount = CurrencyService.convertAmount(currencyRates, fromRate.rateSummary.totalDiscount, fromCurrency, toCurrency);
      toRate.rateSummary.totalExtras = CurrencyService.convertAmount(currencyRates, fromRate.rateSummary.totalExtras, fromCurrency, toCurrency);
      toRate.rateSummary.totalTaxes = CurrencyService.convertAmount(currencyRates, fromRate.rateSummary.totalTaxes, fromCurrency, toCurrency);
      toRate.rateSummary.totalCharges = CurrencyService.convertAmount(currencyRates, fromRate.rateSummary.totalCharges, fromCurrency, toCurrency);
    }

    return toRate;
  }

  getUpdatedRate = (selectedRate: RateDto | ShortRate, selectedExtras: RateExtra[]): RateDto | ShortRate => {

    const _selectedExtras = [...selectedExtras.filter(x => x.autoChecked)];

    const extrasSubTotal = this._rateExtraService.calcAllExtrasTotalCharge(_selectedExtras);

    const taxableAmount = selectedRate.rateSummary.rateBeforeTaxes - Math.abs(selectedRate.rateSummary.totalDiscount) + extrasSubTotal;

    const rateTaxes = this.calcRateTaxes(selectedRate.taxes, taxableAmount);

    const extraMiles = this._rateExtraService.calcExtraMileage4Rate(_selectedExtras, selectedRate.totalFreeMiles, selectedRate.vehicle.mileageCap, selectedRate.rateDetail.rentalMonths, selectedRate.vehicle.mileageUnit);

    const extras = _selectedExtras.reduce((extras: ShortRateExtra[], extra) => {
      const shortExtras = this._rateExtraService.transformExtra2ShortExtras(extra, selectedRate.rateDetail);

      return [...extras, ...shortExtras];

    }, []);

    const _rate: RateDto | ShortRate = {
      ...selectedRate,
      taxes: rateTaxes.taxes,
      extraRates: extras,//seems like should pass all extras without filtering //selectedRate["id"] ? extras.filter(x => x.groupName.toLowerCase() == 'rate') : extras, // selectedRate["id"] does not exist in ShortRate
      rateSummary: {
        ...selectedRate.rateSummary,
        totalExtras: extrasSubTotal,
        totalTaxes: rateTaxes.taxesTotalCharge,
        totalCharges: Number((taxableAmount + rateTaxes.taxesTotalCharge).toFixed(2))
      },
      totalMilesIncluded: extraMiles.totalExtraMiles,
      totalMilesIncludedDisplay: extraMiles.totalMilesIncludedDisplay
    };

    return _rate;
  };

  reCalcRate = (rate: RateDto): RateDto => {

    const extrasTotalCharge = this._rateExtraService.calcShortRateExtrasTotalCharge(rate.extraRates);

    const taxableAmount = rate.rateSummary.rateBeforeTaxes - Math.abs(rate.rateSummary.totalDiscount) + extrasTotalCharge;

    const rateTaxes = this.calcRateTaxes(rate.taxes, taxableAmount);

    return {
      ...rate,
      rateSummary: {
        ...rate.rateSummary,
        totalExtras: extrasTotalCharge,
        totalTaxes: rateTaxes.taxesTotalCharge,
        totalCharges: Number((taxableAmount + rateTaxes.taxesTotalCharge).toFixed(2))
      }
    };
  };

  calcRateTaxes = (taxes: TaxDto[], taxableAmount: number): { taxesTotalCharge: number; taxes: TaxDto[] } => {

    let _taxes: TaxDto[] = [];

    let rateTaxesTotalCharge = 0.0;
    const rateTaxes = taxes.filter(x => x.groupName === 'rate');
    for (let i = 0; i < rateTaxes.length; i++) {
      let taxCharge = 0.0;

      if (rateTaxes[i].chargeType === "%")
        taxCharge = Number((taxableAmount * (rateTaxes[i].taxRate / 100)).toFixed(2));
      else
        taxCharge = Number(rateTaxes[i].taxRate.toFixed(2));

      rateTaxesTotalCharge += taxCharge;

      _taxes = [
        ..._taxes,
        {
          ...rateTaxes[i],
          totalCharge: taxCharge
        }
      ];

    }

    let taxesTotalCharge: number = rateTaxesTotalCharge;
    const otherTaxes = taxes.filter(x => x.groupName.toLowerCase() !== 'rate');
    for (let i = 0; i < otherTaxes.length; i++) {
      let taxCharge = 0.0;

      if (otherTaxes[i].chargeType === "%")
        taxCharge = Number(((taxableAmount + rateTaxesTotalCharge) * (otherTaxes[i].taxRate / 100)).toFixed(2));
      else
        taxCharge = Number(otherTaxes[i].taxRate.toFixed(2));

      taxesTotalCharge += taxCharge;

      _taxes = [
        ..._taxes,
        {
          ...otherTaxes[i],
          totalCharge: taxCharge
        }
      ];
    }

    return { taxesTotalCharge: Number(taxesTotalCharge.toFixed(2)), taxes: _taxes };
  };

  getChargeablePrice = (selectedRate: RateDto | ShortRate, selectedExtras: RateExtra[]): number => {
    const extrasSubTotal = this._rateExtraService.calcAllExtrasTotalCharge(selectedExtras);
    const taxableAmount = selectedRate.rateSummary.rateBeforeTaxes + extrasSubTotal;
    const rateTaxes = this.calcRateTaxes(selectedRate.taxes, taxableAmount);
    return Number((taxableAmount + rateTaxes.taxesTotalCharge - Math.abs(selectedRate.rateSummary.totalDiscount)).toFixed(2));
  };

  getUpdatedExtras = (rate: RateDto, allExtras: RateExtra[], selectedExtras: RateExtra[]) => {

    rate = JSON.parse(JSON.stringify(rate)) as RateDto;
    allExtras = JSON.parse(JSON.stringify(allExtras)) as RateExtra[];
    selectedExtras = JSON.parse(JSON.stringify(selectedExtras)) as RateExtra[];

    const rateExtras = rate.extraRates.reduce((rateExtras: RateExtra[], extra: ShortRateExtra) => {

      let _extra: RateExtra = selectedExtras.find(x => x.code === extra.code);

      if (!_extra || _extra === null) {
        const __ext = allExtras.find(x => x.code === extra.code);
        if (__ext) {
          _extra = {
            ...__ext,
            totalCharge: this._rateExtraService.calcExtraTotalCharge(__ext, true, rate.rateDetail),
            totalChargeChildren: this._rateExtraService.calcExtrasChildrenTotalCharge(__ext, rate.rateDetail)
          };
        }
      }

      if (_extra) {
        return [...rateExtras, _extra];
      }
      else {
        return [...rateExtras];
      }

    }, []);

    const updatedExtras = selectedExtras.reduce((updatedExtras: RateExtra[], extra: RateExtra) => {

      if (!updatedExtras.find(x => x.code === extra.code) && !updatedExtras.find(x => !!x.cumulative.find(y => y === extra.code)))
        return [...updatedExtras, ...this._rateExtraService.applyExtraValidation(extra, allExtras, rate.rateDetail, rate.vehicle.mileageCap, rate.totalFreeMiles)];
      else
        return [...updatedExtras];
    }, [...rateExtras]);

    return updatedExtras;
  };
}
