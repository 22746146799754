import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { SessionService, CommonService } from 'src/app/core/services';

import { Store, select } from '@ngrx/store';
import { State, getRateParams } from 'src/app/store';

@Component({
  selector: 'rez-nav',
  templateUrl: './rez-nav.component.html',
  styleUrls: ['./rez-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RezNavComponent implements OnInit, OnDestroy {

  private subs: Subscription = new Subscription();

  @Input() showRezNav: boolean = false;

  rlao: any = { exact: false };

  vehicleType: string = "car";

  constructor(
    private _commonService: CommonService,
    private _translate: TranslateService,
    private _route: ActivatedRoute,
    private _sessionService: SessionService,
    private store: Store<State>,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.setVehicleType();

    const subs1 = this._translate.onLangChange.subscribe((x) => {
      this.setVehicleType();
      this.rlao = { exact: false }; // workaround for updating routerLinkActive (https://github.com/angular/angular/issues/13865)
    });

    const subs2 = this.store.pipe(
      select(getRateParams),
      tap((params) => {
        if (params && params.vehicleType != this.vehicleType) {
          this.setVehicleType();
          this.rlao = { exact: false }; // workaround for updating routerLinkActive (https://github.com/angular/angular/issues/13865)
        }
      })
    ).subscribe();

    this.subs.add(subs1);
    this.subs.add(subs2);
  }

  setVehicleType = () => {
    const session = this._sessionService.getSession();
    const vehicleTypeFromSession = session && session.rateParams && session.rateParams.vehicleType ? session.rateParams.vehicleType : "";

    const vehTypeKey: string = this._route && this._route.snapshot &&
      this._route.snapshot.firstChild &&
      this._route.snapshot.firstChild.data.vehicletype ?
      "common." + this._route.snapshot.firstChild.data.vehicletype.toLowerCase() :
      vehicleTypeFromSession != "" ? "common." + vehicleTypeFromSession.toLowerCase() : "common.car";

    this._translate.get(vehTypeKey).pipe(
      tap((type: string) => {
        this.vehicleType = type.toLowerCase();
        this.cd.markForCheck();
      }),
      first()
    ).subscribe();
  };

  linkClicked = () => {
    const session = this._sessionService.getSession();
    if (session.rateParams?.pickupBranch) {
      this._commonService.collapseWidget$.next(true);
    }
  };

  ngOnDestroy(): void {
    if (this.subs)
      this.subs.unsubscribe();
  }
}
