import { select, Store } from "@ngrx/store";
import { filter, Observable, of, switchMap, take, tap } from "rxjs";
import { STORE_STATUS_CODES } from "../../core/enums";
import { getCurrentUserLoadState, loadCurrentUserInfo, State } from "../../store";

export const loadUserInfo$ = (store: Store<State>): Observable<boolean> => {
  return store.pipe(
    select(getCurrentUserLoadState),
    tap((userState) => {
      if (!userState.processed && userState.statusCode !== STORE_STATUS_CODES.FAILED) {
        store.dispatch(loadCurrentUserInfo());
      }
    }),
    filter(userState => userState.processed || userState.statusCode === STORE_STATUS_CODES.FAILED),
    take(1),
    switchMap((userState) => {
      if (userState.statusCode === STORE_STATUS_CODES.FAILED) {
        return of(false);
      }
      else {
        return of(userState.processed);
      }
    })
  );
};
