import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, HostListener, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { routeAnimation } from '../shared/animations/animations';

import { Subscription, Observable, of as ObservableOf, timer, interval } from 'rxjs';
import { first, tap, filter, take, switchMap, map, delayWhen } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
  State,
  getAppSettings,
  SetAppSettingsLanguage,
  SetAppSettingsBaseCurrency,
  LoadCurrencyRates,
  ResetRates,
  ResetExtras,
  getRatesLoaded,
  logoutUser
} from '../store';


import { PAGES } from '../core/enums';
import { AppSettings } from '../core/models';

import { WINDOW } from '../core/providers/window.provider';

import { CommonService, ConfigService, AuthenticationService, SeoService, SessionService } from '../core/services';

import { SessionExpiredDialogComponent, NotifyBottomComponent } from '../components';

declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './full-layout.component.html',
  animations: [routeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  appSettings$: Observable<AppSettings>;

  showWidget: boolean = false;
  showRezNav: boolean = false;

  currentUrl: string = "";

  private noIndexRoutes = [
    ...this._commonService.getAllRoutesByKey(
      [
        PAGES.VEHICLE_EXTRAS,
        PAGES.CUSTOMER_DETAILS,
        PAGES.CONFIRMATION,
        PAGES.CHECK_IN,
        PAGES.PASSWORD_RESET,
        PAGES.MY_PROFILE,
        PAGES.CHANGE_PASSWORD,
        PAGES.VERIFY_EMAIL,
        PAGES.VIEW_RECOMMENDATION_EMAIL,
        PAGES.VIEW_CONFIRMATION_EMAIL,
        PAGES.VIEW_CANCELLATION_EMAIL,
        PAGES.ERROR_404,
        PAGES.ERROR_500,
        PAGES.SESSION_EXPIRED,
        PAGES.PAGE_NOT_FOUND
      ]
    )
  ];

  constructor(
    @Inject(WINDOW) private window: Window,
    private _commonService: CommonService,
    private _configService: ConfigService,
    private _seoService: SeoService,
    private _sessionService: SessionService,
    private _authService: AuthenticationService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private store: Store<State>) {

    //const GA_TRACKING_ID: string = 'UA-54159351-1';
    //(<any>window).dataLayer = (<any>window).dataLayer || [];

    const routeEventSubs = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        //(<any>window).ga('set', 'page', event.urlAfterRedirects);
        //(<any>window).ga('send', 'pageview');

        //this.gtag('js', new Date());

        //this.gtag('config', GA_TRACKING_ID, {
        //  'page_path': event.urlAfterRedirects
        //});

        if (event.url) {
          this._commonService.previousRoute = this.currentUrl;
          this.currentUrl = event.url;
        }

        const validUrl = this._commonService.isValidUrlForWidget(event.urlAfterRedirects);

        this.showWidget = validUrl;
        this.showRezNav = validUrl;

        this.setMetaTags(event.url);
      }
    });

    this.allSubs.add(routeEventSubs);

    this._commonService.sessionObs.next(this._sessionService.isSessionValid());
  }

  //private gtag(arg1?, arg2?, arg3?) { (<any>window).dataLayer.push(arguments); }

  ngOnInit(): void {
    this.setDeviceType();

    //this.setupLoader();

    this.loadAppSettings();

    this.setWidgetDisplay();

    this.setupUserLoginRefresh();

    this.setupSessionTimeoutDialog();

    this.setupSnackBar();

    this.setupBottomSheet();

    //this.setupCollapseWidget();
  }

  private setMetaTags = (url: string) => {
    if (this._configService.IsProduction) {
      if (this.noIndexRoutes.find(x => x.indexOf(url) >= 0)) {
        this._seoService.createMetaTag({ name: "robots", content: "noindex, nofollow" });
      }
      else {
        this._seoService.removeMetaTag('name = "robots"');
      }
    }
  };

  private setDeviceType = () => {
    if (this.isBreakpoint('sm') || this.isBreakpoint('xs'))
      this._commonService.IsMobileDevice = true;
    else
      this._commonService.IsMobileDevice = false;

    this._commonService.deviceBreakpoint = this.isBreakpoint('xs') ? 'xs' : this.isBreakpoint('sm') ? 'sm' : this.isBreakpoint('md') ? 'md' : this.isBreakpoint('lg') ? 'lg' : '';
  };

  //private setupLoader = () => {

  //  const loaderSubs = combineLatest([
  //    this.store.pipe(select(getBranchesLoading)),
  //    this.store.pipe(select(getVehiclesLoading)),
  //    this.store.pipe(select(getRatesLoading)),
  //    this.store.pipe(select(getRatesUpdatingStatus)),
  //    this.store.pipe(select(getExtrasLoading)),
  //    this.store.pipe(select(getReservationProcessing))
  //    ],
  //    (brLoading, veLoading, raLoading, raUpdating, exLoading, rzProcessing) => {
  //      const url = this._router.url;
  //      const url2 = this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, this._route.snapshot.firstChild.params.lang);
  //      const url3 = this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_TRUCK, this._route.snapshot.firstChild.params.lang);
  //      const url4 = this._commonService.getTranslatedRouteByKey(PAGES.CUSTOMER_DETAILS, this._route.snapshot.firstChild.params.lang);

  //      if ((raLoading && (url.indexOf(url2) >= 0 || url.indexOf(url3) >= 0)) || rzProcessing && url.indexOf(url4) >= 0) {
  //        return {
  //          loading: false,
  //          immediateClose: true
  //        };
  //      }
  //      else {
  //        return {
  //          loading: brLoading || veLoading || raLoading || raUpdating || exLoading || rzProcessing,
  //          immediateClose: false
  //        };
  //      }
  //    }
  //  ).pipe(
  //    switchMap((args) => {
  //      if (args.loading) {
  //        return ObservableOf(true);
  //      }
  //      else {
  //        if (args.immediateClose) {
  //          return ObservableOf(false);
  //        }
  //        else {
  //          return ObservableOf(args.loading).pipe(
  //            delay(1000),
  //            map(loading => loading)
  //          );
  //        }
  //      }
  //    })
  //  ).subscribe((loading) => {
  //    if (loading) {
  //      this._loaderService.show();
  //    }
  //    else
  //      this._loaderService.hide();
  //  });

  //  this.allSubs.add(loaderSubs);

  //};

  private loadAppSettings = () => {
    this.store.pipe(
      select(getAppSettings),
      first(x => x && x.language && x.language != ""),
      tap((appSettings) => {
        if (appSettings.baseCurrency && appSettings.baseCurrency != "") {
          this.store.dispatch(new SetAppSettingsBaseCurrency(appSettings.baseCurrency));
          this.store.dispatch(new LoadCurrencyRates(appSettings.baseCurrency));
        }
      })
    ).subscribe();

    this.appSettings$ = this.store.pipe(select(getAppSettings));
  };

  private setWidgetDisplay = () => {

    const subs = this.store.pipe(
      select(getRatesLoaded),
      tap((loaded) => {
        if (loaded) {
          //this.collapseWidget = true;
          this._commonService.collapseWidget$.next(true);
        }
        else {
          //this.collapseWidget = false;
          this._commonService.collapseWidget$.next(false);
        }
      })
    ).subscribe();

    this.allSubs.add(subs);
  };

  private setupUserLoginRefresh = () => {
    if (this._authService.isAuthenticated() && this._authService.IsRememberMe) {
      this._authService.rememberMeObs.next(true);
    }

    const subs = this._authService.rememberMeObs.pipe(
      switchMap((rememberMe) => {
        if (rememberMe) {
          const timeout: number = this._configService.SessionTimeout - 3;
          let counter: number = -1;
          return timer(0, 60000).pipe(
            tap((val) => { counter += 1; }),
            filter(val => counter >= timeout),
            switchMap(val => this._authService.refreshAuthToken(this._commonService.getCurrentLanguage())),
            map((user) => user && user !== null && user.userId),
            tap((isRefreshed) => {
              counter = -1;
              if (!isRefreshed) {
                this.store.dispatch(logoutUser());

                if (this._commonService.isProtectedRoute(this.currentUrl)) {
                  this.dialog.closeAll();
                  this._router.navigate([this._commonService.getTranslatedRouteByKey(PAGES.LOGIN)], { queryParams: { returnurl: this.currentUrl } });
                }
              }
            })
          );
        }
        else {
          return ObservableOf(false);
        }
      })
    ).subscribe();

    this.allSubs.add(subs);
  };

  private setupSessionTimeoutDialog = () => {

    const subs = this._commonService.sessionObs.pipe(
      switchMap((reset) => {
        if (reset) {
          const tmr = timer(0, 60000);
          const timeout: number = this._configService.SessionTimeout;

          return tmr.pipe(
            tap((val) => {
              if (val >= timeout - 3) {
                this._authService.refreshAuthToken(this._commonService.getCurrentLanguage()).pipe(first()).subscribe();
              }

              if (val >= timeout) {
                if (!this._authService.IsRememberMe) {
                  this.store.dispatch(logoutUser());
                }
                this.dialog.closeAll();
                const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
                  minWidth: 320,
                  maxWidth: 600,
                  disableClose: true,
                  closeOnNavigation: false,
                  width: this._commonService.deviceBreakpoint == 'xs' ? '100vw' : '',
                  height: 'auto',
                  panelClass: this._commonService.deviceBreakpoint == 'xs' ? 'mobile-dialog' : '',
                  data: { isMobileDevice: this._commonService.IsMobileDevice }
                });

                dialogRef.afterClosed().pipe(
                  tap(() => {
                    this.dialog.closeAll();
                    this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.RENT_A_CAR, this._route.snapshot.firstChild.params.lang)).then(
                      (status) => {
                        if (status) {
                          this.store.dispatch(new ResetRates());
                          this.store.dispatch(new ResetExtras());
                        }
                      }
                    );
                  }),
                  first()
                ).subscribe();
              }

            }),
            filter(val => val >= timeout),
            map(val => val >= timeout),
            take(1)
          );
        }
        else {
          return ObservableOf(false);
        }
      })
    ).subscribe();

    this.allSubs.add(subs);

  };

  private setupSnackBar = () => {

    const subs = this._commonService.snackbarObs.pipe(
      tap((args) => {
        this.openSnackBar(args.message, args.action, args.config);
      })
    ).subscribe();

    this.allSubs.add(subs);

  };

  private setupBottomSheet(): void {

    //const subs = this._commonService.bottomSheetObs.pipe(
    //  tap((args) => {
    //    if (args && args.message && args.message != "") {
    //      setTimeout(() => {
    //        const bsRef = this._bottomSheet.open(NotifyBottomComponent);
    //        if (args.duration > 0) {
    //          setTimeout(() => {
    //            bsRef.dismiss();
    //          }, args.duration);
    //        }
    //      }, 500);
    //    }
    //  })
    //).subscribe();

    const subs = this._commonService.bottomSheetObs.pipe(
      filter(args => args && args.message && args.message != ""),
      delayWhen(args => interval(args.delay)),
      map((args) => {
        return {
          dismissDuration: args.duration,
          bsRef: this._bottomSheet.open(NotifyBottomComponent, { autoFocus: false })
        };
      }),
      tap((args) => {
        timer(args.dismissDuration).pipe(
          tap(() => {
            args.bsRef.dismiss();
          }),
          take(1)
        ).subscribe();
      })
    ).subscribe()

    this.allSubs.add(subs);
  }

  onSwitchLanguage(language: string) {
    //this._commonService.IsAnimationsDisabled = true;
    this._commonService.currentLanguage$.next(language);
    this.store.dispatch(new SetAppSettingsLanguage(language));

    let params: Params;

    if (this._route && this._route.snapshot && this._route.snapshot.firstChild && this._route.snapshot.firstChild.params) {
      params = this._route.snapshot.firstChild.params;
    }

    const url = this._commonService.getTranslatedRouteByUrl(this._router.url, language, params);

    if (this.window) {
      this.window.location.href = url;
    }
    else {
      this._translateService.use(language);

      if (this._router.url != url) {
        this._router.navigateByUrl(url);
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $.scrollUp({
        animation: 'fade',
        activeOverlay: false,
        scrollImg: true,
        easingType: 'easeInOutQuart'
      });
    }, 500);
  }

  getDepth = (outlet) => {
    return outlet.activatedRouteData['depth']
  };

  openSnackBar(message: string, action: string = "", config: MatSnackBarConfig) {
    if (message && message != "") {
      this.snackBar.open(message, action, config);
    }
  }

  isBreakpoint = (alias: string): boolean => {
    if (typeof $ === "function") {
      return $('.device-' + alias).is(':visible');
    }
    return false;
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDeviceType();
  }

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }
}
