import { Component, OnInit, OnChanges, ChangeDetectionStrategy, Input, SimpleChanges } from '@angular/core';

import { Observable, of } from 'rxjs';

import { CommonService } from 'src/app/core/services';

import { BranchDto } from 'src/app/core/models';

@Component({
  selector: 'airport-block',
  templateUrl: './airport-block.component.html',
  styleUrls: ['./airport-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirportBlockComponent implements OnInit, OnChanges {

  @Input() lang: string = "en";
  @Input() branch: BranchDto = null;

  isMobileDevice$: Observable<boolean> = of(false);

  branchInfoLink: string = "";

  constructor(private _commonService: CommonService) {
  }

  ngOnInit(): void {
    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.branch && changes.branch.currentValue && changes.branch.currentValue.code) {
      if (this.branch) {
        let extLink = this.branch.externalLinks.find(x => x.language == this.lang && x.name.toLowerCase() == 'moreinfo');

        if (extLink) {
          this.branchInfoLink = CommonService.MainSiteUrl + "/" + extLink.link;
        }
      }
    }
  }
}
