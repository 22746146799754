<div *ngIf="branch?.isAirportBranch" class="airport-block" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
  <div *ngIf="branch?.code.toUpperCase() === 'YUL'" class="box airport">
    <h4>
      {{'step4-confirm.shuttle-info-heading' | translate}}
    </h4>
    <img class="img-shuttle-car hidden-xs hidden-sm" src="assets/images/confirmation/shuttle-car-{{branch.code | lowercase}}.png" alt="airport" />
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-4 img-airport">
        <img src="assets/images/confirmation/airport-image-{{branch.code | lowercase}}.png" alt="airport" />
      </div>
      <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8 text-justify">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 text" [innerHTML]="'step4-confirm.shuttle-info-detail-' + branch?.code.toLowerCase() | translate">
          </div>
          <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5"></div>
        </div>
        <div class="tip" [innerHTML]="'step4-confirm.shuttle-info-tip-' + branch?.code.toLowerCase() | translate:{'branchInfoUrl':branchInfoLink}">
        </div>
      </div>
    </div>
  </div> <!-- end airport block-->

  <div *ngIf="branch?.code.toUpperCase() === 'YTZ'" class="box airport">
    <h4>
      {{'step4-confirm.shuttle-info-heading' | translate}}
    </h4>
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-4 img-airport">
        <img class="img-airport" src="assets/images/confirmation/airport-image-{{branch.code | lowercase}}.png" alt="airport" />
      </div>
      <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8 text-justify">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 text" [innerHTML]="'step4-confirm.shuttle-info-detail-' + branch?.code.toLowerCase() | translate">
          </div>
          <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            <img class="img-shuttle-car hidden-xs hidden-sm" style="width:100%;" src="assets/images/confirmation/shuttle-car-ytz.png" alt="porter shuttle" />
          </div>
        </div>
      </div>
    </div>
  </div> <!-- end airport block-->
</div>
