import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Subscription, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfirmWithPwdDialogService } from './dlg-confirm-with-pwd.service';
import { CommonService } from 'src/app/core/services';

import { NoEvent, OkEvent } from 'src/app/core/events/dialog.events';
import { NOTIFY_TYPES } from 'src/app/core/enums';
import { ErrorDto } from 'src/app/core/models';

@Component({
  selector: 'confirm-with-pwd-dialog',
  templateUrl: './dlg-confirm-with-pwd.component.html',
  styleUrls: ['./dlg-confirm-with-pwd.component.scss']
})
export class ConfirmWithPwdDialogComponent implements OnInit, OnDestroy {

  private allSubs: Subscription = new Subscription();

  isMobileDevice: boolean = false;

  confirmMsg: string = "dlg-confirm-with-pwd.confirm-msg-default";

  showSpinner: boolean = false;

  step: number = 0;
  pwdFieldType: string = 'password';

  password = new UntypedFormControl('', Validators.required);

  notify$: BehaviorSubject<{
    notifyType: NOTIFY_TYPES,
    notification: string,
    validationErrors: ErrorDto[]
  }> = new BehaviorSubject({
    notifyType: NOTIFY_TYPES.Hidden,
    notification: "",
    validationErrors: []
  });

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dlgService: ConfirmWithPwdDialogService,
    private _commonService: CommonService
  ) {

    this.isMobileDevice = data.isMobileDevice as boolean;

    if (data.dialogType && data.dialogType == "del-acc") {
      this.confirmMsg = "dlg-confirm-with-pwd.confirm-msg-del-acc"
    }
  }

  ngOnInit(): void {
    const subs = this._dlgService.processState.pipe(
      tap((state) => {
        if (state.isCompleted) {
          this.showSpinner = false;
          if (state.errors && state.errors.length > 0) {
            this.notify$.next({ notifyType: NOTIFY_TYPES.Error, notification: state.errors[0].errorMessage, validationErrors: [] });
          }
        }
      })
    ).subscribe();
    this.allSubs.add(subs);
  }

  onButtonClick = (val) => {
    this._dlgService.startProcess();
    this.notify$.next({ notifyType: NOTIFY_TYPES.Hidden, notification: "", validationErrors: [] });

    if (val === 0) {
      this.step = 1;
    }
    else if (val === 1) {
      this.password.markAsTouched({ onlySelf: true });

      if (!CommonService.isNullOrWhiteSpace(this.password.value)) {
        this.showSpinner = true;
        this._dlgService.triggerEvent(new OkEvent(this.password.value));
      }
    }
    else {
      this._dlgService.triggerEvent(new NoEvent(-1));
    }
  };

  closeDialog(dialogResult): void {
    this.dialogRef.close(dialogResult);
  }

  ngOnDestroy(): void {
    if (this.allSubs)
      this.allSubs.unsubscribe();
  }
}
