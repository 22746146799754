<div *ngIf="coveragePanel$ | async as coveragePanel" class="coverage-block" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
  <div *ngIf="coveragePanel !== ''" class="seperator"></div>
  <div *ngIf="coveragePanel !== ''" class="box coverage">
    <h4>
      {{'coverage.car-rental-cvrg' | translate}}
    </h4>
    <div *ngIf="coveragePanel === 'nocoverage'">
      <div class="text-block coverage-icon">
        <span [innerHTML]="'coverage.no-cvrg-msg' | translate"></span>
        <br /><br />
        <span>
          <strong><span [innerHTML]="'coverage.no-cvrg-opt-in' | translate:{vehicleType:translatedVehicleType}"></span></strong>
        </span>
      </div>
      <div class="radio-options">
        <mat-radio-group value="1">
          <div>
            <mat-radio-button value="1" class="normal-wrap-label"><span [innerHTML]="'coverage.no-cvrg-own' | translate"></span>&nbsp;<span class="info" [tooltip]="'coverage.no-cvrg-own-tooltip' | translate" placement="right"><i class="fas fa-info-circle" aria-hidden="true"></i></span></mat-radio-button>
          </div>
          <div class="seperator"></div>
          <div>
            <mat-radio-button #rdoAddCoverage value="2" class="normal-wrap-label">{{'coverage.no-cvrg-add' | translate}}</mat-radio-button><span class="add"><a (click)="rdoAddCoverage.checked = true;onAddCoverageClick(reservation);">{{'buttons.add' | translate}} <i class="fas fa-plus-circle" aria-hidden="true"></i></a></span>
          </div>
        </mat-radio-group>
      </div>
    </div>

    <div *ngIf="coveragePanel === 'full' || coveragePanel === 'nopap'">
      <div class="text-block coverage-icon">
        <span *ngIf="coveragePanel === 'full'" [innerHTML]="'coverage.full-cvrg-msg' | translate"></span>
        <span *ngIf="coveragePanel === 'nopap'" [innerHTML]="'coverage.no-pap-msg' | translate:{vehicleType:translatedVehicleType,deductibleAmt:(reservation.rate.vehicle.vehicleType.toLowerCase() == 'truck' ? '1000$' : '500$')}"></span>
        <br />
        <br />
        <a href="{{mainSiteUrl}}{{'links.coverage' | translate}}" target="_blank" rel="noopener noreferrer" class="btn-main btn-orange normal">{{'buttons.more' | translate}}</a>
      </div>
    </div>

    <div *ngIf="coveragePanel === 'addcdw'">
      <div class="text-block coverage-icon">
        <span [innerHTML]="'coverage.no-cdw-msg' | translate"></span>
        <br /><br />
        <span><strong>{{'coverage.no-cdw-proof' | translate}}</strong></span>
      </div>
      <div *ngIf="(allExtras$ | async) as allExtras" class="radio-options">
        <mat-radio-button checked="true" class="normal-wrap-label"><span [translate]="'coverage.no-cdw-add'" [translateParams]="{coverageRate:getExtraRatePerPeriodByExtraCode('CDW', (unSelectedExtras$ | async), reservation.rate.rateDetail.ratePlan)}"></span></mat-radio-button><span class="add"><a (click)="onAddExtraClick('CDW', allExtras, reservation)">{{'buttons.add' | translate}} <i class="fas fa-plus-circle" aria-hidden="true"></i></a></span>
      </div>
    </div>

    <div *ngIf="coveragePanel === 'addpap'">
      <div class="text-block coverage-icon">
        <span [innerHTML]="'coverage.add-pap-msg' | translate:{vehicleType:translatedVehicleType,deductibleAmt:(reservation.rate.vehicle.vehicleType.toLowerCase() == 'truck' ? '1000$' : '500$')}"></span>
        <br /><br />
        <span><strong>{{'coverage.add-pap-opt-in' | translate}}</strong></span>
      </div>
      <div *ngIf="(allExtras$ | async) as allExtras" class="radio-options">
        <mat-radio-button checked="true" class="normal-wrap-label"><span [translate]="'coverage.add-pap-add'" [translateParams]="{coverageRate:getExtraRatePerPeriodByExtraCode('PAP', (unSelectedExtras$ | async), reservation.rate.rateDetail.ratePlan)}"></span></mat-radio-button><span class="add"><a (click)="onAddExtraClick('PAP', allExtras, reservation)">{{'buttons.add' | translate}} <i class="fas fa-plus-circle" aria-hidden="true"></i></a></span>
      </div>
    </div>
  </div> <!-- end coverage block-->
</div>
