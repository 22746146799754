<div mat-dialog-content class="login-dialog" [ngClass]="{'mobile':isMobileDevice}">
  <button type="button" mat-icon-button aria-label="close" class="btn-close" (click)="closeDialog('cancel')">
    <i class="fas fa-times" aria-hidden="true"></i>
  </button>
  <div class="row header">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-left-0">
      <h4>{{'login.title' | translate}}</h4>
    </div>
  </div>
  <div class="seperator hidden-xs"></div>
  <form [formGroup]="inputForm" (ngSubmit)="onSubmit(inputForm.value)" novalidate>
    <div class="input-box">
      <div *ngIf="continueAsGuest" class="text">
        <i class="fas fa-info fa-2x fa-pull-left" aria-hidden="true"></i>{{'common.login-continue-guest-note' | translate}}
      </div>
      <div class="form-field-box">
        <mat-form-field class="input-full-width">
          <mat-label>{{'common.email-adr' | translate}}</mat-label>
          <input matInput type="email" placeholder="{{'common.email-adr' | translate}}" formControlName="email" name="email" autocomplete="email" required [autofocus]="email?.length <= 0" />
          <mat-error *ngIf="inputForm.get('email').hasError('pattern')">
            <span [innerHTML]="'validations.invalid-email' | translate"></span>
          </mat-error>
          <mat-error *ngIf="inputForm.get('email').hasError('required')">
            <span [innerHTML]="'validations.req-email' | translate"></span>
          </mat-error>
          <span matPrefix class="color-orange"><i class="far fa-envelope fa-fw" aria-hidden="true"></i>&nbsp;</span>
          <button type="button" *ngIf="inputForm.get('email').value" matButton matSuffix mat-icon-button aria-label="Clear" (click)="inputForm.get('email').setValue('')">
            <i class="fas fa-times" aria-hidden="true"></i>
          </button>
        </mat-form-field>
      </div>
      <div class="seperator"></div>
      <div class="form-field-box" CapsLockState CapsLockText="{{'common.caps-on' | translate}}">
        <mat-form-field class="input-full-width">
          <mat-label>{{'common.password' | translate}}</mat-label>
          <input matInput [type]="pwdFieldType" placeholder="{{'common.password' | translate}}" formControlName="password" name="password" required [autofocus]="email?.length > 0" />
          <mat-error *ngIf="inputForm.get('password').hasError('required')">
            <span [innerHTML]="'validations.req-pwd' | translate"></span>
          </mat-error>
          <span matPrefix class="color-orange"><i class="fas fa-key fa-fw" aria-hidden="true"></i>&nbsp;</span>
          <button type="button" *ngIf="inputForm.get('password').value" matSuffix mat-icon-button [attr.aria-label]="'common.show-hide-pwd' | translate" (click)="pwdFieldType = pwdFieldType == 'password' ? 'text' : 'password'">
            <i *ngIf="pwdFieldType === 'text'" class="far fa-eye icon-button" aria-hidden="true"></i>
            <i *ngIf="pwdFieldType === 'password'" class="far fa-eye-slash icon-button" aria-hidden="true"></i>
          </button>
        </mat-form-field>
      </div>
      <div class="seperator2x text-right">
        <button mat-button type="button" color="primary" (click)="onForgotPwdClick()">
          {{'login.forgot-pwd' | translate}}
        </button>
      </div>
      <div class="text-center">
        <button type="submit" class="btn-main btn-orange" [ngClass]="{'full-width':!continueAsGuest, 'normal':continueAsGuest}">{{'buttons.login' | translate}}&nbsp;&nbsp;<i class="fas fa-arrow-right" aria-hidden="true"></i></button>
        <ng-container *ngIf="continueAsGuest">
          &nbsp;&nbsp;
          <button type="button" class="btn-main btn-white normal" (click)="closeDialog('continueAsGuest')">{{'step3-customer-details.cont-as-guest' | translate | uppercase}}</button>
        </ng-container>
      </div>
    </div>
  </form>
  <container-spinner [showSpinner]="isProcessing$ | async" diameter="70"></container-spinner>
</div>
