// ***** Angular Modules ***** //
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

import localeFr from '@angular/common/locales/fr';

// ***** Angular Material Modules ***** //
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// ***** External/Third Party Modules ***** //
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

////import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// ***** Local/Custom Modules ***** //
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LoaderLiteModule } from './components/loader-lite';
import { RezWidgetModule } from './components/rez-widget';
import { SessionExpiredDialogModule } from './components/dlg-session-expired';
import { NanorepModule, SnapengageModule, NotifyBottomModule, AppHeaderComponent, RezNavComponent, AppFooterComponent } from './components';

// ***** Other Imports ***** //
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout.component';
import { guards } from './guards';
import { reducers, effects } from 'src/app/store';
import { ErrorHandlerService } from 'src/app/core/error-handlers/error-handler-service';
import { MyMonitoringService } from 'src/app/core/services';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

const APP_COMPONENTS = [
  AppHeaderComponent,
  RezNavComponent,
  AppFooterComponent
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
        FullLayoutComponent,
        EmptyLayoutComponent,
        ...APP_COMPONENTS
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatBottomSheetModule,
        MatProgressBarModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: false
            }
        }),
        EffectsModule.forRoot(effects),
        //StoreDevtoolsModule.instrument(),
        LoaderLiteModule,
        RezWidgetModule,
        CoreModule.forRoot(),
        SessionExpiredDialogModule,
        NanorepModule,
        SnapengageModule,
        NotifyBottomModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerWhenStable:30000' })], providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        MyMonitoringService,
        ...guards,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ] })
export class AppModule { }
