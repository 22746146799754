<div *ngIf="vehicle" class="other-features" [ngClass]="{'mobile':(isMobileDevice$ | async)}">
  <span *ngIf="!hideFuel">
    <img src="assets/images/rent-a-car/fuel.png" alt="fuel" />&nbsp;&nbsp;<strong>{{'common.city' | translate}}</strong>&nbsp;&nbsp;{{vehicle.mileageCity}}l/100{{vehicle.mileageUnit.toLowerCase()}}&nbsp;&nbsp;&nbsp;<strong>{{'common.highway' | translate}}</strong>&nbsp;&nbsp;{{vehicle.mileageHighway}}l/100{{vehicle.mileageUnit.toLowerCase()}}
  </span>
  <span *ngIf="!hideRange">
    <i class="fas fa-tachometer-alt" aria-hidden="true"></i>&nbsp;&nbsp;{{vehicle.mileageRange}}{{vehicle.mileageUnit.toLowerCase()}}&nbsp;{{'common.mileage-range' | translate}}
  </span>
  <span *ngIf="!hideElectric && vehicle.isElectric">
    <i class="fas fa-leaf" aria-hidden="true"></i>&nbsp;&nbsp;{{'common.veh-elec' | translate}}
  </span>
  <span *ngIf="!hideLuggageCap && vehicle.tonsCapacity && vehicle.tonsCapacity !== ''">
    <img src="assets/images/rent-a-car/ton.png" alt="ton" />&nbsp;&nbsp;{{vehicle.tonsCapacity}}
  </span>
  <span *ngIf="!hideLuggageCap && (!vehicle.tonsCapacity || vehicle.tonsCapacity === '')">
    <i class="fas fa-suitcase" aria-hidden="true"></i>&nbsp;&nbsp;{{vehicle.luggageCapacity}}
  </span>
  <span *ngIf="!hidePassengerCap">
    <i class="fas fa-user" aria-hidden="true"></i>&nbsp;&nbsp;{{vehicle.passengerCapacity}}
  </span>
  <span *ngIf="!hideTransmission">
    <img src="assets/images/rent-a-car/transmission.png" alt="transmission" />&nbsp;&nbsp;{{vehicle.isAutomatic ? ('common.automatic' | translate) : ('common.manual' | translate)}}
  </span>
  <span *ngIf="!hideACInfo">
    <i class="fas fa-snowflake" aria-hidden="true"></i>&nbsp;&nbsp;{{vehicle.hasAC ? ('common.ac' | translate) : ('common.no-ac' | translate)}}
  </span>
  <span *ngIf="!hideMinDriverAge">
    <i class="fas fa-id-card" aria-hidden="true"></i>&nbsp;&nbsp;{{vehicle.minimumAgeOfDriver}} {{'common.years' | translate}} +
  </span>
</div>
