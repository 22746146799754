import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { LoggingService } from '../services';

import { LOG_TYPES } from '../enums';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  constructor(private _logger: LoggingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let response: { status: string, payload: any };

    // extend server response observable with logging
    return next.handle(req)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: evt => {
            response = evt instanceof HttpResponse
              ? { status: 'succeeded', payload: evt }
              : { status: '', payload: null };
          },
          // Operation failed; error is an HttpErrorResponse
          error: err => {
            response = {
              status: 'failed', payload: err
            };
          }
        }),
        catchError(e => throwError(() => e)),
        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}"
        ${response.status} in ${elapsed} ms.`;

          this._logger.log(LOG_TYPES.LOG, msg);
        })
      );
  }
}
