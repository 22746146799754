import { Component, OnInit, AfterViewInit, HostListener, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Observable, combineLatest, of, timer } from 'rxjs';
import { map, tap, first, filter, take } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State, getSelectedExtrasByGroup, getExtrasByGroup, getSelectedExtras, SetSelectedRate, UpdateExtras, getRateById, getSelectedExtrasSource, getSelectedRate } from 'src/app/store';

import { CoverageDialogComponent } from '../dlg-coverage/dlg-coverage.component';

import { Currency, RateDto, RateExtra } from 'src/app/core/models';
import { CommonService, RateService, RateExtraService } from 'src/app/core/services';
import { CoverageDialogService } from '../dlg-coverage/dlg-coverage.service';
import { AddEvent } from 'src/app/core/events/dialog.events';

@Component({
  selector: 'rental-summary-sidebar',
  templateUrl: './rental-summary-sidebar.component.html',
  styleUrls: ['./rental-summary-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RentalSummarySidebarComponent implements OnInit, AfterViewInit {

  selectedCoverageExtras$: Observable<RateExtra[]>;
  unSelectedCoverageExtras$: Observable<RateExtra[]>;
  //freeCoverageExtras$: Observable<RateExtra[]>;

  rentalSummaryFixed: boolean = false;
  private isRWidetStickedToTop: boolean = false;

  @Output() changeVehicleClick = new EventEmitter<string>();
  @Output() paymentModeChange = new EventEmitter<number>();

  @Input() baseCurrency: string;
  @Input() selectedCurrency: Currency;
  @Input() acceptPrepayment: boolean = true;
  @Input() selectedRate: RateDto;
  @Input() containerHeightWithRespectTo: HTMLElement;

  @ViewChild('divRentalSummaryContainer', { static: true }) divRentalSummaryContainer: ElementRef;
  @ViewChild('divRentalSummary', { static: true }) divRentalSummary: ElementRef;

  //chargeablePrice: number = 0.0;

  @Input() rezInfo: { confirmationNo: string, renterFullName: string };

  readonly mainSiteUrl: string = CommonService.MainSiteUrl;

  totalRateExtras: number = 0.0;
  totalAdditionalExtras: number = 0.0;
  chargeablePrice$: Observable<number>;

  isMobileDevice$: Observable<boolean> = of(false);
  acceptPrepayment$: Observable<boolean> = of(false);


  constructor(
    private renderer: Renderer2,
    public dialog: MatDialog,
    private _dlgService: CoverageDialogService,
    private _commonService: CommonService,
    private _rateService: RateService,
    private _rateExtraService: RateExtraService,
    private store: Store<State>) { }

  ngOnInit() {

    this.isMobileDevice$ = this._commonService.IsMobileDeviceAsObservable;
    this.acceptPrepayment$ = of(this.acceptPrepayment);

    this.setExtraCharges(this.selectedRate);

    this.chargeablePrice$ = combineLatest([
      this.store.pipe(select(getRateById(this.selectedRate.id))),
      this.store.pipe(select(getSelectedExtrasSource))
    ],
      (selectedRate, selectedExtras) => {
        if (selectedRate?.id) {
          const updatedSelectedExtras = this._rateExtraService.getUpdatedSelectedExtras(selectedExtras, selectedRate.rateDetail, selectedRate.vehicle.mileageCap, selectedRate.totalFreeMiles);

          const updatedRate = this._rateService.getUpdatedRate(selectedRate, updatedSelectedExtras) as RateDto;
          return updatedRate.rateSummary.totalCharges;
        }
        else {
          return selectedRate?.rateSummary ? selectedRate.rateSummary.totalCharges : 0.0;
        }
      });

    this.selectedCoverageExtras$ = this.store.pipe(
      select(getSelectedExtrasByGroup("coverage")),
      map((extras: RateExtra[]) => {
        const exists = !!(extras && extras.length > 0);

        if (exists) {
          const cumulativeParent = extras.find(x => x.cumulative.length > 0 && x.autoChecked);
          if (cumulativeParent) {
            return extras.filter(x => cumulativeParent.cumulative.indexOf(x.code) == -1 && !x.isFree);
          }
          else {
            return extras.filter(x => !x.isFree);
          }
        }
        else {
          return extras;
        }
      })
    );

    this.unSelectedCoverageExtras$ = this.store.pipe(
      select(getExtrasByGroup("coverage")),
      map((extras: RateExtra[]) => {
        const exists = !!(extras && extras.length > 0);

        if (exists) {
          if (extras.find(x => x.autoChecked && !x.isFree)) {
            const extra = extras.find(x => !x.autoChecked && x.cumulative.length == 0);
            return extra ? [...[], extra] : [];
          }
          else
            return [];
        }
        else {
          return extras;
        }
      })
    );

    this.renderer.listen('window', 'widgetStick', (e) => {
      this.isRWidetStickedToTop = e.detail.isStickedToTop;
    });
  }

  ngAfterViewInit(): void {
    this.setContainersHeight();
    this.renderer.removeClass(this.divRentalSummaryContainer.nativeElement, "collapse");
  }

  onAddCoverageClick = ($eventdata) => {
    let dialogRef = this.dialog.open(CoverageDialogComponent, {
      autoFocus:false,
      maxWidth: 698,
      width: this._commonService.deviceBreakpoint == 'xs' ? '100vw' : '',
      height: 'auto',
      panelClass: this._commonService.deviceBreakpoint == 'xs' ? 'mobile-dialog' : '',
      data: { isMobileDevice: this._commonService.IsMobileDevice, baseCurrency: this.baseCurrency, selectedCurrency: this.selectedCurrency, rateDetail: this.selectedRate.rateDetail, mileageCap: this.selectedRate.vehicle.mileageCap, totalFreeMiles: this.selectedRate.totalFreeMiles }
    });

    this._dlgService.events.pipe(
      tap((event) => {
        if (event instanceof AddEvent) {
          const extras: RateExtra[] = event.data;

          if (extras && extras.length > 0) {
            this.store.dispatch(new UpdateExtras(extras));
            this.updateSelectedRate(dialogRef);
          }
        }
      }),
      first()
    ).subscribe();
  };

  private updateSelectedRate = (dialogRef: any) => {
    this.store.pipe(
      select(getSelectedExtras),
      tap((selectedExtras) => {
        const isLoaded = !!(selectedExtras && selectedExtras.length > 0);

        if (isLoaded) {
          this.store.dispatch(new SetSelectedRate(this._rateService.getUpdatedRate(this.selectedRate, selectedExtras) as RateDto));
        }
      }),
      first()
    ).subscribe();

    combineLatest([
      this.store.pipe(
        select(getSelectedRate)
      ),
      timer(1000) // To delay at least 1000ms to see spinner animation.
    ],
      (rate, _t) => rate
    ).pipe(
      filter(rate => !!rate && !!rate.id),
      tap( rate => this.setExtraCharges(rate)),
      take(1)
    ).subscribe({
      complete: () => dialogRef.close()
   });
  };

  private setExtraCharges = (rate: RateDto) => {
    if (rate && rate.id) {
      this.totalRateExtras = 0.0;
      const rateExtras = rate.extraRates.filter(x => x.groupName.toLowerCase() == "rate");
      for (let i = 0; i < rateExtras.length; i++) {
        this.totalRateExtras += rateExtras[i].totalCharge;
      }

      this.totalRateExtras = Number(this.totalRateExtras.toFixed(2));

      this.totalAdditionalExtras = rate.rateSummary.totalExtras - this.totalRateExtras;
    }
  };

  ChangeVehClick = () => {
    this.changeVehicleClick.emit("");
  };

  PaymentModeChange = (event) => {
    if (event) {
      this.paymentModeChange.emit(event.value);
    }
  };

  @HostListener("window:scroll", [])
  onWindowScroll = () => {

    this.setContainersHeight();
    if (!this._commonService.IsMobileDevice) {
      let divRTC = this.containerHeightWithRespectTo;
      let divRSC = this.divRentalSummaryContainer.nativeElement;
      let divRS = this.divRentalSummary.nativeElement;

      if (divRS && divRTC && Math.floor(divRS.getBoundingClientRect().height) + 50 < Math.floor(divRTC.getBoundingClientRect().height)) {

        if (divRS && divRSC) {
          let divRSCRect = divRSC.getBoundingClientRect();
          let divRSRect = divRS.getBoundingClientRect();

          let topMargin: number = 10;

          if (this.isRWidetStickedToTop) {
            topMargin = 90;
          }

          if (Math.floor(divRSCRect.top) >= Math.floor(divRSRect.top) && Math.floor(divRSRect.top) >= topMargin) {
            this.rentalSummaryFixed = false;
            this.renderer.setStyle(divRS, 'top', '0');
            this.renderer.setStyle(divRS, 'bottom', '');
          }
          else if (Math.floor(divRSCRect.bottom) >= Math.floor(divRSRect.bottom) && Math.floor(divRSRect.top) >= topMargin) {
            this.rentalSummaryFixed = true;
            this.renderer.setStyle(divRS, 'top', topMargin + 'px');
            this.renderer.setStyle(divRS, 'bottom', '');
          }
          else if (Math.floor(divRSCRect.bottom) <= Math.floor(divRSRect.bottom)) {
            this.rentalSummaryFixed = false;
            this.renderer.setStyle(divRS, 'top', '');
            this.renderer.setStyle(divRS, 'bottom', '0');
          }
          else {
            this.rentalSummaryFixed = true;
            this.renderer.setStyle(divRS, 'top', topMargin + 'px');
            this.renderer.setStyle(divRS, 'bottom', '');
          }
        }
      }
      else {
        if (this.rentalSummaryFixed) {
          this.rentalSummaryFixed = false;
          this.renderer.setStyle(divRS, 'top', '0');
          this.renderer.setStyle(divRS, 'bottom', '');
        }
      }
    }
  }

  private setContainersHeight = () => {
    let divRTC = this.containerHeightWithRespectTo;
    let divRSC = this.divRentalSummaryContainer.nativeElement;
    let divRS = this.divRentalSummary.nativeElement;

    if (divRSC && divRS && divRSC.getBoundingClientRect().width > 0)
      this.renderer.setStyle(divRS, 'width', divRSC.getBoundingClientRect().width + 'px');

    if (this._commonService.IsMobileDevice && divRSC && divRS) {
      this.renderer.setStyle(divRSC, 'height', 'auto');
      this.renderer.setStyle(divRS, 'position', 'relative');
    }
    else if (divRTC && divRSC && Math.floor(divRTC.getBoundingClientRect().height) != Math.floor(divRSC.getBoundingClientRect().height)) {
      const height = Math.max(divRTC.getBoundingClientRect().height, divRS.getBoundingClientRect().height);
      this.renderer.setStyle(divRSC, 'height', height + 'px');
      this.renderer.setStyle(divRS, 'position', 'absolute');
    }
  };
}
