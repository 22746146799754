<div class="rez-page-header" [ngClass]="{'mobile':isMobileDevice$ | async}">
  <div class="row">
    <div class="col-md-7 col-lg-7" [ngClass]="{'col-xs-12 col-sm-7':showBtnRecommend,'col-xs-7 col-sm-8':!showBtnRecommend}">
      <h3>{{pageTitle}}</h3>
    </div>
    <div class="col-md-5 col-lg-5 padding-0" [ngClass]="{'col-xs-12 col-sm-5':showBtnRecommend,'col-xs-5 col-sm-4':!showBtnRecommend}">
      <div *ngIf="showBtnRecommend" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a class="btn-recommmend" (click)="onRecommendThisClick()" [ngClass]="{'pull-right':(isMobileDevice$ | async) === false}">
          <i class="fas fa-user fa-2x fa-fw" aria-hidden="true"></i>
          <span>{{'rez-page-header.recommend-to-friend' | translate}}</span>
        </a>
      </div>
      <div [ngClass]="{'col-md-6 col-lg-6 col-xs-6 col-sm-6':showBtnRecommend,'col-md-12 col-lg-12 col-xs-12 col-sm-12 text-right':!showBtnRecommend}">
        <div *ngIf="selectedCurrency$ | async as selectedCurrency" class="currency-panel">
          <label class="text-right" tooltip="{{'common.cnvrt-prices-msg' | translate}}" placement="bottom-right">{{'common.currency' | translate}} <i class="fas fa-info-circle" aria-hidden="true"></i></label>
          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle btn-currency" [disabled]="(isCurrencyRatesLoaded$ | async) === false" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <span data-bind="label">{{selectedCurrency.displayText}}</span>
              <span>&nbsp;&nbsp;<i class="fas fa-chevron-down" aria-hidden="true"></i></span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li *ngFor="let currency of currencies"><a (click)="onCurrencyClick(currency.code, currency.displayText, selectedCurrency)">{{currency.displayText}}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
