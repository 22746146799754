import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subscription, timer, of, throwError } from 'rxjs';
import { tap, take, catchError, map, retry } from 'rxjs/operators';

import { ServiceResult, SmsMessage } from '../models';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class SmsService {

  private readonly ACC_CONFIRM_PHONE_COUNTER_VALUE: number = 120;

  accountConfirmPhoneCounter$: BehaviorSubject<number> = new BehaviorSubject(0);
  private accountConfirmPhoneCounterSubs: Subscription;

  constructor(
    private http: HttpClient,
    private _envService: ConfigService,
  ) { }

  public sendSms(message: SmsMessage): Observable<ServiceResult> {
    const url = this._envService.getSmsApiUrl();

    return this.http.post<ServiceResult>(url, message).pipe(
      retry(3),
      catchError((error: any) => throwError(() => error.json()))
    );
  };

  public validatePhoneNumber(phoneNumber: string, isRequiredField: boolean = true): Observable<boolean> {
    const tel: string = this.getPhoneNoWithoutDialCode(phoneNumber);

    if (!isRequiredField && tel.trim() === "")
      return of(true);
    else if ((isRequiredField && tel === "") || tel.length < 10) {
      return of(false);
    }

    const formattedPhoneNo = `+${phoneNumber.replace(/[\+\s\(\)\-\.]+/, "")}`;

    const url = this._envService.getValidatorApiUrl('/phonenumber', { data: formattedPhoneNo });

    return this.http.get<ServiceResult>(url, {}).pipe(
      map(result => result.data.toLowerCase() === "valid"),
      //retry(2),
      catchError(() => { return of(false); })
    );
  };

  public formatPhoneNo(phone: string) {
    if (phone) {
      return "+" + phone.replace(/[+\s()\-.]+/g, "")
    }
    else {
      return "";
    }
  };

  public startAccountConfirmPhoneCounter(reset: boolean = false) {
    if (this.accountConfirmPhoneCounter$.value === 0 || reset === true) {
      this.stopAccountConfirmPhoneCounter();

      this.accountConfirmPhoneCounter$.next(this.ACC_CONFIRM_PHONE_COUNTER_VALUE);
      this.accountConfirmPhoneCounterSubs = timer(1000, 1000).pipe(
        tap(() => {
          this.accountConfirmPhoneCounter$.next(this.accountConfirmPhoneCounter$.value - 1);
        }),
        take(this.ACC_CONFIRM_PHONE_COUNTER_VALUE)
      ).subscribe({
        complete: () => {
          this.accountConfirmPhoneCounter$.next(0);
        }
      });
    }
  };

  public stopAccountConfirmPhoneCounter() {
    if (this.accountConfirmPhoneCounterSubs) {
      this.accountConfirmPhoneCounterSubs.unsubscribe();
      this.accountConfirmPhoneCounter$.next(0);
    }
  };


  private getPhoneNoWithoutDialCode(phoneNo: string): string {
    const re = /(\+?)(\d+)(-)(\d*)/;
    if (!re.test(phoneNo)) {
      return phoneNo.replace(/[-\s.]+/, "");
    }
    else {
      return phoneNo.replace(re, "$4");
    }
  };
}
