import { createAction, props } from '@ngrx/store';
import { Reservation } from 'src/app/core/models';

export const loadMyReservations = createAction(
  '[Reservations] Load My Reservations',
  (language: string, reset: boolean = true) => {
    return { language: language, reset: reset };
  }
);

export const loadMyReservationsSuccess = createAction(
  '[Reservations] Load My Reservations Success',
  props<{ payload: Reservation[] }>()
);

export const loadMyReservationsFail = createAction(
  '[Reservations] Load My Reservations Fail',
  props<{ payload: any }>()
);

export const updateMyReservations = createAction(
  '[Reservations] Update My Reservations',
  props<{ payload: Reservation[] }>()
);
