import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, switchMap, catchError, exhaustMap } from 'rxjs/operators';

import { LOAD_RESERVATION, LoadReservation, LoadReservationSuccess, LoadReservationFail, CREATE_RESERVATION, CreateReservation, UPDATE_RESERVATION, UpdateReservation, CANCEL_RESERVATION, CancelReservation, CancelReservationSuccess, CancelReservationFail, UpdateReservationComplete, CreateReservationSuccess, CreateReservationFail, LOAD_RESERVATION_BY_ID, LoadReservationById, UpdateReservationFail } from './reservation.action';
import { ReservationService } from 'src/app/core/services';
import { ResponseStatus, Reservation } from 'src/app/core/models';

@Injectable()
export class RezEffects {
  constructor(
    private actions$: Actions,
    private rezService: ReservationService,
  ) { }


  loadRez$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_RESERVATION),
      map((arg: LoadReservation) => { return { lang: arg.language, params: arg.payload }; }),
      switchMap(arg => {
        return this.rezService
          .fetchReservation(arg.lang, arg.params)
          .pipe(
            map(result => {

              const reservation = result.data as Reservation;
              const responseStatus = result.data as ResponseStatus;

              if (responseStatus && responseStatus.statusCode && (responseStatus.statusCode === 40091 || responseStatus.statusCode === 40092)) {
                return new LoadReservationFail(result.data);
              }
              else {
                return new LoadReservationSuccess(reservation);
              }

            }),
            catchError(error => { return of(new LoadReservationFail(error)); })
          );
      })
    ));


  loadRezById$ = createEffect(() => this.actions$
    .pipe(
      ofType(LOAD_RESERVATION_BY_ID),
      map((arg: LoadReservationById) => { return { lang: arg.language, id: arg.id }; }),
      switchMap(arg => {
        return this.rezService
          .fetchReservationById(arg.lang, arg.id)
          .pipe(
            map(result => {

              const reservation = result.data as Reservation;
              const responseStatus = result.data as ResponseStatus;

              if (responseStatus && responseStatus.statusCode && (responseStatus.statusCode === 40091 || responseStatus.statusCode === 40092)) {
                return new LoadReservationFail(result.data);
              }
              else {
                return new LoadReservationSuccess(reservation);
              }
            }),
            catchError(error => { return of(new LoadReservationFail(error)); })
          );
      })
    ));


  addRez$ = createEffect(() => this.actions$
    .pipe(
      ofType(CREATE_RESERVATION),
      map((action: CreateReservation) => { return { addRez: action.payload, currency: action.baseCurrency }; }),
      exhaustMap(arg => {
        return this.rezService
          .addReservation(arg.addRez)
          .pipe(
            map((result) => {
              const status: ResponseStatus = result.data.status as ResponseStatus;
              if (status.statusCode === 40090 || status.statusCode === 40091 || status.statusCode === 40094) {
                return new CreateReservationFail(status);
              }
              else {
                return new CreateReservationSuccess(result.data.reservation, arg.addRez.action);
              }

            }),
            catchError(err => {
              if (err instanceof HttpErrorResponse && err.error && err.error.statusCode) {
                const error: ResponseStatus = {
                  statusCode: err.error.statusCode,
                  status: err.error.status,
                  data: err.error.data
                };
                return of(new CreateReservationFail(error));
              }
              else {
                return of(new LoadReservationFail(err));
              }
            })
          );
      })
    ));


  updateRez$ = createEffect(() => this.actions$
    .pipe(
      ofType(UPDATE_RESERVATION),
      map((action: UpdateReservation) => { return { addRez: action.payload, currency: action.baseCurrency }; }),
      exhaustMap(arg => {
        return this.rezService
          .updateReservation(arg.addRez)
          .pipe(
            map(result => new UpdateReservationComplete(result.data, arg.addRez.action)),
            catchError(err => {
              if (err instanceof HttpErrorResponse && err.error && err.error.statusCode) {
                const error: ResponseStatus = {
                  statusCode: err.error.statusCode,
                  status: err.error.status,
                  data: err.error.data
                };
                return of(new UpdateReservationFail(error));
              }
              else {
                return of(new LoadReservationFail(err));
              }
            })
          );
      })
    ));


  cancelRez$ = createEffect(() => this.actions$
    .pipe(
      ofType(CANCEL_RESERVATION),
      map((action: CancelReservation) => { return { lang: action.language, params: action.payload }; }),
      exhaustMap(arg => {
        return this.rezService
          .cancelReservation(arg.lang, arg.params)
          .pipe(
            map(result => new CancelReservationSuccess(result.data)),
            catchError(error => of(new CancelReservationFail(error)))
          );
      })
    ));
}
