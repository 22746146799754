import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { SessionExpiredDialogComponent } from './dlg-session-expired.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatDialogModule, TranslateModule],
    declarations: [SessionExpiredDialogComponent],
    exports: [SessionExpiredDialogComponent]
})
export class SessionExpiredDialogModule { }
