import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { State, ResetRates, ResetExtras, ResetSelectedRate, ResetReservation } from '../store';

import { SessionService, CommonService, LoggingService } from '../core/services';
import { Session } from '../core/models/session.model';
import { PAGES, LOG_TYPES } from '../core/enums';
import { loadBranches$, loadCountries$, loadRateExtras4Rez$, loadReservation$ } from './common';

@Injectable()
export class CheckInGuard  {

  constructor(
    private _router: Router,
    private _sessionService: SessionService,
    private _commonService: CommonService,
    private _logger: LoggingService,
    private store: Store<State>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    if (route.queryParams?.reservationno && route.queryParams?.lastname) {
      const _session: Session = {
        isModify: false,
        reservation: { reservationNo: route.queryParams.reservationno, lastName: route.queryParams.lastname }
      };

      this._sessionService.setSession(_session);
    }

    const session: Session = this._sessionService.getSession();

    if (!session || session === null || !session.reservation || session.reservation === null) {
      this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.CHECK_IN_START, route.params.lang)).then(
        (status) => {
          if (status) {
            this.store.dispatch(new ResetReservation());
            this.store.dispatch(new ResetRates());
            this.store.dispatch(new ResetExtras());
            this.store.dispatch(new ResetSelectedRate());
          }
        }
      );

      return of(false);
    }
    else {
      return this.checkStore(route.params.lang).pipe(
        switchMap(() => {
          this._commonService.sessionObs.next(true);
          return of(true);
        }),
        catchError((err) => {
          this._logger.log(LOG_TYPES.ERROR, err.message);
          this._router.navigateByUrl(this._commonService.getTranslatedRouteByKey(PAGES.CHECK_IN_START, route.params.lang)).then(
            (status) => {
              if (status) {
                this.store.dispatch(new ResetRates());
                this.store.dispatch(new ResetExtras());
              }
            }
          );

          return of(false);
        })
      );
    }
  }

  checkStore(lang: string): Observable<boolean> {
    return loadReservation$(lang, this._sessionService.getSession(), this.store).pipe(
      tap((rez) => {
        if (rez) {

          this.store.dispatch(new ResetSelectedRate());

          const session: Session = {
            ...this._sessionService.getSession(),
            rateParams: rez.reservation.rateParams
          };

          this._sessionService.setSession(session);

          loadBranches$(rez.reservation.rateParams, this.store).pipe(first()).subscribe();
          loadCountries$(lang, this.store).pipe(first()).subscribe();
          loadRateExtras4Rez$(lang, this._sessionService, this.store, rez.reservation).pipe(first()).subscribe();
        }
      }),
      switchMap((rez) => {
        return of(rez.isLoaded);
      })
    );
  }
}
